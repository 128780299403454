import React, { Component } from "react";
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { Route, Switch, Redirect } from "react-router";
import * as familyspaceActions from '../../actions/familySpaceActions';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import {Sheader} from "./Sheader";
import {SMenu} from "./Smenu";
import {Sactivity} from "./social/Sactivity";
import {SlistProjects} from "./SlistProjects";
import {SFamilyLoginForm} from "./SFamilyLoginForm";
import {Smembers} from "./Smembers";
// import {Spot} from "./Spot";
import {Row,Col} from "reactstrap";
import history from "../../history";
import {Sinvitation} from './Sinvitation';
 import MediaQuery from 'react-responsive';

class FamilySpaceComponent extends Component {
  state = {
    rightDisplay: "list",
    update: false,
    login: null,
    socket: false,
    activityState: true,
  };
  checkInviteRenew () {
    const currentUrl = window.location.href;
    return currentUrl.includes("invitation") || currentUrl.includes("renew")
  }
  async componentDidMount () {
    if (!this.checkInviteRenew()){
      if (!this.props.familyspace.familySpace) {
        this.setState({login: true})
      } else {
        this.init();
        this.setState({login: false})
    }
    }else{
      this.setState({login: false})
    }
  };
  async componentDidUpdate () {
    this.init();
  }

  init () {
    if (!this.checkInviteRenew()){
      const { socket, familySpace } = this.props.familyspace;
      if (socket && familySpace && !this.state.socket){
        this.setState({
            socket: true
          }, async () => {
            socket.emit('join', `familyspace-${this.props.familyspace.familySpace.id}`);
            socket.on('chat', async data => {

              switch (data.type) {
                case "files":
                  if (data.user.id !== this.props.familyspace.user.id || (data.loadForUser && data.loadForUser === true)) {
                    setTimeout(async () => await this.props.familySpaceAction.getFiles(), 1000);
                  }
                  break;
                case "memberActivities":
                  await this.props.familySpaceAction.getMembers();
                  break;
                case "commentActivities":
                case "likes":
                case "dislikes":
                  break;
                default:
                  return undefined;
              }
            });
          });
      }
    }


  }

  goToFamily(){
    history.push(`/famille/${this.props.match.params.clientId}`)
  }

  getState = state => {
    this.setState({activityState: state});
  };

  render() {
    const { update, login} = this.state;
    // const {rightDisplay} = this.props;
    const clientId = this.props.match.params.clientId;
    const cli_logo = this.props.user && this.props.user.identity ? this.props.user.identity.organization.logo : require('../../images/space/gpg_logo_min.png');
    const sUrl = window.location.href;
    const classColumn =  sUrl.indexOf('?mail_token') > -1 ? 'col-12' : 'col-12 col-sm-8 col-md-8 col-lg-10 mx-auto';
    document.title='Espace Famille';
    return(
        <div className="FamilySpace">
          {!this.checkInviteRenew() &&<Sheader logged={login} {...this.props} />}
          {login && <SFamilyLoginForm logo={cli_logo} goTo={()=> this.init()} close={() => this.goToFamily()} />}
          {login === false &&
            <div className="FamilySpaceBody">
              <Row>
                {/* {!this.checkInviteRenew() &&
                <Col className="LeftArea col-12 col-sm-3 col-md-4 col-lg-2">
                  {!login && <MediaQuery minWidth={577}>
                    <SMenu client={clientId} display={(a)=>this.setState({rightDisplay:a ,update: !this.state.update})} />
                  </MediaQuery>}
                </Col> } */}
                <Col className={`${classColumn}`}>
                  <Switch>
                    <Route exact path="/famille/:clientId/familyspace/invitation" component={props => <Sinvitation {...props} type="invite" />} />
                    <Route exact path="/famille/:clientId/familyspace/renew" component={props => <Sinvitation {...props} type="renew" />}/>
                    <Route exact path="/famille/:clientId/familyspace/projects" render={props => <SlistProjects {...props} page="projects" update={update} archived={0}/>} />
                    <Route exact path="/famille/:clientId/familyspace/projects/archive" render={props => <SlistProjects {...props} update={update} archived={1} />} />
                    <Route exact path="/famille/:clientId/familyspace/project/:projectId" render={props => <SlistProjects {...props} page="project" update={update}  archived={0} />} />
                    <Route exact path="/famille/:clientId/familyspace/projects/compare" render={props => <SlistProjects {...props} page="compare" update={update}  archived={0} compare={true} />} />
                    <Route exact path="/famille/:clientId/familyspace/projects/compare/archive" render={props => <SlistProjects {...props} update={update}  archived={1} compare={true} />} />
                    <Route exact path="/famille/:clientId/familyspace/myFamily" render={props => <Smembers {...props} />}/>
                    <Redirect from="/famille/:clientId/familyspace/" to="/famille/:clientId/familyspace/projects" />
                  </Switch>
                </Col>
                {!this.checkInviteRenew() && <Col className={"ChatColumn flex-no-grow"}>
                  <Sactivity getState={state => this.getState(state)}/>
                </Col>}
              </Row>
            </div>
          }
        </div>
    );
  }
}

export const FamilySpace = connect(state => ({
  familyspace: state.familyService,
  configurator: state.configurator
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
  updateConfigurationAction: bindActionCreators(updateConfigurationActions, dispatch),
}))(FamilySpaceComponent);
