import * as actionTypes from '../actions/actionTypes';

const initialState = {
  id: '',
  name: '',
  favorites: [],
  roles: [],
  identity: null,
  latestConfiguration: null,
  error: null,
  family:false,
  isGpgClient: false,
  typesConcession: [],
  metaDescription: '',
  paramsFamilyConfigMax: null,
};

export default function (state = initialState, {type, payload}) {
  switch (type) {

    case actionTypes.USER_LOADED:
    return Object.assign({}, state, {
      id: payload.userData.id ?payload.userData.id:'',
      name: payload.userData.name ?payload.userData.name:'',
      favorites: payload.userData.favorites ?payload.userData.favorites:[],
      roles: payload.userData.roles ?payload.userData.roles:[],
      identity:payload.userData.identity? payload.userData.identity:{},
      family:payload.family ?payload.family :state.family,
      isGpgClient: payload.userData.isGpgClient,
      typesConcession: payload.userData.typesConcession,
      metaDescription: payload.userData.metaDescription,
      paramsFamilyConfigMax: payload.userData.paramsFamilyConfigMax,
    });

    case actionTypes.NO_USER:
      return initialState;

    case actionTypes.USER_CONFIG:
    return Object.assign({}, state, {
      latestConfiguration: payload.configData.configuration
    });

    case actionTypes.USER_CONFIG_ERROR:
    return Object.assign({}, state, {
      error: payload.error
    });
    default:
    return state;
  }
}
