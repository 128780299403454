import React, { useState, useEffect } from "react";
import * as apiService from "../services/api.service";
import Loader from "./Loader";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as uiActions from '../actions/uiActions';


function  CSSLoaderComponent (props) {
  const [cssLoaded, setCssLoaded] = useState(false);
  const [css, setCss] = useState("");
  const [styleHash, setStyleHash] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get('ClientId');
    const hash = Date.now().toString(36);
    setStyleHash(hash);
    apiService.getExternal(`themes?clientId=${clientId}`).then( resp => {
      setCss(resp.theme_styles);
      setCssLoaded(true);
       props.uiActions.isWhiteLabelActive(resp.white_label_enabled)
    })
  }, []);

  if (!cssLoaded) {
    return <Loader />;
  }

  const extranetUrl = process.env.REACT_APP_EXTRANET;
  return <>
    <div dangerouslySetInnerHTML={{ __html: css }} />
    <link href={`${extranetUrl}/css/icons.css?${styleHash}`} rel="stylesheet" />
    <link href={`${extranetUrl}/css/buttons.css?${styleHash}`} rel="stylesheet" />
    {props.children}
  </>;
};

export  const CSSLoader = connect(
  state => ({
    ui:state.ui
  }),
  dispatch => ({
    uiActions: bindActionCreators(uiActions, dispatch),
  }))(CSSLoaderComponent);
