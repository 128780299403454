import {fabric} from 'fabric';
fabric.IText.prototype.getTrueBoundingRect = function (clone) {
  const newLeft = this.left - (this.width/2);
  const tempBottom = this.top - (0.667*this.fontSize) +  this.height; //valeur calculée empiriquement
  const newBottom =  Math.round(tempBottom /1.03); //valeur calculée empiriquement
  const newWidth =   Math.round(this.width / 1.11);//valeur calculée empiriquement
  const newHeight = Math.round(this.height/1.67);//valeur calculée empiriquement


  return {
    left:newLeft,
    rigt: newWidth + newLeft,
    top: newBottom - newHeight+9,
    bottom:newBottom,
    width:newWidth,
    height:newHeight
  }
}

