import * as actionTypes from '../actions/actionTypes';

/**
 * Check if familyspace user already exists in storage && Same for familyspace
 */
const user = localStorage.getItem('gpg-familyspace-user') || null;
const familySpace = localStorage.getItem('gpg-familyspace') || null;
const members = localStorage.getItem('gpg-familyspace-members') || null;
const files = localStorage.getItem('gpg-familyspace-files') || null;
const token = localStorage.getItem('gpg-familyspace-token') || null;


const initialState = {
  projects: [],
  user: user ? JSON.parse(user) : user,
  familySpace: familySpace ? JSON.parse(familySpace) : familySpace,
  members: members ? JSON.parse(members) : [],
  messages: [],
  files: files ? JSON.parse(files) : [],
  socket: null,
  candidates: null,
  loginModal: false,
  token,
  orgsInfos:[],
  selectedProjects:[],
  loadingProjects:true
};

export default function (state = initialState, {type, payload}) {
  switch (type) {

    case actionTypes.FS_SOCKET:
      return Object.assign({}, state, {socket: payload});

    case actionTypes.FS_LOGIN_MODAL:
      return Object.assign({}, state, {loginModal: payload});

    case actionTypes.FS_USER:
      return Object.assign({}, state, {user: payload});

    case actionTypes.FS_PROJECTS:
      return Object.assign({}, state, {projects: payload,loadingProjects : false});

    case actionTypes.FS_PROJECT_IMAGE_STATUS:
      const {projectId, loading, error, errorMsg} = payload;
      if (state.projects) {
        const index = state.projects.findIndex(project=>project.id === projectId);
        
        if (index !== -1){
          let newProjects =  JSON.parse(JSON.stringify(state.projects));
          newProjects[index].error = error ? errorMsg : false;
          newProjects[index].loading = loading;
          return Object.assign({}, state, {projects: newProjects});
        }
      }
      return false;

    case actionTypes.FS_PROJECT_IMAGE:
      if (state.projects){
        const index = state.projects.findIndex(project=>project.id === payload.id);
        if (index !== -1){
          let newProjects =  JSON.parse(JSON.stringify(state.projects));
          newProjects[index].configImage = payload.image;
          newProjects[index].config = payload.config;
          newProjects[index].error = false;
          newProjects[index].loading = false;
          return Object.assign({}, state, {projects: newProjects});
        }
      }
     return false;

    case actionTypes.FS_SPACE:
      return Object.assign({}, state, {familySpace: payload});

    case actionTypes.FS_LOGOUT:
      return Object.assign({}, state, {projects: [], user: null, familySpace: null, members: [],token:null});

    case actionTypes.FS_MEMBERS:
      return Object.assign({}, state, {members: payload});

    case actionTypes.FS_MESSAGES:
      return Object.assign({}, state, {messages: payload});

    case actionTypes.FS_ADD_MESSAGE:
      return Object.assign({}, state, {messages: [...state.messages,payload]});

    case actionTypes.FS_UPDATE_POT:
        return Object.assign({}, state, {familySpace: {...state.familySpace, pot: payload}});

    case actionTypes.FS_ADD_COMMENT:
      const projectIndex = state.projects.findIndex(project=>project.id === payload.project_id);
      if (projectIndex !== -1){
        let newProjects =  JSON.parse(JSON.stringify(state.projects));
        newProjects[projectIndex].comments.push(payload);
        return Object.assign({}, state, {projects: newProjects});
      }
      break;
      case actionTypes.FS_ADD_DOCS:
        const foundIndex = state.projects.findIndex(project=>project.id === payload.id);
        if (foundIndex !== -1){
          let newProjects =  JSON.parse(JSON.stringify(state.projects));
          newProjects[foundIndex].docs= payload.docs;
          return Object.assign({}, state, {projects: newProjects});
        }
        return state;
    
    case actionTypes.UPDATE_EMAIL_SENT:
      console.log("send done ...")
      const index = state.projects.findIndex(project=>project.config_id === payload.reference);
      if (index !== -1){
        console.log('found ...')
        state.projects[index].config.configuration.newfamilyInfos.emailSent = payload.emailSent
        return Object.assign({}, state, {projects: [...state.projects]});
      }
      return state

    case actionTypes.FS_FILES:
      return Object.assign({}, state, {files: payload});
      case actionTypes.FS_TOKEN:
        return Object.assign({}, state, {token: payload});      

    case actionTypes.FS_CANDIDATES:
        return Object.assign({}, state, {candidates: payload});

    case actionTypes.FS_ORGANISATION_INFOS:
      return Object.assign({}, state, {orgsInfos: payload});
      
    case actionTypes.FS_SELECTED_PROJECTS:
      return Object.assign({}, state, {selectedProjects: payload});      

      case actionTypes.FS_ADD_SELECTED_PROJECT:
        const found = state.selectedProjects.find (item=> item === payload);
        if (!found){return Object.assign({}, state, {selectedProjects:[...state.selectedProjects,payload] }); }
        return state
              
      case actionTypes.FS_DELETE_SELECTED_PROJECT:
          return {...state,selectedProjects: state.selectedProjects.filter(item=> item !== payload)};
                  

    default:
      return state;
  }
}
