import React from 'react';

export const InitialChoice = ({type, color}) => {
  let svg;
  switch (type) {
    case "interment":
      svg = <Inhumation color={color} />
      break;
    case "cremation":
      svg = <Cremation color={color} />
      break;
    case "mixed":
      svg = <Mixte color={color} />
      break;
    default:
      return false;
  }

  return svg;
};

export const Group299 = ({color}) =>
(<svg xmlns="http://www.w3.org/2000/svg" width="34.068" height="34" viewBox="0 0 34.068 34">
  <g transform="translate(-1172 -301)">
    <g transform="translate(1171.993 300.908)">
      <g transform="translate(0.008 0.093)">
        <path style={{"--f-main-color": color}} className="svg-ffinalizationAsk" d="M1.442,23.84a17.019,17.019,0,0,0,22.429,8.83l.341-.164,9.348,1.363-3.011-6.363A17.033,17.033,0,1,0,1.442,23.962Z" transform="translate(-0.008 -0.093)"/>
      </g>
    </g>
    <path fill="#fff" d="M7.334-3.691H4.844q-.01-.537-.01-.654a4.356,4.356,0,0,1,.4-1.992A6.074,6.074,0,0,1,6.836-8.1,11.99,11.99,0,0,0,8.271-9.375a1.71,1.71,0,0,0,.361-1.055,1.777,1.777,0,0,0-.64-1.372,2.492,2.492,0,0,0-1.724-.571,2.611,2.611,0,0,0-1.748.6,3.171,3.171,0,0,0-.967,1.816l-2.52-.312a4.181,4.181,0,0,1,1.489-2.969,5.278,5.278,0,0,1,3.628-1.221,5.47,5.47,0,0,1,3.76,1.235,3.74,3.74,0,0,1,1.4,2.876A3.162,3.162,0,0,1,10.8-8.633,11.254,11.254,0,0,1,8.6-6.426,4.185,4.185,0,0,0,7.524-5.264,3.9,3.9,0,0,0,7.334-3.691ZM4.844,0V-2.744H7.588V0Z" transform="translate(1183 325)"/>
</g>
</svg>);

const Inhumation = ({color}) =>
(<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g transform="translate(-544 -437)">
    <circle className="finitial-circle" cx="25" cy="25" r="25" transform="translate(544 437)" style={{"--f-main-color": color}} />
    <g transform="translate(554 449)">
      <path fill="#fff"  d="M115.023,18.18h-2.76V0H96.76V18.18H94v3.148h21.023ZM101.63,4.469h5.764V5.992H101.63Zm5.764,7.617H101.63V10.563h5.764Zm1.473-3.047h-8.709V7.516h8.709Z" transform="translate(-89.227)"/>
      <rect width="30.57" fill="#fff" height="3.148" transform="translate(0 22.852)"/>
    </g>
  </g>
</svg>)

const Cremation = ({color}) =>
(<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g transform="translate(-544 -437)">
    <circle className="finitial-circle" cx="25" cy="25" r="25" transform="translate(544 437)" style={{"--f-main-color": color}} />
  <g transform="translate(499.202 448)">
    <path fill="#fff" d="M76.625,151.392V151H62.914v.393a8.529,8.529,0,0,0-1.518,11.56l3.042,4.285v2.093H75.1V167.24l3.048-4.267A8.614,8.614,0,0,0,76.625,151.392ZM75.1,161.664H64.437V155.57H75.1Z" transform="translate(0 -143.332)"/>
    <rect fill="#fff" width="7.617" height="3.047" transform="translate(65.961 13.762)"/>
    <path fill="#fff" d="M136.269,3.178a2.267,2.267,0,0,0,.165-.842,2.286,2.286,0,1,0-4.57,0,2.267,2.267,0,0,0,.165.842,6.224,6.224,0,0,0-4.407,2.967h13.054A6.224,6.224,0,0,0,136.269,3.178Z" transform="translate(-64.379)"/>
  </g>
  </g>
</svg>);

const Mixte = ({color}) =>
(<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g transform="translate(-544 -437)">
    <circle className="finitial-circle" cx="25" cy="25" r="25" transform="translate(544 437)" style={{"--f-main-color": color}} />
  <g transform="translate(553 449)">
    <rect fill="#fff" width="30.57" height="3.148" transform="translate(0 22.852)"/>
  <path fill="#fff" d="M110.585,20.419l-1.606-2.437a5.357,5.357,0,0,1,.954-7.262v-.246a2.832,2.832,0,0,1,.359-.957,3,3,0,0,1,1.972-1.48V0H96.76V18.18H94v3.148h16.585ZM101.63,4.469h5.764V5.992H101.63Zm-1.473,3.047h8.709V9.039h-8.709Zm7.236,4.57H101.63V10.563h5.764Z" transform="translate(-89.227)"/>
  <path fill="#fff" d="M426.246,221.324v-.214h-7.487v.215a4.659,4.659,0,0,0-.829,6.314l1.664,2.341v1.144h5.825V229.98l1.665-2.331A4.7,4.7,0,0,0,426.246,221.324Zm-.83,5.61h-5.823v-3.328h5.825Z" transform="translate(-395.878 -209.882)"/>
  <rect fill="#fff" width="4.161" height="1.664" transform="translate(24.545 14.557)"/>
  <path fill="#fff" d="M458.833,140.375a1.235,1.235,0,0,0,.09-.46,1.248,1.248,0,1,0-2.5,0,1.235,1.235,0,0,0,.09.46A3.4,3.4,0,0,0,454.11,142h7.13A3.4,3.4,0,0,0,458.833,140.375Z" transform="translate(-431.05 -131.6)"/>
</g>
</g>
</svg>);

export const FbLogo = ({className, size = 30}) =>
(<div className="FacebookLogo">
  <svg className={className} xmlns="http://www.w3.org/2000/svg"  width={size} height={size} viewBox={`0 0 30 30`}>
    <g transform="translate(-1360 -830)">
      <path className="finitial-circle" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(1360 830)"/>
      <path fill="#fff" d="M30.4,8.438l.417-2.715h-2.6V3.961a1.357,1.357,0,0,1,1.53-1.467h1.184V.183A14.441,14.441,0,0,0,28.822,0a3.314,3.314,0,0,0-3.547,3.654V5.723H22.89V8.438h2.384V15h2.935V8.438Z" transform="translate(1348.11 837)"/>
    </g>
  </svg>
  <div className="SocialLogoTitle">Facebook</div>
</div>
)


export const WhatsUpLogo = ({className, size = 30}) =>
(<div className="WhatsAppLogo">
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
    <g transform="translate(-1360 -830)">
      <path className="finitial-circle" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(1360 830)"/>
      <path fill="#fff" d="M12.753,34.18a7.435,7.435,0,0,0-11.7,8.97L0,47l3.941-1.035a7.41,7.41,0,0,0,3.552.9h0a7.386,7.386,0,0,0,5.257-12.69ZM7.5,45.617a6.167,6.167,0,0,1-3.147-.86l-.224-.134-2.337.613.623-2.28-.147-.234a6.19,6.19,0,1,1,11.481-3.285A6.247,6.247,0,0,1,7.5,45.617Zm3.388-4.627c-.184-.094-1.1-.542-1.269-.6s-.295-.094-.419.094-.479.6-.589.73-.218.141-.4.047a5.056,5.056,0,0,1-2.528-2.21c-.191-.328.191-.3.546-1.015a.344.344,0,0,0-.017-.325c-.047-.094-.419-1.008-.573-1.379s-.3-.311-.419-.318S4.985,36,4.862,36a.688.688,0,0,0-.5.231,2.087,2.087,0,0,0-.65,1.55,3.639,3.639,0,0,0,.757,1.922,8.3,8.3,0,0,0,3.174,2.806,3.634,3.634,0,0,0,2.23.465,1.9,1.9,0,0,0,1.252-.884,1.553,1.553,0,0,0,.107-.884C11.193,41.127,11.069,41.08,10.885,40.99Z" transform="translate(1368 805)"/>
    </g>
  </svg>
  <div className="SocialLogoTitle">WhatsApp</div>
  </div>
)


export const MailLogo = ({className, size = 30}) =>
(<div className="EmailLogo">
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30">
    <g transform="translate(-1360 -830)">
      <path className="finitial-circle" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(1360 830)"/>
      <path fill="#fff" d="M14.716,67.715a.176.176,0,0,1,.284.138v5.991a1.407,1.407,0,0,1-1.406,1.406H1.406A1.407,1.407,0,0,1,0,73.844V67.855a.175.175,0,0,1,.284-.138c.656.51,1.526,1.157,4.515,3.328.618.451,1.661,1.4,2.7,1.395s2.109-.961,2.7-1.395C13.192,68.875,14.06,68.225,14.716,67.715ZM7.5,71.5c.68.012,1.658-.855,2.15-1.213,3.888-2.821,4.184-3.067,5.08-3.771a.7.7,0,0,0,.27-.554v-.557A1.407,1.407,0,0,0,13.594,64H1.406A1.407,1.407,0,0,0,0,65.406v.557a.705.705,0,0,0,.27.554c.9.7,1.192.949,5.08,3.771C5.842,70.645,6.82,71.512,7.5,71.5Z" transform="translate(1368 775)"/>
    </g>
  </svg>
  <div className="SocialLogoTitle">Email</div>
  </div>
)
