import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as configurationActions from "../../actions/configurationActions";
import * as uiActions from "../../actions/uiActions";
import { NotifUpdate } from "../configurator/components/NotifUpdate";
import { Messages } from "../../components/messages";
import { Fgranite } from "./Fgranite";
import { Fengraving } from "./Fengraving";
import { Fpattern } from "./Fpattern";
import { Faccessory } from "./Faccessory";
import { FdetailInfos } from "./FdetailInfos";
import { Ffinalization } from "./Ffinalization";
import { getDesignTypeConfiguration, isFamilySpaceOn } from "../../services/utils.service";
import history from "../../history";
import customize from '../../images/personnalise-icon.svg';
import mixteIcon from '../../images/mixte-icone-new.svg';
import { FdetailInfosB } from './components/FdetailInfosB';
import { Modal } from '../../components/Modal';
import { Button } from "reactstrap";
import {SfamilyIcon} from "../family_space/SfamilyIcon";
import {imageExists} from "../helpers/assetsHelpers";
import clientLogo, {ClientLogo} from "../configurator/ClientLogo";
import {getStore} from '../../store';


// Route et Navigation de l'application
const tabList = [
  {label1:"Choisir un",label2: `Modèle`, endpath: null, icon: "01", config: "catalog",class:"First",noconfig:true},
  {label1:"Choisir un",label2:"Granit", endpath: "fgranit", icon: "02", config: "granite", component: Fgranite },
  {label1:"Ajouter une",label2:"Gravure",endpath: "fgravure",icon: "03",config: "patterns",exact:false,component: Fengraving},
  {label1:"Ajouter un",label2:"Motif",endpath: "fmotif",icon: "04",config: "patterns",exact:false,component: Fpattern},
  {label1:"Ajouter des",label2:"Accessoires",endpath: "faccessoire",icon: "05",config: "accessories",component: Faccessory},
  {label1:"Voir et télécharger",label2:"mon projet en 3D",endpath: "ffinalisation",icon: "06",config: "finalization",class:"Last",component: Ffinalization}
];

class FheaderComponent extends Component {
  state={
    logoRequested: false,
    designType: null,
    modalVisible: false,
    showConfirmModal: false,
  }

  async componentDidMount () {
    const designType = await getDesignTypeConfiguration();
    this.setState({designType});
  };

  myComponent = (component, motifOpts, defaultFaceId) => {
    const TheComponent = component;
    return <TheComponent defaultFaceId={defaultFaceId} motifOpts={motifOpts} />;
  };

  getEndUrlIndex (){
    const endPath = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1);
    const found = tabList.findIndex(elt=>elt.endpath === endPath);
    if (found === -1) {
      if (endPath === "customize") return (1)
      return (0);
    }
    else {
      return (found);
    };
  }

  checkEnabled (index){
    const activeIndex = this.getEndUrlIndex();
    switch (activeIndex) {
      case 0:
        if (index !== 0)  {return ("Choisissez votre monument")} // Si on est sur le catalog on interdit l'accès aux autres onglets
        break;
      default:
      if (this.props.configurator.current) {
        const {current} = this.props.configurator;
        // SI le mail a déjà été envoyé interdir l'accès aux étapes modifiables
        if (current.configuration && current.configuration.newfamilyInfos && current.configuration.newfamilyInfos.emailSent){
          if (index !== 0 && index !== (tabList.length -1)){
            return("Configuration terminée");
          }
        }
      } else {
        if (index !==0){return false;} // Tant qu'on n'a pa la config ne pas permettre d'accéder aux autres onglets
      }

      break;
    }
    return (true);
  }
  checkFinalStepDisplay(index) {
    const activeIndex = this.getEndUrlIndex();
    if (activeIndex !== 0 && activeIndex !== (tabList.length -1)){
      return true;
    }
    return false;
  }

  handleClick(e,index) {
    if(this.checkEnabled(index) !== true) {
      e.preventDefault();
    }
    if(index === 0 ) this.props.uiActions.initialChoiceMade(false);
  }

  backtoHome(clientId) {
    history.push(`/famille/${clientId}`);
    this.props.uiActions.initialChoiceMade(false);
  }

  goEndpoint = (endpoint, force = false) => {
    const { configId, clientId } = this.props.match.params;
    if (endpoint === "fcatalog") {
      if (force) {
        history.push(`/famille/${clientId}`);
        this.setState({showConfirmModal: false});
        this.props.uiActions.initialChoiceMade(false);
      } else {
        this.setState({showConfirmModal: true});
      }
    } else {
      history.push(`/famille/${clientId}/${configId}/${endpoint}`);
    }
  };

  googleTagManagerPush = () => {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KDKJX2S');
  };

  render() {
    this.getEndUrlIndex();
    const { configurator,  user } = this.props;
    const configId = configurator.current && configurator.current.configuration ? configurator.current.configuration.reference : null;
    const config = configurator.current && configurator.current.configuration ? configurator.current.configuration : null;
    const {clientId}  = this.props.match.params;
    const { modalVisible, showConfirmModal } = this.state;
    const designType = getDesignTypeConfiguration(config);
    const endPath = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1);
    const activeIndex = this.getEndUrlIndex();
    /*const srcLogo = this.props.user.identity.organization.logo;
    const cliLogo = !srcLogo ? require('../../images/space/logo_gpg_white.png') : srcLogo;*/

    // if (process.env.NODE_ENV === 'production' && clientId==='CA102194'){this.googleTagManagerPush();}
    if (process.env.NODE_ENV === 'production' && getStore().getState().user.isGpgClient){this.googleTagManagerPush();}
    
    return(
     <div className="Configuration">
          <div className="Header">
            <nav className="Header cfg-steps">
            {/*<div className="logobg"><img className="logo" alt="Logo" src={ cliLogo } onClick={()=>this.backtoHome(clientId)}/></div>*/}
              <div onClick={()=>this.backtoHome(clientId)} style={{cursor:'pointer'}}>
                <ClientLogo replaceType={'white'} />
              </div>
              <div className="ClassicVersion">
                  {designType === "A" ?
                    tabList.map(
                      (item, index) =>
                          <NavLink
                            onClick={e=>this.handleClick(e,index)}
                            key={index}
                            className={this.checkEnabled(index) === true ? item.class ? item.class : "":item.class ? item.class + " disable":"disable" }
                            exact activeClassName="active"
                            to={item.endpath ? `/famille/${clientId}/${configId}/${item.endpath}` : `/famille/${clientId}`} >
                            <div className="HeaderLabel">
                              <div className="bigNumber">{item.icon}</div>
                              <div className="label">{item.label1}<br/>{item.label2}</div>
                              {item.class === "Last" && this.checkFinalStepDisplay(index) &&
                              <div className="seeDetail" onClick={()=>history.push(`/famille/${clientId}/${configId}/ffinalisation`)}>
                                Dernière étape et <br/>demande de devis</div>}
                            </div>
                          </NavLink>
                    )
                  :
                  <div className="wrapper-bnav">
                    <a
                      href="true"
                      key={0}
                      className={activeIndex === 0 ? "header-bnav First active" : "header-bnav First"}
                      onClick={e => {
                        e.preventDefault();
                        if (!!this.props.customizeModalOption) {
                          this.props.customizeModalOption('fcatalog')
                        } else {
                          this.goEndpoint('fcatalog');
                        }
                      }}
                    >
                      <div className="HeaderLabel">
                        <div className="bigNumber">
                          <img src={mixteIcon} alt="Modèle" />
                        </div>
                        <div className="label">{"Je choisis"}<br/>{"un modèle"}</div>
                      </div>
                    </a>
                    <NavLink
                      onClick={e => {
                        if (!configId) {
                          e.preventDefault();
                          this.setState({modalVisible: true});
                        };
                        this.handleClick(e,0);
                      }}
                      key={1}
                      className={`header-bnav ${activeIndex > 0 && 'active'}`}
                      to={`/famille/${clientId}/${configId}/customize`}
                    >
                      <div className="HeaderLabel">
                        <div className="bigNumber">
                          <img src={customize} alt="Personnalisation"/>
                        </div>
                        <div className="label">{"Je personnalise"}<br/>{"mon monument"}</div>
                      </div>
                    </NavLink>
                  </div>
                  }
              </div>

              <div className="MobileVersion">
                {designType === "A" ?
                  <>
                    <div className="MobileStep">{activeIndex+1}/6</div>
                    <div className="MobileAction">{tabList[activeIndex].label1 + " " + tabList[activeIndex].label2}</div>
                  </>
                  :
                  <>
                    <div className="MobileStepActionB">
                      {endPath !== "customize" && <div className="HeaderLabel">
                          <div className="bigNumber">
                            <i className="icon material-icons" onClick={() => history.push(`/famille/${clientId}/${configId}/customize`)}>arrow_back_ios</i>
                            <img src={customize} alt="Personnalisation" />
                          </div>
                          <div className="label">{"Je personnalise"}<br/>{"mon monument"}</div>
                      </div>}
                    </div>
                  </>
                }

              </div>

              {isFamilySpaceOn(user) && <SfamilyIcon designType={designType} clientId={clientId}/> }
            </nav>
            {configId &&
              designType === "A" ? <FdetailInfos /> : <FdetailInfosB />
            }
          </div>



        <div className="Content">
          {this.props.configurator.loading && <p className="Loading">Chargement de la configuration en cours...</p>}
        </div>
        <NotifUpdate clientId={clientId} />
        <Messages />
        <Modal
          isOpen={modalVisible}
          onRequestClose={() => this.setState({modalVisible: false})}
          className="customize-modal"
          overlayClassName="customize-modal-overlay"
        >
          <div className="modal-content">
            <div className="modal-body">
              Veuillez d'abord sélectionner un modèle,<br />
              pour ensuite pouvoir le personnaliser.
            </div>

            <div className="actions">
              <Button onClick={() => this.setState({modalVisible: false})}>
                Je choisis un modèle
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showConfirmModal}
          onRequestClose={() => this.setState({showConfirmModal: false})}
          hideClose
          className="customize-modal"
          overlayClassName="customize-modal-overlay"
        >
          <div className="modal-content">
            <div className="modal-body">
              Retourner au catalogue
            </div>

            <div className="modal-button actions">
              <Button onClick={() => this.setState({showConfirmModal: false})}>Annuler</Button>
              <Button onClick={() => this.goEndpoint('fcatalog', true)}>Confirmer</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export const Fheader = connect(
  (state, ownProps) => ({
    user:state.user,
    configurator: state.configurator,
  }),
  dispatch => ({
    actions: bindActionCreators(configurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
  })
)(FheaderComponent);
