import React, { Component } from "react";
import PropTypes from 'prop-types';
import Select from 'react-select';

import { filterLayouts } from "../../../constants";

export class FilterBox extends Component {
  static propTypes = {
    onFilter: PropTypes.func.isRequired
  };

  // Check this commit to optimase it
  // https://github.com/LaFabricGPG/gpggranit-v2/commit/aad4b056ac9a709e97cca72b5470c6c1e1f24e7f
  handleOnSelectLayout = _layout => {
    const layout = _layout.value
    let storedLayouts = this.props.filters.layouts;
    let filters = { layouts: [layout] };
    let newLayouts = filters.layouts;
    // Si on active un filtre alors que "toutes" est cohé on décoche "Toutes"
    if (
      storedLayouts.length === 1 &&
      storedLayouts[0] === "all" &&
      layout !== "all"
    ) {
      this.props.onFilter({layouts: [layout]});
    } else {
      if (storedLayouts.includes(layout)) {
        let index = newLayouts.indexOf(layout);
        newLayouts.splice(index, 1); //remove filter
      } else {
        if (layout ==="all"){ // si on clique sur Toutes on enlève les autres sélections
          filters.layouts=["all"];
        }else{
         newLayouts.push(layout); // add filter
        }
      }
      this.props.onFilter(filters);
    }
  };

  render() {

    const _layouts = filterLayouts.map((item, idx) => ({ value: item.size, label: item.size.replace(",", "x") }));
    _layouts.unshift({ value:"all", label:"Toutes" })

    return (
      <div className="FilterLayout" id="FilterLayout">
        <div>
          <label >
            <h3>Recette</h3>
          </label>
          <Select 
            options={ _layouts }
            defaultValue={_layouts[0]}
            className="FAuto-select" 
            placeholder="Choisir un Recette" 
            onChange={this.handleOnSelectLayout} 
          />
        </div>
      </div>
    );
  }
}
