import React, { Component } from "react";
import {CloseModal} from '../utils/display';
import {Modal} from 'reactstrap';
import {LeftOvalAction} from "../../../components/LeftOvalAction";
import { Fquote } from "../../family/Fquote";
import { gtmFamily } from "../../../services/gtmFamily";

 export  class SaskQuote extends Component {
  state = {askQuote:true,displayQuote:false,logo:false};

  askQuoteClose = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "annuler",
      category: "espace famille",
      subcategory: "liste projets",
      type: "cta",
      from: "popup demander un devis"
    }, { template: false, subtemplate: false }, {}, "product", "page")
    // end GTM
    this.props.close();
  }

  getCustumizedQuote = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "obtenir un devis personnalisé",
      category: "espace famille",
      subcategory: "devis",
      type: "cta",
      from: "popup demander un devis"
    }, { template: "espace famille", subtemplate: "liste projets" }, {}, "product")
    // end GTM
    this.setState({askQuote:false,displayQuote:true})
  }

  render () {
    const {close, informSent, price, config, user,project, colorConfig=null} = this.props;
    const {askQuote, displayQuote} = this.state;
    return (
      <div>
      <Modal isOpen={askQuote} className="ModalAskQuote" >
        <CloseModal action={()=>close()} />
        <div className="SBigModalTitle">{`Votre projet est estimé à partir de ${price}€`}</div>
        <div className="SModalText">Cliquez ci-dessous pour être mis en relation avec un professionnel 
        près de chez vous, pour obtenir un devis personnalisé.</div>
        <div className="ActionButtons">
          <LeftOvalAction 
            text=" Obtenir un devis personnalisé" 
            addClass="fsp-button" 
            // icon="euro" 
            colorConfig={colorConfig ? {mainColor: colorConfig.mainColor, fontColor: colorConfig.fontColor} : null} 
            action={()=>this.getCustumizedQuote()}/>
          <button className="LeftOvalAction fsp-button cancel Grey" onClick={()=>this.askQuoteClose()}>Annuler</button>
        </div>
      </Modal>
      {displayQuote && <Fquote  config={config} user={user} closable openHelp fromSpace={project && project.client}
                                sent= {()=>informSent()} onClose= {()=>this.props.close()}
                                fromClass={`Fquote-customize fsp-quote-success`}/>}
      </div>

      )
  }
}


