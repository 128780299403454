import React, {Component} from 'react';
import {Col,Row,Modal,ModalHeader,Button, Form, FormGroup, Label, Input, FormFeedback, ModalBody,ModalFooter } from "reactstrap";
import RichTextEditor from 'react-rte';
import {post} from '../services/api.service';
import {emailRex} from '../services/utils.service';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { gtmFamily } from '../services/gtmFamily';


const removeEndString = (url) => {return  url.substr(0,url.lastIndexOf("/"));}
/**
 * Composant de saisie de nombre gérant les décimales.
 * La valeur de l'input est synchro avec la prop 'value' lorsque l'input n'est pas focus
 * et sur le blur sinon pour éviter des comportements bizarres lorsque l'utilisateur est
 * en train de saisir un chiffre.
 */
export class ManualMail extends Component {
  topUrl = removeEndString(this.props.url);
  initialText = `<div>
  <br><br>
  J'ai personnalisé un monument funéraire, il est accessible en cliquant ici :<a href=${this.props.url}?download>Téléchargez PDF</a>.
  <br><br>
  Vous aussi vous pouvez personnaliser un monument funéraire en cliquant ici <a href=${removeEndString(this.topUrl)}>Personnaliser</a>
  <br>
  </div>`;
  state={ validate:{sender:true,to:true}, email:"",name:"",to: [], errorMessage:"",emailSending:{sent:false,error:false, successMsg:null},
          content: RichTextEditor.createValueFromString(this.initialText, 'html'), pending:false};

  componentDidMount(){
    // this.setState({content:this.props.url+"?download"})
  }
  handleChange(e){
    const {name,value} = e.target;
    let state = {...this.state};
    state[name] = value;
    this.setState(state);
  }
  onBodyChange = (value) => {this.setState({content:value})};
  validateEmail(email,type) {
    if (email){
      const { validate } = this.state;
      validate[type] = emailRex.test(email) ? true: false;
      this.setState({ validate })
      }
    }

  sendEmail = async ()=>{
     const {email,name,to,content,validate} = this.state;
     let body = content.toString('html');

    if (email === "" || !to || to.length < 1 || name === "" || content === "" || !validate.sender || !validate.to){
       this.setState({errorMessage:" Un des champs obligatoire est manquant ou erroné"});
       this.setState({emailSending: {sent:false,error:false,successMsg:null, pending:false}});
      // GTM
      if (this.props.from == "fTopBar") {
        gtmFamily().formErrors({
          form: "Partager par email",
          name: "champ formulaire",
          category: "formulaire",
          type: "fonctionnel",
          message: "certains champs sont manquants ou erronés"
        })
      }
      // end GTM
     }else{
      this.setState({pending: true})
      const subject = `${name} a personnalisé un monument funéraire, découvrez le.`
      const result = await post(`/api/mail/send`, {email,name,to,subject,body});// send email via back-end
      // this.props.callback(result); // Error: this.props.callback is not a function
      if (result) {
        this.setState({errorMessage: ""})
        this.setState({emailSending: {sent:true,error:false,successMsg:"Vote email à été envoyé avec succès"}});
        this.setState({pending: false})
      }
         // GTM
        if (this.props.from == "fTopBar") {
          gtmFamily().ctaOfConfiguration({
            name: "email",
            category: "partager",
            subcategory: "email",
            type: "picto",
            from: "partager"
          }, { template: "configurateur", subtemplate: "produit" }, {}, "partner", "product", "user", "page")
        }
        // end GTM
     }
  }

  render() {
    const toolbarConfig = { display: []};
    const {validate,errorMessage,to} = this.state;
    const { closeFromModal } = this.props;
  return (
    <Modal
      isOpen={!this.state.icon}
      toggle={() => this.setState({icon:true})}
      onClosed={() => closeFromModal && closeFromModal()}
      className="ManualMailModal"
    >
      <ModalHeader toggle={()=>this.setState({icon:true})}>Votre message</ModalHeader>
        <Form className="ManualMailForm">
          <FormGroup>
            <Row>
              <Col md="2"><Label className="required">Votre email</Label></Col>
              <Col md="9"><Input name="email" required   invalid={!validate.sender}
                      onBlur={e => this.validateEmail(e.target.value,"sender")} onChange={((e)=> this.handleChange(e))} />
              </Col>
              <FormFeedback > Email non valide</FormFeedback>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="2"><Label className="required" >Votre nom</Label></Col>
              <Col md="9"><Input name="name" required   onChange={((e)=> this.handleChange(e))} /></Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="2"><Label className="required">Destinataires</Label></Col>
              <Col md="9">
              <ReactMultiEmail className="multi-email"
                               emails={to} 
                               onChange={(_emails) => {this.setState({ to: _emails });}}
                               validateEmail={email => {return isEmail(email);}}
                               getLabel={(email,index,removeEmail) =>
                                <div data-tag key={index}>{email}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                </div>
                              }/>
              </Col>
              <FormFeedback > Email non valide</FormFeedback>
            </Row>
          </FormGroup>
          <div className="MailTextEditor">
            <Label className="required">Message</Label>
            <RichTextEditor toolbarClassName="toolbar"  toolbarConfig={toolbarConfig}
                            value={this.state.content}  onChange={this.onBodyChange} />
          </div>
          <div class="ButtonWrapper">
            <Button disabled={this.state.pending} className="ManualMailSend" onClick={(fields)=>this.sendEmail()}>Envoyer email</Button>
            {errorMessage !== "" && <Label className="FquoteError">{errorMessage}</Label>}
            {this.state.emailSending.successMsg && <Label className="successMsg">{this.state.emailSending.successMsg}</Label>}
          </div>
        </Form>
    </Modal>
    )
  }
}
