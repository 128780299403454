import React from 'react';
import {typeEngraving, typePattern, typeAccessory} from './Fabric';
import * as _ from 'lodash';
import {getPatternSize} from './../../../services/patterns.service';
import {canDuplicate} from '../../../services/restrictedConfiguration';
import { isConfigRequested } from '../../../services/configurationWarningChecks';

const colorNames = {
  '#ffdf46': 'Dorées',
  '#ffffff': 'Blanches',
  '#000000': 'Noires',
};

export function FabricFooter({
  configuration, patterns, accessories, selectedObjects, onRemoveObjects, onDuplicateObject,
  onMirrorPattern, onChangePattern, onToggleEngravingReservation, onChangeEngravingText, onChangeAccessoryGranite
}) {
  if (!selectedObjects || !selectedObjects.length) {
    return null;
  }

  let description;
  let buttons = [];
  let disabled = isConfigRequested();
  let classDisabled = disabled ? "disabled" : "";

  if (selectedObjects.length > 1) {
    buttons.push((
      <button key="remove" onClick={onRemoveObjects} className={classDisabled} disabled={disabled}>Supprimer les éléments sélectionnés</button>
    ));
  } else {
    const selected = selectedObjects[0];

    buttons.push((
      <button 
        key="remove" 
        onClick={onRemoveObjects} 
        className={`${classDisabled} button-small button-secondary text-dark`} 
        disabled={disabled}>
        <span>Supprimer</span>
        <span className="trash-icon icon-medium text-dark-icon"></span>
      </button>
    ));
    {canDuplicate(configuration,selected.type) && buttons.push((
      <button 
        key="duplicate" 
        onClick={onDuplicateObject} 
        className={`${classDisabled} button-small button-secondary text-dark`}
        disabled={disabled}>
        <span>Dupliquer</span>
        <span className="plus-icon icon-medium text-dark-icon"></span>
      </button>
    ));}

    switch (selected.type) {
      case typePattern:
        const pattern = _.find(patterns, {reference: selected.reference});
        if (pattern)
        {
          const patternconfig = _.find(configuration.patterns, {id: selected.id});
          const patternSize = getPatternSize(patternconfig,pattern);

          description = (
            <div className="Pattern">
              <h1>
                <span className="Name">{pattern.reference}</span>
                <small className="Kind">{pattern.kind}</small>
                {patternSize && <span className ="PatternSize">{`H: ${patternSize.height} cm | L: ${patternSize.width} cm`}</span>}
              </h1>
              <p className="Description">{pattern.description}</p>
            </div>
          );

          buttons.push((
            <button 
              key="mirror" 
              onClick={onMirrorPattern} 
              className={`${classDisabled} button-small button-secondary text-dark`}
              disabled={disabled}>
              <span>Miroir</span>
              <span className="mirror-left-icon icon-medium text-dark-icon"></span>
            </button>
          ));
        }
        // On le retire mais on garde le code au cas où. La fonctionnalité était tout de même pratique.
        // buttons.push((
        //   <button key="changePattern" onClick={onChangePattern} className="EditPattern">
        //     Modifier le motif
        //     <i className="icon material-icons">&#xE545;</i>
        //   </button>
        // ));
        break;

      case typeEngraving:
        const engraving = _.find(configuration.engravings, {id: selected.id});

        if (engraving.reservation) {
          description = (
            <div className="Engraving">
              <h1>
                <small className="Kind">Réserve de gravure</small>
              </h1>
              <p className="Text">{selected.text}</p>
            </div>
          );
          buttons.push((
            <button 
              key="toggleReservation" 
              onClick={onToggleEngravingReservation} 
              className={`${classDisabled} button-small button-secondary text-dark`} 
              disabled={disabled}>
              <span>Basculer en gravure</span>
              <span className="reservation-on-icon icon-medium text-dark-icon"></span>
            </button>
          ));
        } else {
          description = (
            <div className="Engraving">
              <h1>
                <small className="Kind">Lettres {engraving.font.kind} {colorNames[engraving.font.color]}
                  sur
                  poli, {engraving.font.family} {engraving.font.size}mm
                </small>
              </h1>
              <p className="Text">{selected.text}</p>
            </div>
          );
          buttons.push((
            <button key="toggleReservation" 
              onClick={onToggleEngravingReservation} 
              className={`${classDisabled} button-small button-secondary text-dark`} 
              disabled={disabled}>
              <span>Basculer en réserve de gravure</span>
              <span className="reservation-off-icon icon-medium text-dark-icon"></span>
            </button>
          ));
        }
        // buttons.push((
        //   <button className="EditText" key="changeEngravingText" onClick={onChangeEngravingText}>
        //     Modifier le texte
        //     <i className="icon material-icons">&#xE254;</i>
        //   </button>
        // ));
        break;

      case typeAccessory:
        const accessory = selected && _.find(accessories, {reference: selected.reference});

        description = accessory && (
            <div className="Accessory">
              <h1>
                <span className="Reference">{accessory.reference}</span>
                <small className="Kind">{accessory.kind}</small>
              </h1>
              <p className="Description">{accessory.description}</p>
            </div>
          );

        buttons.push((
          <button 
            key="granite" 
            onClick={onChangeAccessoryGranite} 
            className={`${classDisabled} button-small button-primary text-cta`}
            disabled={disabled}>
            <span>Modifier le granit</span>
            <span className="granite-icon icon-medium text-cta-icon"></span>
          </button>
        ));
        break;
        default:
            break;        
    }
  }

  return (
    <div className="Footer">
      <div className="Infos">
        {description}
      </div>
      <div className="Actions">
        {buttons}
      </div>
    </div>
  );
}
