import React from "react";
import { getDesignTypeConfiguration } from '../services/utils.service';

export const OvalAction=({action,text,color= "",arrow = "" ,addClass="",frontIcon=null, image = false, colorConfig=null})=>{
  const iconClass = color ? "icon material-icons": `icon material-icons ${color}`;
  const stepButtonClass = `StepButton ${color} ${arrow}`;
  const designType = getDesignTypeConfiguration();
  const mainClass = `${designType==='A' ? 'StepAction' : 'vStepAction'} ${color} ${addClass}`;

    return (
          <div className={mainClass} onClick={action} style={colorConfig && { "--f-main-color": colorConfig.mainColor, "--f-font-color": colorConfig.fontColor }}>
            {arrow === "left" && <button className={stepButtonClass}>
              <i className={iconClass}>&#xE5C4;</i> </button>}
            {frontIcon && !image && <button className={stepButtonClass}>
            <i className={iconClass}>{frontIcon}</i> </button>}
            {frontIcon && image &&
              <button className={stepButtonClass}>
                <img src={frontIcon} style={{paddingBottom: 10}} alt="description" />
              </button>
            }
            <span className="StepLabel">{text}</span>
            {arrow === "right" && <button className={stepButtonClass}>
                <i className="icon material-icons">&#xE5C8;</i>
            </button>}
          </div>
    )
  }

