import React, { Component } from "react";
import { connect } from "react-redux";
import {SprojectSelect} from "./SprojectSelect ";
import {Row,Col} from 'reactstrap';
import {LeftOvalAction} from "../../../components/LeftOvalAction";
import MediaQuery from "react-responsive/src";
import {isSmartphone} from "../../../services/domFunctions";
import { gtmFamily } from "../../../services/gtmFamily";


class SselectProjectsComponent extends Component {
  state={ searchInput: "",selectedProjects:[], projectsToAdd:{}}

  componentDidMount(){
    let projectsToAdd= {};
    this.props.projects.forEach(project=> {projectsToAdd[project] = true;})
    const projectIds = []
    this.props.familyService.projects.map(project=> {
      if (project.archived === this.props.archived) projectIds.push(project.id)
    });
    this.setState({selectedProjects:projectIds,projectsToAdd});
  }

  toggle = (id) =>{
    let copyAdded = {...this.state.projectsToAdd};
    let toAdd = this.state.projectsToAdd[id] ? false : true;
    let countIds = Object.keys(copyAdded).filter(id=> copyAdded[id] === true).length
    let nbMax = isSmartphone() ? 3 : 4;
    if(countIds < nbMax || !toAdd) {
      copyAdded[id] = toAdd
    }
    this.setState({projectsToAdd:copyAdded});
  } 

  addProjects =() =>{
    let addedProjects = [];
    //TODO mettre les
    for (const id in this.state.projectsToAdd){
      if (this.state.projectsToAdd[id]){addedProjects.push(id)}
    }
    // GTM
    gtmFamily().selectedProjectsCompare(this.props.familyService.projects.filter(p => p.archived == 0));
    // end GTM

    this.props.addedProjects(addedProjects);
  }
  handleSearchInput = ({target}) => {
    const selectedProjects = this.props.familyService.projects.filter(project=>project.name.toLowerCase().includes(target.value.toLowerCase()));
    const selectedProjectsIds = selectedProjects.map(project=>project.id);
    this.setState({selectedProjects:selectedProjectsIds,searchInput:target.value})
  };

  render() {
    const { searchInput,selectedProjects,projectsToAdd } = this.state;
    const { mainColor, fontColor } = this.props.adminConfig.config
    return(
      <div className="SselectProjects">
        <Row className="SselectHeader">
          <div className="title">Sélectionnez les projets à comparer</div>
          <MediaQuery maxWidth={576}>
            <span className="subTitle">(3 projets au maximum)</span>
          </MediaQuery>
          <MediaQuery minWidth={576}>
            <span className="subTitle">(4 projets au maximum)</span>
          </MediaQuery>
          <MediaQuery minWidth={576}>
            <div className="actions">
              <LeftOvalAction text="Valider" addClass="fsp-button" colorConfig={{mainColor, fontColor}} action={()=>this.addProjects()}/>
              <LeftOvalAction text="Annuler" addClass="fsp-button cancel"  action={()=>this.props.close()}/>
            </div>
          </MediaQuery>
        </Row>
        <div className="Ssearch">
          <input placeholder="Rechercher un projet" type="text" value={searchInput} onChange={(e)=>this.handleSearchInput(e)}/>
          <i className="icon material-icons">search</i>
        </div>

        <Row className="AllProjects">
        {selectedProjects.length>0 && selectedProjects.map((projectId,index)=> 
        <Col sm="12" md="6"  key={index}>
          <SprojectSelect key={index} projectId={projectId} toggle={()=>this.toggle(projectId)} checked={projectsToAdd[projectId]}/>
          </Col>)}
        </Row>
        <MediaQuery maxWidth={576}>
          <div className="actions">
            <LeftOvalAction text="Annuler" addClass="Grey fsp-button cancel"  action={()=>this.props.close()}/>
            <LeftOvalAction text="Valider" addClass="fsp-button" colorConfig={{mainColor, fontColor}} action={()=>this.addProjects()}/>
          </div>
        </MediaQuery>
      </div>)
  }
}

export const SselectProjects = connect(
  (state) => ({
    familyService:state.familyService,
    adminConfig: state.adminConfig
  }),
)(SselectProjectsComponent);

