import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyspaceActions from '../../actions/familySpaceActions';
import {Sheader} from "./Sheader";
import {Row, Col, Button} from 'reactstrap';
import { emailRex } from '../../services/utils.service';
import { postFs } from '../../services/api.familyspace.service';

const strongRegex = new RegExp("^(?=.{6,})");
class SinvitationComponent extends React.Component {
  state = {
    seePassword: false,
    mailToken: null,
    password: '',
    confirmPassword: '',
    email: '',
    user: null,
    error: false,
    errorMsg: "",
    space_id: null,
    mailError: false,
    notify: false,
    notifyMsg: ""
  };

  componentDidMount () {
      const search = this.props.location.search;
      let mailToken;
      let space_id = null;
      if (search.split('&').length === 1) {
        mailToken = search.split('=')[1];
      } else {
        mailToken = search.split('&')[0].split('=')[1];
        space_id = search.split('&')[1].split('=')[1];
      }
      const user = this.props.familyspace.user || null;
      this.setState({
        mailToken,
        space_id,
        user,
      });

  };


  handleChange = e => {
    const { name, value } = e.target;
    this.setState({[name]: value});
  };

  checkPassword = () => {
    const { password, confirmPassword } = this.state;

    if (password.length === 0 && confirmPassword.length === 0) {
      return '';
    }
    if (confirmPassword !== password || !strongRegex.test(password) || !strongRegex.test(confirmPassword) ) {
      return 'red';
    } else {
      return 'green';
    }
  };

  checkMail = () => {
    const { email } = this.state;
    if (email === "") return '';
    if (this.validateEmail(email)) {
      return 'green';
    }
    return 'red';
  };

  setPasswordConfirmation () {

    const { mailToken, password, confirmPassword } = this.state;
    this.props.familySpaceAction.setPassword(mailToken, password, confirmPassword)
    .then(response => {
      const { clientId } = this.props.match.params;
      const { user } = this.state;
      if (response.user && response.user.activated) {
        this.props.familySpaceAction.login(response.user.email, password).then(async response => {
        this.props.history.push({
          pathname: `/famille/${clientId}/familyspace`,
        });
        })
      } else if (response.err || response.error) {
        const error = response.err || response.error;
        this.setState({error: true, errorMsg: error});
      } else {
        this.props.history.push({
          pathname: `/famille/${clientId}`,
          search: '?connect',
        });
      }
    });
  };

  validateEmail = email => {
    if (email) {
      return  emailRex.test(email) ? true : false;
    }
    return false;
  };

  renewPassword = async () => {
    const result = await postFs(`/api/familyspace/renew`,{login: this.state.email});
    if (result && !result.error) {
      this.setState({notify: true, notifyMsg: "L'email de réinitialisation du mot de passe a bien été envoyé"});
    }
    else {
      this.setState({error: true, errorMsg: "Une erreur est survenue !"});
    }
  };
  inputPassword = (title,name,value,error,seePassword)=>{
    return  <div className="BlockCreate">
      <div className="TitleInputCreate">{title}</div>
      <div className="InputCreate" style={{borderColor: this.checkPassword()}} >
      <input type={seePassword ? "text":"password"} name={name}
          onChange={this.handleChange} value={value} disabled={error}/>
         <i className="icon material-icons" onClick={()=>this.setState({seePassword:!seePassword})}>remove_red_eye</i>
      </div>
      </div>
  }

  render () {
    const {  password, confirmPassword, error, errorMsg, email, notify, notifyMsg, seePassword } = this.state;
    const familySpaceName = this.props.familyspace.familySpace && this.props.familyspace.familySpace.name ? this.props.familyspace.familySpace.name: "votre espace Famille";
    const welcome = `Vous avez été invité à participer au choix et à la personnalisation d’un monument funéraire pour ${familySpaceName}.`;
    const welcomeDetail1 = `Pour rejoindre vos proches et participer au choix et à la personnalisation d’un monument funéraire, définissez un mot de passe ici.`
    const welcomeDetail2 = `Associé à votre adresse email, il vous permettra d’accéder à l’espace personnel de ${familySpaceName}.`;
    const { mainColor, fontColor } = this.props.adminConfig.config
    return (
      <div className="FamilySpace" id="familyspaceMembers">
            {/* <Sheader {...this.props} invitation={true} /> */}
            <div className="OverallInvitation">
              <div className="MainInvitation">
                {this.props.type === "invite" && <div>
                  <div className="WelcomeInvite"> Bonjour, <div className="Intro">{welcome}</div></div>
                  <Row className="WelcomeSubBlock">
                    <Col md="4"><img src={require(`../../images/space/espace-famille-illsutration.png`)} alt="Espace famille"/></Col>
                    <Col md="8">
                      <div className="WelcomeDetail1">{welcomeDetail1}</div>
                      <div className="WelcomeDetail2">{welcomeDetail2}</div>
                    </Col>                    
                  </Row>
                </div>}
                <div className="PasswordBlock">
                <h3 className="invite">Choisissez un mot de passe :</h3>
                {this.inputPassword("Mot de passe :","password",password,error,seePassword)}
                <p className="passWordGuide" style={{marginTop: "-14px",color: "grey", display:"block",marginLeft: "auto",marginRight: "auto",textAlign: "left",width: "495px",fontSize: "12px"}}>Doit contenir au moins 6 caractères.</p>
                {this.inputPassword("Confirmez votre mot de passe :","confirmPassword",confirmPassword,error,seePassword)}

                {notify && <div className="alert alert-success mt-5"  style={{fontWeight: "bold", fontSize: 16}}>{notifyMsg}</div>}
                {error && <div className="alert alert-danger mt-5" style={{fontWeight: "bold", fontSize: 16}}>{errorMsg}</div>}
                {error &&
                  <input
                    type="text"
                    name="email"
                    placeholder="Renseignez votre adresse email. (Ex : contact@mail.com)"
                    style={{borderColor: this.checkMail()}}
                    onChange={this.handleChange}
                    value={email}
                  />
                }

                <div className="actions" style={{marginTop: 20}}>
                  <Button className="LeftOvalAction fsp-button btn-no-hover" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={() => this.setPasswordConfirmation()} disabled={(password !== confirmPassword) || password.length === 0 || error || notify || !strongRegex.test(password) || !strongRegex.test(confirmPassword)}>Valider</Button>
                  {error && <Button className="LeftOvalAction fsp-button" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={() => this.renewPassword()} disabled={!this.validateEmail(email) || notify}>Renvoyer un email</Button>}
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  };
};

export const Sinvitation = connect(state => ({
  familyspace: state.familyService,
  adminConfig: state.adminConfig
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
}))(SinvitationComponent);
