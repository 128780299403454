import * as types from './actionTypes';

export function toggleBuyingPricesDisplay() {
  return {
    type: types.UI_TOGGLE_SHOW_BUYING_PRICES
  };
}

export function toggleSellingPricesDisplay() {
  return {
    type: types.UI_TOGGLE_SHOW_SELLING_PRICES
  };
}

export function setEditedLineIndex(index) {
  return {
    type: types.UI_SET_CUSTOM_LINE_BEING_EDITED,
    payload: index
  };
}

export function openPatternPicker(pattern) {
  return {
    type: types.UI_OPEN_PATTERN_PICKER,
    payload: pattern
  };
}

export function closePatternPicker() {
  return {
    type: types.UI_CLOSE_PATTERN_PICKER
  };
}

export function addMessage(message) {
  return {
    type: types.UI_ADD_MESSAGE,
    payload: message
  };
}

export function maskMessage(id) {
  return {
    type: types.UI_MASK_MESSAGE,
    payload: {id}
  };
}
export function removeMessage(id) {
  return {
    type: types.UI_REMOVE_MESSAGES,
    payload: {id}
  };
}

export function chooseGraniteOfAccessories(modalParameters) {
  return {
    type: types.UI_EDIT_ACCESSORY_GRANITES_OF,
    payload: modalParameters
  };
}
export function initialChoiceMade(value) {
  return {
    type: types.UI_INIT_CHOICE_MADE,
    payload:value
  };
}
export function isWhiteLabelActive(status) {
  return {
    type: types.WHITE_LABEL_STATUS,
    payload:status
  };
}
