import React from 'react'
import loader from "../images/loader.png"

function FLoader({ size, fspCatalogGranit }) {
  const width = size==="S" ? 64 : 100;
  const height = size==="S" ? 64 : 100;
  const heightContainer = size==="S" ? "100%" : "100vh";
  const position = fspCatalogGranit ? "absolute" : "";
  /*return (<div className="loaderContainer" style={{height: heightContainer}} >
    <div className="sk-cube-grid" style={{width: width, height: height}}>
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
  </div>)*/
  return (<div className="loaderContainer" style={{height: heightContainer, position: position}} >
    <img class="configfLoader" src={loader} style={{width: width, height: height}} />
  </div>)
}

export default FLoader
