import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {connect} from 'react-redux';

import {ListView} from '../../../components/ListView';
import {MonumentCard} from './MonumentCard';
import FLoader from '../../../components/FLoader';

export class MonumentListComponent extends Component {

  hasFeaturedCard = () => {
    // Affichage de la vignette seulement sur la collection "Sélection" et si le prix d'achat est visible pour l'utilisateur
    return false; // Finalement on n'affiche pas la vignette
    //return this.props.collection && this.props.collection === 'featured' && this.props.roles && this.props.roles.indexOf('prix-achat') !== -1;
  };

  getMonumentsList = () => {
    return this.hasFeaturedCard() ? [{
      type: 'image',
      src: '//extranet.gpggranit.com/files/media/primonuments-2017/primonuments-2017.png',
      link: '//extranet.gpggranit.com/files/media/primonuments-2017/',
      alt: `Les primo'numents 2017`,
    },
      ...this.props.monuments] : this.props.monuments;
  };
  renderMonument = monument => {
    if (monument && monument.type === 'image') {
      const Image = (<figure>
        <img decoding = "auto" className="Monument" width="640" height="360" src={monument.src} alt={monument.alt || ''}/>
      </figure>);
      return (
        <div className="MonumentCard">
          { monument.link ? (
            <a className="Monument" href={monument.link}>
              {Image}
            </a>
          ) : Image}
        </div>
      );
    }
    const {mainColor} = this.props.adminConfig.config   
    return (
      <MonumentCard key={monument.reference}
                    monument={monument}
                    hoverInfo={this.props.hoverInfo}
                    onSelect={()=>this.props.onSelect(monument)}
                    family = {this.props.family} 
                    showPrices = {this.props.showPrices} 
                    prices = {this.props.prices}
                    color={mainColor}
                  />
    );
  };
  render() {
     let { loading, family } = this.props;
    return (<>
      {
        loading && family
          ? <FLoader /> 
          : <ListView items={this.getMonumentsList()} renderItem={this.renderMonument} onScroll={this.props.onScroll}/>
      }
      
      </>);
  }
}

MonumentListComponent.propTypes = {
  monuments: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  roles: PropTypes.array,
  collection: PropTypes.string,
};


export const MonumentList = connect(
  (state) => ({
    roles: get(state, 'user.roles'),
    collection: get(state, 'routing.locationBeforeTransitions.query.collection'),
    adminConfig: get(state, 'adminConfig'),
  })
)(MonumentListComponent);
