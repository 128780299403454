import React, { useEffect } from 'react';
import history from "../../history";
import {FengravingPattern} from "./FengravingPattern";
import {Ffooter} from "./Ffooter";
import {Fheader} from "./Fheader";
import FTopBar from './FTopBar';
import { NotifUpdate } from "../configurator/components/NotifUpdate";
import { gtmFamily } from '../../services/gtmFamily';

export const Fpattern = ({match, designType}) => {

  document.body.style.setProperty("background", "unset", "important");
  const { clientId, configId } = match.params;
  function goCustomize (clientId,configId) {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "revenir à ma configuration",
      category: "configurateur",
      subcategory: "configuration",
      type: "cta",
      from: "configuration motif"
    }, { template: "configurateur", subtemplate: "liste motifs" }, {}, "partner", "product", "user", "page")
    // end GTM
    history.push(`/famille/${clientId}/${configId}/customize`)
  };

  return (
    <div>
      <NotifUpdate />
      {designType==='A' && <Fheader match={match}/>}
      {designType==='B' && <FTopBar goCustomize={()=> goCustomize(clientId,configId)} from="Fengraving" />}
      <FengravingPattern match={match} mode="pattern" backAction={() => goCustomize()} />

      {designType === "A" && <Ffooter previous={()=>history.push(`./fgravure`)} next={()=>history.push(`./faccessoire`)} addClass="Wide" />}
  </div>);
}
