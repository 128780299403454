import React, { Component } from "react";
import { connect } from "react-redux";
import {prepareData} from "../../family/components/FpriceList";
import {Table} from "reactstrap";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ThumbDown from "@material-ui/icons/ThumbDown";
import {getDisplayPrice, getDisplayName} from "../utils/display";
import WaitingIcon from "../../../components/WaitingIcon";
import { Modal } from '../../../components/Modal';
import DelIcon  from '../../../images/space/x-circle_1.svg';
import { SprojectFooter } from "../SprojectFooter";
import history from "../../../history";
class SprojectItemInfoComponent extends Component {
  state = {
    project:null,
    imageModalUrl: null,
    loadingImage : true
  };

  componentDidMount(){
    const project = this.props.familyService.projects.find(proj=>proj.id === this.props.projectId);
    if (project){ 
      this.setState({project})
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.projectId !== this.props.projectId) {
      const project = this.props.familyService.projects.find(proj=>proj.id === this.props.projectId);
      if (project){
        this.setState({project})
      }
    }
  }


  toggleImageModal = (imageURL = null) => {
    const { imageModalUrl } = this.state;
    if (imageModalUrl) {
      this.setState({imageModalUrl: null});
    } else {
      this.setState({imageModalUrl: imageURL});
    }
  };

  render () {
    const {user,familyService} = this.props;
    const {project, imageModalUrl, loadingImage} = this.state;
    const likes = project && project.likes ? project.likes.length: 0;
    const dislikes = project && project.dislikes ? project.dislikes.length: 0;
    const data = project && project.config && prepareData(project.config);
    const infoClient = project && familyService.orgsInfos.find(info=>info.clientId=== project.client);
    let Ind = 0;
    let pIndex = this.props.familyService.projects.length
    if (this.props.familyService.projects){
      this.props.familyService.projects.forEach((proj,index) =>{
        pIndex--
        if (proj === project){
          Ind = pIndex
        }
      })
    }
    Ind++
    return(
      <div className="SprojectItemInfo px-2">
        <div className="Name d-flex justify-content-between align-items-center">{getDisplayName(project,Ind)} <img className="delCompProject" src={DelIcon} alt="delete" onClick={() => this.props.delProject(project)}/> </div>
        <hr/>
        {project && project.configImage && <img style={{cursor: 'pointer'}} className="monumentImg compare" src={project.configImage} alt="Monument" onLoad={()=>this.setState({loadingImage: false})} onClick={()=>history.push(`/famille/${user.identity.organization.reference}/familyspace/project/${project.id}`)}/>}
        {(!project || !project.configImage) && <WaitingIcon  designType="B" test={false} />}
        {!!loadingImage && <div className="monumentLoading"/>}

        {/* demander un devis */}
        {project && <SprojectFooter fromCompare={true}  project={project} config={project.config} docs={project && project.docs}/>}
        
        {getDisplayPrice(project)}
        <p>* Prix indicatif à valider avec votre marbrier</p>
        
        <Table >
          <tbody>
            <tr>
              <td>
                <ThumbUp/>
                J'aime</td><td className="Tprice">{likes}
                </td>
            </tr>
            <tr>
              <td>
                {<ThumbDown/>}
                Je n'aime pas</td><td className="Tprice">{dislikes}
                </td>
            </tr>
            {data && data.lines.map((line,index)=>
            <tr key ={index}>
                <td>{line.lines.map((item, index)=><div key={index}>{item}</div>)}</td>
                <td className="Tprice"><strong>{isNaN(line.price) ? line.price: line.price +"€" }</strong></td>
              </tr>)}
          </tbody>
        </Table>
        { infoClient && <div className="clientInfo">
          <img className="compareClientInfoLogo" src={infoClient.logo} alt={infoClient.name}/>
          <span className="compareClientInfoName">{infoClient.name}</span>
          <p className="compareClientInfoAddress"><span className="street">{`${infoClient.street}`}</span><span className="postalncity">{`${infoClient.postal} ${infoClient.city}`}</span>
          <span className="compareClientInfoPhone">Tél {infoClient.phone.replaceAll(' ','.')}</span>
          </p>
        </div>}

        <Modal
          isOpen={!!imageModalUrl}
          onRequestClose={() => this.setState({imageModalUrl: null})}
          className="familyspace-compare-modal"
          overlayClassName="familyspace-compare-modal-overlay"
        >
         {project && project.configImage && <img className="monumentImg-compare" src={project.configImage} alt="Monument" onClick={() => this.toggleImageModal()} />}
        </Modal>
    </div>)
}
}
export const SprojectItemInfo = connect(
  (state) => ({
    familyService:state.familyService,
    user:state.user,
  }),
)(SprojectItemInfoComponent);

