import * as apiService from "../services/api.service";

export function manageStats(props,step) {
  let configId = props.match.params.configId;
  let session = getFamilySession ();
  apiService.post(`/api/family/stats/${configId}`,{step, session});
}

 function uuidv4 () {
  let uuid = "", i, byte

  for (i = 0; i < 20; i++) {
    switch (i) {
      case 4: case 7: case 10: case 13:
        uuid += "-"
        continue
      case 8: // version: V4
        byte = (Math.floor((Math.random() * 255)) & 0x4f) | 0x40
        break
      case 11: // variant: RFC4122
        byte = (Math.floor((Math.random() * 255)) & 0x1f) | 0x80
        break
      default:
        byte = Math.floor((Math.random() * 255))
    }

    if (byte < 16) uuid += "0"
    uuid += byte.toString(16)
  }

  return uuid
};
 function getFamilySession () {
  let sessionid = localStorage.getItem("gpg-family-session");
  if (sessionid) {
    return sessionid;
  } else {
    sessionid = uuidv4();
    localStorage.setItem("gpg-family-session", sessionid);
    return sessionid;
  }
};
 