import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from '../../../history';
import * as familyspaceActions from '../../../actions/familySpaceActions';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import { emailRex } from '../../../services/utils.service';
import { Button} from 'reactstrap';
import { Modal } from '../../../components/Modal';
import clientLogo, {ClientLogo} from "../../configurator/ClientLogo";
import { gtmFamily } from "../../../services/gtmFamily"

const strongRegex = new RegExp("^(?=.{6,})");
class ScreateSpaceComponent extends React.Component {
  state = {
    spaceName: '',email: '',name: '',password: '',confirmPassword: '',
    seePassword: false,mailError: false,waitingCreate: false,errorCreate: false};

  componentDidMount () {
    const { filledMail } = this.props;
    if (filledMail && this.validateEmail(filledMail)) {
      this.setState({email: filledMail});
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({[name]: value});
  };

  validateEmail = email => {
    if (email) {
      return  emailRex.test(email) ? true : false;
    }

    return false;
  };

  checkPassword = () => {
    const { password, confirmPassword } = this.state;
    if (password.length === 0 && confirmPassword.length === 0) {return '';}
    if (confirmPassword !== password) {return 'red';}
    if (!strongRegex.test(password) || !strongRegex.test(confirmPassword)){return 'red';}
    else {return 'green';}
  };
  createSpace = async () => {
    this.setState({waitingCreate:true});
    const { fromMarber, formClient } = this.props;
    const res = await this.props.familySpaceAction.register(this.state, !fromMarber, formClient);
    this.setState({waitingCreate:false});
    if (res && res.error){
      this.setState({errorCreate:res.error})
    }
    else {
      if (this.props.origin === "configurator"){
        this.props.afterCreation(this.state);} // Si on vient du config client on efface l' écran
      else {
        const clientId = this.props.user.identity.organization.reference;
        if (this.props.origin === "familyConfig"){ // On vient d'une configuration famille on la sauve avec un nouvel email
          this.props.updateConfigurationAction.setNewfamilyInfosEmail(this.state.email).then(response => history.push(`/famille/${clientId}/familyspace`))
        }
        else{
          const {save}  = this.props;
          const { email, password } = this.state;
          this.props.familySpaceAction.login(email, password)
          if (save){
            //save config
            await this.props.updateConfigurationAction.setNewfamilyInfosEmail(email);
          }
          history.push(`/famille/${clientId}/familyspace`);
        }
      } //Sinon on entre dans l'espace famille directement
    }

  };

  passwordBlock (field,text){
    const {seePassword} = this.state;
    return ( <div className="BlockCreate">
      <div className="TitleInputCreate required">{text}</div>
      <div className="InputCreate" style={{borderColor: this.checkPassword()}} >
        <input
          type={seePassword ? "text":"password"}
          onChange={e=>this.handleChange(e)}
          value={this.state[field]}
          name={field}
        />
        <i className="icon material-icons" onClick={()=>this.setState({seePassword:!seePassword})}>remove_red_eye</i>
      </div>
      {field==='password' && <p className="passWordGuide" style={{color: "grey",fontSize: "14px",display:"block",marginLeft: "auto",marginRight: "auto",textAlign: "left"}}>Doit contenir au moins 6 caractères.</p>}
    </div>)
  };

  inputBlock (field,text,placeholder) {
    const { mailError, email } = this.state;

    return ( <div className="BlockCreate">
      <div className="TitleInputCreate">{text}</div>
      <input
        className={(field === "email" && mailError) ? "error InputCreate" : "InputCreate"}
        onBlur={() => field === "email" ? this.setState({mailError: !this.validateEmail(email)}): null}
        type="text"onChange={e=>this.handleChange(e)}
        value={this.state[field]}
        name={field}
        placeholder={placeholder}
      />
    </div>)
  };

  returnToLogin = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "vous possédez déjà un espace famille - connectez-vous",
      category: "espace famille",
      subcategory: "login",
      type: "lien",
      from: "login création compte"
    }, { template: "espace famille", subtemplate: "login" })
    // end GTM
    this.props.close();
  }

  cancelAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "annuler",
      category: "espace famille",
      subcategory: "login",
      type: "lien",
      from: "login création compte"
    }, { template: "espace famille", subtemplate: "login" })
    // end GTM
    this.props.close();
  }

  checkEnabledValidate (){

  }

  render () {
    const { password, confirmPassword, email, name, spaceName,waitingCreate,errorCreate } = this.state;
    const {origin,close, familySpace} = this.props;
    const theClass = origin === "configurator" ? "ScreateSpaceConfig" : "ScreateSpace";
    const Title = origin === "configurator" ? "Créer un Espace famille" : "Pour créer votre espace famille";
    const validateText = origin === "configurator" ? "Créer un Espace famille" : "Valider";
    const passwordOK = strongRegex.test(password) ? password === confirmPassword : false;
    const { mainColor, fontColor } = this.props.adminConfig.config
    return (
      <div className={theClass}>
        {/*<img className="clientLogo" src={logo} alt="Logo" />*/}
        <ClientLogo replaceType={'grey'} />
        <div className="ScreateTitle">{Title}</div>
        {this.inputBlock("email","Renseignez une adresse email, pour recevoir l’url de connexion :","Ex : contact@mail.com")}
        {this.inputBlock("spaceName","Nommez l'espace famille :","Ex : Famille Deschamps")}
        {this.inputBlock("name","Renseignez un prénom ou pseudonyme :","Ex : Jean")}

        {this.passwordBlock("password","Choisir un mot de passe :","")}
        {this.passwordBlock("confirmPassword","Confirmer votre mot de passe :","")}

        <div className="actions">
          {!waitingCreate && <Button className="LeftOvalAction fsp-button validate" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={() => this.createSpace()} disabled={(!passwordOK || email.length === 0 || name.length === 0 || spaceName === 0 || !this.validateEmail(email))}>{validateText}</Button>}
          {waitingCreate &&  <span className="WaitRenew"><img src={require(`../../../images/loader.gif`)} alt="Chargement"/> Envoi en cours</span>}
          <Button className="LeftOvalAction Grey fsp-button cancel" onClick={() => this.cancelAction()} >Annuler</Button>
        </div>
        {origin!=="configurator" &&
          <div className="connect fsp-link" onClick={()=>this.returnToLogin()}>Vous possédez déjà un espace famille ? Connectez-vous</div>}

        <Modal isOpen={errorCreate}onRequestClose={() => this.setState({errorCreate: false})} className="ClientErrorModal">
              <div className="errorContent">{errorCreate}</div>
        </Modal>
      </div>
    );
  };
};

export const ScreateSpace = connect(state => ({
  familyspace: state.familyService,
  user: state.user,
  adminConfig: state.adminConfig
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
  updateConfigurationAction: bindActionCreators(updateConfigurationActions, dispatch),
}))(ScreateSpaceComponent);
