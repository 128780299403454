import React, { useState } from 'react';
import { Group299 } from '../../../components/svg';
import { Modal } from "reactstrap";
import MediaQuery from "react-responsive/src";
import { gtmFamily } from '../../../services/gtmFamily';

function FquoteHelp({color}) {
    const [help, setHelp] = useState(false)
    const showAnswer = () => {
        setHelp(!help)
        // GTM
        if (!help) {
            gtmFamily().ctaOfConfiguration({
              name: "pourquoi prendre contact avec un artisan marbrier",
              category: "info marbrier",
              subcategory: "",
              type: "picto",
              from: "configuration tarif"
            }, { template: "configurateur", subtemplate: "produit" })
        }
        // end GTM
    }

    const openStyle = help ? { display: "block" } : { display: "none" }
    return (
        <div class='FHelper-container' style={{position: "relative"}}>
            <div className="FquoteHelp" onClick={()=> showAnswer()}>
                <Group299 />
                <span>Pourquoi prendre contact avec un artisan Marbrier ?</span>
            </div>
            {/* DESKTOP */}
            <MediaQuery minWidth={768}>
                <div style={openStyle} className="FamilyHelpText md-whiteframe-8dp">
                    <div className="arrow"/>
                    <div>Parce que chaque monument est unique, l’expérience d’un Marbrier est indispensable</div>
                    <div className="headText" style={{"--f-main-color":color}}>Il est en mesure de vous :</div>
                    <ul className="itemText" style={{"--f-main-color":color}}>
                    <li>Renseigner sur les dimensions spécifiques de votre monument</li>
                    <li>Conseiller sur le choix du granit</li>
                    <li>Accompagner jusqu’à la pose de votre monument</li>
                    </ul>
                </div>
            </MediaQuery>
            {/* Mobile */}
            <MediaQuery maxWidth={768}>
                <Modal isOpen={help} toggle={()=>setHelp(false)} className="FamilyHelpText">
                    <div className="arrow"/>
                    <div>Parce que chaque monument est unique, l’expérience d’un Marbrier est indispensable !</div>
                    <div className="headText" style={{"--f-main-color":color}}>Il est en mesure de vous :</div>
                    <ul className="itemText" style={{"--f-main-color":color}}>
                    <li>Renseigner sur les dimensions spécifiques de votre monument</li>
                    <li>Conseiller sur le choix du granit</li>
                    <li>Accompagner jusqu’à la pose de votre monument</li>
                    </ul>
                </Modal>
            </MediaQuery>
        </div>
    )

}

export default FquoteHelp;