import { getStore } from '../../store';

export function introIsAvailable(step=null, modal=null) {
    const { configIntro = null } = getStore().getState().configurator || {};
  
    const categories = {
      HeadstonePicker: 'monument',
      GlobalGranitePicker: 'granite',
      FrameGranitePicker: 'granite',
      VeneerGranitePicker: 'granite',
      AccessoriesGranitePicker: 'granite',
      PatternPicker: 'pattern',
      ReservePicker: 'pattern',
      PatternFace: 'pattern',
      AccessoryPicker: 'accessory',
    };
  
    function getStep(modal) {
      return categories[modal] || null;
    }
  
    if (configIntro) {
      if (modal && getStep(modal)) {
        return configIntro.some(element => element.step === getStep(modal) && element.is_modal && element.modal === modal && element.data.length > 0);
      }
      if (step) {
        return configIntro.some(element => element.step === step && !element.is_modal && element.data.length > 0);
      }
    }
    return false;
  }