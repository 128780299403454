import React from "react";
import {isFamilyConfigProject}  from "./utils"

export const getDisplayPrice = (project) => {
  const front = isFamilyConfigProject(project) ? 'À partir de ':'';
  return (
    <div className="Topprice">
      {front} <strong>{project && project.price ? `${project.price}€` : ""}</strong>
    </div>
  );
}

export const getDisplayName = (project, index)=>{
  return <div className="title">{project && project.name ? project.name : `Projet ${index}`}</div>
}

export const CloseModal = ({action})=>{
return (<div className="Sclose" onClick={() => action()}>
          <i className="icon material-icons">&#xE14C;</i>
        </div>)
}
