import React, {Component} from 'react';
import {FacebookShareButton,WhatsappShareButton,EmailShareButton} from "react-share";
import { FbLogo,WhatsUpLogo,MailLogo } from './svg';
import {isSmartphone} from "../services/domFunctions";
import {ManualMail} from "../components/ManualMail";

export class SocialNetwork extends Component {

state={openBar:true,emailOpen : false}
componentDidMount(){}

render(){
  const { getSocialStats, closeFromModal, newDesign } = this.props;

   if (!this.state.emailOpen){
    return (
      <div className="SocialBar" >
         <FacebookShareButton url={window.location.href} onClick={() => getSocialStats('facebook')}>
           <FbLogo className="SocialIcon" size={newDesign ? 50 : 30}/>
         </FacebookShareButton>

         {isSmartphone () &&
          <EmailShareButton
            url={window.location.href}
            onClick={() => {
              getSocialStats('mail');
            }}
          >
            <MailLogo className="SocialIcon"/>
          </EmailShareButton>
         }
         {!isSmartphone () &&
            <button
              className ="ShareEmailButton"
              onClick={()=>{
                getSocialStats('mail');
                this.setState({emailOpen:true});
              }}
            >
              <MailLogo className="SocialIcon" size={newDesign ? 50 : 30}/>
            </button>
          }

         <WhatsappShareButton url={window.location.href} onClick={() => getSocialStats('whatsapp')}>
           <WhatsUpLogo className="SocialIcon" size={newDesign ? 50 : 30}/>
         </WhatsappShareButton>
       </div>
      )}
  else {
    return(
      <ManualMail url={window.location.href} {...this.props} closeFromModal={() => closeFromModal && closeFromModal()} />
    )
  }
 }
}
