import React from 'react';
import Spinner from './Spinner';
import FLoader from './FLoader'


export default function ({big=false,top=200,loading, family = true}) {

  return (
    <div>
      {loading && !family && <Spinner big={big} top={top} />}
      {family && <FLoader size="M" />}
    {!loading &&<div className="NoConfig" >
        Cette configuration n'a pas été trouvée {family ? ", la durée de conservation est limitée": "" }
      </div>}
    </div>
  );
};
