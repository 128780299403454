import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import { Modal } from "../../components/Modal";
import familyB from "../../images/space/familyBlue.svg"
import familyA from "../../images/space/familyBlueA.svg"
import {SFamilyLoginForm} from '../family_space/SFamilyLoginForm';
import * as familyspaceActions from '../../actions/familySpaceActions';
import {LeftOvalAction} from "../../components/LeftOvalAction";
import {imageExists} from "../helpers/assetsHelpers";

class SfamilyIconComponent extends React.Component {
  state = {
    text: "Espace famille",
    srcIcon: this.props.designType === "A" ? familyA : familyB,
    modalVisible: false,
    accessUrl: `/famille/${this.props.clientId}/familyspace`,
    catalog: false,
  };

  componentDidMount = () => {
    const arrayPath = history.location.pathname.split('/famille/')[1];
    const usefullPath = arrayPath.split('/');
    if (usefullPath.length === 1 || (usefullPath.length === 2 && (usefullPath[1] === "" || usefullPath[1] === "/"))) {
      this.setState({catalog: true});
    }
  };

  familyspaceAccess = () => {
    if (this.props.familySpace.user) {
      history.push(this.state.accessUrl);
    } else {
      this.setState({modalVisible: true});
    }
  };

  hideModal = () => {
    this.props.familySpaceAction.closeLoginModal();
    this.setState({modalVisible: false}, () => {
      this.setState({modalVisible: false})
    });
  }

  render () {
    const { designType, familySpace } = this.props;
    const { text, srcIcon, modalVisible, accessUrl, catalog } = this.state;
    const spaceName = familySpace && familySpace.user && familySpace.familySpace &&familySpace.familySpace.name ?
                      familySpace.familySpace.name :"";

    return (
      <div className={`FamilyAccess ${designType}`} style={{right: catalog ? 0 : 165}} onClick={() => this.familyspaceAccess()}>
        {/*<div className="Icon">
            <img  src={srcIcon} alt="Famille"/>
            <div >{`${text} ${spaceName}`}</div>
        </div>*/}
        <div className="AccessSpace">
          {/*<div className ="AccessFamilySpace" onClick={()=> this.familyspaceAccess()}>{`${text} ${spaceName}`}</div> :*/}
          <LeftOvalAction text={`${text} ${spaceName}`}  addClass="BlueA" icon="family" action={()=> this.familyspaceAccess()}/>
        </div>
        <Modal
          isOpen={modalVisible || familySpace.loginModal}
          onRequestClose={() => this.hideModal()}
          className="familyspace-login-modal"
          overlayClassName="familyspace-login-overlay"
        >
          <SFamilyLoginForm url={accessUrl} close={() => this.hideModal()} save={familySpace.loginModal } />
        </Modal>
      </div>
    )
  };
};

export const SfamilyIcon = connect(state => ({
  familySpace: state.familyService
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
}))(SfamilyIconComponent);
