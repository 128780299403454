import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyspaceActions from '../../../actions/familySpaceActions';
import { Button} from 'reactstrap';
import { isFamilySpaceOn} from '../../../services/utils.service';

class SclientComponent extends React.Component {
  state = {openCreate:false,openSearchEmail:false,openConfirm:false,openSaved:false,sendQuote:false,files:[],
           email:"",found:false,name:"",errorMessage:false,check: false, error:"", openError:false,spaceId:''
          };


  async componentDidMount(){
    if (this.checkDisplayButton(true)){
      if (this.props.familyspace.candidates === null){
        const candidates = await this.props.familySpaceAction.getClientCandidates(this.props.user.identity.organization.reference);
        if (candidates && candidates.length>0){this.setState({check:true})}
      }else if (this.props.familyspace.candidates && this.props.familyspace.candidates.length>0){
        this.setState({check:true});
      }
    }

  }


  checkDisplayButton (check){
    if (isFamilySpaceOn(this.props.user)){
      if (this.props.conf.newfamilyInfos && this.props.conf.newfamilyInfos.email){
        return false; // Déjà sauvegardé on n'affiche pas le bouton
      }
      if (check){
        return true;
      }
    }
    return false
  }
  callExtranet(action,reference){
    window.top.postMessage({action, configuration: { reference }},process.env.REACT_APP_EXTRANET);
  }

  render () {
    const {check} = this.state;
    const {conf} = this.props;
    return (
        this.checkDisplayButton(check) && !this.props.isWhiteLabelActive &&
          <button 
            className="text-dark button-secondary button-small saveFspaceButton" 
            onClick={()=>this.callExtranet("configuration.add_to_family_space",conf.reference)}>
            <span className="family-icon icon-medium text-dark-icon" ></span>
            <span>Sauvegarder dans un espace famille</span>
          </button>
    );
  };
};

export const Sclient = connect(state => ({
  familyspace: state.familyService,
  user:state.user,
  isWhiteLabelActive:state.ui.isWhiteLabelActive,
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
}))(SclientComponent);
