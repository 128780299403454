import React, { Component } from "react";
import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as configurationActions from '../../../actions/configurationActions';
import * as familySpaceAction from '../../../actions/familySpaceActions';
import {Modal} from 'reactstrap';
import {CloseModal} from './display';
import * as familyspaceActions from '../../../actions/familySpaceActions';
import {LeftOvalAction} from "../../../components/LeftOvalAction";

export class SchangeNameComponent extends Component {

  state = {projectName:""};

  componentDidMount(){
    const name =  this.props.project ? this.props.project.name : "";
    this.setState({projectName:name})
  }


  changeName = e => {
    const { projectName } = this.state;
    if (projectName){
      this.props.familyspaceActions.updateOneProject(this.props.project.id, {name: projectName})
      .then(response => this.props.close());
    }
  }
  validate = e =>{
    if (this.props.variante){this.createVariantClient()}
    else{this.changeName(e)}
  }
  createVariantClient = async () => {
    const {project} = this.props;
    // On récupère le bon CA famille pour envoyer vers le bon lien
    const candidates = await this.props.familySpaceAction.getClientCandidates(project.client);
    const theClientId = candidates && candidates.length >0 ? candidates[0]:project.client;
    this.props.configurationActions.createVariant({reference:project.config_id},"fgranit",theClientId, theClientId,this.state.projectName);
  }
  render() {
    const variante = this.props.variante;
    const { mainColor, fontColor } = this.props.colorConfig || {};
    return(
        <Modal isOpen={true} className="SchangeName">
            <CloseModal action={()=>this.props.close()} />
            {!variante ? <div className="SBigTitle">Modifier le nom du projet </div> : <div className="SBigTitle">Créer une variante du projet 
            <span>{this.props.project.name}</span> </div> }
            <input type ="text" value={this.state.projectName} className="SsingleInput"
                   onChange={e => this.setState({projectName:e.target.value})} />
            <div className="actions">
              <LeftOvalAction colorConfig={{mainColor: mainColor || null, fontColor: fontColor || null}}  text="Valider" addClass="fsp-button" action={()=>this.validate()}/>
              <LeftOvalAction text="Annuler" addClass="fsp-button cancel" action={()=>this.props.close()}/>
            </div>
        </Modal>
    )
  }
};

export const SchangeName = connect(state =>
  ({ familyService: state.familyService,}), dispatch =>
  ({familyspaceActions: bindActionCreators(familyspaceActions, dispatch),
    configurationActions: bindActionCreators(configurationActions, dispatch),
    familySpaceAction: bindActionCreators(familySpaceAction, dispatch) })
)(SchangeNameComponent);

/*  export const SprojectHeaderActions = connect(state =>
  ({ familyService: state.familyService,}), dispatch =>({
    configurationActions: bindActionCreators(configurationActions, dispatch),
    familySpaceAction: bindActionCreators(familySpaceAction, dispatch)})
)(SprojectHeaderActionsComponent); 
 */