import React, { Component } from 'react';
import Select from 'react-select';
import { Input } from 'reactstrap';
import * as _ from 'lodash';

export class AddressAutocomplete extends Component {
  state = { town: '', selectTowns: false };

  componentWillMount() {
    const { configuration, configPath } = this.props;
    this.setState({ town: _.get(configuration, configPath) || '' });
  }

  handleChangeTown = async (e) => {
    e.persist();
    const { configPath, onChange } = this.props;
    const { value } = e.target;
    onChange(configPath, value);
    this.setState({ town: value });

    if (this.props.test() && value.length > 3) {
      const trimmedValue = value.trim();
      const url = `https://api-adresse.data.gouv.fr/search/?q=${trimmedValue}&city=${trimmedValue}&limit=10`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        });

        if (response.ok) {
          const result = await response.json();
          if (result && result.features && result.features.length > 0) {
            const towns = result.features.map((item) => {
              const { name, city, postcode } = item.properties;
              const label = name === city ? `${city} ${postcode}` : `${name} ${city} ${postcode}`;
              return { value: item, label, name };
            });

            this.setState({ selectTowns: towns });
          } else {
            this.setState({ selectTowns: false });
          }
        }
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    } else {
      this.setState({ selectTowns: false });
    }
  };

  selectPlace = (option) => {
    this.setState({ selectTowns: false, town: option.name });
    this.props.changeSelectedTown(option);
  };

  handleOutsideClick = () => {
    if (this.state.selectTowns) {
      this.setState({ selectTowns: false });
    }
  };

  handleBlur = () => {
    const { configPath, onSave } = this.props;
    onSave(configPath);
  };

  render() {
    const { town, selectTowns } = this.state;
    return (
      <div className="select-address" onClick={this.handleOutsideClick}>
        <Input
          name="FrenchAdressTown"
          autoComplete="no"
          value={town}
          required
          placeholder={this.props.placeholder}
          onChange={this.handleChangeTown}
          onBlur={this.handleBlur}
        />
        {selectTowns && (
          <Select
            options={selectTowns}
            menuIsOpen
            onChange={(option) => this.selectPlace(option)}
            isSearchable={false}
          />
        )}
      </div>
    );
  }
}
