import {typeEngraving} from '../pages/configurator/components/Fabric';

export const isFromStock = (configuration) => configuration.order && configuration.order.stockId;

export const checkModifiablePattern = (configuration,pattern,mode='')=> {
  if (isFromStock(configuration) && pattern.reference === configuration.order.stockPatternId  ){
    return false
  }
  if (mode !='pattern' && configuration.configType == 'family') {
    return false
  }
  return true;
}
  
export const isQuote = (configuration)=> configuration && !!configuration.quoteId

export const canDuplicate = (configuration,type,family=false) => !family && (!isQuote(configuration) || (type === typeEngraving))