import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminConfigActions from "../../actions/adminConfigActions";
import history from "../../history";
import "bootstrap/dist/css/bootstrap.min.css";
import {Row,Col,Button,Table,Input} from "reactstrap";
import * as apiService from "../../services/api.service";
import {AdminFamily} from "./AdminFamily";
import {convertDate} from "../../services/utils.service";
import Select from 'react-dropdown-select';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class AdminFamilyListComponent extends Component {
  state = {
    list: [],
    editList: false,
    editConfig: false,
    client: {},
    searchInput:"",
    listOrgs:null,
    baseConfigMonuments: [],
  };

  componentDidMount() {
    this.updateList();
  }
  updateList(){
    apiService.get(`/api/familyconfig/list`).then(list => {
      const { config } = list.find(item => item.clientId === "0");
      this.setState({ list: list, baseConfigMonuments: config ? config.catalogListMonuments : [] });
    });
  }
  handleKeyPress(target) {
    if(target.charCode===13){
     this.search();
    }
  }
  search() {
    apiService.get(`/api/organizations/searchmain?keyword=${this.state.searchInput}`).then(list => {
      let newList=[];
      if (list && list.length>0){
        newList = list.map(item=>{
          return {...item,label:`${item.clientId} ${item.name} ${item.city}`,value:item.clientId}
        });
      }
      this.setState({listOrgs:newList});
    });
  }
  updateSearch(e){
      this.setState({searchInput:e.target.value})
  }
  selectOrganization(values) {
    if (values && values.length>0){
      const {name,city,clientId} = values[0];
      this.setState({listOrgs:null,editConfig:true,editList:false,client:{clientId:clientId,clientName:name,clientTown:city}});
  }
}
  goToConfig (client) {
    this.setState({ client: client, editConfig: true });
  };
  deleteConfirmConfig  (clientId) {
      confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='SelDeleteConf'>
            <p>Cette configuration famille sera effacée.<br/> Confirmez-vous ?</p>
            <Row>
              <Col md="6"> <Button onClick={() => {this.deleteConfig(clientId);onClose();}}>Oui</Button></Col>
              <Col md="6"> <Button onClick={onClose}>Non</Button></Col>
            </Row>
          </div>
        );
      }
     });
}
  deleteConfig (clientId) {
    apiService.del(`/api/familyconfig/${clientId}`).then(list => {
      this.setState({ list: list });
    });
  };
  async tryConfig (clientId) {
    await this.props.actions.initConfig(clientId);
    history.push(`/famille/${clientId}`);
    window.location.reload();
  }
  setDefautClient () {
    this.setState({client:{clientId:"0",clientName:"Configuration de base"},editConfig:true});
  }

  render() {
    // Check is the call called by iframe admin
    let visibility = "unset"
    if(window.location.href.includes("adminfamilyList")){
      visibility = "hidden"
    }
    return (
      <div className="AdminFamilyList">
        {!this.state.editList && !this.state.editConfig && (
          <div>
            <div className="AdminFamilyHeader">
              <button className="AdminFamilyBack" style={{visibility: visibility}} onClick={() => history.push("/")}>
                <i className="icon material-icons AdminFamilyBackArrow">home</i>Accueil
              </button>
            </div>            
            <Button className="AdminFamilyDefConf" onClick={() => this.setDefautClient()}>
              Accéder à la configuration par défaut
            </Button>
            <Button className="AdminFamilyAddConf" onClick={() => this.setState({ editList: true })}>
              {" "}
              Créer une configuration pour un client
            </Button>
            <Table hover size="sm">
              <thead>
                <tr>
                  <th className="AdminFamilyTitle" colSpan="10">
                    Liste des configurations familles
                  </th>
                </tr>
                <tr>
                  <th>Id client</th>
                  <th>Nom client</th>
                  <th>Ville client</th>
                  <th>Date de modification</th>
                  <th>Voir/Modifier</th>
                  <th>Supprimer</th>
                  <th>Tester</th>
                </tr>
              </thead>
              <tbody>
                {this.state.list.map((config, index) => {
                  return (
                    <tr key={index}>
                      <td>{config.clientId}</td>
                      <td>{config.clientName}</td>
                      <td>{config.clientTown}</td>
                      <td>{convertDate(config.updatedAt)}</td>
                      <td style={{ cursor: "pointer" }} onClick={() => this.goToConfig(config)}>
                        <i className="icon material-icons">visibility</i>
                      </td>
                      <td style={{ cursor: "pointer" }} onClick={() => this.deleteConfirmConfig(config.clientId)}>
                        <i className="icon material-icons">delete</i>
                      </td>
                      {config.clientId !== "0" && <td style={{ cursor: "pointer" }} onClick={() => this.tryConfig(config.clientId)}>
                        <i className="icon material-icons">touch_app</i>
                      </td>     }
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        {this.state.editList && !this.state.editConfig && (
          <div>
            {!this.state.listOrgs && <Input type="search" className="AdminFamilyInput" placeholder="Code ou nom client"
             onKeyPress={(e)=>this.handleKeyPress(e)} onChange={(e)=>this.updateSearch(e)} />}
            {this.state.listOrgs && <div>
              <Button  className="AdminFamilyButton" onClick={() => this.setState({ listOrgs:null,editList: false })}>Annuler</Button>
              < Select className="SelectAdmin"   onChange={(e)=>this.selectOrganization(e)}
            options={this.state.listOrgs} keepOpen={true}/>
            </div>}
            <div>
              <Button  className="AdminFamilyButton" onClick={() => this.search()}>Rechercher</Button>
              <Button  className="AdminFamilyButton" onClick={() => this.setState({ editList: false })}>Annuler</Button>
            </div>
          </div>
        )}
        {this.state.editConfig && (
          <AdminFamily
            client={this.state.client}
            base={this.state.baseConfigMonuments}
            exit={() => this.setState({ editConfig: false })}
            updateList= {() => this.updateList()}
            testConfig={(clientId)=> this.tryConfig(clientId)}
          />
        )}
      </div>
    );
  }
}
export const AdminFamilyList = connect(
  state => ({
    adminConfig: state.adminConfig,
    catalog: state.catalog,
  }),dispatch => ({actions: bindActionCreators(adminConfigActions, dispatch),}))(AdminFamilyListComponent);

