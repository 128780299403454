import React, {Component} from "react";
import {connect} from "react-redux";
import {isSmartphone} from "../../../services/domFunctions";
import {getFsGcsUrl} from "../../../services/api.familyspace.service";
import {bindActionCreators} from "redux";
import * as familyspaceActions from '../../../actions/familySpaceActions';
import {ChatBubble, Close, Image, Send} from '@material-ui/icons';
import {CircularProgress} from '@material-ui/core';
import {Modal} from '../../../components/Modal';
import chat from '../../../images/icon_chat_familyspace.png'
import {saveFiles} from '../utils/utils';
import { gtmFamily } from '../../../services/gtmFamily';

class SactivityComponent extends Component {
  state = {
    socket: false,
    currentMsg: "",
    messages: [],
    dropdownOpen:false,
    open: false,
    unread: [],
    buttonSpinner: false,
    imageModal: false,
    imageModalURL: null,
    hover: false,
  };

  scrollToBottom = () => {
    this.eventsEnd.scrollIntoView({ behavior: "smooth" });
  }

  async componentDidMount() {
    await this.props.familySpaceAction.getMessages();
    await this.props.familySpaceAction.getMembers();
    // Init firsts messages
    this.setState({
      messages: this.props.familyService.messages,
    });
    this.startListening(this.props.familyService.messages);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*this.scrollToBottom();*/
    this.startListening(this.state.messages);
    if (!!this.state.open){
      this.scrollToTheEnd()
    }
  }

  startListening = (messages) =>{
    if (this.props.familyService.socket && !this.state.socket){
      this.setState({socket:true});
      this.props.familyService.socket.on('chat', data => {
        if(data && data.type ==="chat"){
          let chatInfo = {...data};
          let cloneMessages =  [...this.state.messages];
          if (chatInfo.familySpace) {delete chatInfo.familySpace}
          // chatInfo.member_id = data.familySpace.member_id;
          cloneMessages.push(chatInfo);
          this.setState({messages:cloneMessages, currentMsg: ''}, () => this.readingCheck(data));
        }else{
          this.setState({ currentMsg: ''}, () => this.readingCheck(data));
        }
        
      });
    }else{
      //this.readingVerif();
    }

  }

  readingVerif () {
    const events = this.getActivity();
    const currentUser = this.props.familyService.user.id;
    const { unread } = this.state;
    events.forEach(event =>{
      if (event.read_by && event.type==="chat") {
        let readed = false
        event.read_by.forEach( elem =>{
          if (event.read_by.id !== currentUser)
          {
            readed = true
          }
        })
        if (!readed){
          let bFound = false
          unread.forEach(elem => {
            if (elem === event){
              bFound = true
            }
          })
          if (!bFound){
            unread.push(event)
          }
        }
      }
    })
  }

  readingCheck = async elem => {
    const { unread, open } = this.state;
    const sUser = this.props.familyService.user;
    if (elem.type === 'chat' && elem.type !== "files"  && unread.filter(u => u.id === elem.id && u.type === elem.type).length === 0) {

      if (open && elem.read_by.filter(rbUser => rbUser.id === sUser.id).length === 0) {
        elem.read_by.push(sUser);
        return await this.props.familySpaceAction.markAsRead(elem);
      }

      if  (elem.type==="chat" && elem.read_by.filter(rbUser => rbUser.id === sUser.id).length === 0) {
        unread.push(elem);
        this.setState({unread});
      }
    }
  };

  getActivity () {
    const { projects, members, user, files } = this.props.familyService;
    const events = [];
    /*projects && projects.forEach(project => {
      if (project.likes && project.likes.length){project.likes.forEach(like => events.push({...like,type:"like",project_name:project.name,project_id:project.id}));}
      if (project.dislikes && project.dislikes.length){project.dislikes.forEach(dislike => events.push({...dislike,type:"dislike",project_name:project.name,project_id:project.id}));}
      if (project.comments && project.comments.length){project.comments.forEach(comment => events.push({...comment,type:"comment",project_name:project.name,project_id:project.id}));}
    });*/

    files && files.length && files.filter(p => (p.mime_type && p.mime_type.split('/')[0] === "image")).forEach(file => events.push({...file, type: "file", url: getFsGcsUrl(file.space_id,`${file.uuid}.${file.ext}`)}));

   /* if (members && members.length > 0){
      members.forEach(m => {
        events.push({type:"member",tstamp: m.tstamp ,name: m.name})
        if (m.activationDate){
          events.push({type:"member-join",tstamp: m.activationDate ,name: m.name})
        }
      });
    }
*/
    const fullEvents = events.concat(this.state.messages);

    fullEvents.map(e => {
      if (!this.isRead(e) && e.type === "chat" && this.state.open && e.read_by.filter(u => u.id === user.id) === 0) {
        e.read_by.push(this.props.familyService.user);
        this.props.familySpaceAction.markAsRead(e);
      }
      return e
    });
    return fullEvents/*.sort((a, b) => a.tstamp - b.tstamp)*/;
  };

/*   displayEvents (events) {
    events.forEach((event, index) => {
      if (event.type === "like" || event.type === "dislike"){
        event.html = this.LikeDislike(event, `dE-${index}`);
      }else if(event.type ==="comment"){
        event.html = this.Comment(event, `dE-${index}`);
      }
    })
  } */

  getIndex(event) {
    const myProjects = this.props.familyService.projects
    let projectInd = 0
    if (myProjects){
      let count = myProjects.length
      const proj = myProjects.find(elem => elem.id===event.project_id)
      if (proj){
        myProjects.forEach((project, pIndex)=>{
          count--
          if(proj.id === project.id){
            projectInd = count
          }
        })
      }
    }
    projectInd++
    return projectInd
  }

  LikeDislike (event,index) {
    const Index= this.getIndex(event);
    const nomProjet = event.project_name ? event.project_name : `Projet ${Index}`
    return (
      <div className="chatActivity-Event EventElement EventLike" key={index}>
        <div className="EventName">{event.name}&nbsp;</div>
        <div className="EventLikeBody">
          <span className="blue">{event.type==="like"? `aime ` : `n'aime pas `}</span>
          <span>le projet </span>
          <span className="projectName blue">{ nomProjet }</span>
        </div>
      </div>
    );
  }

  Comment (event, index) {
    const Index = this.getIndex(event);
    const nomProjet = event.project_name ? event.project_name : `Projet ${Index}`
    return(
    <div className="chatActivity-Event EventElement EventComment" key={index}>
      {/*<div className="EventName">{event.name}</div>*/}
      <div className="EventCommentBody">
        <span className="EventName">{event.name}&nbsp;</span>
        <span className="blue">a commenté </span>
        <span>le projet </span>
        <span className=" projectName blue">{ nomProjet }</span>
        <span> : </span>
        <span>{`"${event.comment.text}"`}</span>
      </div>
    </div>)
  }

  Member (event,index,join=false){
    const tag = "a rejoint";
    return (<div className="chatActivity-Event EventElement EventMember" key={index}>
      <span className="EventName">{event.name}</span>
      <span>&nbsp;{tag} l'espace famille</span>
    </div>);
  }
  getProjectName(event,projects){
    if(projects.length > 0){
      const found = projects.find(p => p.id === event.project_id);
      if (found){
        return found.name
      }
     }
     return '';
  }

  Image (event, index) {
    const me = event.member_id === this.props.familyService.user.id;
    const theClass = me ? "MyImage": "OtherImage";
    return (
      event.context === "chat" && <div className="chatActivity-Event EventElement EventImage" key={index}>
        <img src={event.url} className={`theImage ${theClass}`} alt="Principale" onClick={() => this.setState({imageModal: true, imageModalURL: event.url})} />
      </div>
    );
  }

  Message (event, index) {
    const {mainColor, fontColor} = this.props.adminConfig.config
    if (event.member_id) {
      event.user = this.props.familyService.members.find(m => m.id === event.member_id);
    }

    const theClass = event.user && event.user.id === this.props.familyService.user.id ? "MyMessage": "OtherMessage";
    const msgClass = event.user && event.user.id === this.props.familyService.user.id ? "OutGoing-Msg" : "Incoming-Msg";
    const name = event.user ? event.user.name :event.read_by.length ? event.read_by[0].name:"";
    return (
      <div className={`chatActivity-Event EventMessage ${theClass}`} key={index}>
        <span className="EventName">{theClass === "MyMessage" ? "" : name}</span>
        <div className={`chatActivity-Event ${msgClass}`} style={{"--f-main-color":mainColor, "--f-font-color":fontColor}}>{event.message}</div>
      </div>
    );
  };

  sendMsg () {
    const { currentMsg } = this.state;
    const { user, familySpace } = this.props.familyService;
    if (currentMsg){
      this.props.familySpaceAction.addMessage(user, currentMsg)
      .then(response => {
        this.props.familyService.socket.emit("chat", {...{familySpace, user, message: currentMsg, room: `familyspace-${this.props.familyService.familySpace.id}`, type: 'chat'}, ...{read_by: response.read_by, id: response.id}});
      });
    }

  };

  toggleEvents = (event, from) => {
    // GTM
    if (from === "circleBtn") {
      gtmFamily().ctaOfConfiguration({
        name: "chat",
        category: "espace famille",
        subcategory: "chat",
        type: "cta",
        from: "bottom right"
      }, { template: false, subtemplate: false }, {}, "user", "page")
    }
    // End GTM
    const { open, unread } = this.state;
    const { user: sUser } = this.props.familyService;
    this.setState({open: !open}, () => {
      this.props.getState(this.state.open);
      if (!open) {
        unread.map(u => {
          u.read_by.push(sUser);
          this.props.familySpaceAction.markAsRead(u);
          return u
        });
        this.clearUnread();
        this.scrollToTheEnd();
      }
    });
  };
  scrollToTheEnd = () => {
    let x = document.querySelector('.chatActivity-Events');
    if (x){x.scroll(0,x.scrollHeight);}
  }
  clearUnread = () => this.setState({unread: []});

  isRead = event => {
    try {
      if (event.type === "member" || event.type === "member-join") {
        return true;
      }

      return event.read_by.filter(u => u.id === this.props.familyService.user.id) > 0;
    } catch (e) {
      return true;
    }
  };

  displayEvents (events, currentMsg) {
    const { imageModal, imageModalURL, buttonSpinner, open } = this.state;
    events.forEach(elem=> {
      /*if (!elem.tstamp){
        console.log(elem)
        const myDate = new Date()
        myDate.toUTCString()
        myDate.setMinutes(myDate.getMinutes() + 60)
        elem.tstamp = Math.round(myDate.getTime()/1000)
      }*/
    })
    events.sort((a,b)=> {
      return a.tstamp - b.tstamp
    })
    const {mainColor, fontColor} = this.props.adminConfig.config
    return (
      <>
        <div className="chatActivity-title" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={(e)=>this.toggleEvents(e, "header")}>
          <h1>Discutez avec vos proches</h1>
        </div>
        <hr/>
        <div className="chatActivity-Events">
          {events.map((event,index)=> {
            /*if (event.type === "like" || event.type === "dislike"){return this.LikeDislike(event,index)}
            if (event.type ==="comment") { return this.Comment(event,index); }
            if (event.type ==="member") { return this.Member(event,index); }
            if (event.type ==="member-join") { return this.Member(event,index,true); }*/
            if (event.type === "chat") { return this.Message(event, index); }
            if (event.type === "file") { return this.Image(event, index); }
            return <div key={index}/>
            })}

          <div className="EndScroll" ref={(el) => { this.eventsEnd = el; }}/>
        </div>
        <div className="chatActivity-AddMsg">
          <input
            className="chatMessage"
            type="text"
            placeholder="Mon message ..."
            value={currentMsg}
            onChange={e => this.setState({currentMsg: e.target.value})}
            onKeyDown={e => e.key === "Enter" && this.props.familyService.socket ? this.sendMsg() : null}
            ref={el => this.inputMsg = el}
          />
          <div className="chatImg" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}}>
            {buttonSpinner ?
              <CircularProgress className="CircularWait"/> :
              <Image onClick={()=>document.getElementById('fileUpload').click()} />}
          </div>
          <input id='fileUpload' className="fileUpload" type='file' multiple="multiple" accept="image/*"
           onChange={e => this.sendImg(e.target.files)} />
          {/*{ this.props.familyService.socket &&
            <Button className="float-right" onClick={() => this.sendMsg()}>
              <img src={plane} alt="Envoi"/>
            </Button>}*/}
          { this.props.familyService.socket && <div className="sendMsg" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={() => this.sendMsg()}>
            <Send/>
          </div>}
        </div>

        <Modal
          isOpen={imageModal}
          onRequestClose={() => this.setState({imageModal: false, imageModalURL: null})}
          className="fit"
          overlayClassName=""
        >
          <img src={imageModalURL} alt="fit"/>
        </Modal>
      </>
    );
  };

  sendImg = async (imageFiles) => {
    const author = this.props.familyService.user;
    const fsAction = this.props.familySpaceAction;
    
    if (Array.from(imageFiles).length > 0) {
      this.setState({imageFiles, buttonSpinner: true});
        await saveFiles(imageFiles,author,fsAction,'chat');
        await Array.from(imageFiles).map(async image => {
        this.props.familyService.socket.emit('chat', {...{type: 'files', room: `familyspace-${this.props.familyService.familySpace.id}`, 
                                                          user: author, loadForUser: true}});
        })
        
      }
      this.setState({ buttonSpinner: false});
  };

  hover =(value)=>{
    if (!isSmartphone()){this.setState({hover:value})}
   }
  render() {
    const { currentMsg, unread, open, hover } = this.state;
    const events = this.getActivity();
    const { members } = this.props.familyService;
/*    events.sort(function(x, y){
      return x.timestamp - y.timestamp;
    })*/
    this.scrollToTheEnd();
    const {mainColor, fontColor} = this.props.adminConfig.config
    return(
      <>
        {unread.length > 0 && <div className="chatActivity-Notif">
          <span>{unread.length}</span>
        </div>}
        <div className="chatActivity-Button" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={(e)=>this.toggleEvents(e, "circleBtn")}>
          {!open && <><ChatBubble/><span className="buttonTitle">Chat</span></>}
          {!!open && <Close className="CloseIcon" />}
        </div>

        {!!open && <div className='chatActivity-Container'>
          {this.displayEvents(events, currentMsg)}
        </div>}
      </>)
  }
}

export const Sactivity = connect(
  state => ({
    familyService: state.familyService,
    adminConfig: state.adminConfig
  }), dispatch => ({
    familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
  })
)(SactivityComponent);

