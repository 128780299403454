import * as _ from 'lodash';
import { assignDeepMultiple } from "./assignDeep.service";
import {getEngravingAvailableInfo,getFontdefaultSize} from "./generateEngravings.service";
import { gtmFamily } from './gtmFamily';


 export const assignModif = _.throttle((changes, requireLinesCalculation, props) => {
  const { engravings, calculateEngravingsLines, actions } = props;
  const result = _.map(engravings, engraving =>
    assignDeepMultiple(engraving, changes)
  );
  if (requireLinesCalculation) {
    calculateEngravingsLines(result);
  }
  actions.updateEngravings(result);
}, 500);

export const pickOne = (path,props) => {
  const values = _.chain(props.engravings)
    .map(path)
    .uniq()
    .value();

  if (values.length === 1) {
    return values[0];
  } else {
    return null;
  }
};
export const onSelectKind = (event,props) => {
  const selectedValue = _.find(event.target.options, "selected").value;
  const color = pickOne("font.color",props);
  const family = pickOne("font.family",props);
  const {user,engravingTypes,adminConfig} = props;
  // On récupère les couleurs et familles des gravures avec un prix correpondant à ce type
  const list = getEngravingAvailableInfo(user,engravingTypes,selectedValue,color,family,adminConfig.engravingsToHide);

  // On peut avoir une valeur nulle quand différentes sortes de gravure sont sélectionnés
  if (selectedValue) {
    assignModif(
      {
        "font.kind": selectedValue,
        "font.color": list.selectedColor.id,
        "font.family":list.selectedFont.id
      },
      false,props
    );
  }
};

export const onSelectColor = (color,props) => {
  // GTM
  gtmFamily().ctaOfConfiguration({
    name: "choisir la couleur",
    category: "configurateur",
    subcategory: "liste gravures",
    type: "picto",
    from: "configuration gravure"
  }, { template: "configurateur", subtemplate: "liste gravures" })
  // end GTM
  const kind = pickOne("font.kind",props);
  const family = pickOne("font.family",props);
  const {user,engravingTypes,adminConfig} = props;
     // On récupère les gravures avec un prix correpondant au type et à la couleur
  const list = getEngravingAvailableInfo(user,engravingTypes,kind,color,family,adminConfig.engravingsToHide);
  // if(props.engravings[0].reservation){
  //   color = color.slice(0, -2) + "80"
  // }
  assignModif({ "font.color": color,"font.family":list.selectedFont.id }, false,props);
};

export const onSelectFamily = (family,props) => {
  // GTM
  gtmFamily().ctaOfConfiguration({
    name: "choisir la typographie",
    category: "configurateur",
    subcategory: "liste gravures",
    type: "picto",
    from: "configuration gravure"
  }, { template: "configurateur", subtemplate: "liste gravures" })
  // end GTM
  const changes = { "font.family": family };
  const prevFamily = pickOne("font.family",props);
  const prevSize = pickOne("font.size",props);
  if (getFontdefaultSize(prevFamily,props.user) === prevSize) {
    changes["font.size"] = getFontdefaultSize(family,props.user);
  }
  assignModif(changes, true, props);
};

export const onSelectAlign = (align,props) => {
  // GTM
  gtmFamily().ctaOfConfiguration({
    name: "choisir l'alignement",
    category: "configurateur",
    subcategory: "liste gravures",
    type: "picto",
    from: "configuration gravure"
  }, { template: "configurateur", subtemplate: "liste gravures" })
  // end GTM
  assignModif({ "font.align": align }, true, props);
};
export const onChangeSize = (size,props) => {
  if (!size) size = 15
  assignModif({ "font.size": size }, true,props);
};

export const onChangeLineHeight = (lineHeight,props) => {
  if (!lineHeight) lineHeight = 0
  assignModif({ "font.lineHeight": lineHeight }, true,props);
};

