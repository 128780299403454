import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {FpriceList,prepareData} from './components/FpriceList';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import { OvalAction } from "../../components/OvalAction";
import { checkStatusFlowers } from '../../services/flower.service';
import showFlowerImg from '../../images/Ornement-ON-icon.svg';
import hideFlowerImg from '../../images/Ornement-OFF-icon.svg';

class FdetailInfosComponent extends Component {
  state = {
    open: false,
    status: "flowersDisabled",
  };

componentDidMount = () => {
  const status = checkStatusFlowers();
  this.setState({status})
}

componentDidUpdate = () => {
  const status = checkStatusFlowers();
  if (status !== this.state.status){
    this.setState({status})
  }
}

  render() {
    const { open, status } = this.state;
    const { isoview, current, user } = this.props;
    const hideFlowers = (this.props.current && this.props.current.configuration) ? this.props.current.configuration.hideFlowers : false;
    const data = current && prepareData(current,user);

    return (
      <div className="FdetailInfos">
       {data && <div className="FsmallDetail">
          <div className="monumentName">{data.name}</div>
          <div className="from">A partir de<span className="price">{` ${data.price} €`} </span> </div>
          <div className="seeDetail" onClick={()=>this.setState({open:true})}>Voir le détail</div>
        </div>}
        {open &&
        <div  className= "FbigDetail">
          {isoview.loading ?
            <p className="Loading">Regénération du visuel en cours</p> :
            <img className="monumentImg" src={isoview.current} alt="Monument"/>
          }
           {this.props.current && <FpriceList  current={current} user={user} from="FbigDetail" />}

          <div className="actions">
              {status !== "flowersHiddenForOrg" && status !== "flowersNotAvailable" &&
                <OvalAction
                action={()=> this.props.updateConfigurationAction.changeFlowerDisplay(!hideFlowers)}
                text={!hideFlowers ? "Cacher les fleurs" : "Afficher les fleurs"}
                color="Blue"
                addClass={`reset ${hideFlowers && 'hide'}`}
                frontIcon={!hideFlowers ? hideFlowerImg : showFlowerImg}
                image={true}
                />
              }
              <div className="Close" onClick={()=>this.setState({open:false})}>
                <i className="icon material-icons">&#xE14C;</i>
              </div>

          </div>
        </div>}
      </div>
    )}
}

export const FdetailInfos = connect(
  state => ({
    user:state.user,
    current: state.configurator.current,
    isoview: state.isoView,
  }), dispatch => ({
    updateConfigurationAction: bindActionCreators(updateConfigurationActions, dispatch),
  })
)(FdetailInfosComponent);
