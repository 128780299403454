import * as apiService from "./api.service";
export const getImprints =  ()=>{
return apiService.get(`/api/catalog/imprints`).then(imprints=>imprints);
}

export const getPatterns =  (query,length,limit,family) =>{
let url = `/api/catalog/patterns?q=${query}&offset=${length}&limit=${limit}`;
return apiService.get(url).then(patterns=> patterns);
}

export const getPatternSize = ({position},{size}) =>{
  if (!position || !size){
    return false
  }
  const scaleX = position.scaleRatio ? position.scale.x/position.scaleRatio.x : position.scale.x;
  const scaleY = position.scaleRatio ? position.scale.y/position.scaleRatio.y : position.scale.y;
  const width  = getCm(size.width,scaleX);
  const height = getCm(size.height,scaleY);
  return({width,height});
}

export const getPatternSizeMax = ({size,scale}) =>{
   const min ={width:getCm(size.width,scale.min), height:getCm(size.height,scale.min)};
   const max ={width:getCm(size.width,scale.max), height:getCm(size.height,scale.max)};
   return ({min,max})
}
const getCm = (a,b)=> Math.round((a*b)/10)
