import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyspaceActions from '../../actions/familySpaceActions';
import { Modal } from '../../components/Modal';
import {SprojectHeader} from "./SprojectHeader";
import {SprojectFooter} from "./SprojectFooter";
import * as apiService from "../../services/api.service";
import {getFsGcsUrl} from "../../services/api.familyspace.service";
import { Button } from 'reactstrap';
import {SdocSortcuts} from './Project/SdocSortcuts';
import defaultIcon  from '../../images/inhumation-icone.svg';
import {CircularProgress}  from '@material-ui/core';
import {saveFiles, checkMaxSize,isFamilyConfigProject,isManual} from './utils/utils';
import zoomIcon  from '../../images/space/zoom.svg';
import {Viewer3D} from '../../components/Viewer3D';
import {getQuoteDoc} from "./utils/utils";
import {imgs} from '../../services/images.service';
import MediaQuery from "react-responsive";
import loadingPicto from "../../images/loader-config-500-new.gif";
import {confirmAlert} from "react-confirm-alert";
import {Close} from "@material-ui/icons";
import * as _ from 'lodash';
import {LeftOvalAction} from "../../components/LeftOvalAction";
import {SaskQuote}  from "./Project/SaskQuote";
import { gtmFamily } from "../../services/gtmFamily";

 class SprojectComponent extends Component {
  state = {
    askQuote: false,
    imageUrl: null,startImg:null,typeUrl:"image",loadingImage:false,
    projectId: null, docs:null,
    isOpenModal: false,errorFile: false,errorFileMessage: "",loadedFile: null,ShowZoom: false,
  };

  async componentDidMount () {
    const project = this.getProject();
    let imageUrl = null;
    if (project) {
       imageUrl = project.config_id ? project.configImage ? project.configImage : await apiService.get(imgs.configuration.iso.large({reference: project.config_id}))
                  :project.quote_id ? null: this.getFirstImage(project.id);
     const docs = project.docs ? project.docs : await this.getAllDoscUrl(project);
     if (project.quote_id && docs && docs.texture){imageUrl = docs.texture}
      // On appelle une API différente de la configuration normale car les paramètres de l'organisation
      let config = null
      if ( project.config_id ) {
        if ( project.config ) { config = project.config; }
        else {
          // Loading
          this.props.familySpaceAction.imageConfigProject({projectId:project.id,loading: true, error: false, errorMsg: false});
          // Request
          config = await apiService.get(`/api/configuration/${project.config_id}`);
        }
      }
      this.props.familySpaceAction.storeImageConfigProject(project.id,imageUrl,config)
      this.setState({imageUrl, projectId: project.id,startImg:imageUrl, docs});
    }
  };
  async getAllDoscUrl (project){
    // On récupère les liens sur les docs du json qui va bien
    const docLink = project.doc_link ? project.doc_link : 
    project.config_id ?  `${process.env.REACT_APP_DOCUMENTS_ORIGIN}/pro-space/configurations/${project.config_id}/documents.json`:
    null;
    if (docLink){
      let response = await fetch(docLink, {headers: Object.assign({},{'Accept': 'application/json', 'Content-Type': 'application/json'})});
      if (response){
        let responseJson = await response.json();
        this.props.familySpaceAction.addDocs({id:project.id,docs:responseJson});
        //Si le client ne veut pas montrer le devis on l'enève
        
        
        if ((project.show_quote === 0 || isFamilyConfigProject(project))  && responseJson.selling){delete responseJson.selling}
       //this.setState({docs:responseJson});
       return responseJson
      }
    }
    return false
  }
  getFirstImage = (projectId,inputFiles= false) =>{
    const theFiles = inputFiles ? inputFiles : this.props.familyService.files;
    const files = theFiles.filter(f => f.project_id === projectId && f.mime_type.split('/')[0] === "image");
    if (files && files.length){
      return  getFsGcsUrl(files[0].space_id,`${files[0].uuid}.${files[0].ext}`)
    }else{
      return defaultIcon
    }
  }

  handleImages = e => {
    const file = e.target.files[0];

    if (checkMaxSize(file.size)) {
      this.setState({errorFile: true, errorFileMessage: "Le fichier sélectionné est trop volumineux ! ( > 2 Mo)"});
    } else {
      this.setState({loadedFile: file, errorFile: false, errorFileMessage: ""});
    }
  };

  addFile = async () => {
    const { idProject, familySpaceAction, familyService } = this.props;
    const { loadedFile } = this.state;

    if (loadedFile) {
      this.setState({buttonSpinner:true})
      await saveFiles([loadedFile],familyService.user,familySpaceAction,'project',idProject);
      this.setState({isOpenModal: false, loadedFile: null,buttonSpinner:false});
    }
  };

  getProject () {
    const { idProject, familyService } = this.props;
    return (familyService.projects.find(item => item.id === idProject));
  };
  removeFileValidate = (file,projectId) => {
    const { mainColor, fontColor } = this.props.adminConfig.config
    confirmAlert({
      customUI: ({onClose}) =>
        <div className="confirm-alert-arhive-modal">
          <div className="close-action">
            <Close onClick={() => onClose()} />
          </div>
          <div className="confirm-alert-block">
            <h2>Confirmez pour supprimer</h2>
            <div className="message">Voulez-vous supprimer ce fichier?</div>
            <div className='actions'>
              <Button className="fsp-button validate" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={() => {
                this.removeFile(file,projectId).then(()=>{
                  /*window.location.reload();*/
                });
                onClose();}}>Oui</Button>
              <Button className="fsp-button cancel" onClick={() => onClose()}>Non</Button>
            </div>
          </div>
        </div>
    });
  }
  removeFile = async (file,projectId) => {
    const files = await this.props.familySpaceAction.deleteFile(file.id);
    // if (this.state.config){this.setState({imageUrl:this.state.startImg})  }
    if (files) {
      //si full on affiche le fichier suivant
      // si liste on affiche la première image
      const imageUrl = this.getFirstImage(projectId,files);
      this.setState({imageUrl});
    }
  };

  archiveProject = async (project, ignoreFilter = false) => {
    await this.props.familySpaceAction.updateOneProject(project.id, {archived: 1});
      this.props.archiveFilter(true);
      if (this.props.full){ this.props.goSmallImage()}
  };

  
  displayMainImage = (type,file,project,files)=>{
    const {startImg,docs,imageUrl} = this.state;
    let getImageUrl = type==="configImage" && !file ? startImg : file;
    if (imageUrl === getImageUrl){return true;}//same image do nothing
    switch(type){
      case "configDoc": 
       const found = getQuoteDoc(project,docs, {files});
       if (found){
          const typeUrl = "image";
          this.setState({typeUrl});
          window.open(found.url);
       }
        break;
      case "view3D": 
        this.setState({imageUrl:"",typeUrl:"view3D"});
        break;
      case "configImage": this.setState({imageUrl:getImageUrl,typeUrl:"image",loadingImage:true}); break;
      case "configDraw": this.setState({imageUrl:getImageUrl,typeUrl:"draw",loadingImage:true}); break;
      case "file":
        const fileUrl = getFsGcsUrl(file.space_id,`${file.uuid}.${file.ext}`);
        this.setState({imageUrl:fileUrl,typeUrl:"image"}); break;
      default: break;
    }
  }


  letShowZoom = (value,project) => {
    if(!this.props.full){
      this.setState({ShowZoom:value}) 
    }
  }

  getQuoteInfos = (project,config,full)=> {
    // Plusieurs possibiilités:
    if (isFamilyConfigProject(project) ){
      const sent = _.get(config, 'configuration.newfamilyInfos.emailSent');
      if (sent){
         // Si confguration famille et devis demandé afficher devis demandé et ne rien permettre
        return ({disabled:true , /* icon:euro, */ text:`Devis demandé`,action:()=>console.log("Do nothing")})
      }else{
        return ({disabled:false,
          /* icon:euro_blue, */ 
          text:`Demander un devis`,
          action: () => this.askQuoteAction(project),
          addClass: `block last fsp-button ask${full ? " top-left" : ""}`
        })
      }
    }else {
      if (isManual(project) && (!project.docs|| !project.docs.selling)){
        // Si configuration famille et devis non demandé afficher formulaire
        return ({disabled:false, /* icon:fileIcon,*/ text:`Joindre un document`, action:()=>this.setState({isOpenModal: true}) })
      }else{
        // Si configuration famille et devis non demandé afficher formulaire
        return ({disabled:false, /* icon:euro, */ text:`Voir le devis`,action:()=>this.displayFullQuote()})
      }
    }
   // Afficher le devis en pleine page si devis marbrier ou config client ou devis manuel 
  }

  gtmProductObj = (project)=> {
    if (!project.config) {
      return {}
    }
    
    const { monument } = project.config.configuration;
    const { granites } = project.config.options;
    const graniteMain = granites.find((granit => granit.reference == monument.graniteMain ))
    const graniteSecondary = granites.find((granit => granit.reference == monument.graniteSecondary ))
    
    return {
      id: monument.productId,
      name: "", // empty
      reference: monument.reference,
      price_from: "", // empty
      granits: [
        graniteMain ? graniteMain.name : monument.graniteMain, 
        graniteSecondary ? graniteSecondary.name : monument.graniteSecondary
      ],
      style: "", // second filter <--
      couleur: "", // empty
      religion: "", // empty
      type: "", // main filter <--
      granit_main: graniteMain ? graniteMain.name : monument.graniteMain,
      flower_button: "", // empty
      v360_button: true //empty
    }
  }

  askQuoteAction = (project) => {
    // GTM
    const { full, fromCompare } = this.props;
    let from = "vignette projet"
    let subtemplate = "liste projets"
    if (fromCompare) {
      from = "comparaison projet"
      subtemplate = "comparateur"
    } else if (full) {
      from = "projet"
      subtemplate = "projet"
    }

    const product = this.gtmProductObj(project);
    gtmFamily().ctaOfConfiguration({
      name: "demander un devis",
      category: "espace famille",
      subcategory: "popup demander un devis",
      type: "cta",
      from: from
    }, 
    { 
      template: "espace famille", 
      subtemplate: subtemplate 
    },
    {
      product: product
    })
    // end GTM
    this.setState({askQuote:true})
  }
  
  render () {
    const { docs, imageUrl,startImg, typeUrl,  isOpenModal, errorFile, errorFileMessage, 
            loadedFile, ShowZoom, loadingImage,buttonSpinner } = this.state;
    const { full, clientId,idProject,compare } = this.props;
    const project = this.getProject();
    const files = project && this.props.familyService.files.filter(f => f.project_id === project.id);
    const classFull = full ? "full": ""; // affichage list ou full screen
    const padDecal10 = (typeUrl === '' || typeUrl==='image') && (project && project.config_id) ? {paddingLeft: "10%"} : {};
    if (!project) return null;
    const imageClass = loadingImage ?"monumentImg hide":`monumentImg maxH ${full ? "" : "transformImg" }`;
    let projectIndex = 0;
    let pIndex = this.props.familyService.projects.length
    const {askQuote} = this.state;
    const quoteInfos = project && this.getQuoteInfos(project,project.config);
    const price = project && project.price ? project.price : "";
    const { user} = this.props;
    const { mainColor, fontColor } = this.props.adminConfig.config

    if (this.props.familyService.projects){
      this.props.familyService.projects.forEach((proj,index) =>{
        pIndex--
        if (proj === project){
          projectIndex = pIndex
        }
      })
    }

    // projectIndex++

    return (
      <div className={`SprojectCommon ${classFull}`}>
        {/* <div className={"block last block"}>
          {quoteInfos.component ? quoteInfos.component :
          <div onClick={()=> quoteInfos.disabled ? null : quoteInfos.action()} 
            style={{cursor: quoteInfos.disabled ? 'not-allowed' : 'pointer', opacity: quoteInfos.disabled ? 0.5 : 1, }}>
            {quoteInfos.icon && <img  src={quoteInfos.icon} alt={quoteInfos.text}/>}
            <span>{quoteInfos.text}</span>
          </div>}
        </div> */}

         {/* Mode Standard */}
      {!compare && <div className={`Sproject ${classFull}`} style={{"--f-main-color":mainColor}}>

        {!full && <SprojectHeader projectId={project && project.id} projectIndex={projectIndex}  full={full} back={() => this.props.goSmallImage()} clientId={clientId}
                        compare={()=>this.setState({compare:true})}  archive={() => this.archiveProject(project, false)}
                        archiveFilter={home => this.props.archiveFilter(home)} />}
        
        <div className="row dispContainer">
          {full && <SprojectHeader projectId={project && project.id} projectIndex={projectIndex}  full={full} back={() => this.props.goSmallImage()} clientId={clientId}
                        compare={()=>this.setState({compare:true})}  archive={() => this.archiveProject(project, false)}
                        archiveFilter={home => this.props.archiveFilter(home)} />}

          <div className={full ? `dispImage col-xl-10 col-lg-9 col-md-8 col-sm-12` : `dispImage not-full`}
          onClick={() => !project.archived ? !full ? this.props.goFullImage(idProject) : null : null} 
          onMouseEnter={()=>this.letShowZoom(true,project)} onMouseLeave={()=>this.letShowZoom(false,project)} >

          {ShowZoom && !project.archived &&
          <div className="ZoomProject" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}}><img className="ZoomIcon" src={zoomIcon} style={{"--f-main-color":mainColor}} alt="Zoom in"/></div>}
          {typeUrl ==="view3D" && <Viewer3D configuration={project && project.config && project.config.configuration} /> }
          {typeUrl ==="image" && imageUrl &&  <img className={imageClass} style={padDecal10} src={imageUrl} onLoad={() =>this.setState({loadingImage:false})} alt="Monument"/>}
          {typeUrl ==="draw" && imageUrl &&  <img className={imageClass} style={padDecal10} src={imageUrl} onLoad={() =>this.setState({loadingImage:false})} alt="Monument"/>}
          {(typeUrl ==="image" || typeUrl==="draw") &&( !imageUrl || loadingImage) && <div className="monumentLoading"/>}
          </div>
            {full &&
                  <MediaQuery minWidth={768}>
                      <div className="slider col-xl-2 col-lg-3 col-md-4 col-sm-0">
                          <SdocSortcuts
                              project = {project}
                              openAdd = {() => this.setState({isOpenModal: true})}
                              files = {files}
                              display={ (type,id) => this.displayMainImage(type,id,project,files)}
                              removeFile = {(file)=>this.removeFileValidate(file,project.id)}
                              startImg = {startImg}
                              docs = {docs}
                              mainColor = {mainColor}
                          />
                      </div>
                  </MediaQuery>
            }

            { quoteInfos && <LeftOvalAction 
              text={quoteInfos.text}
              disabled={quoteInfos.disabled}
              addClass="fsp-button"  
              action={()=>quoteInfos.action()} 
              colorConfig={{mainColor, fontColor}}
              /> }

            { askQuote && <SaskQuote 
              close={()=>this.setState({askQuote:false})} 
              price={price} config={project && project.config} 
              user={user}
              project={project}
              colorConfig={{mainColor, fontColor}}
              informSent= {()=>this.setState({sent:true})} /> }

            <Modal
              isOpen={isOpenModal}
              onRequestClose={() => this.setState({isOpenModal: false})}
              className="familyspace-addQuote-modal"
              overlayClassName="familyspace-login-overlay"
            >
              <h2 className="title">Ajouter une image ou un document au projet</h2>

              <div className="block files">
                <div className="images">
                  <input className="file-type" type='file' onChange={e => this.handleImages(e)} />
                  {errorFile && <div className="error error-message">
                    {errorFileMessage}
                  </div>}
                </div>
              </div>

              <div className="block actions">
              {buttonSpinner ? <CircularProgress className="CircularWait"/> : 
               <Button className="LeftOvalAction fsp-button add add-document" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={() => this.addFile()} disabled={errorFile || !loadedFile}>Ajouter</Button>}
                <Button className="LeftOvalAction Grey fsp-button cancel" onClick={() => this.setState({isOpenModal: false, errorFile: false, errorFileMessage: "", loadedFile: null})}>Annuler</Button>
              </div>

            </Modal>

        </div>
        
        {!project.archived &&  <SprojectFooter openAdd = {() => this.setState({isOpenModal: true})} project={project} full={full} docs={docs} 
                                  archiveFilter={home => {this.archiveProject(project, false)}}/>}
        {full && <MediaQuery maxWidth={767}>
        <div className="shortcutsContainer">
            <SdocSortcuts
                project = {project}
                openAdd = {() => this.setState({isOpenModal: true})}
                files = {files}
                display={ (type,id) => this.displayMainImage(type,id,project,files)}
                removeFile = {(file)=>this.removeFile(file,project.id)}
                startImg = {startImg}
                docs = {docs}
                mainColor = {mainColor}
            />
        </div>
        </MediaQuery>}
      </div>}
      {compare &&  <div className="CompareImage">         
          {typeUrl ==="image" && imageUrl && !loadingImage &&  <img className={imageClass} src={imageUrl}
                                                onLoad={() =>this.setState({loadingImage:false})} alt="Monument"/>}
        {typeUrl ==="image" &&( !imageUrl || loadingImage) && <div className={`monumentLoading ${imageClass}`}/>}
        </div>}
      </div>
      )
  }
};
export const Sproject = connect(state => ({
  familyService: state.familyService,
  user: state.user,
  adminConfig: state.adminConfig,
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
}))(SprojectComponent);


