import React, { Component} from "react";
import PropTypes from 'prop-types';

/**
 * Composant qui permet d' indiquer les mesures d'un côté du placage
 */
export class VeneerSetting extends Component {
  static propTypes = {
    type: PropTypes.any.isRequired, // type de côté gauche,droit,haut,bas
    data: PropTypes.any.isRequired, // données du côté
    changeMeasure: PropTypes.func.isRequired, // Fonction à appeler sur les modifications d'épaisseur
    checkUpdateHeights: PropTypes.func.isRequired, // Fonction pour vérifier la mise à jour complète de toutes les hauteurs
    informOpen: PropTypes.func.isRequired, // Fonction pour informer de l'ouverture du côté
    imageData: PropTypes.any.isRequired,
    displayThick: PropTypes.any.isRequired, // pour les images avec l'épaisseur
  };
  state = { present: false}; // Côté affiché ou pas

  componentWillMount() {
    this.setState({present:this.props.present});

  }
  onChange = (event, type, subtype) => {
    let value =
      !isNaN(event.target.value) && event.target.value !== ""
        ? parseInt(event.target.value)
        : 0;
    // Max 460 for length and 99 for height
    value = subtype === "length" ? value > 460 ? 460: value : value > 99 ? 99: value;

  this.props.changeMeasure(type, subtype, value); 
           
  };
  onBlur = (event) => {
    // On en met à jour les 4 tailles d'un coup que si elles sont sont vides et lors de la sortie de la saisie
    let value =
      !isNaN(event.target.value) && event.target.value !== ""
        ? parseInt(event.target.value)
        : 0;
  this.props.checkUpdateHeights(value);
  }

  addRemove = () =>{ // Appui sur le bouton + ou -
    if (this.state.present){
      this.props.changeMeasure(this.props.type, "length", 0);
      this.setState({ present:false });   
      this.props.informOpen(this.props.type,false);
    }
    else{
      this.setState({ present:true });
      this.props.informOpen(this.props.type,true);
    }
  }
  handleFocus = (event) => event.target.select(); // Afin de selection la saisie dès le focus

  render() {
    const {  type, data, imageData, displayThick } = this.props;
    const classAddRemove= this.state.present ? "Remove":"Add";
    let tempImage = this.state.present ? imageData.img+"-Input.png": displayThick? imageData.img+"-Epaisseur.png": imageData.img+".png";
    const heigth1 = (type ==="right" || type==="left") ? "front":"left";
    const heigth2 = (type ==="right" || type==="left") ? "back":"right";
    return (
      <div>
        <button  className={`AddRemoveButton ${classAddRemove} ${type}`}  onClick={this.addRemove}/>
        <div className= {imageData.cn}>
        <img  src={require(`../../../../images/veneer/${tempImage}`)} alt="entrée"/>
        {this.state.present && 
        <div>
          <span className="SpanInputBox">
            <input className="InputBox" value={data.length} onFocus={this.handleFocus} onChange={e => this.onChange(e, type, "length")}/>cm&nbsp;
          </span>
          <span className={`SpanHeightBox ${type}${heigth1}`}>
            <input className="InputBox Height" value={data[heigth1]} onFocus={this.handleFocus} onChange={e => this.onChange(e, type, heigth1)}
            onBlur={this.onBlur} />cm&nbsp;
          </span>
          <span className={`SpanHeightBox ${type}${heigth2}`}>
                  <input className="InputBox Height" value={data[heigth2]} onFocus={this.handleFocus} onChange={e => this.onChange(e, type, heigth2)}
            onBlur={this.onBlur} />cm&nbsp;
          </span>
        </div>
       }
        </div>
      </div>

    );
  }
}
