import history from '../history'

let toHightLight = null;

/**
 * Permet de se rendre à une url et d'y mettre en avant un élément (ouvrir un panel, selectionner un
 * objet fabric ..).
 *
 * Les composants qui doivent être mis en avant sont responsables de vérifier l'id à mettre en avant
 * avec "shouldFocus" plus bas.
 *
 * @param url Url ou se rendre
 * @param itemToFocusId Id de l'élement à mettre en avant une fois arrivé à cette url.
 *
 */
export function goToAndFocusItem(url, itemToFocusId) {
  toHightLight = itemToFocusId;
  history.push(url);
}

/**
 * Verifie si un élément doit être mis en avant.
 * Si c'est le cas, la variable stockant l'id à mettre en avant est RAZ.
 *
 * @param id Un id à mettre en avant ou non
 * @returns {boolean}
 */
export function shouldFocus(id) {
  if (id && toHightLight && id === toHightLight) {
    toHightLight = null;
    return true;
  }
}
