import * as types from './actionTypes';

export function close(tutorial, userId) {
  return {
    type: types.TUTORIAL_CLOSED,
    payload: {
      userId,
      tutorial,
    },
  };
}

export function open(tutorial, userId) {
  return {
    type: types.TUTORIAL_OPENED,
    payload: {
      userId,
      tutorial,
    },
  };
}
