import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { TranslationContext } from "../../../../context/TranslationContext";

/**
 * Composant qui permet d'ajuster l' épaisseur du placage
 */
export class VeneerThickness extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    // value: PropTypes.any, // Valeur de l'épaisseur
    changeThickness: PropTypes.func.isRequired, // Fonction à appeler sur les modifications d'épaisseur
  };

  componentDidMount(){
    this.adjustButtonStyles();
  }

  adjustButtonStyles = (event) => {
    const { value } = this.props;
    const type = event ? event.target.value : undefined;
    const plusElement = document.querySelector(".Plus");
    const minusElement = document.querySelector(".Minus");

    const applyCommonStyles = (element, bgColor, textColor, borderRad) => {
      element.style.backgroundColor = bgColor;
      element.style.color = textColor;
      element.style.borderRadius = borderRad;
    };
    
    const resetStyles = (element) => {
      element.style.backgroundColor = "transparent";
      element.style.color = "var(--secondary1)";
    };
  
    if (type) {
      if (type === "+") {
        applyCommonStyles(minusElement, "var(--secondary1)", "var(--text-cta)", "4px 0px 0px 4px");
        if (value === 5) {
          resetStyles(event.target);
        }
      } else if (type === "-") {
        applyCommonStyles(plusElement, "var(--secondary1)", "var(--text-cta)", "0px 4px 4px 0px");
        if (value === 3) {
          resetStyles(event.target);
        }
      }
    } else {
      if (value === 5) {
        resetStyles(plusElement);
      } else if (value === 3) {
        resetStyles(minusElement);
      }
    }
  }

  handleClick = async (e, op) => {
    e.persist(); 
    const { changeThickness } = this.props;
    await changeThickness(e, op);
    this.adjustButtonStyles(e);
  }

  render() {
    const t = this.context;
    const { value, changeThickness,enterLeave } = this.props;
    return (
        <div className="Thickness" onMouseEnter={(e)=>enterLeave(e,"enter")} onMouseLeave={(e)=>enterLeave(e,"leave")}>
        <label>
          { t("config3d_monument_modal_veneer_thickness") || "Epaisseur" }
        </label>
        <div className="PlusMinus"> 
        <input className="Minus" readOnly  value="-" onClick={(e) => this.handleClick(e,"-")}/>
        <span className="SpanInputBox">
          <input className="InputBox"  
            onChange ={(e)=>changeThickness(e,"onChange")} 
            onBlur={(e)=>changeThickness(e,null)} 
            value= {value}/> 
          <span>&nbsp;cm&nbsp;&nbsp;</span>
        </span>
        <input className="Plus" readOnly  value="+" onClick={(e) => this.handleClick(e,"+")}/>
        </div>
      </div>
    );
  }
}
