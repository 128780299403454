import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import {ModalButton} from '../../../components/ModalButton';
import * as _ from 'lodash';
import {GranitePicker} from '../components/GranitePicker'
import { isConfigRequested } from "../../../services/configurationWarningChecks"
import { TranslationContext } from "../../../context/TranslationContext";
import {imgs} from '../../../services/images.service';

/**
 * ModalButton permettant de choisir le granite pour tout le placage
 */
class VeneerGranitePickerComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    veneer: PropTypes.object,
  };

  modal;

  onSelectGranite = (graniteRef) => {
    if(this.props.monument.kind === 'PLT'){
      this.props.actions.updateGraniteMonument(graniteRef);
    }
    this.props.actions.updateGraniteVeneer(graniteRef);
    this.modal.close();
  };

  makePreviewUrl = (graniteRef)=> {
    const {monument, veneer} = this.props;
    return imgs.configuration.monument.iso.thumbnail({
      monument,
      veneer: Object.assign({}, veneer, {granite: graniteRef})
    }, {
      excludeDefaultPattern: true
    })
  };

  render() {
    const t = this.context;
    const veneer = this.props.veneer;
    const hasVeneer = _.some(
      ['left', 'right', 'back', 'front'],
      (side) => {
        const {left, right, back, front, length, thickness} = _.get(veneer, `dimensions.${side}`) || {};
        return !!length && !!thickness && (!!left || !!right || !!back || !!front);
      });
      const disabled = !hasVeneer || isConfigRequested();

    const buttonContent = t("config3d_granit_button_veneer") || "Placage";
    return (
      <div className={"btn-wrapper"}>
        <ModalButton
          ref={(_modal) => this.modal = _modal}
          buttonContent={buttonContent}
          buttonIcon="veneer-icon"
          className="VeneerGranitePickerPopupModal buttonModal background-page"
          overlayClassName="VeneerGranitePickerPopupModalOverlay"
          disabled={disabled}
          buttonClassName={`btn-config-secondary text-dark config-button-background ${!disabled && 'config-button-hover'}`}
          toggleSteps={this.props.toggleSteps}
          launchIntro={this.props.launchIntro}
          madalName={this.props.madalName}
          >
          <GranitePicker
            currentGraniteRef={veneer && veneer.granite}
            onPickGraniteRef={this.onSelectGranite}
            suggestionImageURL={this.makePreviewUrl}
            title={t("config3d_granit_modal_veneer_title") || "Choisissez un granit à appliquer sur le placage"}
          />
        </ModalButton>
      </div>
    );
  }
}

export const VeneerGranitePicker = connect((state) => ({
    veneer: state.configurator.current.configuration.veneer,
    monument: state.configurator.current.configuration.monument
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(VeneerGranitePickerComponent);
