import TagManager from 'react-gtm-module';

export default class GtmCommon {
  static instance;
  static clientId;
  constructor(mode, gtmId, clientId = null) {
    this.mode = mode;
    this.clientId = clientId;
    
    if (this.mode === "family" && this.clientId != null) {
      this.initGtmFamily(gtmId)
    }
    else if (this.mode === "pro") {
      this.initGtmPro(gtmId)
    }
  }

  initGtmFamily(gtmId) {
    // if (process.env.NODE_ENV === 'production' && this.clientId === 'CA102194' ){
      TagManager.initialize({ gtmId: gtmId });
    // }
  }

  initGtmPro(gtmId) {
    // if (process.env.NODE_ENV === 'production'){
      TagManager.initialize({ gtmId: gtmId });
    // }
  }

  getMode() {
    return this.mode
  }
}
