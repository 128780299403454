import React, { Component } from "react";
import {Sproject} from "../Sproject";
import { connect } from "react-redux";
import {getDisplayPrice, getDisplayName} from "../utils/display";



export class SprojectSelectComponent extends Component {

  state={
    _isMounted: false
  }
  componentDidMount() {
    this.setState({_isMounted: true})
  }
  componentWillUnmount() {
    this.setState({_isMounted: false})
  }

  render() {
    const {mainColor} = this.props.adminConfig.config
    const {projectId,checked,toggle} = this.props;
    let Ind = 0;
    let pIndex = this.props.familyService.projects.length
    const project = this.props.familyService.projects && this.props.familyService.projects.find((proj,index)=> {
      pIndex--
      if (proj.id === projectId){
        Ind = pIndex
        return proj
      }
    });
    Ind++
    return(
      <div className="SprojectSelect" onClick={() => toggle()}>
          <div className="SprojectHeader">
            <div className="SselectCheck">
             <div className="greyCircle"/>
             {checked && <div className="blueCircle" style={{"--f-main-color":mainColor}} /> }
            </div>
            {getDisplayName(project,Ind)}
            {getDisplayPrice(project)}
          </div>
        <hr/>
        <div className="dispImage">
          {this.state._isMounted && <Sproject idProject={project.id} compare={true}/>}
        </div>
      </div>
      )
  }
}

export const SprojectSelect = connect(
  (state) => ({
    familyService:state.familyService,
    adminConfig: state.adminConfig
  }),
)(SprojectSelectComponent);
