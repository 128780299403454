import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as updateConfigurationActions from '../../actions/updateConfigurationActions'
import * as configurationActions from '../../actions/configurationActions'
import { PatternFabric } from '../configurator/motif/PatternFabric'
import { AccessoryFabric } from '../configurator/accessory/AccessoryFabric'
import { AccessoryGranite } from '../configurator/granite/AccessoryGranitePicker'
import { formatFace } from '../../services/format.service'
import { FpatternParams } from './FpatternParams'
import { Button } from 'reactstrap'
import { isSmartphone } from '../../services/domFunctions'
import * as _ from 'lodash'
import { warnOnTooManyItems } from '../../services/configurationWarningChecks'
import { engravingDefaultText } from './FengravingParams'
import LoadConfig from '../../components/LoadConfig'
import { OvalAction } from '../../components/OvalAction'
import MediaQuery from 'react-responsive/src'
import { getDesignTypeConfiguration } from '../../services/utils.service'
import { gtmFamily } from '../../services/gtmFamily';
import { Modal } from '../../components/Modal';

class FengravingPatternComponent extends Component {
  state = {
    selectedObjects: [],
    motifOpts: null,
    update: false,
    showParam: false
  }

  componentDidMount() {
    const { configId } = this.props.match.params
    !this.props.configurator && this.props.actions.getConfiguration(configId)
    if (!isSmartphone()){
      this.setState({ showParam: true })
    }
    this.setState({ update: true })
  }
  componentDidUpdate() {
    const { configurator } = this.props
    if (this.state.update && configurator) {
      // Permet d'afficher immédiatemeconfigurator: state.configurator.current,nt un warning s'il y a trop
      // de motifs ou patterns en place.configurator: state.configurator.current,
      // Le check à la modification est faitconfigurator: state.configurator.current, dans les updateConfigurationActions.
      warnOnTooManyItems()
      const motifOpts = {
        face: _.find(configurator.options.faces, 'default'),
        drawing: false,
        defaultFace: true
      }
      this.setState({ motifOpts: motifOpts, update: false })
      if (motifOpts.face) {
        if (
          this.props.configurator.configuration.engravings.length === 0 &&
          this.props.mode === 'engraving'
        ) {
          this.props.updateActions.addEngraving(
            this.props.user,
            motifOpts.face,
            null,
            engravingDefaultText
          )
        }
      }
    }
  }

  onSelectObjects = (selectedObjects) => {
      this.setState({ selectedObjects: selectedObjects || [] })
  }

  addPatternMobileAction = () => {
    const { configurator: current, user: { paramsFamilyConfigMax } } = this.props;
    if(current.configuration.patterns.length > parseInt(paramsFamilyConfigMax.FAMILY_CONFIG_MAX_MOTIFS) - 1) {
      this.setState({displayMsgConfigMax: true}) 
    } else {
      // GTM
      gtmFamily().ctaOfConfiguration({
        name: "ajouter un motif",
        category: "configurateur",
        subcategory: "motif",
        type: "cta",
        from: "configuration motif"
      }, { template: "configurateur", subtemplate: "motif" })
      // end GTM
      this.setState({ showParam: true })
    }
  }

  render() {
    const { displayMsgConfigMax } = this.state
    const { user: { paramsFamilyConfigMax } } = this.props;
    const { motifOpts } = this.state
    const { backAction } = this.props
    const config = this.props.configurator && this.props.configurator.current
      ? this.props.configurator.current.configuration
      : false
    const designType = getDesignTypeConfiguration(config)
    // Certains monuments n'ont pas de face
    const faceName = motifOpts && motifOpts.face && formatFace(motifOpts.face)

    if (!this.props.configurator) {
      return <LoadConfig big loading={this.props.loading} />
    }
    return (
      <div className={this.props.mode}>
        {this.props.mode === 'accessory' && this.props.configurator && (
          <div>
            <AccessoryFabric
              family={true}
              drawing={true}
              onReady={() => this.setState({ fabricReady: true })}
            />
            <AccessoryGranite family={true} />
          </div>
        )}

        {this.props.mode !== 'accessory' &&
          (faceName ? (
            <PatternFabric
              face={motifOpts.face}
              drawing={false}
              family={true}
              mode={this.props.mode}
              onSelectObjects={this.onSelectObjects}
              onReady={() => this.setState({ fabricReady: true })}
              designType={designType}
            />
          ) : (
            this.props.configurator && (
              <p className='Error'>
                Aucune pièce n'est disponible pour gravure ou motif sur ce
                monument pour le moment.
                <br />
                Vous pouvez contacter votre marbrier pour personnaliser ce
                modèle.
              </p>
            )
          ))}
        {this.props.mode === 'pattern' &&
            faceName &&
            this.state.showParam && (
              <FpatternParams
                close={() => this.setState({ showParam: false })}
              />
            )}
        <MediaQuery maxWidth={768}>
          {this.props.mode === 'pattern' && !this.state.showParam && (
            <OvalAction
              action={() => this.addPatternMobileAction()}
              text={'Ajouter un motif'}
              frontIcon='add'
              addClass='addBtn Plus'
            />
          )}
        </MediaQuery>
        <Modal
          isOpen={displayMsgConfigMax}
          onRequestClose={() => this.setState({displayMsgConfigMax: false})}
          className="modal-display-msg"
        >
          <p>{`Vous avez atteint le nombre maximum de motifs, veuillez supprimer un des ${paramsFamilyConfigMax.FAMILY_CONFIG_MAX_MOTIFS} motifs pour pouvoir en ajouter un autre.`}</p>
        </Modal>
      </div>
    )
  }
}

export const FengravingPattern = connect(
  (state) => ({
    configurator: state.configurator.current,
    loading: state.configurator.loading,
    isoview: state.isoView,
    user: state.user
  }),
  (dispatch) => ({
    actions: bindActionCreators(configurationActions, dispatch),
    updateActions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(FengravingPatternComponent)
