import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Row,Modal} from 'reactstrap';
import {LeftOvalAction} from "../../../components/LeftOvalAction";
import {SprojectItemInfo} from './SprojectItemInfo';
import {SselectProjects} from './SselectProjects';
import * as familyspaceActions from '../../../actions/familySpaceActions';
import {SmobileCompare} from './SmobileCompare';
import MediaQuery from "react-responsive/src";
import { gtmFamily } from "../../../services/gtmFamily";

export class ScompareProjectsComponent extends Component {
  state = {
    addProjects: false,
    goFull:false,
    IDSelected:false,
    selectedProjects:[]
  };
  componentDidMount(){
    const {addProjects, selectedProjects} = this.state;
    let projects = selectedProjects.length ? selectedProjects : this.props.familyService.selectedProjects;
    if (projects.length === 0) {
      this.setState({addProjects:true});
    }
  }
  showModalAddProjects(bool){
    this.setState({addProjects:bool})
  }

addProjects (ids) {
  const intIds = ids.map(id=>parseInt(id,10));
  this.props.familySpaceAction.updateSelectedProjects(intIds);
  this.setState({addProjects:false, selectProjects:intIds});
  // GTM
  const projects = [];
  intIds.map(id => {
    let found = this.props.familyService.projects.find(project => project.id === id)
    if (found) {
      projects.push(found)
    }
  })
  gtmFamily().pageDispayFamilySpace("compare", projects);
  // end GTM
}

delProject (projectId) {
  // GTM
  gtmFamily().ctaOfConfiguration({
    name: "suppression du projet",
    category: "espace famille",
    subcategory: "comparateur",
    type: "cta",
    from: "comparateur"
  }, 
  { 
    template: "espace famille", 
    subtemplate: "comparateur" 
  }, {})
  // end GTM
  const newProjects = this.props.familyService.selectedProjects.filter((item)=> item !== projectId );
  this.props.familySpaceAction.updateSelectedProjects(newProjects);
  this.setState({addProjects:false, selectProjects:newProjects});
  // GTM
  const projects = [];
  newProjects.map(id => {
    let found = this.props.familyService.projects.find(project => project.id === id)
    if (found) {
      projects.push(found)
    }
  })
  gtmFamily().pageDispayFamilySpace("compare", projects)
  // end GTM
}


render () {
  const {addProjects, selectedProjects} = this.state;
  let projects = selectedProjects.length ? selectedProjects : this.props.familyService.selectedProjects;
  const {archived} = this.props;
  const { mainColor, fontColor } = this.props.adminConfig.config
  return (
    <div className="SprojectCommon">
      <div className="ScompareProjects col-md-12">
        <MediaQuery minWidth={576}>
          <Row className="ScompareHeader">
            {/* <div className="title">Comparer les projets {archived == 1 ? "archivés" : ""}</div> */}
            <LeftOvalAction colorConfig={{mainColor, fontColor}} text="Ajouter un projet"  addClass="fsp-button Add" icon="plus" action={()=>this.showModalAddProjects(true)}/>
          </Row>
        </MediaQuery>
        <div className="ScompareBox" >
          <Row>
            <div className="CompareContainer">
              <MediaQuery minWidth={577}>
                {projects.length === 0 &&
                  <div className="noProjToCompare">
                    <p>Sélectionnez des monuments à comparer</p>
                  </div>}
                {projects.map((item,index) =>
                  this.isDiplayed(item) && <SprojectItemInfo  key={index} projectId={item}  delProject={()=>this.delProject(item)} />)
                }
              </MediaQuery>
              <MediaQuery maxWidth={576}>
                <SmobileCompare projects={projects} archived={archived} delProject={(id)=>this.delProject(id)} open={()=>this.showModalAddProjects(true)}/>
              </MediaQuery>
            </div>
          </Row>
          <Modal isOpen={addProjects}>
            <SselectProjects archived={archived} projects={projects} close={()=>this.showModalAddProjects(false)} addedProjects={(a)=>this.addProjects(a)} />
          </Modal>
        </div>
    </div>
  </div>
  )}

  isDiplayed(item) {
    let archived = this.props.archived
    return this.props.familyService.projects.some(proj=>proj.id === item && proj.archived === archived)
  }
}

export const ScompareProjects = connect(
  (state) => ({
    familyService:state.familyService,
    adminConfig: state.adminConfig,
  }), dispatch => ({
    familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
  })
)(ScompareProjectsComponent);
