import React, { Component} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import * as configurationActions from '../../../actions/configurationActions'
import { userCan } from "../../../services/userRights.service";
import {configurationOrdered} from "../../../services/utils.service";
import MediaQuery from "react-responsive/src";
import { isConfigRequested } from '../../../services/configurationWarningChecks'
import { TranslationContext } from "../../../context/TranslationContext";
import {isQuote} from '../../../services/restrictedConfiguration';


// const inputFile = <input accept={acceptedMimetypes}
class PlaceOrderModalButtonComponent extends Component {
  static contextType = TranslationContext;
  state = { infosAreCorrect: false, sent:false,preorderComment:"", files:{}};


  sendOrderMessage(action,reference){
    window.top.postMessage({action, configuration: { reference }},process.env.REACT_APP_EXTRANET)
  }

  render() {
    const t = this.context;
    const { fullConfiguration, user } = this.props;
    if (!user.identity) return null;

    const { configuration } = fullConfiguration;
    const  orderedText = userCan.placeOrder() ? "Configuration commandée":"Configuration précommandée";

    if (configurationOrdered(configuration)) {
      return (
        <span className="Order alreadyOrdered">{orderedText}</span>
      );
    }

      const titleButton = userCan.placeOrder() 
        ? t("config3d_finalize_button_order") || "Passer en commande"
        : t("config3d_finalize_footer_button_pre-order") || "Passer en précommande";
      const action = userCan.placeOrder() ? "configuration.order" : "configuration.preorder";
  

    return (
      <div className="d-flex" style={{height: "51px"}}>
        {!isQuote(configuration) && 
          <button 
            style={{width: "200px"}}
            className="button-secondary button-medium text-dark mr-4"
            onClick={()=>this.props.configurationActions.createVariant(configuration, "granit")}>
            <span className="duplicate2-icon icon-large text-dark-icon" style={{width:"40px"}}></span>
            <div>
              Créer une variante
            </div>
          </button>
        }

        <button 
          className="button-primary button-medium text-cta Order flex-grow-1"
          onClick={()=>this.sendOrderMessage(action,configuration.reference)}
          disabled={
            !configuration.project ||
            !configuration.project.customer ||
            (!userCan.placeOrder() && !userCan.preOrder()) ||
            this.state.sent ||
            isConfigRequested()
          }>
          <span className="true-icon icon-large text-cta-icon"></span>
          <div>{titleButton}</div>
        </button>
      </div>

      /* { <button className="Order" onClick={()=>this.sendOrderMessage(action,configuration.reference)}
      disabled={
        !configuration.project ||
        !configuration.project.customer ||
        (!userCan.placeOrder() && !userCan.preOrder()) ||
        this.state.sent ||
        isConfigRequested()
      }>
        <MediaQuery maxWidth={576}>
          <i className="material-icons">
          shopping_cart
          </i>
        </MediaQuery>
        {titleButton}</button>} */
    );
  }
}

export const PlaceOrderModalButton = connect(
  state => ({
    fullConfiguration: state.configurator.current,
    user: state.user
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    configurationActions: bindActionCreators(configurationActions, dispatch)
  })
)(PlaceOrderModalButtonComponent);