import * as types from './actionTypes';
import * as api from '../services/api.service';

const checkFamily = clientId => {
  if (clientId) { return  clientId};
  // L'url famille est du type /famille/clientId
  const elements =window.location.pathname.split('/');
  if(elements.length >=3 && elements[1]==="famille"){
      return elements[2];
    }
  return false
}
export function saveUserState (userData) {
  return {
    type: types.USER_LOADED,
    payload: {
      userData
    }
  };
}

export function load (client) {
  const clientId = checkFamily(client);
  const endUrl = clientId ? {client:clientId}:{};
  return (dispatch) => {
    return api.get(`/api/user`,endUrl)
      .then((userData)=> {
        return dispatch({
          type: types.USER_LOADED,
          payload: {
            userData,family:clientId
          }
        })
      })
      .catch(error => {
        alert('Impossible de charger vos paramètres utilisateur : ' + error.message);
        return dispatch({
          type: types.NO_USER,
          payload: {
            error: error.message
          }
        });
      });
  }
}
export function getUserConfig () {
  return (dispatch) => {
    api.get('/api/user/latestconfig')
      .then((configData)=> {
        dispatch({
          type: types.USER_CONFIG,
          payload: {
            configData
          }
        })
      })
      .catch(error => {
        dispatch({
          type: types.USER_CONFIG_ERROR,
          payload: {
            error: error.message
          }
        });
      });
  }
}
