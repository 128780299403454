import * as actionTypes from '../actions/actionTypes';
import * as _ from 'lodash';

const initialState = {
  showBuyingPrices: false,
  showSellingPrices: false,
  customLineEdited: null,
  patternPicker: null,
  messages: [],
  accessoryGraniteModalParameters: null,
  initChoiceMade:false,
  isWhiteLabelActive:false,
};

export default function (state = initialState, {type, payload}) {
  switch (type) {

    case actionTypes.UI_TOGGLE_SHOW_BUYING_PRICES:
      return Object.assign({}, state, {showBuyingPrices: !state.showBuyingPrices});

    case actionTypes.UI_TOGGLE_SHOW_SELLING_PRICES:
      return Object.assign({}, state, {showSellingPrices: !state.showSellingPrices});

    case actionTypes.UI_SET_CUSTOM_LINE_BEING_EDITED:
      return Object.assign({}, state, {customLineEdited: payload});

    case actionTypes.UI_EDIT_ACCESSORY_GRANITES_OF:
      return Object.assign({}, state, {accessoryGraniteModalParameters: payload});       

    case actionTypes.UI_OPEN_PATTERN_PICKER:
      return Object.assign({}, state, {
        // On ne stocke que l'id et la reference, pas besoin de l'objet complet.
        // Cela permet aussi de s'assurer que l'on écrase pas directement l'objet contenu dans la
        // configuration
        patternPicker: {
          id: payload.id,
          reference: payload.reference
        }
      });
    case actionTypes.UI_CLOSE_PATTERN_PICKER:
      return Object.assign({}, state, {
        patternPicker: null
      });

    case actionTypes.UI_ADD_MESSAGE:
      const message = _.find(state.messages, {id: payload.id});
      if (message && message.className !== "danger" && payload.id !=="variant-creation-ok") return state;

      payload.isMasked=false;
      return Object.assign({}, state, {
        messages: state.messages.concat([payload])
      });

    case actionTypes.UI_MASK_MESSAGE:
      // Si un message est masqué,  un appels à add_message avec le même id
      // n'aura pas d'effet
      if (!_.find(state.messages, {id: payload.id,isMasked:false})) return state;
      return Object.assign({}, state, {
        messages: state.messages.map(
          message=>(message.id !== payload.id ?
              message :
              Object.assign({}, message, {isMasked: true})
          ))
      });
    case actionTypes.UI_REMOVE_MESSAGES:
      if (!_.find(state.messages, {id: payload.id})) return state;
      // Si un message est supprimé, un appel à add_message le re-affichera
      return Object.assign({}, state, {
        messages: _.reject(state.messages, {id: payload.id})
      });

    case '@@router/LOCATION_CHANGE':
      // L'ensemble des messages est effacé à tout changement de page.
      return Object.assign({}, state, {
        messages: []
      });

    case actionTypes.UI_INIT_CHOICE_MADE:
      return Object.assign({}, state, {
        initChoiceMade: payload
      });

    case actionTypes.WHITE_LABEL_STATUS:
      return Object.assign({}, state, {
        isWhiteLabelActive: payload
      });

    default:
        return state;
  }
}
