import React, { createContext, useState, useEffect } from "react";
import * as apiService from "../services/api.service";
import Loader from "../components/Loader";

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const [translation, setTranslation] = useState([]);
    const [translationLoaded, setTranslationLoaded] = useState(false);

    useEffect(() => {
        apiService.getExternal("translations").then((resp) => {
            setTranslation(resp.key_values);
            setTranslationLoaded(true);
        });
    }, []);

    const t = (key) => {
        const translationItem = translation.find((item) => item.key === key);
        return translationItem ? translationItem.value : key;
    };

    if (!translationLoaded) {
        return <Loader />;
    }

    return (
        <TranslationContext.Provider value={t}>
            {children}
        </TranslationContext.Provider>
    );
};
