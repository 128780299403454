import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import {hexToRgb} from "../../../services/utils.service";

import {scrollPageTo} from '../../../services/domFunctions';
import {FilterBox} from './FilterBox';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as configurationActions from '../../../actions/configurationActions';
import {userCan} from '../../../services/userRights.service';
import {getMonumentCategory} from "../../../services/utils.service";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import * as apiService from "../../../services/api.service";
const SearchIcon = require("../../../images/Search-icon-catalog.svg");


const iconList = [
  {
    className: "Category-001-399",
    search: null,
    image: "monuments.png",
    header: "Tous les monuments",
    text: "Découvrez tous nos monuments ou explorez nos gammes",
    format: "middle"
  },
  {
    className: "Category-400-499",
    search: "400-499",
    image: "contemporains.png",
    header: "Contemporains",
    text: "GPG 400 à 499"
  },
  { className: "Category-500-599", search: "500-599", image: "plats.png", header: "Plats", text: "GPG 500 à 599" },
  {
    className: "Category-600-699",
    search: "600-699",
    image: "classiques.png",
    header: "Classiques",
    text: "GPG 600 à 699"
  },
  { className: "Category-700-799", search: "700-799", image: "doubles.png", header: "Doubles", text: "GPG 700 à 799" },
  {
    className: "Category-800-899",
    search: "800-899",
    image: "musulmans-israelites.png",
    header: "Musulmans et Israëlites",
    text: "GPG 800 à 899"
  },
  { className: "Category-CIN", search: "CIN", image: "cineraires.png", header: "Cinéraires", text: "GPG 900 à 999" },
  { className: "Category-TBL", search: "TBL", image: "tombales.png", header: "Tombales", text: null },
  { className: "Category-SEM", search: "SEM", image: "semelles-icone.png", header: "Semelle", text: null },
  { className: "Category-ACC", search: "ACC", image: "accessoires-icon.png", header: "Accessoires", text: null },
  { className: "Category-PLT", search: "PLT", image: "placage-icone.png", header: "Placage", text: null },
  {
    className: "Category-Exclusive",
    exclusive: true,
    search: "exclusive",
    image: "monuments-exclusifs-icon.png",
    header: "Monuments exclusifs",
    text: null,
  },
  {
    className: "Category-Favorite",
    exclusive: "check",
    search: "favorites",
    image: "favoris.png",
    header: "Mes favoris",
    text: "Regroupez les monuments de votre choix",
    format: "middle"
  },
  {
    className: "Category-New",
    exclusive:"check",
    search: "new",
    image: "nouveautes.png",
    header: "Nouveautés",
    text: "Consultez les nouveautés de notre catalogue",
    format: "middle"
  }
];
export class SearchBoxComponent extends Component {

  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    $list: PropTypes.any,
  };

  state = {
    query: '',
    theColors: '',
    granits: [],
    selectedGranit: null,
    usedGranit: [],
    layout: ''
  }

  componentDidMount() {
    // Get colors
    apiService.get('/api/catalog/granitcolors').then(result => this.setState({
      theColors: result
    }))
    // Get granits
    apiService.get('/api/catalog/granits').then(result => this.setState({
      granits: result
    }))
  }

  countCategory = type =>{
    const monuments = this.props.catalog.visibleMonuments;
    switch (type) {
      case null:
        return(monuments.length);
      case "favorites":
        return (monuments.filter(item=> this.props.favorites.includes(item.reference)).length);
      case "new":
        return (monuments.filter(item=>item.isNew===true).length);
      case "exclusive":
        return (monuments.filter(item=>item.isExclusive===true).length);
      default:
         return (monuments.filter(item=>item.category===type || (type === 'CIN' && item.category === '900-999')).length);
      }
    
  }

  initConfiguration = (monument) => {
    const {configurationActions} = this.props;
    this.setState({
      initializing: true,
    });
    // this.filterLayouts(monument);
    configurationActions.initConfiguration(monument,this.props.config);
  };

  onClickIcon = type => {
    // Reset le texte si la recherche est faite par catégorie, pour bien
    // montrer que le texte est ignoré.
    let param = null;
    let monumentComponents = getMonumentCategory();
    if((type in monumentComponents) && type !== "ACC") {
      return this.initConfiguration(type)

    }
    else {
      switch (type) {
        case null:
          break;
        case "favorites":
          param = this.props.featured ? { isFeatured: true } : { favorites: this.props.favorites };
          break;
        case "new":
          param = { isNew: true };
          break;
      case "exclusive":
          param = {exclusive: true};
          break;
        default:
          param = { category: type };
          break;
      }
    }
    
    this.search(param);
  };
  searchForm;
  scrollToList = () => {
    /*
     En théorie le formulaire de recherche fait un écran de haut, donc pour scroller aux résultats il
     suffirait de scroller vers y=window.innerHeight

     En pratique, le formulaire fait plus d'un écran de haut quand l'écran n'est pas large.
     On mesure donc la hauteur du formulaire pour scroller jusqu'aux résultats qui le suivent
     verticallement.

     */
    scrollPageTo(this.searchForm.getBoundingClientRect().height);
  }


  search = (search) => {
    // console.log(search)
    this.props.onSearch(search);
    if (!this.props.noScroll) {this.scrollToList();}
  }

  checkExclusive = (data,exclusiveMonuments) =>{
    if (data.exclusive === true){
      if (!exclusiveMonuments){
        return false;
      }
    }
    return true;
  }
  getClass = (data,exclusiveMonuments)=>{
    if (data.exclusive === "check" && !exclusiveMonuments){
      return data.className +'  Largecat';
    }
    return data.className;
  }

  // start filter functions of ReactSearchAutocomplete -------------------------------------------- 
  handleOnSearch = (string, results) => {
    this.setState({query: string});
  }

  handleOnSelect = (item) => {
    // the item selected
    this.setState({query: item.realName});
    // this.search({query: item.realName, granit: this.state.usedGranit, layout: this.state.layout});
    this.search({query: item.realName});
  }

  handleOnClear = () => {
    // the item selected
    this.setState({query: null});
    this.search({query: null});
  }

  onSearchGraniteBtn = () => {
    // get autocomplet input
    const inputFeild = document.querySelector('._Autocomplete input').value;
    if ( inputFeild && inputFeild.length > 1 && this.state.query && this.state.query.length > 1) {
      this.search({query: this.state.query});
    } else {
      this.handleOnClear();
    }
  }
  // End filter functions of ReactSearchAutocomplete -------------------------------------------- 

  colorFilter = (color)=>{
    const selectedColor = this.state.theColors.filter(_color => _color.name == color).map(_color => _color.id);
    const granit = this.state.granits.find(_granit => selectedColor.includes(_granit.color))
    const _used = granit? {id: granit.id, reference: granit.reference, name: granit.name}: null;
    const _selected = granit? {value: granit.id, reference: granit.reference, label: granit.name}: null;
    this.setState({selectedGranit: _selected, usedGranit: _used});
    this.search({query: this.state.query});
  };
  handleOnSelectGranit = (granit) => {
    const _used = granit? {id: granit.value, reference: granit.reference, name: granit.label}: null;
    this.setState({selectedGranit: granit, usedGranit: _used});
    this.search({query: this.state.query});
  };
  handleOnRecetteFilter = (layouts) => {
    this.props.onFilter(layouts)
    this.search({query: this.state.query});
  };
  
  render() {
    // on vérifie l'existence des monuments exclusifs en base et en config
    const exclusiveMonuments = this.props.catalog.exclusive;
    const isAccessories = this.props.catalog.search && this.props.catalog.search.category==='ACC' ? true : false;
    const userRight = this.props.userRight
    let listIcon = iconList
    let monumentsAccessoriesNames = this.props.catalog.monumentsAccessoriesNames
    if(!userCan.frameConfig()){
      listIcon = listIcon.filter(icon => icon.search !== "SEM")
     }
     if(!userCan.veneerConfig()){
      listIcon = listIcon.filter(icon => icon.search !== "PLT")
     }
     if(!userCan.accessoryConfig()){
      listIcon = listIcon.filter(icon => icon.search !== "ACC")
      monumentsAccessoriesNames = monumentsAccessoriesNames.filter(item => !item.name.includes("ACCESSOIRE") && !item.name.includes("VASE") && !item.name.includes("JARDINIÈRE"))
     }
    
    this.state.theColors && this.state.theColors.map(elt => {
      // colorinrgb[colorinrgb.indexOf(Math.max(...colorinrgb))] -= 15
      let colorinrgb = hexToRgb(elt.color)
      const color = "rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",1)";
      const color2 ="rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",0.4)"
      colorinrgb = colorinrgb.map(x => x - 75);
      const color1 ="rgba("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+", 0.9)"
      elt.backcolor = "linear-gradient(0deg,"+color1+" 3%, "+color+" 40%, "+color2+" 90%)"
    })

    const _granits = this.state.granits.filter(granit => (!parseInt(granit.graniteHide) && parseInt(granit.available))).map(granit => ({ value: granit.id, label: granit.name, reference: granit.reference }));
    _granits.sort(function(a,b) {
      var x = a.label.toLowerCase();
      var y = b.label.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    const permissionSearchBloc = userCan.fullPrice()
    const searchBoxStyle = !permissionSearchBloc? {float: "left"}: {}
    const recetteBoxStyle = !permissionSearchBloc? {float: "right"}: {}

    return (
       <div className="MonumentSearchBox" ref={(node)=>this.searchForm = node}>
        { userRight && <div className="Categories">
          <div className="SearchFilter">
            <div className="_FilterL2">

              <div className="_FilterCatalog">
                <label><h3> { !permissionSearchBloc && "Recherche"}&nbsp; </h3></label>
                <div className="_Autocomplete">
                  <ReactSearchAutocomplete
                    items={monumentsAccessoriesNames}
                    onSearch={this.handleOnSearch}
                    onSelect={this.handleOnSelect}
                    onClear={this.handleOnClear}
                    placeholder="Recherche"
                    showIcon={false} 
                    styling={{ 
                      width: "140px", 
                      height: "36px", 
                      boxShadow: "none",
                      fontSize: "16px",
                      fontFamily: "Cuprum, Arial, sans-serif",
                      clearIconMargin: '0 0 0 0',
                      searchIconMargin: '0 0 0 6px',
                      border: "2px solid #b4b4b4",
                      borderRadius: 0,
                      placeholderColor: "#676767",
                      color: "#303030" 
                    }}
                    fuseOptions={{ 
                      ignoreLocation: true, 
                      threshold: 0.1, 
                      sortFn: (a, b) => (a.realName - b.realName), 
                      minMatchCharLength: 1, 
                      keys: [ "name", "name2", "name3"],
                    }}
                    autoFocus
                  />
                  <button className="Find" onClick={this.onSearchGraniteBtn}><img src={SearchIcon} alt="Recherche"/></button>
                </div>
              </div>
              { permissionSearchBloc && <div className="GraniteColors">
                  <label><h3>Couleur</h3></label>
                  <div className="gColors">
                    <button className="AllColors" onClick={()=>this.colorFilter(null)}>Toutes</button>
                    {this.state.theColors && this.state.theColors.map(elt=> <button key={elt.name} className="colorFilter" style={{backgroundImage: elt.backcolor}} onClick={()=>this.colorFilter(elt.name)}/>)}
                  </div>
              </div>}
              { permissionSearchBloc && <div className="FilterGranit">
                  <label><h3>Granit</h3></label>
                  <div className="FGranit">
                    <Select 
                      options={_granits} 
                      isClearable 
                      className="FAuto-select" 
                      placeholder="Tout" 
                      value={this.state.selectedGranit}
                      onChange={this.handleOnSelectGranit} 
                    />
                  </div>
              </div>}
              <div className="_FilterBox">
                {!isAccessories && <FilterBox onFilter={this.handleOnRecetteFilter} filters={this.props.catalog.filters}/>}
              </div>
              
            </div>

          </div>   
            <div className="FilterButtons" id="filterButtons">   
            {listIcon.map(
                  (icon,index) =>
                    this.checkExclusive(icon,exclusiveMonuments) && (
                      <div key={index} className={this.getClass(icon,exclusiveMonuments)} onClick={() => this.onClickIcon(icon.search)}>
                        <figure>
                        {this.props.catalog.filters.layouts[0] !=="all" && <div className="Count">({this.countCategory(icon.search)})</div>}
                          <img src={require(`../../../images/catalog/${icon.image}`)} alt="Categorie"/>
                          <figcaption className={icon.format ? "" : "figcaption"}>
                            <h2>{icon.header}</h2>
                            <p className={icon.format ? "figcaption" : ""}>{icon.text}</p>
                          </figcaption>
                        </figure>
                      </div>
                    )
                )}          
              </div>

        </div> }
      </div>
    );
  }
}
export const SearchBox = connect((state) => ({
  monuments: state.catalog.monuments,
}),
(dispatch) => ({
  configurationActions: bindActionCreators(configurationActions, dispatch)
})
)(SearchBoxComponent);
