import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminConfigActions from "../../actions/adminConfigActions";
import "bootstrap/dist/css/bootstrap.min.css";
import {Button,Row,Col} from "reactstrap";
import Select from 'react-dropdown-select';
import { SortableMonuments } from './components/AdminSortableMonuments';
import arrayMove from 'array-move';
import { confirmAlert } from 'react-confirm-alert';
import { FiltersBurial } from "../../constants";
import * as apiService from "../../services/api.service";
import {getMonumentImage} from '../../services/image.fct.service';

const otherfilters =[
  { label:"Cinéraires",type:"type",filter:"cremation"},
  { label:"Mixtes",type:"type",filter:"mixed"}
];
class AdminFamilyCatalogComponent extends Component {
  state = {
    monumentsRestrictList:[],restrictedCategory:[],mounted:false,searching: false,
    lastRemoved: null, showAdd: false,selectedIndex:1,
    filteredList:[],filters: [...FiltersBurial,...otherfilters]
  };

  async componentDidMount() {
      if (this.props.fullConfig && this.props.fullConfig.config) {
        const { config } = this.props.fullConfig;
        if (config.catalogListMonuments){
          let monumentsRestrictList = [...config.catalogListMonuments];
          const preconfList = await apiService.get(`/api/admin/monuments`);
          preconfList.forEach(elt=>{
            let index = monumentsRestrictList.findIndex(item=>item.reference === elt.reference);
            if (index!==-1){
              monumentsRestrictList[index].familyConfig = elt.familyConfig;
              monumentsRestrictList[index].imageFamily = elt.imageFamily;        
            }
          })  
          monumentsRestrictList.forEach(mnt=>{
            let index2 = this.props.infos.monuments.findIndex(item=>item.reference === mnt.reference);
            if (index2!==-1){
              mnt.graniteMain = {reference:this.props.infos.monuments[index2].graniteMainReference};
              mnt.monumentType = this.props.infos.monuments[index2].monumentType;
              mnt.category = this.props.infos.monuments[index2].category;
              mnt.isFeatured = this.props.infos.monuments[index2].isStar;
              mnt.productId = this.props.infos.monuments[index2].productId;
            }            
          })    
        this.setState({monumentsRestrictList,filteredList:[...monumentsRestrictList],selectedIndex:1});
        }
    }
    this.setState({mounted:true});
  }
  getImage =  (monument)=> {
    if (monument && monument.reference && monument.graniteMain){
      const image = monument.imageFamily ? monument.imageFamily : getMonumentImage(monument,"thumbnail");
      return image
    }else{return ""}
  }

  changeMonumentList(selectedMonuments) {
    if (this.state.monumentsRestrictList.length < selectedMonuments.length) {
      this.setState({lastRemoved: null});
    }
    this.setState({monumentsRestrictList:selectedMonuments,filteredList:selectedMonuments});
  }

  updateMonumentList() {
   const selectedMonuments = this.state.monumentsRestrictList;
    let storedMonuments = [];
    if(selectedMonuments){
      selectedMonuments.forEach((elt, index)=> storedMonuments.push({reference:elt.reference,name:elt.name,weight:index}));
    }
    if (storedMonuments.length !==0){// On évite d'écrire une liste vide au cas où les données n'auraient pas été reçues
      this.updateConfiguration({catalogListMonuments:storedMonuments});
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
              <div className='SelDeleteConf'>
              <p>La liste des monuments  a été mise à jour</p>
              <Row><Col md="6"> <Button onClick={onClose}>OK</Button></Col></Row>
            </div> )}});
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='SelDeleteConf'>
              <p>La liste des monuments sera effacée.<br/> Confirmez-vous ?</p>
              <Row>
                <Col md="6"> <Button onClick={() => {this.updateConfiguration({catalogListMonuments:[]});onClose();}}>Oui</Button></Col>
                <Col md="6"> <Button onClick={onClose}>Non</Button></Col>
              </Row>
            </div>
          );}});
      }
  }

  updateConfiguration(obj) {
    if (this.state.mounted){
      this.props.updateCfg(obj);
      this.componentDidMount();
      this.setState({lastRemoved:null})
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const newList = arrayMove(this.state.monumentsRestrictList, oldIndex, newIndex);
    this.setState({monumentsRestrictList: newList,filteredList: newList});
  };

  removeItem = weight => {
    let restMonuList = this.state.monumentsRestrictList;
    const lastRemoved = `${restMonuList[weight].label} en position ${weight}`;
    restMonuList.splice(weight, 1);
    restMonuList.forEach((elt, index)=> elt.weight = index);
    this.setState({monumentsRestrictList: restMonuList,filteredList:restMonuList, lastRemoved});

  };

  displayIcons = (item) =>{
    if (this.state.selectedIndex === 1){
      return  <i className="icon material-icons delete below" title={`Supprimer le monument `} onClick={()=>this.removeItem(item.weight)}>
      delete_forever</i>  
    }else{
      return <div/>
    }

  }
  getCleanList = () => {
    // On enlève les monuments déjà présents à la liste totale
    if (this.props.infos){
      const cleanList =  this.props.infos.monuments.filter (item=> !this.state.monumentsRestrictList.some(elt=>elt.reference === item.reference))
      return cleanList.map(monument=> ({value:monument.reference,label:monument.name,graniteMainReference:monument.graniteMainReference}))
    }
    else return []

  }
  addItem = (monument) =>{
    //On ajoute le monument en tête de file
    if (monument.length){
      let cloneList = [...this.state.monumentsRestrictList];
      cloneList.unshift({weight:0,reference:monument[0].value,name:monument[0].label,graniteMain:{reference:monument[0].graniteMainReference}});
      this.setState({monumentsRestrictList:cloneList,filteredList:cloneList});
    }
  }
  onClickIcon = (index) => {
    if(index!==-1 && index !== undefined){
       let cloneList = [...this.state.monumentsRestrictList];
        if (this.state.filters[index].category){
          cloneList = this.state.monumentsRestrictList.filter(item=>item.category === this.state.filters[index].categoryType);
        }else if (this.state.filters[index].filter === "isFeatured"){
          cloneList = this.state.monumentsRestrictList.filter(item=>item.isFeatured === "1");
        }else if ((this.state.filters[index].filter && this.state.filters[index].filter !== "all")){
          cloneList = this.state.monumentsRestrictList.filter(item=>item.monumentType === this.state.filters[index].filter);
        }else{ //Tous
          this.componentDidMount();
          return
        }
        this.setState({filteredList:cloneList,selectedIndex:index});
    }
  }  
  getClassName = (index) =>{
    let className = "FilterButton ";
    className += index === this.state.selectedIndex ? " active":"";
    return className;
  };  
  render() {
    const { filteredList, lastRemoved, showAdd, selectedIndex } = this.state;
    const cleanList = this.getCleanList();

    return (
      <div className="AdminMain">
          <div className="AdminMain-monuments">
            <div className="header-admin-principal">
            <i className="icon material-icons close-icon" onClick={() => this.props.close()}>close</i>
              <i className="icon material-icons admin-bookmarks">bookmarks</i>{` Monuments`}
              <span className="AdminFilterButtons"> {this.state.filters.map((elt,index) =>
                    <button key={index} className={this.getClassName(index)} onClick={() => this.onClickIcon(index)}>
                        {elt.label}
                    </button>
                )}</span>
             {!showAdd && selectedIndex === 1 && <div className="addMonument" onClick={()=>this.setState({showAdd:true})}>
              <i className="icon material-icons add " title={`Ajouter un monument `} >add_circle</i>
              Ajouter un monument
              </div>}
              {showAdd && 
                <Select id="monument" name="monument"className="monumentSelectMain"
                searchable={true} clearable={true}options={cleanList} onChange={e => this.addItem(e)}/>
              }

              <div className="container adminContainer">
                Restreindre à une liste de monuments
                <Button  className="AdminFamilyButton" onClick={() => this.updateMonumentList()}>Mettre à jour</Button>
                {lastRemoved &&
                  <div className="last-removed">Dernier monument supprimé: {lastRemoved}</div>
                }
              </div>
              </div>
            <div className="adminPreconfig">
             <SortableMonuments commonList={filteredList}  get_image={this.getImage} axis="xy" disabled={selectedIndex !== 1}
              disp_icons={this.displayIcons} add_config={this.addConfig}  onSortEnd={this.onSortEnd}  distance={2} />
            </div>
          </div>
      </div>
    )
  }
}
export const AdminFamilyCatalog = connect(
  state => ({
    adminConfig: state.adminConfig,
    catalog:state.catalog
  }),dispatch => ({actions: bindActionCreators(adminConfigActions, dispatch),}))(AdminFamilyCatalogComponent);
