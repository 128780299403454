import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';


/**
 * Composant qui Affiche un bouton rectangluaire avec deux valeurs à choisir et un titre
 */
export class TwoChoiceButton extends Component {
  static propTypes = {
    title: PropTypes.any, // Liste de items à afficher
    classUsed: PropTypes.any,
    item1: PropTypes.any.isRequired, // Contenu se trouvant au dessus de la partie scrollable
    item2: PropTypes.any.isRequired, // Contenu se trouvant en dessous de la partie scrollable   
    value: PropTypes.any,
    onSelect: PropTypes.func.isRequired, // Fonction à appeler sur selection
  };


  render() {
    const { title, classUsed, item1, item2, value, data } = this.props;
    return (
      <div className ={"TwoChoiceButton "+classUsed}>
        <label>{title}</label>
        <div style={{display: "flex"}}>
          {/* <button className={cn('', {active:value === item1 })}
          onClick={() =>this.props.onSelect(classUsed,item1,data)}>{item1}</button> */}
          <button 
            style={{borderRadius: "6px 0 0 6px"}}
            className={cn('button-medium', {active:value === item1 })}
            onClick={() =>this.props.onSelect(classUsed,item1,data)}>
            <span>{item1}</span>
          </button>

          {/* <button className={cn('', {active:value === item2 })}
          onClick={() =>this.props.onSelect(classUsed,item2,data)}>{item2}</button> */}
          <button 
            style={{borderRadius: "0 6px 6px 0"}}
            className={cn('button-medium', {active:value === item2 })}
            onClick={() =>this.props.onSelect(classUsed,item2,data)}>
            <span>{item2}</span>
          </button>
        </div>
      </div>
    );
  }
}
