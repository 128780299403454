import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import cn from 'classnames';
import * as _ from 'lodash';

import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import {ModalButton} from '../../../components/ModalButton';
import {ScrollListView} from '../../../components/ScrollListView';
import {FaceCard} from './FaceCard';
import {formatFaceToId} from '../../../services/format.service';
import {isQuote} from '../../../services/restrictedConfiguration';
import history from '../../../history';
import { isConfigRequested } from '../../../services/configurationWarningChecks'
import { TranslationContext } from "../../../context/TranslationContext";

/**
 * ModalButton permettant de choisir la face
 */
class MotifFacePickerComponent extends Component {
  static contextType = TranslationContext;

  state;
  modal;

  constructor(props) {
    super(props);
    this.state = {
      drawing: props.drawing
    };
  }

  onSelectFace = (face) => {
    history
      .push(`/configuration/${this.props.configuration.reference}/motifs-gravures/${formatFaceToId(face, this.state.drawing)}`);
    this.modal.close();
  };

  renderItem = (face) => {
    return face && (
        <FaceCard key={formatFaceToId(face, this.state.drawing)} configuration={this.props.configuration}
                  face={face}
                  drawing={this.state.drawing}
                  selected={formatFaceToId(this.props.face, this.props.drawing) ===
                  formatFaceToId(face, this.state.drawing)}
                  onSelectFace={this.onSelectFace}/>)
  };

  render() {
    const t = this.context;
    const header = (
      <section className="Header">
        <div className="HeaderContainer">
          <div className="buttons-group">
            <button onClick={() => this.setState({drawing: false})}
                    className={cn('button', {active: !this.state.drawing})}>
              { t("config3d_patternandengravings_modal_otherpart_graniton") || "Avec rendu granit" }
            </button>
            <button onClick={() => this.setState({drawing: true})}
                    className={cn('button', {active: this.state.drawing})}>
              { t("config3d_patternandengravings_modal_otherpart_granitoff") || "Sans rendu granit" }
            </button>
          </div>
        </div>
      </section>
    );
    let has2Headstones = false;
    const accessories = this.props.configuration.accessories.map(accessory => accessory.reference)
    const facesWithIndex = this.props.faces.map(face => {
      let index = 6;
      if (face.piece.reference.match(/STELE1?/i) && !face.piece.reference.match(/STELE2/i)) {
        index = 0;
      } else if (face.piece.reference.match(/TOMBALE/i) && face.profile.reference.match(/Dessus/i)) {
        index = 1;
      } else if (face.piece.reference.match(/STELE2/i)) {
        index = 2;
        has2Headstones = true;
      }
      else if (face.piece.reference.match(/TOMBALE/i) && face.profile.reference.match(/Droite/i)) {
        index = 3;
      } else if (face.piece.reference.match(/TOMBALE/i) && face.profile.reference.match(/Avant/i)) {
        index = 4;
      } else if (face.piece.reference.match(/TOMBALE/i) &&
        face.profile.reference.match(/Gauche/i)) {
        index = 5;
      }
      if (face.piece.name == "Accessoire" && isQuote(this.props.configuration)) {
        // return {index, face};
        if(accessories.includes(face.piece.code)){
          return {index, face};
        }  
      }
      else{
        return {index, face};
      } 
    });
    if (!has2Headstones) {
      facesWithIndex.push({index: 2, face: null});
    }
    const facesInOrder = _.sortBy(facesWithIndex, 'index').map(faceInfos => faceInfos && faceInfos.face);

    const buttonContent = t("config3d_patternandengravings_button_otherpart") || "Autre Pièce";
    return (
      <ModalButton ref={(_modal) => this.modal = _modal} buttonContent={buttonContent} buttonClassName={`OtherPiece ${this.props.buttonClassName ? this.props.buttonClassName : ''}`} 
                   buttonIcon="other-piece-icon"
                   className="FacePickerPatternModal buttonModal secondary3"
                   overlayClassName="FacePickerPatternModalOverlay"
                   disabled={isConfigRequested()}
                   toggleSteps={this.props.toggleSteps}
                   launchIntro={this.props.launchIntro}
                   madalName={this.props.madalName}   
                  >
        {header}
        <ScrollListView items={facesInOrder} renderItem={this.renderItem} className="FacePickerPattern"
                        chunkSize="3" drawing={this.state.drawing}/>
      </ModalButton>
    );
  }
}

export const MotifFacePicker = connect((state) => ({
    configuration: state.configurator.current.configuration,
    faces: state.configurator.current.options.faces,
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(MotifFacePickerComponent);
