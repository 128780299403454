import React, {useContext} from 'react';
import * as configurationActions from '../actions/configurationActions'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { TranslationContext } from "./../context/TranslationContext";

function CreateVariantButtonComponent({configuration, configurationActions, path = 'granit'}) {
  const t = useContext(TranslationContext);
  return (
    // <button className="CreateVariant"
    //         onClick={()=>configurationActions.createVariant(configuration, path)}>
    //           <i className="icon material-icons">&#xE14D;</i>
    //   Créer une variante
      
    // </button>
    <div className="btn-wrapper">
      <div className="btn-config">
        <button 
          className="SquareButton btn-config-secondary text-dark secondary3 secondary3-hover"
          onClick={()=>configurationActions.createVariant(configuration, path)}>
          <span className="duplicate2-icon icon icon-large"></span>
          <div>
            { t('config3d_finalize_button_variant') || "Variante" }
          </div>
        </button>
      </div>
    </div>
  )
}
export const CreateVariantButton = connect(null, dispatch=>({
  configurationActions: bindActionCreators(configurationActions, dispatch)
}))(CreateVariantButtonComponent)
