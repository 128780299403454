import catalog from './catalog';
import configurator from './configurator';
import user from './user';
import lastAction from './lastAction';
import ui from './ui';
import isoView from './isoView';
import adminConfig from './adminConfig';
import familyService from './familyService';

export default {
  catalog,
  configurator,
  user,
  lastAction,
  ui,
  isoView,
  adminConfig,
  familyService
}
