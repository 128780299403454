import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {LazyImage} from '../../../components/LazyImage';
import {displayFaceConfigImage} from '../../../services/image.fct.service';
import {formatFace} from '../../../services/format.service';

/**
 * Composant affichant une face dans la popup de choix de face sur l'écran Motifs & Gravures
 */
export function FaceCard({configuration, face, drawing, selected, onSelectFace, className}) {
  const url= displayFaceConfigImage(configuration,face,drawing,'small')

  return (
    <div className={cn(['FaceCard', className, {selected: selected}])}>
      <div className="Face" onClick={() => !selected && onSelectFace(face)}>
        <LazyImage src={url}/>
        <div className="Infos">
          <span className='Name'>{formatFace(face)}</span>
        </div>
      </div>
    </div>
  )
}

FaceCard.propTypes = {
  configuration: PropTypes.shape({
    monument: PropTypes.shape({
      reference: PropTypes.string.isRequired,
      graniteMain: PropTypes.string.isRequired,
    }).isRequired,
    frame: PropTypes.shape({
      granite: PropTypes.string
    }),
  }).isRequired,
  face: PropTypes.shape({
    piece: PropTypes.shape({
      reference: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    profile: PropTypes.shape({
      reference: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  drawing: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectFace: PropTypes.func.isRequired,
};
