import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ModalButton} from '../../../components/ModalButton';
import {checkDisplaySimpleConfig, getReactAppExtranet} from "../../../services/utils.service";
import { TranslationContext } from "../../../context/TranslationContext";

function getDocumentsUrl(object) {
  if (object.quoteId) {
    return getReactAppExtranet(`/documents/${object.client}/${object.folderId}/${object.quoteId}/${object.reference}?share=true`)
  } else {
    return getReactAppExtranet(`/pro-space/configurations/${object.reference}/documents?share=true`)
  }
}

class ShareComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    configuration: PropTypes.object.isRequired,
  };

  modal;

  render() {
    const t = this.context;
    const { isFinalizationStep = false, configuration} = this.props;

    const commonModalButtonProps = {
      buttonIcon: "share5-icon icon-width",
      buttonClassName: "btn-config-secondary text-dark config-button-background config-button-hover",
      children: (
        <iframe
          width="100%"
          title="documents"
          height="100%"
          style={{ border: 0, margin: 0, padding: 0, borderRadius: "15px"}}
          src={getDocumentsUrl(configuration)}>
        </iframe>
      ),
    };

    return (
      <div className="btn-wrapper">
        {!isFinalizationStep && !checkDisplaySimpleConfig(configuration) ? (
          <ModalButton
            buttonContent= {t("config3d_finalize_button_share") || "Partager"}
            className="DocumentsModal"
            overlayClassName="DocumentsModalOverlay"
            {...commonModalButtonProps}
          />
        ) : (
          <ModalButton
            {...commonModalButtonProps}
          />
        )}
      </div>
    );
  }
}

export const Share = connect((state, ownProps) => ({
    configuration: state.configurator.current.configuration,
  })
)(ShareComponent);
