import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import {ModalButton} from '../../../components/ModalButton';
import {HeadstoneCard} from './HeadstoneCard';
import { TranslationContext } from "../../../context/TranslationContext";
import * as _ from 'lodash';

/**
 * ModalButton permettant de choisir la stèle du monument
 */
class HeadstonePickerComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    configuration: PropTypes.shape({
      monument: PropTypes.shape({
        headstone: PropTypes.shape({
          reference: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
    headstones: PropTypes.arrayOf(PropTypes.shape({
      reference: PropTypes.string.isRequired,
      granites: PropTypes.arrayOf(PropTypes.shape({
        reference: PropTypes.string.isRequired,
        madeInFrance: PropTypes.bool,
      })).isRequired,
    })).isRequired
  };

  modal;

  onClose = () => {
    this.modal.close();
  };

  render() {
    const t = this.context;
    const {headstones, configuration} = this.props;

    const filteredHeadstones = headstones ? headstones.filter(headstone =>
      headstone.layouts.find(row => row.layout === configuration.monument.layout && row.monument === configuration.monument.reference.substr(0,4))
    ) : [];
    const buttonContent = t("config3d_monument_button_headstone") || "Stèle";
    return (
      <div className="MainButton">
        <ModalButton ref={(_modal) => this.modal = _modal} 
                     buttonContent={buttonContent}
                     disabled={filteredHeadstones.length <= 1}
                     buttonIcon="headstone-icon"
                     buttonClassName={`btn-config-secondary config-button-background text-dark ${filteredHeadstones.length <= 1 ? 'disabled' : 'config-button-hover'}`}
                     className="buttonModal background-page"
                     toggleSteps={this.props.toggleSteps}
                     launchIntro={this.props.launchIntro}
                     madalName={this.props.madalName}
                    >
          <div className="Header">
            <div className="HeaderContainer">
              <h1 className="Headline">
                { t("config3d_monument_modal_headstone_title") || "Choisissez une combinaison stèle - soubassement" }
              </h1>
            </div>
          </div>
          <HeadstonePickerContent filteredHeadstones={filteredHeadstones} {...this.props} onClose={this.onClose}/>
        </ModalButton>
      </div>
    );
  }
}

class HeadstonePickerContent extends Component {
  static contextType = TranslationContext;
  state = {
    moreOpened: false,
  };

  seeMore = () => {
    this.setState({
      moreOpened: true,
    })
  };

  onSelectHeadstone = (headstone) => {
    this.props.actions.updateHeadstone(headstone.reference);
    this.props.onClose();
  };

  renderItem = (headstone) => {
    return (
      <div key={headstone.reference} className="ListViewItem">
        <div className="ListViewItemContainer">
          <HeadstoneCard configuration={this.props.configuration}
                         headstone={headstone}
                         onSelectHeadstone={this.onSelectHeadstone}
          />
        </div>
      </div>
    );
  };

  render() {
    const t = this.context;
    const {filteredHeadstones, configuration} = this.props;
    const selectedHeadstoneRef = configuration.monument.headstone.reference;

    const warning = (!!configuration.patterns.length || !!configuration.engravings.length) && (
        <p className="Warning">
          { t("config3d_monument_modal_headstone_subtitle") || "Attention&nbsp;: le changement de stèle supprimera les motifs et les gravures de la configuration." }
        </p>
      );

    // Les stèles sont déjà triées côté serveur
    const suggested = _.takeWhile(filteredHeadstones, ({featured, suggested, layout}) => featured || suggested);
    const others = _.takeRightWhile(filteredHeadstones, ({featured, suggested}) => !featured && !suggested);
    // On retire la stèle sélectionnée des autres et on l'ajoute dans les suggérés
    const selected = _.remove(others, ({reference}) => reference === selectedHeadstoneRef);
    if (selected && selected.length) {
      suggested.push(...selected);
    }

    return (
      <div className="HeadstonePicker">
        {warning}
        {/* <h4>Combinaisons suggérées</h4> */}
        <div className="ListView">
          {
            _.map(suggested, this.renderItem)
          }
        </div>
        {
          !!others.length && (
            this.state.moreOpened ? (
                <div>
                  <h4>
                    { t("config3d_monument_modal_headstone_suggestion") || "COMBINAISONS SUGGÉRÉES" }
                  </h4>
                  <div className="ListView">
                    {
                      _.map(others, this.renderItem)
                    }
                  </div>
                </div>
              ) : (
                <button onClick={this.seeMore} class="button-primary button-medium">
                  <span class="chevron-down-icon icon-medium text-dark-icon"></span>
                  <span>
                    { t("config3d_monument_modal_headstone_more_combinations") || "Plus de combinaisons" }
                  </span>
                </button>
              )
          )
        }
      </div>
    )
  }

}

export const HeadstonePicker = connect((state) => ({
    configuration: state.configurator.current.configuration,
    headstones: state.configurator.current.headstones,
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(HeadstonePickerComponent);
