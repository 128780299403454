import React from 'react'
import { connect } from 'react-redux'
import history from '../../../history'
import { bindActionCreators } from 'redux'
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions'
import { fabric } from 'fabric'
import * as uiActions from '../../../actions/uiActions'
import {
  Fabric,
  typeEngraving,
  typePattern,
  defaultOptions
} from '../components/Fabric'
import { imgs } from '../../../services/images.service'
import { displayFaceConfigImage } from '../../../services/image.fct.service'
import * as _ from 'lodash'
import { formatFaceToId } from '../../../services/format.service'
import { assignDeep } from '../../../services/assignDeep.service'
import { EngravingParams } from '../motif/EngravingParams'
import {
  engravingDefaultText,
  FengravingParams
} from '../../family/FengravingParams'
import { checkModifiablePattern } from '../../../services/restrictedConfiguration'
import { getFontInfo, engravingOpacity } from '../../../services/generateEngravings.service'
import MediaQuery from 'react-responsive/src'
import { isSmartphone } from '../../../services/domFunctions'

const familyLoader = require('./../../../images/loader_79.png');
const patternDefaults = Object.assign({}, defaultOptions, {
  lockScalingFlip: true,
  lockScalingX: false,
  lockScalingY: false,
  lockUniScaling: false,
  originX: 'center',
  originY: 'center',
  selectable: true
})

const engravingDefaults = Object.assign({}, defaultOptions, {
  lockScalingFlip: true,
  lockScalingX: true,
  lockScalingY: true,
  lockUniScaling: true,
  hasControls: false,
  lockRotation: true,
  hasRotatingPoint: false,
  originX: 'center',
  originY: 'center',
  selectable: false
})

class PatternFabricComponent extends Fabric {
  itemsProperties = {
    patterns: {
      add: this.addPatternsToCanvas.bind(this),
      update: this.updatePatternProperties.bind(this)
    },
    engravings: {
      add: this.addEngravingsToCanvas.bind(this),
      update: this.updateEngravingProperties.bind(this)
    }
  }

  addObject = () => {
    this.props.actions.addEngraving(
      this.props.user,
      this.props.face,
      null,
      engravingDefaultText
    )
  }

  actionBarProps() {
    return {
      onMirrorPattern: this.onMirrorPattern.bind(this),
      onToggleEngravingReservation: this.onToggleEngravingReservation.bind(this)
    }
  }

  footerProps() {
    return {
      patterns: this.props.patterns,
      onChangePattern: this.onChangePattern.bind(this),
      onMirrorPattern: this.onMirrorPattern.bind(this),
      onToggleEngravingReservation:
        this.onToggleEngravingReservation.bind(this),
      onChangeEngravingText: this.onChangeEngravingText.bind(this)
    }
  }

  onCloseFengravingParams() {
    this.unselectAll();
    this.setState({editEngrav: false});
  }

  renderSpecificComponents() {
    const screenHeight = window.innerHeight - 150
    const designType = this.props.designType
    const selectedEngravingsId = _.chain(this.state.selectedObjects)
      .filter({ type: typeEngraving })
      .map('id')
      .value()
    return (
      <div>
        {this.props.family && this.props.mode === 'engraving' && (
          // family version of params
          <> 
            <FengravingParams
              engravingsId={selectedEngravingsId || []}
              editEngrav={this.state.editEngrav}
              onClose={() => this.onCloseFengravingParams()}
              face={this.props.face}
              calculateEngravingsLines={this.calculateEngravingsLines.bind(
                this
              )}
              removeObject={this.onRemoveObjects}
              removeAll={this.removeAllEngravings}
              activateObject={this.activateObjectById}
              backAction={() => history.goBack()}
            />
            <div
              className={`addElem${designType}`}
              onClick={() => this.addObject()}
            >
              <div className='IconAddBlocIcon'>
                <svg
                  width='14'
                  height='15'
                  viewBox='0 0 14 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13.6663 8.33325H7.83301V14.1666H6.16634V8.33325H0.333008V6.66658H6.16634V0.833252H7.83301V6.66658H13.6663V8.33325Z'
                    fill='#324854'
                  />
                </svg>
              </div>
              <span>Ajouter une gravure</span>
            </div>
          </>
        )}
        {!this.props.family && (
          //Standard version of params
          <EngravingParams
            engravingsId={selectedEngravingsId || []}
            onClose={this.unselectAll}
            calculateEngravingsLines={this.calculateEngravingsLines.bind(this)}
          />
        )}
      </div>
    )
  }

  hasBackgroundChanged(nextProps) {
    if (
      !this.props.accessoryMode &&
      formatFaceToId(this.props.face, this.props.drawing) !==
        formatFaceToId(nextProps.face, nextProps.drawing)
    ) {
      // La face a changé, il faut tout redessiner
      this.initCanvas(nextProps)
      return true
    }
    return false
  }

  getBackgroundImage({ configuration, face, drawing }) {
    return displayFaceConfigImage(configuration, face, drawing)
  }

  addAllItemsToCanvas(props) {
    this.addPatternsToCanvas(props, this.getItemsFromProps(props, 'patterns'))
    this.addEngravingsToCanvas(
      props,
      this.getItemsFromProps(props, 'engravings')
    )
  }

  getItemsFromProps({ configuration, face }, type) {
    return _.chain(configuration[type])
      .filter('id')
      .filter((item) => !item.required)
      .filter({
        face: {
          piece: face.piece.reference,
          profile: face.profile.reference
        }
      })
      .value()
  }

  addPatternsToCanvas(props, patternsToAdd) {
    //En mode gravure seule on ne peut pas changer les motifs
    
    _.each(patternsToAdd, (pattern) => {
      if(props.face.piece.name=="Accessoire" && props.face.piece.index != pattern.face.index ) return;
      const patternObj = new fabric.Image(null, patternDefaults)
      patternObj.set({
        id: pattern.id,
        reference: pattern.reference,
        type: typePattern,
        left: pattern.position.fabricJS.x * this.canvas.width,
        top: pattern.position.fabricJS.y * this.canvas.height,
        flipX: pattern.position.scale.x < 0, // Mode miroir
        scaleX: Math.abs(pattern.position.scale.x),
        scaleY: pattern.position.scale.y,
        angle: pattern.position.rotation,
        imprint: pattern.imprint ? pattern.imprint : false,
        selectable: checkModifiablePattern(this.props.configuration, pattern,this.props.mode)
      })
      this.updatePatternProperties(props, pattern, patternObj)
      this.canvas.add(patternObj)
    })
  }

  updatePatternProperties({ face, drawing, patterns }, pattern, object) {
    const patternConfig = _.find(patterns, { reference: pattern.reference })
    const loader = this.props.family ? familyLoader : '';
    object.set({
      reference: pattern.reference
      // minScale: patternConfig.scale.min,
      // maxScale: patternConfig.scale.max,
    })

    const placeholder = imgs.pattern(
      pattern.reference,
      drawing ? 'drawing' : 'granite',
      'thumbnail'
    )
    if (object.getSrc() !== placeholder){
      if(loader != ''){
        this.setImage(loader, object, patternConfig, face)
        setTimeout(()=>{
          this.setImage(placeholder, object, patternConfig, face)
        })
      }else{
        this.setImage(placeholder, object, patternConfig, face)
      }
      
    }
  }
  firstTimeEmbeddedPattern(embedded) {
    // Vérifie si c'est la première fois qu'on visualise le motif associé au monument
    const pattern = _.find(this.props.configuration.patterns, {
      reference: embedded.reference
    })
    if (pattern && pattern.position && !pattern.position.scaleRatio) {
      return true
    }
    return false
  }
  setImage(_src, object, patternConfig, face) {
    const loader = this.props.family ? familyLoader : '';
    if (patternConfig) {
      object.setSrc(_src, () => {
        object.setCoords()

        // L'echelle doit prendre en compte le ratio entre la taille réelle de l'image et celle à afficher + l 'echellement du monument
        // selon la vu ex: Face ou dessus
        // le rapport antre cette echelle et l'echelle standard est stockée dans Ratiox et y
        const theScaleX =
          (patternConfig.size.width / object.width) *
          ((face.zone.scale * this.canvas.width) / face.zone.size.width)
        const theScaleY =
          (patternConfig.size.height / object.height) *
          ((face.zone.scale * this.canvas.height) / face.zone.size.height)
        if (_src != loader){
          if (this.firstTimeEmbeddedPattern(patternConfig) && !object.ratioX) {
            //seulement la première fois on met la bonne echelle sinon motif tout petit
            object.set({
              scaleX: object.scaleX * theScaleX,
              scaleY: object.scaleY * theScaleY,
              minScale: patternConfig.scale.min * theScaleX,
              maxScale: patternConfig.scale.max * theScaleY,
              ratioX: theScaleX,
              ratioY: theScaleY
            })
          } else {
            object.set({
              minScale: patternConfig.scale.min * theScaleX,
              maxScale: patternConfig.scale.max * theScaleY,
              ratioX: theScaleX,
              ratioY: theScaleY
            })
          }
        }else{
          object.set({
            minScale: patternConfig.scale.min * theScaleX,
            maxScale: patternConfig.scale.max * theScaleY,
            //ratioX: theScaleX,
            ratioY: theScaleY
          })
        }
        this.canvas.renderAll()

        this.updateActionBarPosition()
      })
    }
  }

  addEngravingsToCanvas(props, engravings) {
    const engravingsToUpdate = []
    const selectable = !(this.props.configuration.configType=='family' && this.props.mode != 'engraving')
    const editable = props.family ? false : true
    _.each(engravings, (engraving) => {
      if(props.face.piece.name=="Accessoire" && props.face.piece.index != engraving.face.index ) return;
      const engravingObject = new fabric.IText(
        engraving.text,
        engravingDefaults
      )
      engravingObject.set({
        id: engraving.id,
        type: typeEngraving,
        left: engraving.position.fabricJS.x * this.canvas.width,
        top: engraving.position.fabricJS.y * this.canvas.height,
        angle: engraving.position.rotation,
        selectable: selectable,
        editable: editable
      })

      this.updateEngravingProperties(props, engraving, engravingObject)

      this.canvas.add(engravingObject)
      // Si la taille n'a pas encore été calculé, on la calcule
      if (!engraving.position.size) {
        engravingsToUpdate.push(
          Object.assign({}, engraving, {
            position: Object.assign({}, engraving.position, {
              size: {
                width: engravingObject.width / this.canvas.width,
                height: engravingObject.height / this.canvas.height
              }
            }),
            lines: this.calculateEngravingLines(engravingObject)
          })
        )
      }
    })

    if (engravingsToUpdate.length) {
      this.props.actions.updateEngravings(engravingsToUpdate)
    }
    if (
      this.props.family &&
      this.props.mode === 'engraving' &&
      engravings.length > 0
    ) {
      this.activateObjectById(engravings[0].id)
    }
  }

  updateEngravingText(id, text) {
    // let obj = _.find(objects, {id: id});
  }
  updateEngravingProperties(
    { face, drawing },
    engraving,
    object,
    typeUpdate = null
  ) {
    const theFont = getFontInfo(engraving.font.family, this.props.user)
    if (theFont) {
      object.set({
        fontFamily: theFont.family,
        fontSize:
          ((engraving.font.size * theFont.scale) / face.zone.size.width) *
          face.zone.scale *
          this.canvas.width,
        textAlign: engraving.font.align,
        lineHeight: engraving.font.lineHeight / engraving.font.size + 1,
        text: engraving.text,
      })
      if (theFont.fontStyle || theFont.fontStyle === "") {
        object.set({fontStyle:  theFont.fontStyle })
      }
      // delete object.fontStyle

      // if(engraving.font.style) {
      //   object.set({fontStyle: engraving.font.style})
      // }

      // if(engraving.font.opacity) {
      //   object.set({opacity: engraving.font.opacity})
      // }
      if (typeUpdate !== 'update') {
        // Important pour empêcher d'avoir un curseur fantôme
        object.abortCursorAnimation()
      }
      if (drawing) {
        if (engraving.reservation) {
          // Dessin et gravure réservée
          object.set({
            cursorColor: '#000',
            fill: 'transparent', //'transparent'
            stroke: '#000',
            strokeWidth: 0.75 //0.75
          })
        } else {
          // Dessin et gravure non réservée
          object.set({
            cursorColor: '#000',
            fill: engraving.font.color,
            stroke: '#000',
            strokeWidth: 0.5
          })
        }
      } else {
        if (engraving.reservation) {
          // Granite et gravure réservée
          object.set({
            cursorColor: engraving.font.color,
            fill: 'transparent', // 'transparent'
            stroke: engraving.font.color, // engraving.font.color
            strokeWidth: 1.5 //1.5
          })
        } else {
          // Granite et gravure non réservée
          object.set({
            cursorColor: engraving.font.color,
            fill: engraving.font.color,
            stroke: 'transparent',
            strokeWidth: 0
          })
        }
      }
      this.canvas.renderAll()
    }
  }

  onMirrorPattern() {
    const patternObject = this.state.selectedObjects[0]
    const pattern = _.find(this.props.configuration.patterns, {
      id: patternObject.id
    })
    if (!pattern) return

    const flipX = !patternObject.flipX
    const scaleX = pattern.position?.scale?.x

    const updatedPattern = assignDeep(
      assignDeep(
        pattern,
        'position.scale.x',
        flipX ? -Math.abs(scaleX) : Math.abs(scaleX)
      ),
      'hflip',
      flipX
    )
    
    this.props.actions.updatePatterns([updatedPattern])
    patternObject.set({
      flipX
    })
    this.canvas.renderAll()
  }

  onChangePattern() {
    this.props.uiActions.openPatternPicker(this.state.selectedObjects[0])
  }

  onToggleEngravingReservation() {
    const engraving = _.find(this.props.configuration.engravings, {
      id: this.state.selectedObjects[0].id
    })
    if (!engraving) return
    // if(engraving.reservation) {
    //   engraving.font.opacity = 1
    // } else {
    //   engraving.font.opacity = engravingOpacity(this.props.user)
    // }

    this.props.actions.updateEngravings([
      Object.assign({}, engraving, {
        reservation: !engraving.reservation
      })
    ])
  }

  onChangeEngravingText() {
    this.state.selectedObjects[0].enterEditing()
    this.state.selectedObjects[0].selectAll()
  }

  calculateEngravingsLines(engravings) {
    this.applyOnSelectedObjects((objects) => {
      _.forEach(engravings, (engraving) => {
        let obj = _.find(objects, { id: engraving.id })
        obj = fabric.util.object.clone(obj)
        this.updateEngravingProperties(this.props, engraving, obj)
        engraving.position = Object.assign({}, engraving.position, {
          size: {
            width: obj.width / this.canvas.width,
            height: obj.height / this.canvas.height
          }
        })
        engraving.lines = this.calculateEngravingLines(obj)
      })
    })
  }
  calculateEngravingSingleLeftAlign(obj, delta = 0, text = null, length = 1) {
    return [
      {
        text: text ? text : obj.text,
        position: {
          '2d': {
            x: (obj.left + delta) / this.canvas.width,
            y: (obj.top + delta) / this.canvas.height
          },
          size: {
            width: obj.width / this.canvas.width,
            height: obj.height / (this.canvas.height * length)
          },
          scale: {
            x: 1,
            y: 1
          },
          rotation: obj.angle
        }
      }
    ]
  }
  calculateEngravingLines(obj) {
    if (obj.textAlign === 'left') {
      //Cas de l'alignement gauche c'est géré sur une ligne
      return this.calculateEngravingSingleLeftAlign(obj) // Car Lumiscaphe aligne à gauche sur une ligne mais au centre sur plusieurs
    }
    const split = obj.text.split('\r\n')
    let TheLines = []
    split.forEach((item, index) => {
      // On ajoute le pas puis un décallage par rapport au haut qui dépend de la taille de l'objet
      const delta = (index * obj.height) / split.length - (2 * obj.height) / 5
      const line = this.calculateEngravingSingleLeftAlign(
        obj,
        delta,
        item,
        split.length
      )
      TheLines.push(line[0])
    })
    return TheLines
  }
}
export const PatternFabric = connect(
  (state) => ({
    user: state.user,
    undoCounter: state.configurator.undoCounter,
    configuration: state.configurator.current.configuration,
    patterns: state.configurator.current.patterns,
    className: `PatternEngravingZone ${
      state.configurator.current.configuration.designType &&
      state.configurator.current.configuration.designType === 'B'
        ? 'PatternEngravingZoneB'
        : ''
    }`
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch)
  })
)(PatternFabricComponent)
