import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./App";
import {CSSLoader} from './components/CssLoader';
import { configureStore } from "./store";
import { TranslationProvider } from "./context/TranslationContext";
import "./css/variables.css"
import "./sass/global.scss";

configureStore((store, history) => {
  const isFamilyConfigurator = () => {
    const url = window.location.href;
    return url.includes("famille")
  }

  const appComponent = (
    <Provider store={store}>
      {isFamilyConfigurator() ? (
        <App history={history} />
      ) : (
        <TranslationProvider>
          <CSSLoader>
            <App history={history} />
          </CSSLoader>
        </TranslationProvider>
      )}
    </Provider>
  );

  ReactDOM.render(
    appComponent,
    document.querySelector("#gpgConfigurator3d")
  );
});
