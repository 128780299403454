import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from './Modal';
import {introIsAvailable} from "../pages/helpers/introHelpers"
import {clickTheCanvas} from '../pages/helpers/config3dHelper';

const cn = require('classnames');
/**
 * Toggle l'affichage d'une modal
 *
 * <p>
 *   TODO Il faudrait faire une méthode renderModal pour le contenu de la modal
 *   car actuellement le contenu est rendu même quand la modal est fermée
 * </p>
 */
export class ModalButton extends Component {
  static propTypes = {
    buttonContent: PropTypes.any.isRequired,
    buttonIcon: PropTypes.any,
    buttonClassName: PropTypes.string,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    openAtStart: PropTypes.bool,
    noModal: PropTypes.bool,
  };

  state = {
    open: false
  };
  componentDidMount(){
    if (this.props.openAtStart){
      this.setState({open:true});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { madalName } = this.props;
    if (this.state.open && this.state.open !== prevState.open && madalName) {
      setTimeout(() => {
        // this.props.launchIntro(true, madalName);
      }, 500)
    }
  }

  toggle = () => {
    if (!this.props.disabled){
      
      clickTheCanvas();
      this.setState({
        open: !this.state.open
      });
    }
  };

  close = () => {
    this.setState({
      open: false
    });
    if (this.props.onModalClose){
      this.props.onModalClose();
    }
  };

  render() {
    const {children, buttonContent, buttonIcon, buttonClassName, disabled,noModal, additionalInfo=null, madalName, ...others} = this.props;
    return (
      <div className="ModalButton">
        {noModal && children}
        {!noModal && 
        <button className={cn(`${this.props.buttonClassName ? this.props.buttonClassName : ""} SquareButton`, {disabled})}
                onClick={this.toggle} disabled={disabled}>
          <span className={`${buttonIcon} icon icon-large`}></span>
          {(buttonContent && !additionalInfo) && <div>{buttonContent}</div>}
        </button>
        }
        {additionalInfo && <div className="additional-info text-dark">{additionalInfo}</div> }
        {!disabled  && !noModal && (
          <Modal {...others} isOpen={this.state.open} onRequestClose={this.close}>
            {introIsAvailable(false, madalName) && <span onClick={() => this.props.toggleSteps(this.props.madalName)} class="open-tutorial question-circle-icon icon icon-large"></span>}
            {children}
          </Modal>
        )}
      </div>
    );
  }
}
