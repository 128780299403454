import React, { Component } from "react";
import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as configurationActions from '../../actions/configurationActions';
import * as familySpaceAction from '../../actions/familySpaceActions';
import {isSmartphone} from "../../services/domFunctions";
import { createBrowserHistory } from 'history';
import history from "../../history";
import MediaQuery from "react-responsive/src";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ReactDOM from "react-dom";
import { gtmFamily } from "../../services/gtmFamily";
import { Row, Col } from 'reactstrap';
import duplicateIcon from "../../images/icons-config-famille/duplicate-icon-gpg-granit.svg"
import shareIcon from "../../images/icons-config-famille/share-icon-gpg-granit.svg"
import compareIcon from "../../images/icons-config-famille/compare-icon-gpg-granit.svg"
import writeIcon from "../../images/icons-config-famille/write-icon-gpg-granit.svg"
import archiveIcon from "../../images/icons-config-famille/archive-icon-gpg-granit.svg"

let forceRefreshHistory = createBrowserHistory({ forceRefresh: true })
export class SprojectHeaderActionsComponent extends Component {
  state = {changeName:false,clicked:false};

  gtmProductObj = ()=> {
    if (!this.props.project.config) {
      return {}
    }

    const { monument } = this.props.project.config.configuration;
    const { granites } = this.props.project.config.options;
    const graniteMain = granites.find((granit => granit.reference == monument.graniteMain ))
    const graniteSecondary = granites.find((granit => granit.reference == monument.graniteSecondary ))
    
    return {
      id: monument.productId,
      name: "", // empty
      reference: monument.reference,
      price_from: 0, // empty
      granits: [
        graniteMain ? graniteMain.name : monument.graniteMain, 
        graniteSecondary ? graniteSecondary.name : monument.graniteSecondary
      ],
      styles: [], // second filter <--
      couleurs: [], // empty
      religions: [], // empty
      types: [], // main filter <--
      granit_main: graniteMain ? graniteMain.name : monument.graniteMain,
      flower_button: "", // empty
      v360_button: true //empty
    }
  }

  editMonumentAction = () => {
    const { project, full } = this.props;
    // GTM
    const product = this.gtmProductObj();
    gtmFamily().ctaOfConfiguration({
      name: "modifier le monument",
      category: "configurateur",
      subcategory: "produit",
      type: "menu",
      from: "vignette projet"
    }, 
    { 
      template: "espace famille", 
      subtemplate: full ? "projet" : "liste projets" 
    }, 
    {
      product: product
    })
    // end GTM
    forceRefreshHistory.push(`/famille/${project.client}/${project.config_id}/customize`);
  }

  createVarianteAction = () => {
    const { project, full } = this.props;
    // GTM
    const product = this.gtmProductObj();
    gtmFamily().ctaOfConfiguration({
      name: "créer une variante",
      category: "configurateur",
      subcategory: "produit",
      type: "menu",
      from: "vignette projet"
    }, 
    { 
      template: "espace famille", 
      subtemplate: full ? "projet" : "liste projets" 
    },
    {
      product: product
    })
    // end GTM
    this.props.creerVariante();
  }

  compareAction = () => {
    const { project, full } = this.props;
    // GTM
    const product = this.gtmProductObj();
    gtmFamily().ctaOfConfiguration({
      name: "comparer",
      category: "espace famille",
      subcategory: "comparateur",
      type: "menu",
      from: "vignette projet"
    }, 
    { 
      template: "espace famille", 
      subtemplate: full ? "projet" : "liste projets" 
    },
    {
      product: product
    })
    // end GTM
    this.props.familySpaceAction.addSelectedProject(project.id); 
    history.push(`/famille/${project.client}/familyspace/projects/compare`)
  }

  archiveAction = () => {
    const { project, archive, full } = this.props;
    // GTM
    const product = this.gtmProductObj();
    gtmFamily().ctaOfConfiguration({
      name: "archiver",
      category: "espace famille",
      subcategory: "archiver",
      type: "menu",
      from: "vignette projet"
    }, 
    { 
      template: "espace famille", 
      subtemplate: full ? "projet" : "liste projets" 
    },
    {
      product: product
    })
    // end GTM
    document.removeEventListener('mousedown', this.handleClickOutside);
    archive(project)
  }

  getlistActions = () =>{
    const {project, config, shareProjectAction, full=null} = this.props;
    const isConfig = project && !!project.config_id;
    const isConfigClient = isConfig && project.config_id.startsWith('2');

    let actions = [];
    actions.push({text:"Partager", short:"Partager", icon: shareIcon, action:()=> shareProjectAction()});
    actions.push({text:`Comparer`, short:"Comparer", icon: compareIcon, action:() => this.compareAction()})
    if (isConfig && !isConfigClient){
      // Si la configuration a été finalisé et le devis demandé on ne peut changer la configuration
      const disable = (config && config.configuration && config.configuration.newfamilyInfos.emailSent) || false;
      actions.push({text:`Modifier`,disabled: disable, short:"Modifier monument", icon: writeIcon, action:() => disable ? null : this.editMonumentAction()})
    }
    if (isConfig){ // Pour toutes le configurations on peut proposer de créer une variante ou de comaprer
      actions.push({text:`Créer une variante`,short:"Créer variante", icon: duplicateIcon, action:() => this.createVarianteAction()});
    }
    // On peut tout archiver et tout comparer
    actions.push({text:`Archiver`, short:"Archiver", icon: archiveIcon, action:() => this.archiveAction()})
    return actions;
  }



  render() {
    const listActions = this.getlistActions();
    const {clicked} = this.state;
    const {full} = this.props;

    return(
      <Row>
        {listActions.map((action,index) => {
          return (
            <Col xs={2} sm={2}
              key={index} 
              className={`${index === 0 ? 'action ml-auto' : 'action'}`}
              style={full ? {/*"display":"flex", "flexDirection":"column", "minWidth":"115px", */ "margin":"0", "lineHeight":"25px", "height":"100%", "padding":"15px 0"} : {}}
              onClick={() => action.action()}>              
              <span className={!full ? "tooltip top" : ""} tooltip-text={action.text}>
                <img style={{"width": "30px"}} src={action.icon} alt={action.text} />
              </span>
              <MediaQuery minWidth={576}>
              {full && <p style={{"marginBottom": 0}}> {action.text} </p>}
              {!full && null}
              </MediaQuery>
            </Col>
          )
        })}
      </Row>

      // <div className={`project-side${full ? " project-side-full" : "" }`}>
      //   <div onClick={()=>this.displayProjectMenu(clicked)} className={`ProjectMenu ${clicked ? "pms-margin": ""} ${full ? "secondaryBtn outlinedBtn": ""}`}>
      //     <MediaQuery minWidth={577}>
      //       <span>Menu</span>
      //       <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
      //     </MediaQuery>
      //     <MediaQuery maxWidth={576}>
      //         <MenuIcon className="material-icons Smenu-nav-burger"/>
      //     </MediaQuery>
      //   </div>  
      //   <div className="SheaderActions">
      //       {clicked &&
      //         <div className={`ProjectMenuContent md-whiteframe-8dp fade-in ${full ? "ProjectMenuContentFull" : "" }`}>
      //         <div className="menuList">
      //           {listActions.map((action,index) =>
      //             <div key={index} className="action" onClick={() => action.action()}>{isSmartphone()? action.short: action.text}</div>
      //           )}
      //         </div>
      //           <MediaQuery maxWidth={576}>
      //             <CloseIcon className="material-icons Smenu-nav-burger" onClick={()=>this.setState({clicked: !clicked})}/>
      //           </MediaQuery>
      //       </div>}
      //   </div>
      // </div>
    )
  }
  handleClickOutside = (event)=>{
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        clicked: false
      });
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }
  displayProjectMenu(clicked) {
    if(!clicked){
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    this.setState({clicked: !clicked});
  }
};

export const SprojectHeaderActions = connect(state =>
  ({ familyService: state.familyService,}), dispatch =>({
    configurationActions: bindActionCreators(configurationActions, dispatch),
    familySpaceAction: bindActionCreators(familySpaceAction, dispatch)})
)(SprojectHeaderActionsComponent);

