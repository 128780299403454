import TagManager from 'react-gtm-module';
import GtmCommon from './gtmCommon';

class GtmPro extends GtmCommon {
  constructor() {
    super("pro", "GTM-MNDPDK4")
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new GtmPro();
    }
    return this.instance
  }
}
export const gtmPro = () => GtmPro.getInstance();