import React, { Component} from "react";
import * as _ from "lodash";
import { lazyImageSrcAttr, lazyImageClassName } from "./LazyImage";

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 *
 * https://codepen.io/gapcode/pen/vEJNZN
 */
function detectIE() {
  var ua = window.navigator.userAgent;
  return (
    (ua.indexOf("MSIE ") || ua.indexOf("Trident/") || ua.indexOf("Edge/")) > 0
  );
}

/**
 * Composant liste qui charge les images en lazy selon la position du scroll.
 * Par défaut, il se base sur le scroll de la page mais il est possible de fournir un autre élément via
 * la props scrollableElement.
 */
export class ListView extends Component {
  chunks = [];

  constructor() {
    super();
    this.recomputeChunkVisibility = _.debounce(
      this.recomputeChunkVisibility,
      250
    );
  }

  registerChunk = (node, index) => {
    if (!node) return;
    this.chunks[index] = {
      offset: node.offsetTop,
      node: node,
      shown: false
    };
  };

  componentWillReceiveProps(nextProps) {
    this.unregister(this.props);
    this.register(nextProps);
  }

  componentDidMount() {
    this.register(this.props);
    this.recomputeChunkVisibility();
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps, this.props)) return;
    this.recomputeChunkVisibility();
  }

  componentWillUnmount() {
    this.unregister(this.props);
  }

  register = props => {
    (props.scrollableElement || window || document).addEventListener(
      "scroll",
      this.onPageScroll
    );
  };

  unregister = props => {
    (props.scrollableElement || window || document).removeEventListener(
      "scroll",
      this.onPageScroll
    );
  };

  recomputeChunkVisibility = () => {
    var scrollTop =
      (
        this.props.scrollableElement ||
        (document.documentElement || document.body)
      ).scrollTop || window.pageYOffset;
    var top = scrollTop - window.innerHeight;
    var bottom = top + window.innerHeight * 2;
    if (this.props && this.props.onScroll) {
      this.props.onScroll(top);
    }
    this.chunks.forEach(function(chunk) {
      if (chunk.shown) {
        return;
      }
      let show = chunk.offset > top && chunk.offset < bottom;
      if (show) {
        chunk.shown = true;
        _.each(chunk.node.getElementsByClassName(lazyImageClassName), img => {
          if (!img.getAttribute(lazyImageSrcAttr)) return; // LazyImage avec lazy == false
          img.src = img.getAttribute(lazyImageSrcAttr);

          if (detectIE()) {
            img.style.zoom = getComputedStyle(img).zoom || 1; // Force refresh on IE browsers
          }
        });
      }
    });
  };

  onPageScroll = _.debounce(this.recomputeChunkVisibility, 500);

  render() {
    const { items, renderItem, chunkSize = 12 } = this.props;
    return (
      <div className="ListView">
        <div className="ListViewContainer">
          {_.chunk(items, chunkSize).map((group, groupIndex) => (
            <div
              className="ListViewChunk"
              key={groupIndex}
              ref={node => this.registerChunk(node, groupIndex)}
            >
              {group.map((item, index) => (
                <div className="ListViewItem" key={index}>
                  <div className="ListViewItemContainer">
                    {renderItem(item, chunkSize * groupIndex + index)}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
