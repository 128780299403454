import * as types from './actionTypes';
import * as api from '../services/api.service';

export  function load(config=null,price=false, force = false) {
  return  (dispatch, getState) => {
    // Si les monuments sont déjà chargés, on se contente de reset
    // la recherche
    if (getState().catalog.monuments.length && ! price && !force) {
      return dispatch({
        type: types.CATALOG_SEARCH,
        payload:{
          adminConfig: config
        }
      })
    }
    dispatch({
      type: price ? types.CATALOG_LOAD_PRICE_START : types.CATALOG_LOAD_START,
    });
     const url = price ? `/api/catalog/monuments?price=1` :`/api/catalog/monuments`;

     api.get(url)
      .then(monuments => {
        dispatch({
          type: price ? types.CATALOG_LOAD_PRICE_SUCCESS : types.CATALOG_LOAD_SUCCESS,
          payload: {
            catalogLoaded: true,
            monuments: monuments,
            adminConfig: config,
            price:price

          }
        })
      })
      .catch(error => {console.log(error)
        dispatch({
          type: price ? types.CATALOG_LOAD_PRICE_FAILURE : types.CATALOG_LOAD_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  }
}

export function search(payload,config=null, monuSearch = false) {
  return (dispatch, getState) => {
    dispatch({
      type: types.CATALOG_LOAD_START,
    });
    dispatch({
      type: types.CATALOG_SEARCH,
      payload:{
        search:payload,
        monuSearch,
        adminConfig:config
      }
    });
  }
}
export function searchAccessories(payload) {
  return (dispatch, getState) => {
    dispatch({type: types.CATALOG_SEARCH_ACCESSORIES,
    payload: {accessories: payload ? payload.accessories: [], search: payload && payload.query ? payload.query: ""}});
  }
}
export function monuSearch (payload) {
  return (dispatch, getState) => {
    dispatch({
      type: types.CATALOG_MONU_SEARCH,
      payload,
    });
  };
};

export function filter(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: types.CATALOG_FILTER,
      payload
    });
  }
}

export function orderSimilar(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: types.CATALOG_ORDER_SIMILAR,
      payload
    });
  }
}

export function filterType(payload) {
  return {
      type: types.CATALOG_FILTER_TYPE,
      payload
  };
}

export function selectMonument(monument) {
  return {
    type: types.CATALOG_SELECT_MONUMENT,
    payload: {
      monument,
    }
  };
}

export function closeMonument() {
  return {
    type: types.CATALOG_UNSELECT_MONUMENT
  };
}


export function changeOrder(reference, order) {
  return (dispatch, getState) => {
    return api.post("/api/admin/monuments/order", {reference: reference, order: order})
      .then(() => {
        return dispatch({
          type: types.CATALOG_CHANGE_ORDER_MONUMENT,
          payload: {
            order,
            reference
          }
        })
      })
      .catch(error => console.log(error));
  }
}

export function resetByAlphabeticalOrder() {
  return (dispatch, getState) => {
    let m = getState().catalog.visibleMonuments.map(obj => obj.reference)
    return api.post("/api/admin/monuments/order/reset", {monuments: m})
      .then(() => {
        return dispatch({ type: types.CATALOG_RESET_ALPHABETICAL_ORDER })
      })
      .catch(error => console.log(error));
  }
}