import React from 'react';
import loader from '../images/loader@4x.gif';
import loaderMin from '../images/loader.gif';
import newLoader from '../images/loader-config-500-new.gif';

export default function ({ big = false, test=true, designType, centerText = false, wrapStyle = {} }) {

  const loadingGif = designType === "B" ? newLoader : big ? loader : loaderMin;

  return (
    <div  className="LoadingIcon" style={wrapStyle}>
      <img src={loadingGif} alt="Icône d'attente"/>
      {test && <div className="LoadingMonument" style={centerText ? {textAlign: 'center', margin: 'auto'} : {textAlign: 'unset'}}>Chargement de votre monument en cours</div>}
    </div>
  );
};
