import React, { Component } from "react";
import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as familyspaceActions from '../../../actions/familySpaceActions';
import moment from 'moment';
class ScommentsBlockComponent extends Component {
state={}
  commentDate = (data)=>{
        require("moment/locale/fr");
        let date = new Date(data.tstamp*1000)
        const offset = new Date().getTimezoneOffset();
        date.setMinutes(date.getMinutes()+offset)
        return ` • ${moment(date).fromNow()}`;
       }
  
  render() {
    const {colorConfig} = this.props
    let {comments} = this.props;
    comments = comments.reverse()
    comments = comments.sort(function(x, y){
      return y.tstamp - x.tstamp;
    })
    return(
        <div className="CommentsBlock" style={{ "--f-main-color": colorConfig.mainColor, "--f-font-color": colorConfig.fontColor }}>
                {comments.map((data,index)=><div className="CommentBlock" key ={index}><div className="CommentHead"><span>{data.name}</span>{this.commentDate(data)}</div><div className="CommentText">{data.comment.text}</div></div>)}
        </div>
      )
  }
}

export const ScommentsBlock = connect(
  state => ({familyService:state.familyService}),
  dispatch =>({familyspaceActions: bindActionCreators(familyspaceActions, dispatch)})
)(ScommentsBlockComponent);

