import React from "react";
import {OvalAction} from "../../components/OvalAction";
const defaultPrevious ="Étape précédente";
const defaultNext ="Étape suivante";


export const Ffooter=({previous,next,ptext= defaultPrevious ,ntext= defaultNext,addClass="",mid,mtext,similar })=>{
  const footClass = `Ffooter ${addClass}`;
    return (
        <div className={footClass}>
          {previous &&  <OvalAction action={previous} text={ptext} arrow="left" addClass="Left" />}
          {similar &&  <OvalAction action={mid} text={mtext}  addClass="Middle" />}
          {next &&  <OvalAction action={next} text={ntext} arrow="right" addClass="Right" color="Blue"/>}
        </div>
      )
  }

