import {getFs,getFsGcsUrl} from '../../../services/api.familyspace.service';
import {getStore} from '../../../store';
import * as api from '../../../services/api.service';

export const getOwnerEmail = () => {
  const familyServiceInfo = getStore().getState().familyService;
  if (familyServiceInfo && familyServiceInfo.user && familyServiceInfo.user.email) {
    return familyServiceInfo.user.email;
  }
  return null;
}

export const  getFamilyInfo = async ()  => {
  const email = getOwnerEmail();
  if (email) {
    const familyInfo = await getFs(`/api/lastFamilyInfos/${email}`);
    if (familyInfo && familyInfo.newfamilyInfos) {
      return familyInfo.newfamilyInfos;
    }
  }
  return false;
}

export const isOwner = familyService => familyService.user && familyService.user.id && 
                                          familyService.familySpace && familyService.familySpace.member_id &&
                                          familyService.familySpace.member_id === familyService.user.id;


export const informFamily = async (clientId) => {
  const emailOwner = getOwnerEmail();
  if (emailOwner) {
    // Send an info to the space users that a project was added
    await api.post(`/api/familyspace/inform`,{infoType:"family_space_new_family_project_manual",
              sender:emailOwner,ref:"",email:emailOwner,clientId});
  }
}


export const saveFiles = async (files,author,familySpaceAction,context,idProject=null) =>  {

  const filesData = await Promise.all([].map.call(files, function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onloadend = function () {
            resolve({ data: reader.result, infos: {context, originalName: file.name, size: file.size, mimeType: file.type} });
        };
        reader.readAsDataURL(file);
    });
  }));
  for (let i=0; i<filesData.length;i++){
     await familySpaceAction.setFile({data: filesData[i].data, infos:filesData[i].infos, author, project_id: idProject});
  }
  return filesData;
}

export const checkMaxSize = (size) => ((size/1024)/1024).toFixed(4) > 2

export const isFamilyConfigProject = (project) => project && project.config_id && project.config_id.startsWith('4')
export const isClientConfigProject = (project) => project && project.config_id && project.config_id.startsWith('2')
export const isClientQuote = (project) => project && project.quote_id
export const isManual = (project) => project && (!project.quote_id && !project.config_id)

export const getQuoteDoc = (project,docs, storage)=>{
  if (isClientConfigProject(project) || isClientQuote(project)) {
    if(project.show_quote){
      if (docs && docs.selling){return ({iframe:true,url:docs.selling})}
    }else {
      const result = getFile(storage.files, project,'client_manual_quote');
      if (result) {return result}
    }
  }
  else if (isManual(project)){
    const result = getFile(storage.files, project,'project_quote_manual');
    if (result) {return result}
  }
  return false
}
const getFile =  (files,project, context) =>{
  if (files && files.length){
    const found = files.find(file=> file.project_id && file.project_id === project.id && file.context === context);
    if (found){ 
      const url =  getFsGcsUrl(found.space_id,`${found.uuid}.${found.ext}`);
      if (found.mime_type=== "application/pdf" || found.mime_type.split('/')[0] === "image"){
        return ({iframe:true,url})}
      else {return ({iframe:false,url})}
      }
  }
  return false
}

export const getPriceInfos = (project,  familyService)=>{
  const found = familyService.orgsInfos.find(item=>item.clientId === project.client);
  if (found){
    // "identity.organization.packageText" identity.organization.displayPackagePrice
    return ({identity:{organization:{packageText:found.packageText,displayPackagePrice:found.displayPackagePrice}}})
  }
  return false
}
