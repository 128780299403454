import React from 'react';
import cn from 'classnames';
import * as uiActions from '../actions/uiActions';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

/**
 * Affiche les messages gérés par le store
 *
 *  Cliquer sur un message le masque.
 *
 *  Un message masqué est encore dans le dom mais avec la class isMasked,
 *  pour permettre au css de faire une transition.
 *
 *  L'ensemble des messages est effacé sans transition quand on change
 *  de page.
 *
 * @param messages Array de messages du store
 * @param uiActions Permet de masquer les messages
 * @returns {XML}  Elément à inclure dans l'appli pour afficher
 * tous les messages.
 */
function MessagesComponent({messages, uiActions}) {
  const icons = {
    'info': (<i className="icon material-icons">&#xE866;</i>),
    'success': (<i className="icon material-icons">&#xE86C;</i>),
    'warning': (<i className="icon material-icons">&#xE002;</i>),
    'error': (<i className="icon material-icons">&#xE000;</i>),
  }
  return (
    <div id="MessagesContainer">
      {messages.map(({className, id, innerHTML, isMasked}, i)=>(
        <div
          key={id}
          onClick={()=>uiActions.maskMessage(id)}
          className={cn('Message', className, {isMasked})}>
            <div className="Text" dangerouslySetInnerHTML={{__html: innerHTML}}/>
            <span className="Type">
              <i className="icon material-icons">&#xE14C;</i>
              {icons[className] || (<i className="icon material-icons">&#xE88E;</i>)}
            </span>
          </div>
      ))}
    </div>)
}

export const Messages = connect((state) => ({
    messages: state.ui.messages
  }),
  dispatch=>({
    uiActions: bindActionCreators(uiActions, dispatch),
  })
)(MessagesComponent);

