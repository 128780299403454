import React, { Component } from "react";
import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import like from '../../../images/space/like.svg';
import like_inactive from '../../../images/space/like_inactive.svg';
import dislike from '../../../images/space/dislike.svg';
import dislike_inactive from '../../../images/space/dislike_inactive.svg';
import * as familyspaceActions from '../../../actions/familySpaceActions';
import { ThumbUp } from '@material-ui/icons';
import { ThumbDown } from '@material-ui/icons';
import { gtmFamily } from "../../../services/gtmFamily";

const icons = {"likes":{active:like,inactive:like_inactive,title:`J'aime`},
               "dislikes":{active:dislike,inactive:dislike_inactive, title:"Je n'aime pas"}};

 class SlikeDislikeComponent extends Component {
  state = {
    hover: false,
  };

  searchMyInfo = (tab)=>{
    if (!tab || !Array.isArray(tab)) {return false};
    const {user} =  this.props.familyService;
    return tab.find(item=> item.id === user.id);
  }

  gtmProductObj = ()=> {
    if (!this.props.project.config) {
      return {}
    }

    const { monument } = this.props.project.config.configuration;
    const { granites } = this.props.project.config.options;
    const graniteMain = granites.find((granit => granit.reference == monument.graniteMain ))
    const graniteSecondary = granites.find((granit => granit.reference == monument.graniteSecondary ))
    
    return {
      id: monument.productId,
      name: "", // empty
      reference: monument.reference,
      price_from: 0, // empty
      granits: [
        graniteMain ? graniteMain.name : monument.graniteMain, 
        graniteSecondary ? graniteSecondary.name : monument.graniteSecondary
      ],
      styles: [], // second filter <--
      couleurs: [], // empty
      religions: [], // empty
      types: [], // main filter <--
      granit_main: graniteMain ? graniteMain.name : monument.graniteMain,
      flower_button: "", // empty
      v360_button: true //empty
    }
  }

  gtmEvent = () => {
    // GTM
    const { social, project, full } = this.props;
    const product = this.gtmProductObj();
    if (social == "likes") {
      gtmFamily().ctaOfConfiguration({
        name: "j'aime",
        category: "espace famille",
        subcategory: "like",
        type: "picto",
        from: "vignette projet"
      }, 
      { 
        template: "espace famille", 
        subtemplate: full ? "projet" : "liste projets" 
      },
      {
        product: product
      })
    }
    else if (social == "dislikes") {
      gtmFamily().ctaOfConfiguration({
        name: "je n'aime pas",
        category: "espace famille",
        subcategory: "dislike",
        type: "picto",
        from: "vignette projet"
      }, 
      { 
        template: "espace famille", 
        subtemplate: full ? "projet" : "liste projets" 
      },
      {
        product: product
      })
    }
    // end GTM
  }

  addSocial = (answered)=>{
    this.gtmEvent();
    const {social} = this.props;
    this.delAllLikesDislikes(social);
    if (!answered){this.addLikeDislike(social);}

    /*else{ this.setState({hover:!this.state.hover})}
    */
  }

  addLikeDislike = async social => {  
    const { user } = this.props.familyService;
    let valueToSend = {};
    let myDate = new Date()
    const offset = Math.abs(new Date().getTimezoneOffset());
    myDate.setMinutes(myDate.getMinutes()+offset)
    const newElement = {id: user.id, tstamp: Math.round(myDate/1000), name: user.name, userId: user.id, read_by: [user]};
    const newValue =  this.props.project[social].length ? [...this.props.project[social],newElement]:[newElement];
    valueToSend[social]= newValue;
    this.props.familyspaceActions.updateOneProject(this.props.project.id, valueToSend)
    .then(response => {
      const socialResponse = social === "likes" ? response.likes : response.dislikes;
      const socialItem = socialResponse.find(sr => sr.id === user.id);
      this.props.familyService.socket.emit('chat', {...{type: social, room: `familyspace-${this.props.familyService.familySpace.id}`, user},
      ...{id: user.id, project_id: response.id, read_by: socialItem.read_by || [], project: response}});
    });
  
  }

  delAllLikesDislikes = async (social) => {
    const { user } = this.props.familyService;
    let valueToSend = {};
    let bLikeDislikeFound=false;
    
     //Delete any likes or dislikes of the current user found.

    if (this.searchMyInfo(this.props.project.likes)) {
      let LikesList = this.props.project["likes"];
      valueToSend["likes"] = LikesList.filter(Like => Like.id!==user.id);
      bLikeDislikeFound=true;
    }
    if (this.searchMyInfo(this.props.project.dislikes)) {
      let DislikesList = this.props.project["dislikes"];
      valueToSend["dislikes"] = DislikesList.filter(Dislike => Dislike.id!==user.id);
      bLikeDislikeFound=true;
    }
    if(bLikeDislikeFound){
      this.props.familyspaceActions.updateOneProject(this.props.project.id, valueToSend).then(response => {
        const socialResponse = social === "likes" ? response.likes : response.dislikes;
        this.props.familyService.socket.emit('chat', {...{type: social, room: `familyspace-${this.props.familyService.familySpace.id}`, user},
          ...{id: user.id, project_id: response.id, read_by: /*socialItem.read_by || */[], project: response}});
      });
    }
  }

  render() {
    const {social,project,mainColor} = this.props;
    const {likes,dislikes} = this.props.project;
    const answered = social === "likes" ? this.searchMyInfo(likes) : this.searchMyInfo(dislikes);
    const addClass = answered && social !== "comments" ? "": ""; // answered -> to set black thumbs
    const disabledClass = social === 'likes' ? 'disabledLike' : 'disabledDislike'
    return (
      <div className={`LikeDislikes ${addClass} ${answered ? disabledClass : ''}`} style={{"--f-main-color":mainColor}} onClick={() => this.addSocial(answered)}
           onMouseEnter={() => this.setState({hover:true})} onMouseLeave={() => this.setState({hover:false})} >
           {/*<img src={addClass ? icons[social].inactive: icons[social].active} className={`${answered ? 'disabled thumb' : 'thumb'}`} alt={social}/>*/}
        {social ==='likes' ? <ThumbUp/> : <ThumbDown/>}
        <span>{project[social] ? project[social].length: 0}</span>
        {/*{hover && project[social] && project[social].length>0 &&
        <div className="SocialBlock">
          <div className="SocialInfo">
            <div className="title">{icons[social].title}</div>
            {project[social].map((person,index)=><div key={index} className="person">{person.name}</div>)}
          </div>
        </div>}*/}
      </div>
    );
  };
};

export const SlikeDislike = connect(
  state => ({
    familyService: state.familyService
  }),
  dispatch =>({
    familyspaceActions: bindActionCreators(familyspaceActions, dispatch)
  })
)(SlikeDislikeComponent);

