import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import {Card,CardBody,CardHeader,Button,CardTitle} from "reactstrap";


export class SimpleAdminConfig extends Component {
  state = {
    mainColor:"", // Couleur principale 
    fontColor:"", // Couleur de police
    mounted:false,
  };

  componentDidMount(){
      if (this.props.fullConfig && this.props.fullConfig.config)
      {
          this.setState({mainColor:this.props.fullConfig.config.mainColor || ''});
          this.setState({fontColor:this.props.fullConfig.config.fontColor || ''});
      }
      this.setState({mounted:true});
  }

  updateColor() {
    this.props.updateCfg({mainColor:this.state.mainColor, fontColor:this.state.fontColor});
  }

  render(){
    // const { color } = this.state;
    const { mainColor, fontColor} = this.state;
    return (

      <Card className="AdminConfigurator">
        <CardHeader tag="h3"><i className="icon material-icons">settings</i>{` Paramètres`}</CardHeader>
          <CardBody>
              <CardTitle>Couleur principale</CardTitle>
              <input value={mainColor} style={{ width: '80%', marginLeft: '15px'}} onChange={v => this.setState({mainColor:v.target.value})}/>
              {mainColor && <div style={{ width: 50,height: 50, marginTop: 20,marginBottom: 20,backgroundColor: mainColor}}/>}
              <br />
              <CardTitle>Couleur de police</CardTitle>
              <input value={fontColor} style={{ width: '80%', marginLeft: '15px'}} onChange={v => this.setState({fontColor:v.target.value})}/>
              {fontColor && <div style={{ width: 50,height: 50, marginTop: 20,marginBottom: 20,backgroundColor: fontColor}}/>}

              {this.state.mounted && <Button  className="AdminFamilyButton" onClick={() => this.updateColor()}>Mettre à jour</Button>}
        </CardBody>
    </Card>
    )
  }
}

