import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import cn from 'classnames';
import {CreateVariantButton} from '../../../components/CreateVariantButton';
import {LazyImage} from '../../../components/LazyImage';
import {scrollPageTo} from '../../../services/domFunctions';

const moment = require('moment');

class CatalogFooterComponent extends Component {

  state = {

    loaded: false,
    entering: false,
  };

  $preview = null; // Composant HTML Preview

  scrollToTop = () => {
    scrollPageTo(0);
  }

  imageLoaded = () => {
    this.setState({
      loaded: true,
      entering: true,
    });

/*     setTimeout(() => {
      this.setState({
        entering: false,
      });
    }, (this.$preview && 5000) || 0); // 5s d'animation */ 
  }

  render() {
    const {previousConfiguration, isoView, displayScrollToTopButton} = this.props;
    if (!previousConfiguration) return null;
    let {reference, thumbnail, createdAt, customer} = previousConfiguration;

    // Si on est en train de modifier l'image ou c'est un autre monument on n'affiche pas le footer tant que la 
    // nouvelle image n'est pas prête
    if (isoView.loading || (isoView.configuration && (isoView.configuration !== reference))) return null;

    // Première configuration de l'utilisateur -> peut servir à savoir si c'est sa première con²ion !
    if (!previousConfiguration || !reference) return null;

    let loading = false;
    if (isoView.configuration === reference) {
      // On ne peut à priori pas être dans un autre mode mais on teste quand même au cas où
      loading = isoView.loading;
      thumbnail = loading ? undefined : isoView.current;
    }

    return (
      <div className="CatalogFooter">
        <div className="CatalogFooter-ScrollToTop">
          {displayScrollToTopButton && (
            <button className="CatalogFooter-ScrollToTop-Button" onClick={this.scrollToTop}>
              <i className="icon material-icons">&#xE316;</i>
              <span>Revenir</span>
              <small>aux collections</small>
            </button>
          )}
        </div>
        <div className="Actions">
          <Link className="ResumeConfiguration"
                to={`/configuration/${reference}/monument`}>
            <i className="icon material-icons">&#xE409;</i>
            <span>Reprendre
              <small>la configuration</small>
            </span>
          </Link>
          <div ref={(c) => this.$preview = c} className={cn('ConfigurationPreview', {'enter': this.state.loaded, 'entering': this.state.entering})}>
            <LazyImage className="Monument" src={thumbnail} lazy={false} onLoad={this.imageLoaded} frame={true}/>
            <div className="Footer">
              <h1 className="FamilyName">{customer || '(aucune référence)'}</h1>
              <p className="Details">
                <span className="creationInfo">Créée le {moment(createdAt).format('DD/MM/YYYY')} </span>
                <span className="referenceInfo"> Réf. : CA{reference} </span>
              </p>
            </div>
          </div>
          <hr className="Separator"/>
          <CreateVariantButton className="CreateVariant" configuration={{reference}} path="finalisation"/>

        </div>
        <p>Sélectionnez le type de monument pour démarrer votre configuration ou reprenez la
          configuration en cours.</p>
      </div>
    );
  }
}

CatalogFooterComponent.propTypes = {
  previousConfiguration: PropTypes.shape({
    reference: PropTypes.string,
    updatedAt: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
};

export const CatalogFooter = connect(
  (state) => ({
    previousConfiguration: state.user.latestConfiguration,
    isoView: state.isoView,
  })
)(CatalogFooterComponent);