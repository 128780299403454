import React, { Component } from "react";
// import * as _ from 'lodash';

// import { Modal } from "../../components/Modal";
import { Modal } from "../../components/Modal";
import { MonumentList } from "../catalog/components/MonumentList";
import { MonumentCarousel } from "../catalog/components/MonumentCarousel";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as catalogActions from "../../actions/catalogActions";
import * as configurationActions from "../../actions/configurationActions";
import * as adminConfigActions from "../../actions/adminConfigActions";
import * as uiActions from "../../actions/uiActions";
import { getDesignTypeConfiguration } from '../../services/utils.service';
import { FfilterCatalog } from './FfilterCatalog';
import { FiltersBurial } from '../../constants';
import {Fheader} from './Fheader';
import { FinitialChoice, typeMonuments } from './FinitialChoice';
import * as apiService from "../../services/api.service";
import LoadConfig from '../../components/LoadConfig';
import FLoader from '../../components/FLoader';
import {isSmartphone} from '../../services/domFunctions';
import { gtmFamily } from '../../services/gtmFamily';

class FcatalogComponent extends Component {

  state = { loadingSelectedMonument: false, familyConfigSent: false,
            modeAsked:false,mode:"", disableChooseButton: false,
            showHeader:false,initMonuPop: false, fIndex: 1, exceptions: ['400B', '505A', '506A', '507A', '509A'], loading:false, monumentSelected: []};

  async componentDidMount() {
    // const {family} = this.props.user;
    const { step } = this.props;
    const params = this.props.match.params;

    // const familyConfig = await apiService.get(`/api/familyconfigdef/${family}`);
    const familyConfig = this.props.adminConfig
    
    // initialiser les couleurs par défaut
    if(familyConfig && familyConfig.config) {
      let {mainColor, fontColor} = familyConfig.config
      if (mainColor === "" || !mainColor) { familyConfig.config.mainColor = "#FF9D1E" }
      if (fontColor === "" || !fontColor) { familyConfig.config.fontColor = "#001F2A" }
    }
    
    // Hide loader
    this.finishLoading()
    //document.body.classList.add('body-background');
    this.props.adminConfigActions.initConfig(familyConfig);

    if (familyConfig && familyConfig.config && familyConfig.config.color) {
      document.documentElement.style.setProperty('--fcblue', familyConfig.config.color);
    }
    else if (getDesignTypeConfiguration() === "B") {
      document.documentElement.style.setProperty('--fcblue', "#3568ba");
    }

     switch (step) {
      case 2: // Cas de l'entrée avec un monument on va directement à la page des granits
        const { exceptions } = this.state;
        const isException = exceptions.find(e => params.monumentId.startsWith(e));
        const granit = isException ? params.monumentId.slice(-2) : null;
        const monu = isException !== undefined ? isException + granit : params.monumentId;
        this.goToGranites(monu,true);
        break;
      case 6: // Cas de l'entrée avec un monument pour aller à la dernière étape de la configuration
        if (params.selectionId){
          this.startFromSelection(params.selectionId);
        } else{
          this.setState({loadingSelectedMonument: true});
          this.props.configurationActions.initConfiguration(params.monumentId,{startPage:{value: 'ffinalisation'}},params.clientId,true);
        }
          break;
      default:
        this.props.configurationActions.stopConfiguration(); //nettoyage au cas où il y aurait une vieille config
        this.setState({showHeader:true});

        document.body.style.background ="none";// On n'utilise pas le background du configurateur de base
        // Charge le catalogue de monuments
        if (!this.state.monumentSelected.length && params.monumentId && params.clientId) {
          const ref = params.monumentId.slice(0, params.monumentId.length-2);
          const monumentSelected = await apiService.get(`/api/catalog/monuments?price=1&references=${ref}`)
          this.setState({monumentSelected})
        } else if (!this.props.catalog.monuments || !this.props.catalog.monuments.length) {
          const param = familyConfig && familyConfig.config ? familyConfig.config : null;
          this.props.actions.load(param,false);
        }
        if(this.props.ui.initChoiceMade){
          this.chooseMode(this.props.ui.initChoiceMade);
        }
        
    };
  }
  
  componentWillUpdate(prevProps, prevState) {
    
    // show loader on mode selected
    if(prevState.modeAsked !== this.state.modeAsked && this.state.modeAsked === false) {
      this.setState({ loading: true });
    }
  }

  
  componentDidUpdate(prevProps) {
    if (this.state.loading === true && (this.props && this.props.catalog && this.props.catalog.loading === false)) {
      this.setState({ loading: false });
    }
    // GTM
    if (prevProps.catalog.setPrice === false && this.props.catalog.setPrice === true && this.state.modeAsked) {
      let self = this;
      const { mainFilter, secondFilter } = this.getFilters(this.state.mode, this.state.fIndex)
      gtmFamily().listOfMonuments(
        "product.list_display",
        self.props.catalog.visibleMonuments,
        { 
          mainFilter: mainFilter, 
          secondFilter: secondFilter 
        }
      )
    }
    // end GTM
    if ((prevProps.catalog.monuments.length === 0)  && (this.props.catalog.monuments.length >0)){
      //Lors de l'arrivé du catalogue si le choix a déjà été effectué mettre le bon filtre en place
      if (this.state.modeAsked){
        this.props.actions.filterType(this.state.mode);
      }
      this.props.actions.load(this.props.config,true);
      // GTM
      let self = this;
      gtmFamily().listOfMonuments(
        "product.list_display",
        self.props.catalog.visibleMonuments
      )
      // end GTM
    }
    const { monumentId } = this.props.match.params;
    //Click sur l'icône entreprise on repose la question inhumation/crémation
    if (prevProps.ui.initChoiceMade && !this.props.ui.initChoiceMade){
      this.setState({modeAsked:false})
    }
    if (this.props.step === 1 && this.state.initMonuPop === false) {
      const { exceptions } = this.state;
      const isException = exceptions.find(e => monumentId.startsWith(e));

      const ref = isException !== undefined ? isException : monumentId.slice(0, monumentId.length-2);
      let selectedMonument = null;
      if (this.state.monumentSelected.length) {
        selectedMonument = this.state.monumentSelected[0];
      } else {
        selectedMonument = this.props.catalog.visibleMonuments.find(item => (item.reference === ref || item.reference === monumentId));
      }
      if (selectedMonument) {
        if (getDesignTypeConfiguration() === "B") {
          this.goToCustom(selectedMonument);
        } else {
          this.setState({initMonuPop: selectedMonument, loadingSelectedMonument: false,}, () => this.props.actions.selectMonument(selectedMonument));
        }
      }
    }
  }

  finishLoading = () => {
    // const {loading} = this.state;
    this.setState({loading: false});
  }

  onFilter = (payload) => {
    payload.mode = this.state.mode
    this.props.actions.search(payload);
  };

  onSearch = searchMonumentText => {
    this.props.actions.monuSearch(searchMonumentText);
  };

  onSelectMonument = async (monument) => {
    await this.props.actions.selectMonument(monument);
    // GTM
    const { mainFilter, secondFilter } = this.getFilters(this.state.mode, this.state.fIndex)
    gtmFamily().selectedMonument(
      monument, 
      {
        mainFilter: mainFilter, 
        secondFilter: secondFilter 
      },
      this.props.catalog.visibleMonuments
    )
    // end GTM
    if (this.props.config.catalogBypassCarousel) {
      this.goToGranites(monument);
    } else if (getDesignTypeConfiguration() === "B") {
      this.goToCustom(monument);
    }
  };

  closeModal = () => {
    this.props.actions.closeMonument();
  };

  closeChoice = () =>{
    this.setState({modeAsked:true})
  };

  chooseMode = async (mode) => {
    
    await this.props.actions.filterType(mode);
    this.setState({modeAsked:true,mode:mode})
    await this.props.uiActions.initialChoiceMade(mode);
    
    // GTM
    let self = this;
    const { mainFilter, secondFilter } = this.getFilters(mode, this.state.fIndex)
    gtmFamily().listOfMonuments(
      "product.list_display",
      self.props.catalog.visibleMonuments, 
      { 
        mainFilter: mainFilter, 
        secondFilter: secondFilter 
      },
      self.props.catalog.setPrice
    )
    // end GTM
  };

  getFilters = (mode, secondFilterIndex) => {
    const mainFilter = typeMonuments.find(tm => tm.type == mode).title
    const defaultSecondFilter = mode === "interment" ? FiltersBurial[secondFilterIndex].label : ""
    return { 
      mainFilter : mainFilter,
      secondFilter: defaultSecondFilter
    }
  }

  async goToCustom (monument, ref= false) {
    if (!this.state.disableChooseButton) { // On n'autorise qu'un seul appui sur Choix
      const { clientId } = this.props.match.params;
      this.setState({disableChooseButton:true,loadingSelectedMonument:true});
      if(this.props.match.params.monumentId){
        monument.graniteMain = this.props.match.params.monumentId.slice(-2);
      }
      this.props.configurationActions.initConfiguration(monument,{startPage:{value: 'customize'}},clientId,ref);
    }
  }

  goToGranites(monument,ref=false){
    if (!this.state.disableChooseButton){ // On n'autorise qu'un seul appui sur Choix
      const { clientId } = this.props.match.params;
      this.setState({disableChooseButton:true,loadingSelectedMonument:true});
      this.props.configurationActions.initConfiguration(monument,{startPage:{value: 'fgranit'}},clientId,ref);
    }
  }
  startFromSelection(selection){
    const { clientId } = this.props.match.params;
    if (!this.state.disableChooseButton){ // On n'autorise qu'un seul appui sur Choix
    this.setState({disableChooseButton:true,loadingSelectedMonument:true});
    this.props.configurationActions.initConfigurationFromSelectionRef(selection,clientId);
  }
  }
  similar(monument){
    if (monument && monument.similar){
      this.props.actions.closeMonument();
      this.onFilter (false);
      // this.setState({fIndex:1}); //On passe le filtre à tous les monument
      this.props.actions.orderSimilar(monument);
    }
  };
  showInitChoiceModal(){
    this.props.uiActions.initialChoiceMade(false);
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "annuler",
      category: "configurateur",
      subcategory: "",
      type: "picto",
      from: "filtres"
    }, { template: "catalogue", subtemplate: "liste produits" })
    // end GTM
  }

  render() {
    const {catalog,user} = this.props;
    const params = this.props.match.params;
    const {modeAsked,mode,showHeader,initMonuPop,fIndex} = this.state;
    const designType = getDesignTypeConfiguration();
    const loading = this.state && this.state.loading;
    document.title = "Catalogue de l'Espace Famille";
    const hideCatalog = isSmartphone() && !modeAsked; 
    const {mainColor} = this.props.adminConfig.config
    const showFilter = ["cremation","interment"].includes(mode)
    return (
      <div>
        {loading && <FLoader />}
        {!this.props.ui.initChoiceMade && initMonuPop === false && !this.props.step  && showHeader &&
          <FinitialChoice clientId={params.clientId} isOpen={!modeAsked} choose={this.chooseMode}
                          logo={user.identity.organization.logo} designType={designType} connect={this.props.location.search === "?connect"}/>}
          <div className="Fcatalog">
          {this.state.loadingSelectedMonument && <LoadConfig big loading={this.state.loadingSelectedMonument} />}

          {showHeader && designType==="A" && <Fheader match={this.props.match} /> }
          <div className={`fCatalogTopBar ${modeAsked && 'heightActive'}`}>
            {this.props.ui.initChoiceMade && !this.state.loadingSelectedMonument && designType==="B" && <div className="goToInitChoiceBtn" style={{"--f-main-color": mainColor}} onClick={()=> this.showInitChoiceModal()}><span>Annuler</span><i className="icon material-icons">close</i></div>}
            {modeAsked && !this.state.loadingSelectedMonument && catalog.monuments && catalog.monuments.length>0  && showFilter &&
              <FfilterCatalog
                config={this.props.config}
                mode={mode}
                onFilter={payload => this.onFilter(payload)}
                search={searchText => this.onSearch(searchText)}
                handleFIndex = {newIndex => this.setState({fIndex: newIndex})}
                fIndex={fIndex}
              />
            }
          </div>
          {(this.props.catalog.selectedIndex === null) && !hideCatalog &&
            <div id="CatalogList"className={`catalog${designType}`}>
              <MonumentList
                monuments={this.props.catalog.visibleMonuments}
                onSelect={this.onSelectMonument.bind(this)}
                loading={this.state.loading}
                family = {true}
              />
            </div>}
            
            
          <Modal
            isOpen={this.props.catalog.selectedIndex !== null && !this.props.config.catalogBypassCarousel && getDesignTypeConfiguration() !== "B"}
            onRequestClose={this.closeModal}
            overlayClassName="CatalogModalOverlay Family"
            className="CatalogModal">
            <MonumentCarousel slideToShow={this.props.catalog.selectedIndex} ffooter={true} ptext="Retour au catalogue"
            previous={this.closeModal} next={elt=> {
              if (getDesignTypeConfiguration() === "B") {
                this.goToCustom(elt)
              } else {
                this.goToGranites(elt)
              }
            }} ntext="Choisir ce modèle"
            mid={elt=>this.similar(elt)} mtext="Voir les monuments similaires"
            textRight="Modèle suivant" textLeft="Modèle précédent"/>
          </Modal>
        </div>
        </div>
    )}
}

export const Fcatalog = connect(
  state => ({
    catalog: state.catalog,
    ui:state.ui,
    config: state.adminConfig.config,
    user:state.user,
    adminConfig: state.adminConfig
  }),
  dispatch => ({
    actions: bindActionCreators(catalogActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
    configurationActions: bindActionCreators(configurationActions, dispatch),
    adminConfigActions: bindActionCreators(adminConfigActions, dispatch),
  })
)(FcatalogComponent);
