import React from 'react'
import {imgs} from '../../../services/images.service';
import {LazyImage} from '../../../components/LazyImage';
import {getPatternSizeMax} from './../../../services/patterns.service';

export function patternCarouselSlides({patterns, loadMore, seeSimilarPatterns, onPickPattern, isUpdate, imprint = false, t = null}) {
  const renderPatternSlide = (selectedPattern, objectIndex) => {
    if (objectIndex > patterns.length - 10) {
      loadMore()
    }
    const size = getPatternSizeMax(selectedPattern);
    const minMax = `Dimension minimum : ${size.min.height} x ${size.min.width} cm | Dimension maximum : ${size.max.height} x ${size.max.width} cm`;
    return (
      <div className="Pattern">
        <LazyImage className="FullSize"
                   lowResImage={selectedPattern.images.render}
                   src={selectedPattern.images.render} lazy={false} noCdn={true}/>

        {/* Footer */}
        <div className="Footer">
          <div className="Infos">
            <h1>
              <span className="Name">{selectedPattern.reference}</span>
              <small className="Kind">{selectedPattern.kind}</small>
              <span className="PatternSize">{minMax}</span>
            </h1>
            <p className="Description">{selectedPattern.description}</p>

            {/* Préchargement des thumbnails pour une insertion immédiate dans le canvas */}
            <img style={{height: 0, width: 0, opacity: 0}} alt="Dessin"
                 src={selectedPattern.images.drawing}/>
            <img style={{height: 0, width: 0, opacity: 0}} alt="Granit"
                 src={imgs.pattern(selectedPattern.reference, 'granite', 'thumbnail')}/>

          </div>
          <div className="Actions d-flex">
            {!imprint && (
              <button class="button-secondary button-small text-dark" onClick={() => seeSimilarPatterns(selectedPattern)}>
                <span>{ t('config3d_patternandengravings_button_pattern_similar') }</span>
                <span class="search-icon icon-medium text-dark-icon"></span>
              </button>
            )}
            <button class="button-primary button-small text-cta" onClick={() => onPickPattern(selectedPattern)}>
              <span>{ isUpdate ? t('config3d_patternandengravings_button_pattern_choose') : t('config3d_patternandengravings_button_pattern_add')} {imprint ? t('config3d_patternandengravings_button_pattern_add_locket') : t('config3d_patternandengravings_button_pattern_add_pattern')}</span>
              <span class="plus-icon icon-medium text-dark-icon"></span>
            </button>
          </div>
        </div>
      </div>
    )
  }
  return renderPatternSlide;
}
