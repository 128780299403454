// import {ConfiguratorCaton} from "./pages/configurator/ConfiguratorCaton";
export const config = {
  // ------------------------- CATALOGUE ----------------------------
  //  catalogHideSearchBox:true,   // Masquer la  recherche
  //  catalogHideFilterLayout:true, //  Masquer le filtrage de recette
  //  catalogHideAllIcons:true,    //Masquer les icônes de catégorie
  //  catalogPreFilter:"800-899", //pour n'afficher qu' une seule catégorie filtré dès le départ
  //  catalogHideIcons:{'Category-Exclusive':true,'Category-400-499':true}, // Pour masquer certains icônes de catégorie
  //  catalogListMonuments:["001BF04","002AH05","006AG06","015BF15","016AF06","016AG37","016AH37"],
  //  catalogHideFooter:true, //Masquer le bas de page
  //  catalogBypassCarousel:true, // A la suite d'une séléecion de monument dans le catalogue ne pas afficher le carousel et entrer directement

   // ------------------------- CONFIGURATEUR ----------------------------
    // specificComponent:{ component:ConfiguratorCaton,endpath:'caton'}, // Pages spécifique à développer
  //  startPage: 'caton',  // page de démarrage par défaut monument, les autres sont granit,motifs-gravures,accessoires,finalisation ou spécifique
  //  hideTopBar:true, //masquer la barre noire de navigation
  // ----Configuration des navigation et des icônes à afficher
  //  tabAndButtons: {catalog:{},
  //                  monument:{headstone:true,layout:true,base:true,frame:true,veneer:true},
  //                  granite:{monument:true,frame:true,veneer:true,accessories:true},
  //                  patterns:{patterns:false,engreaving:true,reserve:true,otherRoom:false,granite:false},
  //                  accessories:{vase:true,planters:false,others:true,granite:true},
  //                 finalization:{}
  //               },
  // hideMonumentTutorial:true, // Masquer le bouton tutoriel dans la page monument
  // buttonsDisabled:{_3D:false,document:true,details:false}, // Masquage des boutons "vue 3D", "Espace Doculments" et "Détails"
  // lowResolution3D:true,  // pour la vue 3D n'utiliser que les images de basse résolution pour accélerer l'affichage
  // hideNextButtons:true, // Masquer les boutons de changement d'étape en bas des pages 
  pledg: {
    dashboard_url: process.env.REACT_APP_PLEDG_BASE_URL || "https://dashboard.ecard.pledg.co/",
  },
};