import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as _ from 'lodash';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions' ;
import MediaQuery from "react-responsive/src";
import { TranslationContext } from "../../../context/TranslationContext";

const path = 'family.payment.advance';

class ProjectPaymentSettingsComponent extends Component {
  static contextType = TranslationContext;
  state = {isEditing: false}

  startEditing = ()=>this.setState({isEditing: true, tempValue: (_.get(this.props.configuration, path) * 100) + ''})
  endEditing = (value)=> {
    this.setState({tempValue: value});
    this.props.actions.updatePath(path, parseInt(value || 0) / 100)
    // this.setState({isEditing: false})
  }

  render() {
    const t = this.context;
    const {configuration} = this.props;
    const advance = _.get(configuration, path)
    return (
      <div className="ProjectPaymentSettings">
        <div className='d-flex align-items-center'>
          <span className='label'>
            { t('config3d_finalize_footer_family-payment_advance') || "Acompte de" }
          </span>
          <div className="input-wrapper py-1">
            <input 
              type="number"
              step="5" 
              className="custom-input-field" 
              min="0" max="100" 
              defaultValue={Math.round(advance * 100)} 
              onChange={(ev)=> {
                this.endEditing(ev.target.value);
              }}
              />
            <span className="custom-input-addon">%</span>
          </div>
        </div>
      </div>
    )

  }
}

/**
 * Composant permettant de modifier la valeur de l'acompte de la configuration en cours
 */
export const ProjectPaymentSettings = connect(
  (state) => ({
    configuration: state.configurator.current.configuration
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(ProjectPaymentSettingsComponent);



