import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyspaceActions from '../../actions/familySpaceActions';
import {getFsGcsUrl} from '../../services/api.familyspace.service';
import { Modal } from '../../components/Modal';
import { Button} from 'reactstrap';
import { Person, PersonAdd, Delete, Edit, Close } from '@material-ui/icons';
import {CircularProgress}  from '@material-ui/core';
import {isSmartphone} from "../../services/domFunctions";
import {Sinvite} from "./Sinvite";
import { AccountCircle } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import { emailRex } from '../../services/utils.service';
import { CheckCircleSharp } from '@material-ui/icons';
import {saveFiles, checkMaxSize} from './utils/utils';
import MediaQuery from "react-responsive/src";
import { gtmFamily } from "../../services/gtmFamily";

class SmembersComponent extends React.Component {
  state = {
    nameModalVisible: false,
    inviteModalVisible: false,
    newName: '',
    inviteMail: [''],
    disabledRow: [],
    image: null,
    imagePreview: null,
    error: false,
    errorMessage: "",
    personalInformationsModal: false,
    name: '',
    email: '',
    emailConfirm: '',
    waitImageLoad:false,
  };

  componentDidMount () {
    const { familySpace, user } = this.props.familyspace;
    this.setState({newName: familySpace.name, name: user.name, email: user.email, emailConfirm: user.email});
    this.props.familySpaceAction.getMembers();
    // GTM
    gtmFamily().familySpaceMyFamily();
    // end GTM
  }

  /**
   *
   */
  changeName = async () => {
    const { image, newName } = this.state;
    const fsAction = this.props.familySpaceAction;
    const fsService = this.props.familyspace;
    const author = this.props.familyspace.user;
    let familySpace = fsService.familySpace
    if (newName && familySpace.name !== newName) {
      fsAction.updateFamilyspace({name:newName})
    }
    if (image) {
      this.setState({waitImageLoad:true});
      const res = await saveFiles([image],author,fsAction,'avatar');
      this.setState({newName: '', nameModalVisible: false, image: null, error: false, errorMessage: ''});
      fsService.socket.emit('chat', {...{type: 'files', room: `familyspace-${fsService.familySpace.id}`, user: author},...{files: res}});
      this.setState({waitImageLoad:false});
    }
    this.setState({nameModalVisible: false, image: null, imagePreview: null, error: false, errorMessage: ""})
  };

  /**
   *
   * @param {*} e
   */
  handleImage = async e => {
    const file = e.target.files[0];
    if (checkMaxSize(file.size)) {this.setState({image: null,error: true,errorMessage: "Le fichier séléctionné est trop volumineux",});}
     else {this.setState({image: file,error:false,imagePreview: URL.createObjectURL(file)});}
  };

  /**
   *
   * @param {string} email
   */
  validateEmail = email => {
    if (email) {
      return  emailRex.test(email) ? true : false;
    }

    return false;
  };

  /**
   *
   * @param {object} e
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({[name]: value});
  };

  /**
   *
   * @param {*} m
   * @param {*} i
   */
  resendInvitation = (m, i) => {
    const newRow = this.state.disabledRow;
    newRow.push(i);
    this.setState({disabledRow: newRow});
    this.props.familySpaceAction.sendInvitations([m.email], true)
    .then(response => {
      this.props.familySpaceAction.getMembers();
    });
  };

  changeAction = () => isSmartphone() ? <Edit className="Edit"/> : "Modifier mes infos";
  deleteAction = () => isSmartphone() ? <Delete className="Delete"/> : "Supprimer";

  getAvatar = () => {
    const { files } = this.props.familyspace;
    const avatar = files.find(p => p.context === "avatar");

    if (!!avatar) {
      return <img src={getFsGcsUrl(avatar.space_id,`${avatar.uuid}.${avatar.ext}`)} alt="avatar"/>
    }

    return <AccountCircle fontSize='large' style={{fontSize: 34, marginLeft: 10}} />;
  }

  removeFamilyspace = (member) => {
    if (!member) {
      // GTM
      gtmFamily().ctaOfConfiguration({
        name: "supprimer l'espace famille",
        category: "espace famille",
        subcategory: "popup supprimer espace famille",
        type: "cta",
        from: "bottom menu"
      }, { template: "espace famille", subtemplate: "ma famille" })
      // end GTM
    }

    const { mainColor, fontColor } = this.props.adminConfig.config
    confirmAlert({
      customUI: ({onClose}) =>
      <div className="confirm-alert-arhive-modal">
        <div className="close-action">
          <Close onClick={() => onClose()} />
        </div>
        <div className="confirm-alert-block">
          <h2>{member ? "Confirmer pour supprimer" : "Supprimer l'espace famille"}</h2>
          <div className="message">{member ? "Êtes-vous sûr de vouloir supprimer le membre ?" : "L'espace famille sera définitivement supprimé et toutes les données associées seront supprimées. Confirmez-vous ?"}</div>
          <div className='actions'>
            <button className="validate fsp-button"
            style={{"--f-main-color": mainColor, "--f-font-color": fontColor}}
            onClick={() => {
              member ? this.props.familySpaceAction.removeMember(member) : this.removeFamilySpaceAction();
              onClose();}}
            >
            Confirmer la suppression
            </button>
            <Button className="fsp-button cancel" onClick={() => { this.closePopupDeleteAction(member); onClose(); }}>Annuler</Button>
          </div>
        </div>
      </div>
    });
  };   
  
  closePopupDeleteAction = (member) => {
    if (!member) {
      // GTM
      gtmFamily().ctaOfConfiguration({
        name: "annuler",
        category: "espace famille",
        subcategory: "ma famille",
        type: "cta",
        from: "popup supprimer espace famille"
      }, { template: "espace famille", subtemplate: "ma famille" })
      // end GTM
    }
  }

  removeFamilySpaceAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "supprimer",
      category: "espace famille",
      subcategory: "supprimer espace famille",
      type: "cta",
      from: "popup supprimer espace famille"
    }, { template: "espace famille", subtemplate: "ma famille" })
    // end GTM
    this.props.familySpaceAction.removeFamilySpace()
  }


  updatePersonalInformations = async (name, email) => {
    await this.props.familySpaceAction.updateMember({name, email});
    this.setState({personalInformationsModal: false});
  }

  editNameAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "modifier le nom",
      category: "espace famille",
      subcategory: "popup modifier le nom",
      type: "cta",
      from: "top menu"
    }, { template: "espace famille", subtemplate: "ma famille" })
    // end GTM
    this.setState({nameModalVisible: true});
  }

  editPersonalInfos = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "modifier mes infos",
      category: "espace famille",
      subcategory: "popup modifier infos personnelles",
      type: "cta",
      from: "ligne"
    }, { template: "espace famille", subtemplate: "ma famille" })
    // end GTM
    this.setState({personalInformationsModal: true})
  }

  inviteMemberAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "inviter un proche",
      category: "espace famille",
      subcategory: "popup inviter un proche",
      type: "cta",
      from: "bottom menu"
    }, { template: "espace famille", subtemplate: "ma famille" })
    // end GTM
    this.setState({inviteModalVisible: true});
  }

  render () {
    const { familySpace, user, members } = this.props.familyspace;
    const { inviteModalVisible, imagePreview, error, errorMessage, email, name, emailConfirm , waitImageLoad} = this.state;
    const { mainColor, fontColor } = this.props.adminConfig.config

    return (
      <div className="familyspace-members">
            <MediaQuery minWidth={576}>
              <div>Gérer mon espace famille</div>
            </MediaQuery>
            <div className="members-header">
              <h3>{familySpace.name}</h3>
              {/* {this.getAvatar()} */}
              <button className="LeftOvalAction secondaryBtn outlinedBtn fsp-button outline membersButton" onClick={() => this.editNameAction()}>Modifier le nom</button>
              {familySpace.member_id === user.id && <button
                  className="LeftOvalAction fsp-button remove"
                  style={{"--f-main-color":"red", "--f-font-color":"#fff"}}
                  onClick={() => this.removeFamilyspace()}
                  disabled={familySpace.member_id !== user.id || !user.activated}
                >
                  <Delete fontSize="" style={{"--f-main-color":"#fff"}} />
                  Supprimer l'espace famille
              </button>}
            </div>
            
            <MediaQuery minWidth={576}>
              <p>Gérer les membres de mon espace famille</p>
            </MediaQuery>
            
            <MediaQuery maxWidth={576}>
              <div className="members-actions">
                <Button
                  className="LeftOvalAction fsp-button membersButton invite"
                  onClick={() => this.inviteMemberAction()}
                  disabled={user && !user.activated}
                  style={{"--f-main-color":mainColor, "--f-font-color":fontColor}}
                >
                  <PersonAdd fontSize="" style={{color:fontColor}}/>
                  Inviter un proche
                </Button>
                {/* {familySpace.member_id === user.id && <Button
                  className="LeftOvalAction Red membersButton remove"
                  onClick={() => this.removeFamilyspace()}
                  disabled={familySpace.member_id !== user.id || !user.activated}
                >
                  <Delete fontSize="large" />
                  Supprimer l'espace famille
                </Button>} */}
              </div>
            </MediaQuery>
            <div className="members-list">
              <div className="table table-members">
                <div className="membersBody">
                  {members.map((m, i) =>
                    <div className="membersRow" key={i}>
                      <div className="membersCol icon_email_contain">
                        <div className="membersCol icon">
                          <Person style={{color: user.id === m.id ? '#3568ba' : '#8c8e8e'}} fontSize="large" />
                        </div>
                        <div className="membersCol mail">{m.email} : {m.name}</div>
                      </div>
                      <div className="membersCol role">{familySpace.member_id === m.id ? "Administrateur" : "Membre"}</div>
                      <div className="membersCol options">
                        {user.id === m.id ?
                          <>
                            {/* <Button className="LeftOvalAction secondaryBtn textBtn Grey membersButton" style={{visibility:'hidden'}} >Supprimer</Button> */}
                            <button className="LeftOvalAction fsp-button outline btn-edit-info" onClick={() => this.editPersonalInfos()} disabled={!user.activated}>Modifier mes infos</button>
                          </>
                        :
                          <>
                            {familySpace.member_id === user.id &&  <Button className="fsp-link LeftOvalAction secondaryBtn textBtn membersButton" onClick={() => this.removeFamilyspace(m.id)}>Supprimer</Button>}
                            {!m.activated ?
                              this.state.disabledRow.includes(i) &&
                                <Button className="LeftOvalAction Grey membersButton" disabled={true} style={{background: "white", border: "none"}} onClick={() => null}>
                                  <CheckCircleSharp style={{color: "#4caf50", fontSize: "28pt"}} />
                                </Button>
                                // <Button className="LeftOvalAction secondaryBtn outlinedBtn Grey membersButton" disabled={this.state.disabledRow.includes(i)} onClick={() => this.resendInvitation(m, i)}>Renvoyer l'invitation</Button>
                            : null}
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MediaQuery minWidth={576}>
              <div className="">
                <button
                  className="LeftOvalAction fsp-button invite btn-no-hover"
                  style={{ "--f-main-color": mainColor, "--f-font-color": fontColor, "float": "left", "marginTop":"10px" }}
                  onClick={() => this.inviteMemberAction()}
                  disabled={user && !user.activated}
                >
                  <PersonAdd fontSize="" style={{"color": fontColor, "marginRight": "3px"}} />
                  Inviter un proche
                </button>
                {/* {familySpace.member_id === user.id && <Button
                  className="LeftOvalAction Red membersButton remove"
                  onClick={() => this.removeFamilyspace()}
                  disabled={familySpace.member_id !== user.id || !user.activated}
                >
                  <Delete fontSize="large" />
                  Supprimer l'espace famille
                </Button>} */}
              </div>
            </MediaQuery>
        <Modal
          isOpen={this.state.personalInformationsModal}
          onRequestClose={() => this.setState({personalInformationsModal: false})}
          className="familyspace-addQuote-modal family-space-form"
          overlayClassName="familyspace-member-name-modal-overlay familyspace-login-modal-overlay"
        >
          <div className="modalBlock">
            <h2 className="title">Informations personnelles</h2>

            <div className="block">
              <label>Prénom ou pseudonyme : </label>
              <input type="text" name="name" value={name || ''} onChange={e => this.handleChange(e)} placeholder="Ex : Jean" />
            </div>

            <div className="block">
              <label>Votre adresse email : </label>
              <input
                type="text"
                name="email"
                value={email || ''}
                disabled="disabled"
                onChange={e => this.handleChange(e)}
                placeholder="Ex : contact@mail.com"
                style={{backgroundColor:"rgba(247,247,247,1)",color:"grey",cursor:"not-allowed"}}
                className={!this.validateEmail(email) ? "error-input" : null}
              />
            </div>

            {/*<div className="block">
            <label>Confirmer votre adresse email : </label>
            <input
              type="text"
              name="emailConfirm"
              value={emailConfirm}
              disabled="disabled"
              onChange={e => this.handleChange(e)}
              className={ email !== emailConfirm ? "error-input" : null}
            />
          </div>*/}

            <div className="block actions">
              <button className="LeftOvalAction add fsp-button" style={{cursor: (!name|| !email  || !this.validateEmail(email) || email !== emailConfirm) ? "not-allowed" : null, "--f-main-color": mainColor, "--f-font-color": fontColor}} onClick={() => this.updatePersonalInformations(name, email)} disabled={!name || !email || !this.validateEmail(email) || email !== emailConfirm}>Modifier</button>
              <button className="LeftOvalAction textBtn fsp-button cancel" onClick={() => this.setState({name: user.name, email: user.email, personalInformationsModal: false})}>Annuler</button>
            </div>
          </div>

        </Modal>

        <Modal
          isOpen={this.state.nameModalVisible}
          onRequestClose={() => this.setState({nameModalVisible: false})}
          className="familyspace-addQuote-modal family-space-form"
          overlayClassName="familyspace-member-name-modal-overlay familyspace-login-modal-overlay"
        >
          <div className="modalBlock">
            <h2 className="title">Modifier l'espace famille</h2>

            <div className="block input-name">
              <label>Nom</label>
              <input
                type="text"
                placeholder="Choisissez un nom"
                onChange={e => this.setState({newName: e.target.value})}
                value={this.state.newName}
              />
            </div>

            <div className="block image">
              <label>Photo</label>
              <br />
              <div className="img-input-block">
                {imagePreview ?
                  <img src={imagePreview} alt="Défunt" />
                  :
                  <AccountCircle fontSize='large' />
                }
                <input
                  type="file"
                  accept="image/*"
                  className="file-type"
                  onChange={e => this.handleImage(e)}
                />
              </div>

              {error && <div className="error error-message">{errorMessage}</div>}
            </div>

            <div className="block actions">
              {waitImageLoad ?
                <CircularProgress className="CircularWait"/> :
                <button 
                  className="LeftOvalAction fsp-button" 
                  style={{cursor: error ? "not-allowed" : null, "--f-main-color": mainColor, "--f-font-color": fontColor}} 
                  onClick={() => this.changeName()} disabled={error}
                >
                    Enregistrer les modifications
                </button>}
                {/* <button className="LeftOvalAction Grey cancel" onClick={() => this.setState({nameModalVisible: false, image: null, imagePreview: null, error: false, errorMessage: ""})}>Annuler</button> */}
            </div>
          </div>
        </Modal>

        {inviteModalVisible && <Sinvite close={()=>this.setState({inviteModalVisible:false})} from="Smembers"/>}
      </div>
    );
  };

};

export const Smembers = connect(state => ({
  familyspace: state.familyService,
  adminConfig: state.adminConfig
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
}))(SmembersComponent);
