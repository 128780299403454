import React, { useState, useEffect } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

const SortableMonument = SortableElement(({ index, item, get_image, disp_icons,add_config,selected,refresh_image, change_order }) => {
  
  const [order, setOrder] = useState(null); 
  useEffect(() => {
    setOrder(item.monumentOrder)
  }, [])

  useEffect(() => {
    setOrder(item.monumentOrder)
  }, [item.monumentOrder])
  
  const handleChangeOrder = e => setOrder(e.target.value)
  const handleEnterPress = e => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (order != null && order > 0)
        change_order(item.reference, order)
    }
  }
  
  return (
  <div className="Monument" key={`monument-${index}`}>
    {/* Monument Order */}
      <input type="number" className="orderInput" min="1" max="999" value={order} title="Entrée ou tabulation pour valider" onFocus={e=>e.target.select()} onChange={handleChangeOrder} onKeyDown={handleEnterPress}/>
    {/* End Monument Order */}
  <div className="title">{item.name}</div>
    {( item.clientConfig || item.familyConfig ) && refresh_image &&
      <i className="icon material-icons refresh" title= "Regénérer le cache image" onClick={()=>refresh_image(item)} >
          autorenew</i>}

    {disp_icons(item)}
    {selected===item.reference && <ul className="choiceAdd">
        <li onClick={()=>add_config("duo")}>Preconf pour client et famille</li>
        <li onClick={()=>add_config("client")}>Preconf pour client</li> 
        <li onClick={()=>add_config("famille")}>Preconf pour famille</li></ul>}  

    <img src={get_image(item)} alt="Monument"/>                                         
  </div>  )
    
}
 )

 export const SortableMonuments = SortableContainer(({  get_image, disp_icons,add_config,selected, commonList,disabled,refresh_image, change_order }) => 
<div className="MonumentGrid">
  {commonList.map((item,index)=>
    <SortableMonument key={index} index={index} disabled={disabled} item={item} selected={selected} get_image={get_image} 
     disp_icons={disp_icons} add_config={add_config} refresh_image={refresh_image} change_order={change_order}  />)}
</div>              

)




