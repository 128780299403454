import React from 'react';
import loader from '../images/loader@4x.gif';
import loaderMin from '../images/loader.gif';

export default function ({ big = false, top = 200 }) {

  return (
    <div style={{width: '100%', marginTop: `${top}px`, textAlign: 'center'}} >
      <img src={big ? loader : loaderMin} alt="Loader" />
    </div>
  );
};
