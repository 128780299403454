import {getStore} from '../store';
import { isQuote } from './restrictedConfiguration';

export const checkStatusFlowers = (fullConfig)=>{ // Retourne un des 4 statuts des affichages de fleur
  const configuration = fullConfig ? fullConfig : getStore().getState().configurator.current;
  if (!flowersAvaialble(configuration)){return "flowersNotAvailable"};
  if (checkOrganizationHideFlowers()){return "flowersHiddenForOrg"}
  const status = configuration && configuration.configuration && configuration.configuration.hideFlowers? "flowersHidden" : "flowersShown";
  return status
}
export const checkConfigHideFlowerImg = (fullConfig)=>{  
  //Vérifie si le parmaètre hideFlowers doit être envoyé
  const status = checkStatusFlowers(fullConfig);
  return (status === "flowersHiddenForOrg" || status ===  "flowersHidden")
}
export const checkOrganizationHideFlowers = ()=> { // Vérifie si le client a demandé à masquer les fleurs
  const user = getStore().getState().user;
  if(!user.identity){
    return false;
  }
  return user.identity.organization.hideFlowers
}
const flowersAvaialble = (configuration)=>{
  if (configuration && configuration.configuration && isQuote(configuration.configuration )){
    return true;
  }
  // Vérifie si le monument ou un des accessoire peut-être fleuri
  if(configuration && !checkMonumentWithFlowers(configuration) && !checkConfigAccessoryWithFlowers(configuration)){
    return false
  }  
return true
}
 
export const checkHideAccessoryFlower = (reference)=> { // Vérifie l'affichage d'un accessoire sur sélection

  if (checkOrganizationHideFlowers()) {return true} 

  const configuration = getStore().getState().configurator;
  if (!configuration || !configuration.current) return false;
  if (checkConfigAccessoryWithFlowers(configuration.current)){
// Si la personne a demandé à masquer les fleurs sur la configuration actuelle on n'affiche pas les fleurs sur la
// séléction d'accessoire
    return configuration.current.configuration.hideFlowers
  }
  return false;
}

 const checkConfigAccessoryWithFlowers = (configuration) =>{ // Vérifie si un des accessoire configuré peut être fleuri
  if (!configuration || !configuration.configuration) { return false};
  const configuredAccs = configuration.configuration.accessories;
  const infoAccs = configuration.accessories;
  if (!configuredAccs.length || !infoAccs || !infoAccs.length){
    return false
  }
  let oneFound = false;
  configuredAccs.forEach(acc=>{
    const found = infoAccs.find(item=>item.reference === acc.reference);
    oneFound = oneFound || (found && found.flowersAvailable);
  })
  return oneFound
}
const checkMonumentWithFlowers = ({configuration, options}) =>{  // Vérifie sir le monument configuré peut être fleuri
  if (configuration){
    return !!options.flowersIsAvailable || false
  }
  return false
}



