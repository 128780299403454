import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import * as configurationActions from "../../actions/configurationActions";
import * as uiActions from "../../actions/uiActions";

class ClientLogoComponent extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { replaceType } = this.props;
    const logo = this.props.user.identity.organization.logo;
    const logoRempli = replaceType==="white" ? require('../../images/space/logo_gpg_white.png') : require('../../images/space/gpg_logo_min.png') ;
    const clLogo = !logo ? logoRempli : logo;
    return (
      <div className="logoContainer">
        <img className="clientLogo" src={clLogo} alt="Logo" />
      </div>
    );
  }
}

export const ClientLogo = connect(
  (state, ownProps) => ({
    user:state.user,
    configurator: state.configurator,
  }),
  dispatch => ({
    actions: bindActionCreators(configurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
  })
)(ClientLogoComponent);
