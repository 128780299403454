import React, { useEffect } from 'react';
import {getStore} from '../../store';
import history from "../../history";
import {FengravingPattern} from "./FengravingPattern";
import {Ffooter} from "./Ffooter";
import FTopBar from './FTopBar';
import { Fheader } from './Fheader';
import { engravingDefaultText } from './FengravingParams'
import { initialChoiceMade } from '../../actions/uiActions'
import { NotifUpdate } from "../configurator/components/NotifUpdate";
import { gtmFamily } from '../../services/gtmFamily';

export const Fengraving =  ({match, designType}) => {

  useEffect(() => { 
    const { clientId } = match.params;
  }, []);

  function goCustomize () {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "revenir à ma configuration",
      category: "configurateur",
      subcategory: "configuration",
      type: "cta",
      from: "configuration gravure"
    }, { template: "configurateur", subtemplate: "liste gravures" }, {}, "partner", "product", "user", "page")
    // end GTM
    const { clientId, configId } = match.params;
    history.push(`/famille/${clientId}/${configId}/customize`)
  }
  const current = getStore().getState().configurator.current;
  const config = current ? current.configuration: null;
  return (
    <div className="ConfigurationPatternsEngravings">
      <NotifUpdate />
      {designType==="A" && <Fheader match={match}/> }
      {designType==='B' && <FTopBar goCustomize={()=> goCustomize()} config={config} from="Fengraving" />}
      {designType === "A" &&
        <div className="TitrePage">Ajoutez une gravure</div>
      }

      <FengravingPattern match={match} mode="engraving" backAction={() => goCustomize()} />
      {designType === "A" &&
        <Ffooter previous={()=>history.push(`./fgranit`)} next={()=>history.push(`./fmotif`)} addClass="Wide" />
      }
    </div>
  );
};
