import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import * as uiActions from "../../../actions/uiActions";
import { Modal } from "../../../components/Modal";
import { ScrollListView } from "../../../components/ScrollListView";
import { PatternCard } from "./PatternCard";
import * as patternsService from "../../../services/patterns.service";
import * as _ from "lodash";
import { Carousel } from "../../../components/carousel";
import { patternCarouselSlides } from "./patternCarouselSlides";
import { OptionPanel } from "../components/OptionPanel";
import cn from "classnames";
import { isConfigRequested } from "../../../services/configurationWarningChecks"
import { TranslationContext } from "../../../context/TranslationContext";
import {introIsAvailable} from "../../helpers/introHelpers"
import {clickTheCanvas} from '../../helpers/config3dHelper';

const limit = 24;
const defaultKeyWords = [
  "Animal",
  "Végétal",
  "Paysage",
  "Personnage",
  "Objet",
  "Symbole"
];
const defaultState = {
  openPicker: false,
  selectedPatternIndex: null,
  query: "",
  keywords: [], // C'est la première recherche qui initialise les keywords par défaut
  patterns: [],
  total: 0,
  isLoadingMore:false
};

/**
 * Modal permettant de rechercher et choisir un motif à ajouter sur la face courante
 */
class PatternPickerComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    face: PropTypes.object.isRequired,
    drawing: PropTypes.bool,
    imprint: PropTypes.bool.isRequired,
    selectedPattern: PropTypes.object,
    patterns: PropTypes.array,
    patternToUpdate: PropTypes.bool
  };

  state = defaultState;
  searchInput;

  componentWillReceiveProps({ patterns, patternToUpdate }) {
    if (patternToUpdate && !this.state.openPicker) {
      const patternConfig = _.find(patterns, {
        reference: patternToUpdate.reference
      });
      this.openModalPicker(patternConfig.keywords.join(", "));
    }
  }

  toggleModalPicker = () => {
    if (this.state.openPicker) {
      this.closeModalPicker();
    } else {
      clickTheCanvas();
      this.openModalPicker();
    }
  };

  toggleModalPickerForSimilarPattern = () => {
    if (this.state.openPicker) {
      this.closeModalPicker();
    } else {
      this.openModalPicker(
        this.props.selectedPattern &&
          this.props.selectedPattern.keywords.join(", ")
      );
    }
  };

  openModalPicker = (query = "") => {
    this.setState({
      openPicker: true,
      query
    });
    this.search();
    !this.props.imprint &&
      setTimeout(() => this.searchInput && this.searchInput.focus());
  };

  closeModalPicker = () => {
    //CHANGED this.setState(defaultState);
    this.setState({openPicker: false,selectedPatternIndex: null});
    this.props.uiActions.closePatternPicker();
  };

  closeModalCarousel = () => {
    this.setState({
      selectedPatternIndex: null
    });
  };

  search = _.debounce(() => {
    // On vide les résultats quand la recherche commence.
    // Cela provoque le scroll vers le haut de la liste et évite qu'un utilisateur
    // clique sur un motif qui vient d'être remplacé par le chargement des résultats
    // de recherche.
    this.setState({ patterns: [] });
    return this.doSearch(this.state.query, []);
  }, 300);

  onClickKeyword = keyword => {
    this.setState(
      {
        // query:keyword
        query: _.chain(this.state.query)
          .split(",")
          .map(_.trim)
          .filter() // Evite les keywords vides
          .concat(keyword)
          .uniq() // Evite qu'un double clique ajoute deux fois le keyword
          .value()
          .join(", ")
      },
      this.search
    );
  };

  loadMore = () => {
    if (this.state.patterns.length < this.state.total && !this.state.isLoadingMore) {
      this.isLoadingMore = true;
      this.doSearch(this.state.lastQuery, this.state.patterns);
    }
  };

  doSearch =  (query, patterns) => {
    if (this.props.imprint) {
        patternsService.getImprints().then(imprints=>{
        this.setState({ patterns: imprints, total: imprints.length,isLoadingMore:false});
      });
    } else {
        patternsService.getPatterns(query,patterns.length,limit).then(result=>{
          this.setState({
            lastQuery: query,
            keywords: result.keywords.length ? result.keywords : defaultKeyWords,
            patterns: patterns.concat(result.patterns.list),
            total: result.patterns.total,
            isLoadingMore:false
          });
        });
    }
  };

  renderItem = (pattern, index) => {
    return (
      <PatternCard
        key={index}
        pattern={pattern}
        onSelectPattern={() => this.onSelectPattern(index)}
      />
    );
  };

  onSelectPattern = index => {
    if (this.props.imprint) {
      this.onPickPattern(this.state.patterns[index]);
    } else {
      this.setState({
        selectedPatternIndex: index
      });
    }
  };

  onPickPattern = pattern => {
    const { face, patternToUpdate, actions } = this.props;
    if (patternToUpdate) {
      actions.updatePatternOfPattern(patternToUpdate.id, pattern);
    } else {
      actions.addPattern(face, pattern);
    }
    this.closeModalPicker();
  };

  seeSimilarPatterns = pattern => {
    this.setState({query:pattern.keywords});
    // this.state.query = pattern.keywords;
    this.search();
    this.closeModalCarousel();
  };

  searchBoxChange = event => {
    this.setState({ query: event.target.value }, this.search);
  };

  componentDidUpdate(prevProps, prevState) {
    const { madalName } = this.props;
    if (this.state.openPicker && this.state.openPicker !== prevState.openPicker && madalName) {
      setTimeout(() => {
        // this.props.launchIntro(true, madalName);
      }, 500)
    }
  }

  render() {
    const t = this.context;
    const { imprint, showIcon = true, madalName } = this.props;

    const header = (
      <>
        <div className="Header">
          <div className="HeaderContainer">
            <h1 className="Headline">
              {imprint
                ? t("config3d_patternandengravings_modal_reservation_locket_title") || "Sélectionnez un médaillon"
                : t("config3d_patternandengravings_modal_pattern_title") || "Rechercher un motif parmi plus de 1000 disponibles !"}
            </h1>
          </div>
        </div>
        {!imprint && (
          <div style={{"marginTop":"16px"}}>
            <form
              noValidate
              action=""
              onSubmit={event => {
                event.preventDefault();
                this.search();
              }}
            >
              <input
                ref={ref => (this.searchInput = ref)}
                type="text"
                placeholder="Entrez vos mots-clés ou cliquez sur un thème"
                value={this.state.query}
                onChange={this.searchBoxChange}
              />
            </form>
            <ul className="Keywords text-dark">
              {this.state.keywords.map((keyword, index) => (
                <li key={keyword}>
                  <div onClick={() => this.onClickKeyword(keyword)}>
                    {keyword}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );

    let scrollableFooter;
    if (
      this.state.patterns.length &&
      this.state.patterns.length < this.state.total
    ) {
      scrollableFooter = (
        <div className="More">
          <button onClick={() => this.loadMore()} class="button-primary button-medium">
            <span>Voir plus de motifs</span>  
          </button>
        </div>
      );
    }

    const button = this.props.selectedPattern ? (
      <OptionPanel
        closeOnClickInside={true}
        className="AddPatternOptionPanel"
        buttonContent={ t("config3d_patternandengravings_button_pattern") || "Motifs" }
        buttonIcon="pattern2-icon"
        buttonClassName="btn-config-tertiary secondary2 text-light secondary2-hover secondary2-hover-border"
      >
        <button
          className="OptionPanelSquareItem secondary3-hover"
          onClick={this.toggleModalPicker}
        >
          Nouveau motif
        </button>
        <button
          className="OptionPanelSquareItem secondary3-hover"
          onClick={this.toggleModalPickerForSimilarPattern}
        >
          Motif similaire
        </button>
      </OptionPanel>
    ) : imprint ? (
      <button
        className="OptionPanelSquareItem secondary3-hover"
        onClick={this.toggleModalPicker}
      >
        {showIcon && <i className="icon material-icons">&#xE1AD;</i>}
        { t("config3d_patternandengravings_button_reservation_locket") || "Médaillon" }
      </button>
    ) : (
      <button
        className={`SquareButton ${this.props.buttonClassName ? this.props.buttonClassName : ''} ${isConfigRequested() && 'disabled'}`} 
        disabled={isConfigRequested()}
        onClick={this.toggleModalPicker}
      >
        {showIcon && <span className={`pattern2-icon icon`}></span> }
       <div>
        { t("config3d_patternandengravings_button_pattern") || "Motifs" }
       </div> 
      </button>
    );

    return (
      <div className="ModalButton">
        {button}

        <Modal
          isOpen={this.state.openPicker}
          onRequestClose={this.closeModalPicker}
          className={cn("PatternPickerModal buttonModal background-page", {
            pattern: !imprint,
            imprint: imprint
          })}
          overlayClassName="PatternPickerModalOverlay" 
        >
          {introIsAvailable(false, madalName) && <span onClick={() => this.props.toggleSteps(this.props.madalName)} class="open-tutorial question-circle-icon icon icon-large"></span>}
          {header}
          <ScrollListView
            items={this.state.patterns}
            renderItem={this.renderItem}
            className="PatternPicker"
            scrollableFooter={scrollableFooter}
          />
        </Modal>

        <Modal
          isOpen={this.state.selectedPatternIndex !== null}
          onRequestClose={this.closeModalCarousel}
          className="PatternModal"
          overlayClassName="PatternModalOverlay"
        >
          <Carousel
            slidesData={this.state.patterns}
            slideToShow={this.state.selectedPatternIndex}
            renderSlide={this.makePatternSlideRenderer(t)}
          />
        </Modal>
      </div>
    );
  }

  makePatternSlideRenderer = (t) =>
    patternCarouselSlides({
      patterns: this.state.patterns,
      loadMore: this.loadMore,
      seeSimilarPatterns: this.seeSimilarPatterns,
      onPickPattern: this.onPickPattern,
      isUpdate: this.props.patternToUpdate,
      imprint: this.props.imprint,
      t
    });
}

export const PatternPicker = connect(
  state => ({
    patterns: state.configurator.current.patterns,
    patternToUpdate: state.ui.patternPicker
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch)
  })
)(PatternPickerComponent);
