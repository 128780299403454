import  {Component} from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as configurationActions from '../../actions/configurationActions';


/**
 * Composant sans render pour initialiser une configuration à partir d'un id de monument et rediriger
 * vers la bonne page du configurateur. Ce composant est mappé sur l'url '/monument/:ref' et est appelé
 * depuis l'extranet.
 */
class MonumentComponent extends Component {

  componentWillMount() {
    const { monument,selectionId } = this.props.match.params;
    if (!monument && selectionId){
      this.props.actions.initConfigurationFromSelectionRef(selectionId);
    }else{
      this.props.actions.initConfigurationFromExtranet(monument);
    }

  }

  render() {
    return null;
  }
}

export const Monument = connect(
  undefined,
  (dispatch) => ({
    actions: bindActionCreators(configurationActions, dispatch)
  })
)(MonumentComponent);
