import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import history from '../../history';
import { prepareData } from './components/FpriceList';
import gpg from '../../images/svg_icon.svg';
import { isFamilySpaceOn } from '../../services/utils.service';
import { Modal } from "../../components/Modal";
import { SFamilyLoginForm } from '../family_space/SFamilyLoginForm';
import { ManualMail } from '../../components/ManualMail';
import MediaQuery from "react-responsive/src";
import {isSmartphone} from "../../services/domFunctions";
import clientLogo, {ClientLogo} from "../configurator/ClientLogo";
import * as _ from 'lodash';
import {connect} from 'react-redux';
import { gtmFamily } from '../../services/gtmFamily';

class FTopBar extends Component {
  state={
    shareBtns: false,
    emailOpen: false, 
    _isMounted: false,
    _isSharing: false 
  }

    componentDidMount() {
      this.setState({_isMounted:true})
      const {shareBtns} = this.state;
      if (!shareBtns) {
        document.addEventListener('mousedown', this.clicOutsideShareBtns)
      }
    }

    componentWillUnmount() {
      this.setState({_isMounted:false})
      document.removeEventListener('mousedown', this.clicOutsideShareBtns, true);
    }
    toggleShareBtns () { 
      // GTM
      gtmFamily().ctaOfConfiguration({
        name: "partager",
        category: "partager",
        subcategory: "",
        from: "configuration header",
        type: "cta"
      }, { template: "configurateur", subtemplate: "produit" }, {}, "partner", "product", "user")
      // end GTM
      if (!isSmartphone()){
        this.setState({shareBtns:!this.state.shareBtns})
      }else{
        this.shareOnMobile();
      }
    }

    closeMailModal () {
      this.setState({emailOpen: false});
    }

    shareOnMobile() {
      const {_isSharing} = this.state;
      if (!_isSharing && window.navigator.share) {
        this.setState({_isSharing:true})       
        window.navigator.share({
          title: window.location.title,
          url: window.location.href
        })
        .then(() => {
          // Rien faire, partage réussi ici 
        })
        .catch((error) => {
          // Si jamais erreur, dans le catch pour pas bloqué
        })
        .finally(() => {
          this.setState({_isSharing:false})        // Dans tout les cas executé pour remettre le sharing a false
        });
      }
    }

     clicOutsideShareBtns = (event) => {
      const {from} = this.props;
      const { _isMounted } = this.state;
      if (from ==="Fcustomize"){
        if (this.state.shareBtns === false) {
          return
        }else{
          if (from !== 'Fengraving'){
            const domNode = _isMounted && ReactDOM.findDOMNode(this)
          if (!domNode || !domNode.contains(event.target)) {
            if (event.target.contains(document.getElementById('shareBtnsCont'))) {
              this.setState({shareBtns: false});
            }
          }
          }
        }
      }
    }

    barClick = () => {
      const { priceDetails, pricesPanel } = this.props;
      const { shareBtns } = this.state;
      if (priceDetails) 
        pricesPanel();
      if (shareBtns)
        this.toggleShareBtns();
    }

    seePrices = () => {
      // GTM
      gtmFamily().ctaOfConfiguration({
        name: "voir le tarif",
        category: "tarif",
        subcategory: "",
        type: "cta",
        from: "configuration header"
      }, { template: "configurateur", subtemplate: "produit" }, {}, "partner", "product", "user", "page")
      // end GTM
      const { priceDetails, pricesPanel } = this.props;
      pricesPanel()
    }

    shareConfig = (from, fbURL= null) => {
      switch (from) {
        case 'fb':
          window.open(fbURL,'_blank')
          // GTM
          gtmFamily().ctaOfConfiguration({
            name: "facebook",
            category: "partager",
            subcategory: "facebook",
            type: "picto",
            from: "partager"
          }, { template: "configurateur", subtemplate: "produit" })
          // end GTM
          break;
        case 'save':
          this.props.familyspaceAccess("shareBtn")
          break;
        case 'mail':
          this.setState({emailOpen:true})
          break;
      }
    }

    render(){
      
      const { current, user, from, pricesPanel, priceDetails, download, familySpace, familySpaceUser, isFamilySpaceOn, openQuoteAsk } = this.props;
      const currentURL = window.location.href;
      const waURL = `whatsapp://send?text=Configures avec moi le monument: ${currentURL}`;
      const fbURL = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}`;
      const mailURL= `mailto:?subject=Configure avec moi ce monument&body=Bonjour, je partage avec toi cette configuration ${currentURL}`;
      const { shareBtns } = this.state;
      const displayLogo = _.get(user, "identity.organization.displayFconfigLogo", false)
      const {mainColor, fontColor} = this.props.adminConfig.config

      if (from ==="Fcustomize"){
          let name;
          if(!current.configuration.monument?.alternativeMonumentName) {
            const data = current && prepareData(current,user);
            name = data.name.replace('GPG','');
            name = name.replace(/\s/g, '');
          } else {
            name = current.configuration.monument.name
          }
          return(
            <>
              <div className="fTopBarContainer" onClick={()=>this.barClick()}>
                <h1 className="hide-element">Monument funéraire GPG {name}</h1>
                {displayLogo && <ClientLogo replaceType={'grey'} />}
                <span className="monumentRef"><span>{!current.configuration.monument?.alternativeMonumentName && "GPG "}</span>{name}</span>
                <div className="infosButtons">
                  {/* Share Desktop */}
                  <MediaQuery minWidth={768}>
                    <div className="infoBtn infoShare" onClick={()=>this.toggleShareBtns()}>
                      <div className="svgContainer">
                        {shareBtns && 
                          <p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M23.6667 2.68325L21.3167 0.333252L12 9.64992L2.68333 0.333252L0.333328 2.68325L9.65 11.9999L0.333328 21.3166L2.68333 23.6666L12 14.3499L21.3167 23.6666L23.6667 21.3166L14.35 11.9999L23.6667 2.68325Z" fill="#6A7782"/>
                            </svg>
                            <span>Partager</span>
                          </p>                      
                        }
                        {!shareBtns && 
                          <p>
                            <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M21.6167 25.0166C22.5 24.1833 23.6833 23.6666 25 23.6666C27.7667 23.6666 30 25.8999 30 28.6666C30 31.4333 27.7667 33.6666 25 33.6666C22.2333 33.6666 20 31.4333 20 28.6666C20 28.2666 20.0667 27.8833 20.15 27.5166L8.4 20.6499C7.5 21.4833 6.31667 21.9999 5 21.9999C2.23333 21.9999 0 19.7666 0 16.9999C0 14.2333 2.23333 11.9999 5 11.9999C6.31667 11.9999 7.5 12.5166 8.4 13.3499L20.15 6.49992C20.0667 6.13325 20 5.73325 20 5.33325C20 2.56659 22.2333 0.333252 25 0.333252C27.7667 0.333252 30 2.56659 30 5.33325C30 8.09992 27.7667 10.3333 25 10.3333C23.6833 10.3333 22.4833 9.81659 21.6 8.98325L9.85 15.8333C9.93333 16.2166 10 16.5999 10 16.9999C10 17.3999 9.93333 17.7833 9.85 18.1666L21.6167 25.0166ZM26.6667 5.33325C26.6667 4.41659 25.9167 3.66659 25 3.66659C24.0833 3.66659 23.3333 4.41659 23.3333 5.33325C23.3333 6.24992 24.0833 6.99992 25 6.99992C25.9167 6.99992 26.6667 6.24992 26.6667 5.33325ZM5 18.6666C4.08333 18.6666 3.33333 17.9166 3.33333 16.9999C3.33333 16.0833 4.08333 15.3333 5 15.3333C5.91667 15.3333 6.66667 16.0833 6.66667 16.9999C6.66667 17.9166 5.91667 18.6666 5 18.6666ZM23.3333 28.6666C23.3333 29.5833 24.0833 30.3333 25 30.3333C25.9167 30.3333 26.6667 29.5833 26.6667 28.6666C26.6667 27.7499 25.9167 26.9999 25 26.9999C24.0833 26.9999 23.3333 27.7499 23.3333 28.6666Z" fill="#6A7782"/>
                            </svg>
                            <span>Partager</span>
                          </p>}
                      </div>
                    </div>
                  </MediaQuery>
                  {/* Download Desktop & mobile */}
                  <div className="infoBtn infoDownload" onClick={download}>
                    <div className='svgContainer'>
                      <p>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z" fill="#6A7782"/>
                        </svg>
                        <span>Télécharger</span>
                      </p>
                    </div>
                  </div>
                  {/* Share Mobile */}
                  <MediaQuery maxWidth={768}>
                    <div className="infoBtn infoShare" onClick={()=>this.toggleShareBtns()}>
                      <div className="svgContainer">
                        <p>
                          <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M21.6167 25.0166C22.5 24.1833 23.6833 23.6666 25 23.6666C27.7667 23.6666 30 25.8999 30 28.6666C30 31.4333 27.7667 33.6666 25 33.6666C22.2333 33.6666 20 31.4333 20 28.6666C20 28.2666 20.0667 27.8833 20.15 27.5166L8.4 20.6499C7.5 21.4833 6.31667 21.9999 5 21.9999C2.23333 21.9999 0 19.7666 0 16.9999C0 14.2333 2.23333 11.9999 5 11.9999C6.31667 11.9999 7.5 12.5166 8.4 13.3499L20.15 6.49992C20.0667 6.13325 20 5.73325 20 5.33325C20 2.56659 22.2333 0.333252 25 0.333252C27.7667 0.333252 30 2.56659 30 5.33325C30 8.09992 27.7667 10.3333 25 10.3333C23.6833 10.3333 22.4833 9.81659 21.6 8.98325L9.85 15.8333C9.93333 16.2166 10 16.5999 10 16.9999C10 17.3999 9.93333 17.7833 9.85 18.1666L21.6167 25.0166ZM26.6667 5.33325C26.6667 4.41659 25.9167 3.66659 25 3.66659C24.0833 3.66659 23.3333 4.41659 23.3333 5.33325C23.3333 6.24992 24.0833 6.99992 25 6.99992C25.9167 6.99992 26.6667 6.24992 26.6667 5.33325ZM5 18.6666C4.08333 18.6666 3.33333 17.9166 3.33333 16.9999C3.33333 16.0833 4.08333 15.3333 5 15.3333C5.91667 15.3333 6.66667 16.0833 6.66667 16.9999C6.66667 17.9166 5.91667 18.6666 5 18.6666ZM23.3333 28.6666C23.3333 29.5833 24.0833 30.3333 25 30.3333C25.9167 30.3333 26.6667 29.5833 26.6667 28.6666C26.6667 27.7499 25.9167 26.9999 25 26.9999C24.0833 26.9999 23.3333 27.7499 23.3333 28.6666Z" fill="#6A7782"/>
                          </svg>
                          <span>Partager</span>
                        </p>
                      </div>
                    </div>
                  </MediaQuery>
                  {/* Save desktop */}
                  <MediaQuery minWidth={768}>
                    <div className="infoBtn infoFamilySpace" onClick={()=>this.props.familyspaceAccess()}>
                      <div className='svgContainer'>
                        <p>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.357 1.857C2.95918 1.857 2.57765 2.01504 2.29634 2.29634C2.01504 2.57765 1.857 2.95918 1.857 3.357V16.6427C1.85701 17.0034 1.98699 17.352 2.22312 17.6247C2.45926 17.8973 2.78573 18.0758 3.14272 18.1273V11.9284C3.14272 11.4169 3.34591 10.9264 3.70758 10.5647C4.06926 10.203 4.5598 9.99986 5.07129 9.99986H14.9284C15.4399 9.99986 15.9305 10.203 16.2921 10.5647C16.6538 10.9264 16.857 11.4169 16.857 11.9284V18.1273C17.214 18.0758 17.5405 17.8973 17.7766 17.6247C18.0127 17.352 18.1427 17.0034 18.1427 16.6427V5.53072C18.1427 5.13215 17.985 4.75072 17.703 4.46957L15.5301 2.29672C15.3908 2.1573 15.2254 2.04671 15.0433 1.97126C14.8613 1.89582 14.6661 1.85699 14.469 1.857H14.2856V5.49986C14.2856 5.75312 14.2357 6.00391 14.1388 6.23789C14.0419 6.47188 13.8998 6.68448 13.7207 6.86357C13.5416 7.04265 13.329 7.18471 13.095 7.28163C12.8611 7.37855 12.6103 7.42843 12.357 7.42843H6.78557C6.27409 7.42843 5.78355 7.22524 5.42187 6.86357C5.06019 6.50189 4.857 6.01135 4.857 5.49986V1.857H3.357ZM6.14272 1.857V5.49986C6.14272 5.85472 6.43072 6.14272 6.78557 6.14272H12.357C12.5275 6.14272 12.691 6.07499 12.8116 5.95443C12.9321 5.83387 12.9999 5.67036 12.9999 5.49986V1.857H6.14272ZM15.5713 18.1427V11.9284C15.5713 11.7579 15.5036 11.5944 15.383 11.4739C15.2624 11.3533 15.0989 11.2856 14.9284 11.2856H5.07129C4.90079 11.2856 4.73728 11.3533 4.61672 11.4739C4.49616 11.5944 4.42843 11.7579 4.42843 11.9284V18.1427H15.5713ZM0.571289 3.357C0.571289 2.61819 0.864783 1.90963 1.38721 1.38721C1.90963 0.864783 2.61819 0.571289 3.357 0.571289H14.4699C15.2087 0.571333 15.9172 0.864856 16.4396 1.38729L18.6124 3.56015C19.1353 4.083 19.4284 4.79186 19.4284 5.53072V16.6427C19.4284 17.3815 19.1349 18.0901 18.6125 18.6125C18.0901 19.1349 17.3815 19.4284 16.6427 19.4284H3.357C2.61819 19.4284 1.90963 19.1349 1.38721 18.6125C0.864783 18.0901 0.571289 17.3815 0.571289 16.6427V3.357Z" fill="#4D616B"/>
                          </svg>
                          <span>Sauvegarder</span>
                        </p>
                      </div>
                    </div>
                  </MediaQuery>
                  {/* Tarif Desktop & Mobile */}
                  <div className={`infoBtn infoPrice ${priceDetails && 'open'}`} onClick={this.seePrices}>
                    <div className="svgContainer">
                      <p>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20 25.8333C18.0187 25.8351 16.0751 25.2919 14.3819 24.263C12.6887 23.2342 11.3111 21.7594 10.4 20H20V16.6667H9.3C9.21667 16.1167 9.16667 15.5667 9.16667 15C9.16667 14.4333 9.21667 13.8833 9.3 13.3333H20V10H10.4C11.3128 8.24198 12.6907 6.7683 14.3835 5.73967C16.0764 4.71103 18.0191 4.16692 20 4.16667C22.6833 4.16667 25.15 5.15001 27.05 6.78334L30 3.83334C27.2557 1.36245 23.6928 -0.00332782 20 6.08923e-06C13.4667 6.08923e-06 7.93333 4.18334 5.86667 10H0V13.3333H5.1C4.96502 14.4404 4.96502 15.5597 5.1 16.6667H0V20H5.86667C7.93333 25.8167 13.4667 30 20 30C23.85 30 27.35 28.55 30 26.1667L27.0333 23.2167C25.15 24.85 22.7 25.8333 20 25.8333Z" fill="#6A7782"/>
                        </svg>
                        <span>{isSmartphone() ? "Voir tarif" : "Voir le tarif" }</span>
                      </p>
                    </div>
                  </div>
                  {/* Ask for Quote Desktop */}
                  <MediaQuery minWidth={768}>
                    <div className={`infoBtn infoQuoteAsk ${priceDetails && 'open'}`} onClick={openQuoteAsk} style={{"--f-main-color": mainColor, "--f-font-color": fontColor}} >
                      <div className="svgContainer">
                        <p>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 15.5C10.8112 15.5011 9.64507 15.1751 8.62915 14.5578C7.61322 13.9405 6.78669 13.0556 6.24 12H12V10H5.58C5.53 9.67 5.5 9.34 5.5 9C5.5 8.66 5.53 8.33 5.58 8H12V6H6.24C6.78766 4.94519 7.61441 4.06098 8.63012 3.4438C9.64582 2.82662 10.8115 2.50015 12 2.5C13.61 2.5 15.09 3.09 16.23 4.07L18 2.3C16.3534 0.81747 14.2157 -0.00199669 12 3.65354e-06C8.08 3.65354e-06 4.76 2.51 3.52 6H0V8H3.06C2.97901 8.66421 2.97901 9.3358 3.06 10H0V12H3.52C4.76 15.49 8.08 18 12 18C14.31 18 16.41 17.13 18 15.7L16.22 13.93C15.09 14.91 13.62 15.5 12 15.5Z" fill={fontColor}/>
                          </svg>
                          <span>Demander un devis</span>
                        </p>
                      </div>
                    </div>
                  </MediaQuery>
                </div>
              </div>
              {shareBtns && <div id="shareBtnsCont" className="shareBtnsContainer">
                  <MediaQuery minWidth={768}>
                    <div className= {`shareTopBtn fbBtn`} onClick={()=>this.shareConfig("fb", fbURL)}>
                    <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.3284 29.995V16.335H14.9367L15.6217 10.9867H10.3284V7.58004C10.3284 6.0367 10.7584 4.98004 12.9734 4.98004H15.78V0.211705C14.4144 0.0653563 13.0418 -0.0053075 11.6684 3.78032e-05C7.59502 3.78032e-05 4.79835 2.4867 4.79835 7.0517V10.9767H0.220016V16.325H4.80835V29.995H10.3284Z" fill="white"/>
                    </svg>
                  </div>
                  {(!familySpace || !familySpaceUser) && isFamilySpaceOn(this.props.user) && 
                    <div className= {`shareTopBtn fmBtn`} onClick={()=>this.shareConfig("save")}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.7825 6.9675L17.0325 3.2175C16.9624 3.14799 16.8793 3.09299 16.7879 3.05567C16.6966 3.01835 16.5987 2.99943 16.5 3H4.5C4.10218 3 3.72064 3.15803 3.43934 3.43934C3.15804 3.72064 3 4.10217 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V7.5C21.0006 7.40129 20.9817 7.30345 20.9443 7.21207C20.907 7.12069 20.852 7.03758 20.7825 6.9675ZM9 4.5H15V7.5H9V4.5ZM15 19.5H9V13.5H15V19.5ZM16.5 19.5V13.5C16.5 13.1022 16.342 12.7206 16.0607 12.4393C15.7794 12.158 15.3978 12 15 12H9C8.60218 12 8.22064 12.158 7.93934 12.4393C7.65804 12.7206 7.5 13.1022 7.5 13.5V19.5H4.5V4.5H7.5V7.5C7.5 7.89782 7.65804 8.27935 7.93934 8.56066C8.22064 8.84196 8.60218 9 9 9H15C15.3978 9 15.7794 8.84196 16.0607 8.56066C16.342 8.27935 16.5 7.89782 16.5 7.5V4.8075L19.5 7.8075V19.5H16.5Z" fill="white"/>
                      </svg>
                    </div>
                  }
                  <div className= {`shareTopBtn mailBtn`} onClick={()=>this.shareConfig("mail")}>
                    <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M34.25 0.25H1.75C1.05859 0.25 0.5 0.808594 0.5 1.5V26.5C0.5 27.1914 1.05859 27.75 1.75 27.75H34.25C34.9414 27.75 35.5 27.1914 35.5 26.5V1.5C35.5 0.808594 34.9414 0.25 34.25 0.25ZM32.6875 4.57812V24.9375H3.3125V4.57812L2.23438 3.73828L3.76953 1.76563L5.44141 3.06641H30.5625L32.2344 1.76563L33.7695 3.73828L32.6875 4.57812ZM30.5625 3.0625L18 12.8281L5.4375 3.0625L3.76563 1.76172L2.23047 3.73438L3.30859 4.57422L16.6523 14.9492C17.0361 15.2473 17.5082 15.4092 17.9941 15.4092C18.4801 15.4092 18.9522 15.2473 19.3359 14.9492L32.6875 4.57812L33.7656 3.73828L32.2305 1.76563L30.5625 3.0625Z" fill="white"/>
                    </svg>
                  </div>
                  </MediaQuery>
              </div>}
                {this.state.emailOpen && <ManualMail url={currentURL} closeFromModal={() => this.closeMailModal()} from="fTopBar" />}

            </>)
        }else {
          return (
            <div className="fTopBarContainer orangeBgContainer" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={()=>this.props.goCustomize()}>
              <div className="backArrowContainer">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27.3333 12.3337H7.04996L16.3666 3.01699L14 0.666992L0.666626 14.0003L14 27.3337L16.35 24.9837L7.04996 15.667H27.3333V12.3337Z" fill={fontColor}/>
                </svg>
              </div>
              <span>revenir à ma configuration</span>
            </div>
          )
        }

    }
}

export default FTopBar = connect((state) => ({
  adminConfig: state.adminConfig
}),
)(FTopBar);
