import React, {Component} from 'react';
import * as userActions from '../../../actions/userActions'
import * as api from '../../../services/api.service'
import cn from 'classnames';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

class FavoriteStarComponent extends Component {
  state = {spinning: false};

  onClick = () => {
    this.setState({spinning: true});

    let {reference, isFav}=this.props;

    let stop = ()=>this.setState({spinning: false});

    (isFav ? api.del : api.put)('/api/user/favorites/' + reference)
      .then(this.props.userActions.saveUserState)
      .then(stop, stop);
  }

  render() {
    const {isFav, mode}=this.props;

    const buttonProps = {
      className: cn('FavoriteStar', {
        active: isFav,
        spinning: this.state.spinning
      }),
      onClick: this.onClick,
      title: "Ajouter / retirer des favoris"
    }
    if (mode === 'button') {
      return <button {...buttonProps}>
        <span>{isFav ? 'Retirer des favoris' : 'Ajouter aux favoris'}</span>
        {isFav ? (<i className="icon material-icons">&#xE838;</i>) : (
          <i className="icon material-icons">&#xE83A;</i>)}
      </button>
    } else {
      return <a {...buttonProps}>
        {isFav ? (<i className="icon material-icons">&#xE838;</i>) : (
          <i className="icon material-icons">&#xE83A;</i>)}
      </a>
    }
  }
}

export const FavoriteStar = connect((state, ownProps) => ({
    isFav: state.user.favorites.indexOf(ownProps.reference) !== -1
  }),
  (dispatch) => ({
    userActions: bindActionCreators(userActions, dispatch)
  })
)(FavoriteStarComponent);


