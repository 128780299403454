import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {onKeyPressed} from '../services/domFunctions';

const animationDuration = 300; // Durée de la transition

export class Carousel extends Component {
  static propTypes = {
    slidesData: PropTypes.array.isRequired, // Array avec toutes les données des slides
    slideToShow: PropTypes.number, // Slide initiale, 0 par défault
    renderSlide: PropTypes.func.isRequired, // (slideData, slideIndex)=><slide>
    renderClass: PropTypes.func, // Ajoute une classe au carousel en fonction de l'item affiché.
                                 // (slideData)=>'string'
  };

  state = {
    slideToShow: (this.props && this.props.slideToShow) || 0,
    animation: ''
  };

  clearListener;

  componentDidMount() {
    this.clearListener = onKeyPressed({
      37: this.goPrev,
      39: this.goNext
    })
  }

  componentWillUnmount() {
    this.clearListener()
  }

  setAnim = (animation)=> {
    if (this.state.animation) {
      return false;
    }

    this.setState({animation});
    setTimeout(()=> {
      this.setState({animation: ''});
    }, animationDuration);
    return true;
  }

  goPrev = ()=> {
    if (this.state.slideToShow > 0) {
      this.setAnim('animate--previous');
      this.setState({slideToShow: this.state.slideToShow - 1});
    }
  }

  goNext = ()=> {
    if (this.state.slideToShow < this.props.slidesData.length - 1) {
      this.setAnim('animate--next');
      this.setState({slideToShow: this.state.slideToShow + 1});
    }
  }

  render() {
    const i = this.state.slideToShow;
    const slideData = this.props.slidesData[i];
    const prev = this.props.slidesData[i - 1];
    const next = this.props.slidesData[i + 1];

    return (
      <div
        className={cn('Carousel', this.props.className, this.props.renderClass &&
          this.props.renderClass(slideData))}>

        {/*
         Quand on arrive à la fin de la liste, et qu'il n'y a pas de suivant (ou de précédent), on garde les boutons, juste masqués.
         En effet, le caroussel est souvent affiché dans une modale, avec les flèches qui dépassent sur l'overlay.
         Si on retire complètement les flèches, alors l'utilisateur risque de fermer la modale accidentellement en voulant cliquer
         sur les flèches disparues, son clic arrivant sur l'overlay de la modale.
         */}
        {this.state.slideToShow > 0 && (
        <div className="Previous" onClick={this.goPrev}>
          <span className="chevron-left-icon icon-medium text-cta-icon"></span>
          {this.props.textLeft && <div className="LabelPrevious">{this.props.textLeft}</div>}
          </div>)}

        {this.state.slideToShow < this.props.slidesData.length - 1 && (
          <div className="Next " onClick={this.goNext}>
            <span className="chevron-right-icon icon-medium text-cta-icon"></span>
            {this.props.textRight && <div className="LabelNext">{this.props.textRight}</div>}
            </div>)}

        <div className={'ScrollContainer ' + this.state.animation}>
          <div className="SlidesWrapper">
            <div className="Slide PreviousSlide">{prev && this.props.renderSlide(prev, i)}</div>
            <div className="Slide CurrentSlide">{this.props.renderSlide(slideData, i)}</div>
            <div className="Slide NextSlide">{next && this.props.renderSlide(next, i)}</div>
          </div>
        </div>
      </div>
    );
  }
}
