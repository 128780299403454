
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import * as storage from 'redux-storage'
import debounce from 'redux-storage-decorator-debounce'
import filter from 'redux-storage-decorator-filter'
import createEngine from 'redux-storage-engine-localstorage';
import {routerReducer} from 'react-router-redux';
import thunk from 'redux-thunk';

import * as types from './actions/actionTypes';
import reducers from './reducers';
import history  from "./history";
import { connectRouter } from "connected-react-router";

let store;


export function getStore() {
  return store;
}

export function configureStore(callback) {
  // Encapsule nos reducers avec celui de redux-storage pour permettre le chargement initial
  const reducer = storage.reducer(combineReducers({
    ...reducers,
    routing: routerReducer // Pour synchroniser l'état de react-router dans le store
  }));

  // Création de l'engine LocalStorage
  const storageEngine = debounce(
    filter(createEngine('config3D'), [
        // Ajouter les propriétés à sauvegarder ici (cf
        // https://github.com/michaelcontento/redux-storage-decorator-filter)
        ['tutorial', 'viewed'],
      ]
    ), 1000);

  // Création du middleware de redux-storage qui se charge d'appeler le engine.save()
  const storageMiddleware = storage.createMiddleware(storageEngine, [
    // Ajouter les actions blacklistées
  ], [
    // Ajouter les actions whitelistées
    types.TUTORIAL_CLOSED,
  ]);

  const middlewares = [thunk, storageMiddleware];
  if (process.env.NODE_ENV === 'development') {
    // Utile pour logger les erreurs dans les actions, mais pollue la console.
    //middlewares.push(require('redux-logger')());
  }

  store = createStore(
    connectRouter(history)(reducer),
    {}, // Etat initial
    compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f // Redux DevTools
    )
  );

  // Chargement de l'état depuis le stockage persistent
  const load = storage.createLoader(storageEngine);
  load(store).then(loaded, loaded);

  function loaded() {
    // Create an enhanced history that syncs navigation events with the store
    callback(store, history);
  }
}
