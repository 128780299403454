import * as actionTypes from '../actions/actionTypes';

const initialState = {
  previous: null,
  current: null,
  loading: false,
  configuration: null,
};

export default function (state = initialState, {type, payload}) {
  switch (type) {

    case actionTypes.CONFIGURATION_GET_START:
      return initialState;

    case actionTypes.CONFIGURATION_GET_SUCCESS:
      return {
        previous: null,
        current: payload.isoView,
        loading: false,
        configuration: payload.configuration.configuration.reference,
      };

    case actionTypes.CONFIG_UPDATE_ISO_VIEW:
      return Object.assign({}, state, {
        previous: state.current,
        current: payload,
        loading: false,
      });

    case actionTypes.CONFIG_UPDATE_ISO_VIEW_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });
    case actionTypes.SET_OFF_ISO_VIEW_LOADING:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
        return state;
  }
}
