import React, {Component} from 'react';
import ReactModal from 'react-modal';
import {navBarId} from '../pages/configurator/Configurator'
ReactModal.defaultStyles = {
  overlay: {},
  content: {}
}

// Pas de padding par défaut. On laisse le contenu gérer ses padding/margin.
ReactModal.defaultStyles.content.padding = 0;

/**
 * Encapsule le composant react-modal et ajoute un bouton pour fermer la modal
 */
ReactModal.setAppElement('#gpgConfigurator3d');

export class Modal extends Component {
  appNavBar;

  componentWillMount() {
    if (this.props.classNameBefore) {
      let before = document.createElement('div');
      before.className = this.props.classNameBefore;
      document.body.appendChild(before);
    }
    this.appNavBar = document.getElementById(navBarId);
    // Dans le catalogue, il y a une modale sans navbar
    this.appNavBar && this.appNavBar.addEventListener('click', this.closeModal);
  }

  componentWillUnmount() {
    // Dans le catalogue, il y a une modale sans navbar
    this.appNavBar && this.appNavBar.removeEventListener('click', this.closeModal);
  }

  closeModal = ()=> {
    this.props.onRequestClose();
  }

  render() {
    return (
      <ReactModal onRequestClose={this.props.onRequestClose} {...this.props}>
        {this.props.children}
        {!this.props.hideClose && (
          <div className="CloseModal" onClick={this.props.onRequestClose}>
            <i className="icon material-icons">&#xE14C;</i>
          </div>
        )}
      </ReactModal>
    )
  }
}

