/**
 *
 * Fait scroller la page doucement jusqu'à la position verticale Y.
 *
 * @param y : Number vertical de la fenêtre à la fin de l'animation.
 */
export function scrollPageTo(y) {
  // N'aura un effet que sur ipad sous une iframe
  if (window.parent && window.parent.window)
    window.parent.window.postMessage({ type: "scrollTo", payload: { y } }, "*");

  const start = window.pageYOffset || window.scrollY || 0;
  const delta = y - start;

  // La durée de la transition est fixée à 20 frames
  let stepsTotal = 20;
  let step = 0;

  function tick() {
    var x = step / stepsTotal;
    window.scrollTo(0, start + interpolateSmooth(x) * delta);
    if (step++ < stepsTotal) {
      (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
        setTimeout(tick, 16);
    }
  }

  tick();
}

function interpolateSmooth(x) {
  // https://www.google.fr/search?q=(sin((x-0.5)*PI)%2B1)%2F2
  // Map les valeurs de 0->1 vers 0->1 avec une amorce et une fin douces
  return (Math.sin((x - 0.5) * Math.PI) + 1) / 2;
}
/**
 * Appelle callback() quand un clic à lieu en dehors de element
 * @param element un élément du DOM
 * @param callback une fonction, appellée sans argument
 * @returns {clearListeners} une fonction pour retirer les listeners
 */
export function onClickOutsideOf(element, callback) {
  var cancelled = false;

  function elementListener() {
    cancelled = true;
  }

  function windowListener() {
    if (cancelled) {
      cancelled = false;
    } else {
      callback && callback();
    }
  }

  window.addEventListener("click", windowListener);
  element && element.addEventListener("click", elementListener);

  return function clearListeners() {
    window.removeEventListener("click", windowListener);
    element && element.removeEventListener("click", windowListener);
  };
}

/**
 * Exécute un callback à la pression d'une touche du clavier.
 * On lui passe directement une map {clef:callback}.
 * Par exemple, pour alert('gauche') quand on appuie sur la flèche gauche,
 * il suffit d'executer
 *
 * onKeyPressed({37:()=>alert('gauche')});
 *
 * @param mapKeyCodeToCallback objet avec en clef des code de touche et en valeur des callbacks
 *   correspondants
 * @returns {function()} fonction de nettoyage qui retire les listeners
 */
export function onKeyPressed(mapKeyCodeToCallback) {
  function runAssociatedCallback(e) {
    if (mapKeyCodeToCallback[e.which]) {
      mapKeyCodeToCallback[e.which]();
    }
  }

  window.addEventListener("keyup", runAssociatedCallback);

  return () => window.removeEventListener("keyup", runAssociatedCallback);
}

// export const isSmartphone = () => window.innerWidth <= 490;
export const isSmartphone = () => window.innerWidth <= 768;
