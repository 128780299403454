import React from 'react'
import loadingPicto from "../images/icons-config-famille/loading.svg";

function Loader() {
  return (
    <div className="loadingContainer">
      <img className="loadingImg" src={loadingPicto} alt="Chargement..." />
    </div>
  )
}

export default Loader
