import {getStore} from '../store';

function checkRole(role) {
  return ()=> (getStore().getState().user.roles || []).indexOf(role) !== -1;
}

export const userCan = {
  seeBuyingPrices: checkRole('prix-achat'),
  seeAndChangeSellingPrices: checkRole('prix-vente'),
  cannotChangeSellingPrices: checkRole('edit-selling-price'),
  placeOrder: checkRole('commander'),
  preOrder: checkRole('preorder'),
  configAdmin: checkRole('gpg_admin_config'),
  clientAdminFamily : checkRole('client_admin_family'),
  granitePicto : checkRole('granite_pictos'),
  frameConfig: checkRole('frame_config'),
  veneerConfig: checkRole('veneer_config'),
  accessoryConfig: checkRole('accessory_config'),
  modifyVeneer : checkRole('modifyVeneer'),
  fullPrice: checkRole('full_price'),
  isItBuyer: checkRole('prix-achat') || checkRole('commander'),
  isProfilPnf: checkRole('profil_pnf'),
}

export const getOrganization = () => {
  return(getStore().getState().user.identity.organization)
}

