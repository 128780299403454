import React, { Component} from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../../actions/updateConfigurationActions";
import * as _ from "lodash";
import { TwoChoiceButton } from "../../../../components/TwoChoiceButton";
import { VeneerSetting } from "./VeneerSetting";
import { VeneerThickness } from "./VeneerThickness";
import {userCan,getOrganization} from '../../../../services/userRights.service';
import { TranslationContext } from "../../../../context/TranslationContext";


const heightPaths = ["back.left","back.right","front.left","front.right",
  "left.front","left.back","right.front","right.back"];
const defaultTitle = "Ajouter les placages souhaités";
const defaultLayout = "Closoir AR / Parpaing AV";

class VeneerConfigureComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    veneer: PropTypes.shape({
      dimensions: PropTypes.shape({
        back: PropTypes.shape({left: PropTypes.number,right: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number}),
        front: PropTypes.shape({left: PropTypes.number,right: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number}),
        right: PropTypes.shape({front: PropTypes.number,back: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number}),
        left: PropTypes.shape({front: PropTypes.number,back: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number})
      })
    })
  };
  implantations = [{front: "Closoir",back: "Closoir",side: "Long",value: "Closoir AR / Closoir AV"},
                   {front: "Closoir",back: "Parpaing",side: "ClosoirAvant", value: "Parpaing AR / Closoir AV"},
                   {front: "Parpaing",back: "Closoir",side: "ClosoirArriere",value: "Closoir AR / Parpaing AV"},
                   {front: "Parpaing",back: "Parpaing",side: "Court",value: "Parpaing AR / Parpaing AV"}
                  ];

  state = {updateAllHeights: true, // pour mettre à jour toutes les hauteurs en une fois quand elles sont vides
    inputValueThick: null, 
    displayThick: false, // pour afficher les icônes d'epaisseurs
    title: defaultTitle, // titre initial du haut de page
    subtitle:"",
    openSides:{right:false,left:false,front:false,back:false},// Pour informer si les côtés sont ouverts
    layout: defaultLayout,
    dimensions: { //3cm par défaut
      right: { length: 0, back: 0, front: 0, thickness: 3 },
      front: { length: 0, left: 0, right: 0, thickness: 3 },
      left:  { length: 0, back: 0, front: 0, thickness: 3 },
      back:  { length: 0, left: 0, right: 0, thickness: 3 }
    },
    error:""
  };

  componentWillMount() {
    const { veneer } = this.props;
    let copyVeneer = {right:{},front:{},left:{},back:{}};
    const thickness = veneer && veneer.dimensions && veneer.dimensions.front && veneer.dimensions.front.thickness
        ? veneer.dimensions.front.thickness: 3;
    let sidesOpen = {...this.state.openSides};
    // On copie les côtés de Redux en ne prenant que ce qui est rempli

    ["back", "left", "right", "front"].forEach(side => {
      sidesOpen[side] =  this.monumentHasSides(side,veneer.dimensions);
      
      copyVeneer[side] = sidesOpen[side] ?{...veneer.dimensions[side]}:veneer.dimensions ?
      this.copyOrZero(side,veneer.dimensions[side]):{...this.state.dimensions[side]};
      // Si la structure est vide on prend la valeur de l'état par défauts
    });
    // On affiche le bon titre selon les valeurs redux
    let infoTitle = this.getTitle(veneer.dimensions,thickness,veneer.layout);
    const layout = veneer.layout ? veneer.layout : this.state.layout; 
    // L'état est mis à jour avec toutes les valeurs redux
    this.setState({
      inputValueThick: thickness,
      dimensions: copyVeneer,
      layout:layout,
      openSides:sidesOpen,
      title:infoTitle.title,
      subtitle:infoTitle.subtitle,
      updateAllHeights: _.every(heightPaths, path => !_.get(this.props.veneer.dimensions, path))
    }); 
  }

  copyOrZero= (side,value)=>{ // On est dans le cas ou redux a un côté avec une hauteur ou longueur nulle ou inexistante on copie
    let answer ={};      
        // la partie existante de la structure (ex: hauteur car mise pour tout le monde)
    if (!value) return {...this.state.dimensions[side]};
    if (side === "right" || side ==="left")
    {
      answer = { length: 0, back: 0, front: 0, thickness: 3 };
      ["back","front","length","thickness"].forEach(field=>{
       if (value[field]){
         answer[field] = value[field];
       }
      })
    }
    if (side === "front" || side ==="back")
    {
      answer = { length: 0, left: 0, right: 0, thickness: 3 };
      ["left","right","length","thickness"].forEach(field=>{
       if (value[field]){
         answer[field] = value[field];
       }
      })
    }    
    return (answer);
  }

  changeMeasure = (type, subtype, value) => {
    let newDimensions = {...this.state.dimensions};
    newDimensions[type][subtype] = value;
    // la mise à jour de la hauteur arrière gauche entraine la mise à jour de hauteur gauche arrière de même pour les 3 autres
    if (subtype !== "length") {
      newDimensions[subtype][type] = value;
    }
    let infoTitle = this.getTitle(newDimensions, this.state.inputValueThick,this.state.layout);
    this.setState({ dimensions: newDimensions, title:infoTitle.title, subtitle:infoTitle.subtitle });  
  };

  changeThickness = (event, type) => {
    return new Promise((resolve) => {
      if (type === "onChange" && event.target.value === "") {
        this.setState({ inputValueThick: "" }, () => {
          resolve();
        });
      } else {
        let thickness = this.state.inputValueThick;
        thickness = type === "-" && thickness !== 0 ? thickness - 1 // appui sur "-"
            : type === "+" ? thickness + 1 // appui sur "+"
            : !isNaN(event.target.value) && event.target.value !== ""
            ? parseInt(event.target.value)
            : 3; // 3 par défaut
        thickness = thickness < 3 ? 3 : thickness > 5 ? 5 : thickness; //valeur entre 3 et 5
        if (type !== "onChange") {
          let dimensions = { ...this.state.dimensions };
          ["back", "left", "right", "front"].forEach(side => { // on met à jour l' épaisseur
            dimensions[side].thickness = thickness;
          });
          this.setState({ dimensions: dimensions });
        }
        // L'épaisseur est prise en compte dans les dimensions du titre
        let infoTitle = this.getTitle(this.state.dimensions, thickness,this.state.layout);
        this.setState({ inputValueThick: thickness,title:infoTitle.title, subtitle:infoTitle.subtitle  });
        this.setState({ inputValueThick: thickness, title: infoTitle.title, subtitle: infoTitle.subtitle }, () => {
          resolve();
        });
      }
    });
  };

  checkUpdateHeights = value => {
    let newDimensions = { ...this.state.dimensions };
    // Mets à jour toutes les hauteurs initialement
    if (this.state.updateAllHeights) {
      heightPaths.forEach(field => {
        const twoLevels = field.split(".");
        newDimensions[twoLevels[0]][twoLevels[1]] = value;
      });
      this.setState({ updateAllHeights: false ,dimensions: newDimensions});
    }
  };
  save = () => {
    let pb = false;
    let error="";
    // Prend en compte les erreurs dûes à un côté ouvert mais avec hauteur ou longueur nulle
    const translate ={right:"droit", front:"devant" , left:"gauche", back:"derrière"};
    ["back", "left", "right", "front"].forEach(side => {
      if (this.state.openSides[side] && this.monumentHasSides(side)===false){
        const  type= this.state.dimensions[side].length === 0 ? "longueur":"hauteur";
        error += `La ${type} du côté ${translate[side]} ne peut pas être nulle.
        
        `;
        pb = true;
      }
    });
    if (!pb){ // Si pas d'erreur on stocke
      let veneer = {...this.props.veneer,layout:this.state.layout,dimensions:this.state.dimensions,display:this.props.display,details:this.state.subtitle};
      this.props.actions.updateVeneer(veneer,this.props.display);
      this.props.onClose();
    }
    else{
      this.setState({error:error+" Merci d'indiquer une valeur ou déselectionner le côté."});
    }
  };

  saveDefaultVeneer = async () => {
    let pb = false;
    let error="";
    // Prend en compte les erreurs dûes à un côté ouvert mais avec hauteur ou longueur nulle
    const translate ={right:"droit", front:"devant" , left:"gauche", back:"derrière"};
    ["back", "left", "right", "front"].forEach(side => {
      if (this.state.openSides[side] && this.monumentHasSides(side)===false){
        const  type= this.state.dimensions[side].length === 0 ? "longueur":"hauteur";
        error += `La ${type} du côté ${translate[side]} ne peut pas être nulle.
        
        `;
        pb = true;
      }
    });
    if (!pb){ // Si pas d'erreur on stocke
      let veneer = {layout:this.state.layout,dimensions:this.state.dimensions,display:this.props.display,details:this.state.subtitle};

      await this.props.actions.defaultVeneer(veneer,getOrganization().reference);
      this.props.onClose();
    }
    else{
      this.setState({error:error+" Merci d'indiquer une valeur ou déselectionner le côté."});
    }
  };


  informOpen = (type,open) =>{ // on récupére l'information sur les côtés ouverts par l'utilisateur
   let openSides = {...this.state.openSides};
   openSides[type] = open;
   this.setState({openSides:openSides});
  }

  monumentHasSides = (side,type = null) => {
    // vérifie si le côté existe: epaisseur et longueur non vide et une des hauteurs non vide
    const tabSides = side !== "all" ? [side] : ["left", "right", "back", "front"];
    const data =  type ? type:this.state.dimensions;
    return _.some(tabSides, side => {
      const { left, right, back, front, length, thickness } = _.get(data,side,{});
      return (
        !!length && !!thickness && (!!left || !!right || !!back || !!front)
      );
    });
  };
  selectLayout = (type, value, data) => {
    // Choix de la configuration Closoir/Parpaing
    let position = null;
    if (type === "Front") {
      position = this.implantations.find(
        item => item.front === value && item.back === data.back // nouvelle valeur front et ancienne valeur back
      );
    }
    if (type === "Back") {
      position = this.implantations.find(
        item => item.back === value && item.front === data.front // nouvelle valeur back et ancienne valeur front
      );
    }
    // La configuration modifie les dimensions affichées dans le titre
    let infoTitle = this.getTitle(this.state.dimensions, this.state.inputValueThick, position.value);
    if (position) {
      this.setState({layout: position.value,title:infoTitle.title, subtitle:infoTitle.subtitle});
    }
  };


  displayThick = (event, enter) => {  // Pour afficher les images avec l'épaisseur indiquée
    if (enter === "enter" && !this.monumentHasSides("all")) {
      this.setState({ displayThick: true });
    } else {
      this.setState({ displayThick: false });
    }
  };


  manageImages = layout => { // Choisit les 4 bonnes images les images input et épaisseur sont gérées dans VeneerSetting
    const backImage = { cn: "BackImage " + layout.back, img: `Bas-Haut-${layout.back}` };
    const frontImage = { cn: "FrontImage " + layout.front, img: `Bas-Haut-${layout.front}`};
    const leftImage = {cn: "LeftImage " + layout.side + " ",img: `Gauche-Droite-${layout.side}`};
    const rightImage = {cn: "RightImage " + layout.side + " ",img: `Gauche-Droite-${layout.side}`};
    return {back: backImage, front: frontImage, left: leftImage, right: rightImage};
  };

  getTitle = (data,thickness,layout)=>{ // Récupère le bon titre à afficher selon le nb de placages, les tailles, epaisseurs et
                                        // configuration Closoir Parpaing
    const t = this.context;
    if (this.monumentHasSides("all",data)){ // Si au moins un des côtés est présent
      let nbSides = 0;
      let length = 0;
      let width = 0;
      ["back", "left", "right", "front"].forEach(side => {
        if (this.monumentHasSides(side,data)){ // ne prend en compte que les côtés ayant une longueur/hauteur
          nbSides++;
          if (side === "left" || side === "right"){
            length = this.adjustLength (length,data[side].length,layout,thickness,data); 
          }else{
            width = this.adjustWidth (width,data[side].length,side,layout,thickness,data);
          }
        }
      })
      const title = nbSides ===1 ? "1 placage": `${nbSides} ${ t("config3d_monument_modal_veneer_title_on") || "placages" }` ; // 1 ou plusieurs placages 
      const subtitle = nbSides>1 && length !== 0 && width !== 0 ? `${ t("config3d_monument_modal_veneer_subtitle") || "Dimensions hors tout : "} ${length} ${ t("config3d_monument_modal_veneer_measurementunit") || "cm"}  x ${width} ${ t("config3d_monument_modal_veneer_measurementunit") || "cm"}`:"";
      return({title:title,subtitle:subtitle});
    }
    return ({
      title: t("config3d_monument_modal_veneer_title_off") || "Ajouter les placages souhaités",
      subtitle:""
    });
  }
  adjustWidth= (prevWidth,newWidth,side,layout,thickness,data)=>{
    if (newWidth ===0) return (prevWidth); // on garde la valeur précédente 
    const leftRight = this.checkBothSides("left","right",data);
    switch (layout){
      case "Closoir AR / Closoir AV":
        newWidth = leftRight ? newWidth + 2*thickness : newWidth + thickness;
      break;
      case "Parpaing AR / Closoir AV":
        if (side==="front"){      
          newWidth = leftRight ? newWidth + 2*thickness : newWidth + thickness;
        }
      break;
      case "Closoir AR / Parpaing AV":
        if (side==="back"){
          newWidth = leftRight ? newWidth + 2*thickness : newWidth + thickness;
        } 
        break;
      default:
        break;
    }
    if (newWidth>prevWidth){ // on renvoie le plus grand des deux
      return(newWidth) 
    }else{
      return (prevWidth)
    }
  }

  adjustLength= (prevLength,newLength,layout,thickness,data)=>{
    if (newLength ===0) return (prevLength); // on garde la valeur précédente 
    const frontback = this.checkBothSides("front","back",data);
    const back =  this.monumentHasSides("back",data)
    const front = this.monumentHasSides("front",data)
    switch (layout){
      case "Parpaing AR / Closoir AV":
        if(!back){
          newLength = newLength - thickness;
        }
        else if(!front){
          newLength = newLength + thickness;
        }
      case "Closoir AR / Parpaing AV":
        newLength = frontback ?  newLength + thickness : front? newLength + thickness : newLength;  // on ajoute une épaisseur
      break;
      case "Parpaing AR / Parpaing AV":
        newLength = frontback ?  newLength + 2*thickness : newLength + thickness; // on ajoute deux épaisseurs
      break;
      default:
      break;
    }
    if (newLength>prevLength){ // on renvoie le plus grand des deux
      return(newLength) 
    }else{
      return (prevLength)
    }
  }
  checkBothSides = (side1,side2,data) =>{
    return (this.monumentHasSides(side1,data) && this.monumentHasSides(side2,data))
  }
  render() {
    const t = this.context;
    const {title,subtitle,openSides,displayThick,dimensions} = this.state;

    let hasVeneer = this.monumentHasSides("all"); // Si au moins un des côtés est présent
    let layoutItem = this.implantations.find(
      item => item.value === this.state.layout
    );
    const imageData = this.manageImages(layoutItem);
    return (
          <div className=" StepVeneer background-page">
            <div className="TitleStepVeneer text-dark">
              <div className="BigTitle">
                {title}
                <div  className="SmallTitle">{subtitle} </div>
              </div>
              {/* <button
                className="ButtonStepVeneer"
                onClick={hasVeneer ? this.save:this.props.onClose}
              >
                {hasVeneer ? "Valider":"Fermer"}
              </button> */}
              <button 
                className={`${hasVeneer ? "button-primary text-cta" : "secondary2 text-light"} button-small`}
                onClick={hasVeneer ? this.save:this.props.onClose}>
                <span>{
                  hasVeneer 
                    ? t("config3d_monument_modal_veneer_validatebutton") || "Valider"
                    : t("config3d_monument_modal_veneer_closebutton") || "Fermer"
                }</span>
              </button>
              {userCan.modifyVeneer() && hasVeneer && (this.props.monument.category==="PLT") &&<button
                className="ButtonDefVeneer"
                onClick={this.saveDefaultVeneer}
              >
                Mémoriser les positions et valider
              </button>
              }
            </div>
            <div className="VeneerDisplay">
            <p className="ErrorText">{this.state.error}</p> {/* Appel au composant gérant chaque côté */}
              {["back", "left", "right", "front"].map(side => (
                <VeneerSetting
                  key={side}
                  present={openSides[side]}
                  type={side}
                  data={dimensions[side]}
                  changeMeasure={this.changeMeasure}
                  checkUpdateHeights = {this.checkUpdateHeights}
                  imageData={imageData[side]}
                  displayThick={displayThick}
                  informOpen = {this.informOpen}
                />
              ))}
            </div>
            <div className="FootVeneer">
              {this.state.openSides.front && (/* Config Closoir-Parpaing Avant affichée seulement si Avant ouvert */
                <TwoChoiceButton
                  title={t("config3d_monument_modal_veneer_frontclosure") || "Closoir Avant"}
                  classUsed="Front"
                  item1={t("config3d_monument_modal_veneer_closuretoggle_closure") || "Closoir"}
                  item2={t("config3d_monument_modal_veneer_closuretoggle_breezeblock") || "Parpaing"}
                  value={layoutItem.front}
                  onSelect={this.selectLayout}
                  data={layoutItem}
                />
              )}
              {/* Affichage de la sélection d'épaisseur */}
              <VeneerThickness 
                value={this.state.inputValueThick}
                changeThickness={this.changeThickness}
                enterLeave={this.displayThick}
              />
              {this.state.openSides.back && (/* Config Closoir-Parpaing Avant affichée seulement si Avant ouvert */
                <TwoChoiceButton
                  title={t("config3d_monument_modal_veneer_backclosure") || "Closoir Arrière"}
                  classUsed="Back"
                  item1={t("config3d_monument_modal_veneer_closuretoggle_closure") || "Closoir"}
                  item2={t("config3d_monument_modal_veneer_closuretoggle_breezeblock") || "Parpaing"}
                  value={layoutItem.back}
                  onSelect={this.selectLayout}
                  data={layoutItem}
                />
              )}
            </div>
          </div>
    );
  }
}

export const VeneerConfigure = connect(
  (state) => ({
    monument: state.configurator.current.configuration.monument,
    veneer: state.configurator.current.configuration.veneer
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(VeneerConfigureComponent);
