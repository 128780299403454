import React, {Component, useContext, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import * as uiActions from '../../../actions/uiActions';
import {ModalButton} from '../../../components/ModalButton';
import {Lines} from '../ConfiguratorCompletion';
import {Totals} from './CompletionTotals';
import { TranslationContext } from "../../../context/TranslationContext";

export class DetailsComponent extends Component {
  static contextType = TranslationContext;
  render() {
    const t = this.context;
    const additionalInfo = t("config3d_actionbuttons_details") || "Détails";
    const {disabled} = this.props;  
    return (
      <div className="btn-wrapper">
        <ModalButton buttonContent="Détails" 
                    buttonIcon="finalization-icon"
                    className={'ConfigurationDetailsPopupModal ' + (disabled ? 'disabled' : '')}
                    disabled={disabled}
                    overlayClassName="ConfigurationDetailsPopupModalOverlay"
                    additionalInfo={additionalInfo}
                    buttonClassName={`btn-config-secondary text-dark background-page ${!disabled && 'secondary3-hover text-dark-border'} `}>
          <ModalConfigurationDescription/>
        </ModalButton>
      </div>
    );
  }
}
export const Details = connect()(DetailsComponent);


function ModalConfigurationDescriptionComponent(props) {
  const t = useContext(TranslationContext);
  const {uiActions, onRequestClose} = props;
  const {showBuyingPrices, showSellingPrices} = props.ui;

  useEffect(() => {
    const bottomValue = showSellingPrices ? '140px' : '80px';
    document.getElementById('configuration-details').style.bottom = bottomValue;
  }, [showSellingPrices]);

  // useEffect(() => {
  //   // remove the last <hr> from detailsContainer
  //   const detailsContainer = document.getElementById("detailsContainer")
  //   if (detailsContainer) {
  //     const lastHr = detailsContainer.querySelector("hr:last-child")
  //     console.log("detailsContainer found", lastHr)
  //     if (lastHr) {
  //       lastHr.remove()
  //     }
  //   }
  // })

  return (
    <div className="ConfigurationDetailsPopup">
      <div className="Header">
        <div className="HeaderContainer">
          <h1>{t("config3d_finalize_quote-modal_title") || "Détails du projet"}</h1>
        </div>
      </div>
      <section className="ConfigurationDetails" id="configuration-details">
        <div className="ConfigurationDetailsScroll" style={{
          marginBottom: showSellingPrices ? "13.3rem" : "8.3rem",
        }}>
          <div className='row my-3'>
            <div className='col-8'></div>
            <div className='col-2 price-title d-flex justify-content-around'>
            {showSellingPrices ? (
              <>
                {(showBuyingPrices && showSellingPrices) && <span>{t("config3d_finalize_quote-modal_purchase-price") || "Prix d’achat"}</span>}
              </>
            ) : (
              <></>
            )}
            </div>
            <div className='col-2 price-title'>
              {showSellingPrices && <span>{t("config3d_finalize_quote-modal_selling-price") || "Prix de vente"}</span>}
            </div>
          </div>

          <Lines {...props} isDetailsModal={true} />
        </div>
      </section>
      <div className="Footer">
        <Totals {...props} isDetailsModal={true} onRequestClose={onRequestClose} />
      </div>
    </div>
  )
}

export const ModalConfigurationDescription = connect(
  state => ({
    canChangePrice: true,
    ui:state.ui,
    user: state.user,
    configurator: state.configurator,
    ...state.configurator.current
  }),

  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch)
  })
  
)(ModalConfigurationDescriptionComponent);