import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminConfigActions from "../../actions/adminConfigActions";
import "bootstrap/dist/css/bootstrap.min.css";
import { Badge, Row, Col, Button } from "reactstrap";
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import history from "../../history";
import {Table} from "reactstrap";
import Select from 'react-dropdown-select';
import Modal from 'react-modal';
import * as apiService from "../../services/api.service";
import {convertDate,getReactAppFamily,isStandardClient} from "../../services/utils.service";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { confirmAlert } from 'react-confirm-alert';
import loader from '../../images/loader@4x.gif';
import * as _ from "lodash";
import fr from 'date-fns/locale/fr';
import {imgs} from '../../services/images.service';

registerLocale('fr', fr);

class AdminDashboardComponent extends React.Component {
  state = {
    alert: null,
    exportLoading: false,
    total: 0,
    modalItem: null,
    leadModalVisible: false,
    lead: null,
    totalCount: 0,
    list: [],
    allOrganizations: [],
    organizations: [],
    limitOptions: [10, 25, 50, 75, 100, 1000],
    params: {
        page: 0,
        limit: 25,
        sort: "createdAt",
        order: -1
    },
    loading: true,
    searchAffect: '',
    sessionCount: 0,
    sharedCount: 0,
    endedCount: 0,
    session: 0,
    shared: 0,
    ended: 0,
    downloaded: 0,
    downloadedCount: 0
  };

  componentDidMount() {
    this._fetchFamilyConfiguration();
    this._fetchOrganizations();
    this.searchOrgs('');
  };

  searchOrgs = (search) =>{
    apiService.get(`/api/organizations/searchmain?keyword=${search}`)
    .then(list => {
      list = list.filter(elt=> isStandardClient(elt.clientId) && elt.name);
      list = list.sort((a,b) => a.name.localeCompare(b.name));
      this.setState({allOrganizations: list});
    });
  }

  /**
   * récupère les organisations associées à une configuration de type 'family'
   */
  _fetchOrganizations() {
    apiService.get(`/api/organization/family/list`)
      .then(res => {
        const orgs = res && res.data ? res.data : [];
        this.setState({organizations: orgs})
      });
  };

  _searchOrganization = _.debounce(search => {
      this.searchOrgs(search);
  }, 300);

  /**
   * liste les configurations de type famille / "family"
   */
  _fetchFamilyConfiguration() {
    this.setState({loading: true});
    apiService.get(`/api/configurations/family/list`, this.state.params)
    .then(list => {
      this.setState({
        list: list.data,
        total: list.total,
        totalCount: list.totalCount,
        downloadedCount: list.downloadedCount,
        endedCount: list.endedCount,
        sharedCount: list.sharedCount,
        sessionCount: list.sessionCount,
        session: list.session,
        shared: list.shared,
        ended: list.ended,
        downloaded: list.downloaded,
        loading: false});
    });
  };

  /**
   *
   * @param {*} clientId
   */
  removeConfiguration(clientId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='SelDeleteConf'>
            <p>Le confirguration du client {clientId} sera effacée.<br/> Confirmez-vous ?</p>
            <Row>
              <Col md="6"> <Button onClick={() => { this.deleteConfig(clientId); onClose(); }}>Oui</Button></Col>
              <Col md="6"> <Button onClick={onClose}>Non</Button></Col>
            </Row>
          </div>
        );
      },
    });
  };

  /**
   *
   * @param {*} client
   * @param {*} reference
   */
  showConfiguration(client, reference, step) {
    if (step == 'ffinalisation') {
      step = 'customize';
    }
    step = step || 'fgranit';
    const url = `${getReactAppFamily()}/famille/${client}/${reference}/${step}`;
    window.open(url);
  };


  addLead (org) {
    let lead = this.state.lead || [];
    if (lead.find(o => o.clientId === org.clientId)) {
      this.removeFromLead(org.clientId);
    } else {
      lead.push(org);
      this.setState({lead, allOrganizations: []});
    }
  };

  removeFromLead (clientId) {
    let lead = this.state.lead;
    const idx = lead.findIndex(o => o.clientId === clientId);
    const removed = lead.splice(0, idx);
    this.setState({lead: removed, allOrganizations: []});
  };

  /**
   *
   */
  async changeLead() {
    const { modalItem } = this.state;
    const lead = this.state.lead.map(l => {
      delete l.label;
      delete l.value;
      return l;
    });
    let name ="";
    lead.forEach(item=> name = name + item.name + " ");
    const imageMonument=await apiService.get(imgs.configuration.iso.large(modalItem.config))
    .then(isoView => isoView); 

    const data = await apiService.post('/api/configuration/family/affect', {organization: lead, configuration: modalItem.config.reference,imageMonument:imageMonument});

    if (data) {
      this._fetchFamilyConfiguration();
      this._fetchOrganizations();
      this.setAlert(`La configuration a bien été affectée à ${name}  !`, 'success');
      this.setState({
        modalItem: null,
        lead: null,
        leadModalVisible: false,
      });
    }
  };

  /**
   *
   */
  async exportCSV () {
    this.setState({exportLoading: true});

    let params = this.state.params;
    params.toexport = true;
    const csvData = await apiService.get(`/api/configurations/family/list`, params);

    if (csvData) {
      delete params.toexport;

      this.setState({exportLoading: false});
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, 'family-configurations' + fileExtension);
    }
  };

  async deleteConfig(reference) {
    const result = await apiService.del(`/api/configurations/family/${reference}`);

    if (result) {
      this._fetchFamilyConfiguration();
      this.setAlert('La configuration a été supprimée avec succès !', 'success');
    }
  }

  /**
   *
   * @param {*} item configuration
   * @param {*} index
   */
  getRow(item, index) {
    const { config, organization } = item;
    const organizationName = organization ? organization.name: '-';
    const configClient = config.client ? config.client : '';
    return (
      <tr key={`${index}-${config.reference}`}>
        <td style={{width: '220px'}}>
          {configClient}
          <div>
            <small>{organizationName}</small>
          </div>
        </td>
        <td>{config.designType ? config.designType : "A"}</td>
        <td>{config.configSubType ==="ENAOS"? "oui":"non"}</td>
        <td>{config.reference}</td>
        <td>{config.monument.reference}</td>
        <td>{config.step ? config.step : ' - '}</td>
        <td style={styles.loader}>
          {config.stats.pdfDownloaded ?
            <i className="icon material-icons" style={{color: 'green'}}>done</i>
          : " - "}
        </td>
        <td style={styles.loader}>
          {config.stats.share && config.stats.share.length > 0 ?
            <i className="icon material-icons" style={{color: 'green'}}>done</i>
          : " - "}
        </td>
        <td>
            {!!config.newfamilyInfos ?
              <div style={styles.familyInfos}>
                <span>{config.newfamilyInfos.firstname &&`${config.newfamilyInfos.firstname}`}
                      {config.newfamilyInfos.lastname &&  `${config.newfamilyInfos.lastname}`}</span> <br />
                <span>{config.newfamilyInfos.email}</span> <br />
                {config.newfamilyInfos.postcodeburial && <span>{config.newfamilyInfos.postcodeburial}<br /></span> }
                {config.newfamilyInfos.cityburial && <span>{`${config.newfamilyInfos.cityburial}`}</span>} < br />
                {config.newfamilyInfos.phone &&<span>{config.newfamilyInfos.phone}</span>}
              </div>
            :
              ' - '
            }
        </td>
        <td>
          <div>Prix d'achat: {config.price.discounted} € HT</div>
          <div>Prix de vente: {config.price.selling} € TTC</div>
        </td>
        <td>
        {!!config.newfamilyInfos && 
        <button className="btn btn-outline-dark" type="button" onClick={() => this.setState({modalItem: item, leadModalVisible: true})}>
            Affecter
          </button> 
             }
        </td>
        <td>
          {config.extended ?
            <>
              <div>{config.extended.configurationRef}</div>
              <div>
                <small>{config.extended.configurationClient}</small>
              </div>
            </>
          : ' - '}
        </td>
        <td>{convertDate(config.createdAt)}</td>
        <td>
          {config.stats && config.stats.session &&
            <span style={styles.options}>
              <i className="icon material-icons" style={{color: 'grey', cursor: 'not-allowed'}}>how_to_reg</i>
            </span>
          }
          <span style={styles.options}>
            <i className="icon material-icons" style={{color: 'blue', cursor: 'pointer'}} onClick={() => this.showConfiguration(config.client, config.reference, config.step)}>visibility</i>
          </span>
          <span style={styles.options}>
            <i className="icon material-icons" style={{color: 'red', cursor: 'pointer'}} onClick={() => this.removeConfiguration(config.reference)}>delete</i>
          </span>
        </td>
      </tr>
    );
  };

  /**
   *
   * @param {*} message
   * @param {*} className can be => primary, secondary, success, danger, warning, info, light, dark
   */
  setAlert (message, className) {
    className = `alert alert-${className} dashboard-alert container`;
    this.setState({
      alert: {message, class: className, title: ''}
    }, () => setTimeout(() => this.setState({alert: null}),3000));
  };

  /**
   * familyCheckbox in table header
   * @param {*} target from event checkbox
   */
  handleFilterCheckbox(target) {
    const id = target.id;

    let params = this.state.params;
    params[id] = target.checked;
    if (!params[id]) delete params[id];
    this.setState({params}, () => this._fetchFamilyConfiguration());
  };

  /**
   * input from filters
   * @param {*} param0 object with id & value
   */
  handleChange({id, value}) {
    const params = this.state.params;
    Object.assign(params, {[id]: value});
    if (!value || value === "" || value === undefined) {
      delete params[id];
    }
    this.setState({params});
  };

  /**
   *
   * @param {*} e event
   */
  handleChangeLimit(e) {
    let params = this.state.params;
    params.limit = parseInt(e.target.value, 10);
    this.setState({params}, () => this._fetchFamilyConfiguration());
  }

  getPagination () {
    const nbPage = Math.ceil(this.state.total/this.state.params.limit);
    const page = this.state.params.page;
    const pagination = [];
    for (let i = 0; i < nbPage; i++) {
      if (i > parseInt(page,10)+15) {
        i = nbPage+1;
        pagination.push(<span key={Math.random()*i+1*1000} className="pagination-dots"> ...</span>);
        return pagination;
      }
      pagination.push(
        <input
          key={Math.random()*i+1*1000}
          type="button" value={(i+1).toString()}
          className={"from-control "}
          style={page === i ? styles.current : null}
          onClick={() => {
            let params = this.state.params;
            params.page = i;
            this.setState({params}, () => this._fetchFamilyConfiguration())
          }}
        />
      );
    }
    return pagination;
  };

  sortBy (actionName) {
    const params = this.state.params;
    params.page = 0;
    if (actionName === params.sort) {
      params.order = 1 === parseInt(params.order, 10) ? -1 : 1;
    } else {
      params.sort = actionName;
      params.order = 1;
    }
    this.setState({params}, () => this._fetchFamilyConfiguration());
  };

  render () {
    const { list, loading, total, params, totalCount, limitOptions, organizations,
          exportLoading, lead, leadModalVisible, modalItem, allOrganizations,
          sessionCount, sharedCount, endedCount, downloadedCount, session, shared, ended, downloaded } = this.state;
    // Check is the call called by iframe admin
    let visibility = "unset"
    if(window.location.href.includes("adminfamilyDashboard")){
      visibility = "hidden"
    }
    return (
      <div className="container-fluid">
        <div className="AdminFamilyHeader">
          <button className="AdminFamilyBack" style={{visibility: visibility}} onClick={() => history.push("/")}>
            <i className="icon material-icons AdminFamilyBackArrow">home</i>Accueil
          </button>
        </div>

        <div className="d-flex flex-row dashboard-wrap-filters" style={styles.filters}>
            <div style={styles.textItemInfo}>
              <div className="text-info">
                *La lecture se fait en mettant le total filté sur le total général. (exemple: 780 /5000, 780 configurations filtrées sur 5000 totales)
              </div>
              Total: <Badge color='secondary'>{total} /{totalCount}</Badge> <br />
              Utilisateurs: <Badge color='secondary'>{session} /{sessionCount}</Badge> (<i className="icon material-icons" style={{color: 'grey', fontSize: 18}}>how_to_reg</i>)<br />
              Finalisées: <Badge color='secondary'>{ended} /{endedCount}</Badge> <br />
              Partagées: <Badge color='secondary'>{shared} /{sharedCount}</Badge> <br />
              Téléchargées: <Badge color='secondary'>{downloaded} /{downloadedCount}</Badge> <br />
            </div>

            <div className="d-flex dashboard-filters" style={styles.elemFilters}>
              <div style={styles.elemFilter}>
                <label style={styles.label}>Client :</label>
                <Select
                  id="client" name="client"
                  className="form-selection"
                  placeholder="Client"
                  searchable={true} clearable={true}
                  options={organizations.map(org => ({value: org.clientId, name: org.name, label: `${org.name} (${org.clientId})`}))}
                  onChange={e => this.handleChange({id: 'client', value: e.length > 0 ? e[0].value : null})}
                />

                <div className="row">
                  <div style={styles.secondElemFilter}>
                    <label style={styles.label}>Étape :</label>
                    <input  name="step" id="step" className="form-control" onChange={e => this.handleChange(e.target)} />
                  </div>
                </div>
              </div>

              <div style={styles.elemFilter}>
                <label style={styles.label}>Configuration :</label>
                 <input  name="configuration" id="reference" className="form-control" onChange={e => this.handleChange(e.target)} />

                 <div className="row">
                    <div style={styles.secondElemFilter}>
                      <label style={styles.label}>À partir du </label>
                      <div>
                        <DatePicker
                          id="startDate"
                          name="startDate"
                          selected={params.startDate || null}
                          onChange={startDate => this.handleChange({value: startDate, id: "startDate"})}
                          locale='fr'
                          placeholderText="Choisir une date"
                          isClearable
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                 </div>
              </div>

              <div style={styles.elemFilter}>
                <label style={styles.label}>Monument :</label>
                <input  name="monument" id="monument" className="form-control" onChange={e => this.handleChange(e.target)} />

                <div className="row">
                    <div style={styles.secondElemFilter}>
                      <label style={styles.label}>Jusqu'au </label>
                      <div>
                        <DatePicker
                          id="endDate"
                          name="endDate"
                          selected={params.endDate || null}
                          onChange={endDate => this.handleChange({value: endDate, id: "endDate"})}
                          locale='fr'
                          placeholderText="Choisir une date"
                          isClearable
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                 </div>
              </div>
              <div style={styles.elemFilter}>
                <div style={styles.elemFilter}>
                  <label style={styles.label}>Famille :</label>
                  <input  name="familyInfo" id="familyInfo" className="form-control" onChange={e => this.handleChange(e.target)} />
                </div>
                <div style={styles.secondElemFilter}>
                  <label style={styles.label}>
                    Enaos :
                  </label>
                  <select id="configSubType" name="configSubType" className="form-control" value={params.configSubType} onChange={e => this.handleChange(e.target)}>
                      <option key={1} value=""></option>
                      <option key={2} value="oui">oui</option>
                      <option key={3} value="non">non</option>
                  </select>
                </div>
              </div>
              <div style={styles.elemFilter}>
                <label style={styles.label}>
                  Résultats par page :
                </label>
                <select id="page-results" name="limit" className="form-control" value={params.limit} onChange={e => this.handleChangeLimit(e)}>
                  {limitOptions.map((option, i) =>
                    <option key={i} value={option}>{option}</option>)
                  }
                </select>
              </div>

              <div style={styles.elemFilter} className="dashboard-filters-actions">
                <input type="button" value="Filtrer" className="from-control" onClick={() => {
                  const tempParams = this.state.params;
                  tempParams.page = 0;
                  this._fetchFamilyConfiguration();
                  this.setState({params: tempParams});
                }} />

                <div style={{marginTop: 10}}>
                  <button type="button" className="form-control" onClick={() => this.exportCSV()} disabled={this.state.exportLoading}>
                    {exportLoading && <span className="spinner-border spinner-border-sm" style={{marginRight: 10}} role="status" aria-hidden="true"></span>}
                    Exporter
                  </button>
                </div>
              </div>
            </div>
        </div>

        {this.state.alert && <div className={this.state.alert.class} role="alert">
          <strong>{this.state.alert.title}</strong> {this.state.alert.message}
        </div>}

        {loading &&
          <div className="container" style={styles.loader}>
            <img src={loader} alt="Chargement" />
          </div>
          }

        {!loading &&
          <>
            <div className="container-fluid pagination-box">
              {this.getPagination()}
            </div>
            <Table className="dashboard-table table table-striped">
              <thead className="thead-dark">
                  <tr style={styles.textItem}>
                    <th>
                      <button onClick={() => this.sortBy('client')}>ID client</button>
                      {params.sort === 'client'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'client'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('designType')}>designType</button>
                        {params.sort === 'designType'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                        {params.sort === 'designType'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('configSubType')}>ENAOS</button>
                      {params.sort === 'configSubType'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'configSubType'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('reference')}>Configuration</button>
                      {params.sort === 'reference'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'reference'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('monument.reference')}>Monument</button>
                      {params.sort === 'monument.reference'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'monument.reference'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('step')}>Étape</button>
                      {params.sort === 'step'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'step'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => console.log('-')} disabled>Téléchargée</button>
                      <input id="downloaded" name="downloaded" type="checkbox" checked={this.state.params.downloaded || false} onChange={e => this.handleFilterCheckbox(e.target)} />
                    </th>
                    <th>
                      <button onClick={() => console.log('-')} disabled>Partagée</button>
                      <input id="shared" name="shared" type="checkbox" checked={this.state.params.shared || false} onChange={e => this.handleFilterCheckbox(e.target)} />
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('newfamilyInfos')}>Famille</button>
                      <input id="isFamily" name="isFamily" type="checkbox" checked={this.state.params.isFamily || false} onChange={e => this.handleFilterCheckbox(e.target)} />
                      {params.sort === 'newfamilyInfos'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'newfamilyInfos'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('price.discounted')}>Prix</button>
                      {params.sort === 'price.discounted'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'price.discounted'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>
                      <button onClick={() => console.log('-')} disabled>Affectation</button>
                    </th>
                    <th>
                      <button onClick={() => console.log('-')}>Origine</button>
                    </th>
                    <th>
                      <button onClick={() => this.sortBy('createdAt')}>Céé le</button>
                      {params.sort === 'createdAt'&& parseInt(params.order, 10) === 1  && <i className="icon material-icons">arrow_upward</i>}
                      {params.sort === 'createdAt'&& parseInt(params.order, 10) === -1  && <i className="icon material-icons">arrow_downward</i>}
                    </th>
                    <th>{/** actions */}</th>
                  </tr>
                </thead>
                <tbody>
                  {list && list.map((item, index) => this.getRow(item, index))}
                </tbody>
            </Table>
            <div className="container-fluid pagination-box">
              {this.getPagination()}
            </div>
          </>
        }
        <Modal
          isOpen={leadModalVisible}
          onRequestClose={() => this.setState({leadModalVisible: false})}
          className="lead-modal container"
        >
          <div className="lead-modal-header">
            <h2>Affecter la configuration {modalItem && modalItem.config.reference}</h2>
          </div>

          <div className="lead-modal-content">
            {modalItem &&
              <>
                <input
                  className="form-selection change-lead"
                  placeholder = "Rechercher"
                  value={this.state.searchAffect}
                  onChange={({target}) => this.setState({searchAffect: target.value}, this._searchOrganization(target.value))}
                  onFocus={({target}) => this.setState({searchAffect: target.value}, this._searchOrganization(target.value))}
                />
                {allOrganizations && 
                  <>
                    <div className="caption">Séléctionnez un client</div>
                    <ul>
                      {allOrganizations.map((org, index) =>
                        <li
                          key={index}
                          onClick={() => this.addLead(org)}
                          className={(lead && lead.find(l => l.clientId === org.clientId)) ? 'active-lead' : ''}
                        >
                          {`${org.name} (${org.clientId})`}
                        </li>
                      )}
                    </ul>
                  </>
                }
              </>
            }

            {lead && lead.length > 0 &&
              <div className="row lead-list">
                <div className="list-title">Affecter à :</div>
                <div className="list">
                  {lead.map((item, index) =>
                    <div key={index} className="badge badge-secondary" onClick={() => this.removeFromLead(item.clientId)}>
                      {`${item.name} (${item.clientId})`} <i className="material-icons">close</i>
                    </div>
                  )}
                </div>
              </div>
            }

            <div className="actions-lead-wrap">
              <div className="actions-lead-button">
                {lead && <button className="btn btn-outline-primary" type="button" onClick={() => this.changeLead()}>Confirmer</button>}
                <button className="btn btn-outline-secondary" type="button" onClick={() => this.setState({lead: null, modalItem: null, leadModalVisible: false, searchAffect: '', allOrganizations: []})}>Annuler</button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };
};

const styles = ({
  filters: {
    marginTop: 10,
    marginBottom: 10,
    padding: 15,
    background: 'white',
  },
  textItem: {
    fontSize: 16,
  },
  textItemInfo: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  elemFilter: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 100
  },
  secondElemFilter: {
    paddingLeft: 15,
    paddingRight: 10,
    marginTop: 10,
    minWidth: 100
  },
  elemFilters: {
    width: '80%',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
  },
  options: {
    marginLeft: 10,
  },
  loader: {
    textAlign: 'center',
  },
  label: {
    paddingBottom: 5,
    fontSize: 12,
  },
  current: {
    background: 'lightGrey',
  }
});

export const AdminDashboard = connect(
  state => ({
    adminConfig: state.adminConfig,
  }),dispatch => ({actions: bindActionCreators(adminConfigActions, dispatch),}))(AdminDashboardComponent);
