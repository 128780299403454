import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminConfigActions from "../../actions/adminConfigActions";
import "bootstrap/dist/css/bootstrap.min.css";
import {Container,Row,Col,FormGroup,Input} from "reactstrap";
// import { AdminCatalog } from "./AdminCatalog";
// import { AdminConfigurator } from "./AdminConfigurator";
import {SimpleAdminConfig} from "./SimpleAdminConfig";
import { AdminFamilyCatalog } from "./AdminFamilyCatalog";
import * as apiService from "../../services/api.service";
import {AdminMainGranits} from "./components/AdminMainGranits";

const defaultConfig = { config:{
  "catalogListMonuments": [],
  // "color":false,
  "mainColor":"", 
  "fontColor":"",
  "engravingsToHide": [],
  "granitesToHide": [],
  "catalogBypassCarousel": false,
}, clientId:"",clientName:"",clientTown:""};


class AdminFamilyComponent extends Component {

  state ={
    new:false,fullConfig:{},infos:null,
    load:true,showGranit:false,showMonument:false}
     
  componentDidMount(){
    const {clientId,clientTown,clientName} = this.props.client;
    apiService.get(`/api/familyconfig/infosorg/${clientId}`).then (infos=>{
      apiService.get(`/api/familyconfig/${clientId}`).then (fullConfig=>{
        if (!fullConfig){
          const newConfig ={...defaultConfig,clientId:clientId,clientName:clientName,clientTown:clientTown};
          this.setState({fullConfig:newConfig,new:true,load:false,infos:infos});
        }
        else{
          this.setState({fullConfig:fullConfig,new:false,load:false,infos:infos});
        }
      })
   })
  }

  updateConfigElt (obj){
    if (obj){
      const fullConfig = this.state.fullConfig;
      const keys = Object.keys(obj);
      keys.map((key) => fullConfig.config[key] = JSON.parse(JSON.stringify(obj[key])))
      apiService.post(`/api/familyconfig/${fullConfig.clientId}`,fullConfig);
      if (this.state.new){
        this.props.updateList();
      }
      this.setState({fullConfig:fullConfig,new:false});
    }
   }
  toggleValue(item) {
    const field = item.target.name;
    let obj = {};
    obj[field] = !this.state.fullConfig.config[field]; //simple boolean used to display element
    this.updateConfigElt(obj);
  }
  checkBox(field,message) {
    const { config } = this.state.fullConfig;

    return (
      <FormGroup  check className="checkbox">
      <Row>
        <Col md="2">
         <Input type="checkbox" name={field} onChange={(item)=>this.toggleValue(item)}
        defaultChecked={config[field]} />
        </Col>
        <Col md="10">{message}</Col>
      </Row>
      <br/>
    </FormGroup>
    )}
  render(){
    const {showMonument,showGranit,fullConfig} = this.state;
    return (
    <div className="AdminFamily">
      <button className="AdminFamilyBack"  onClick={()=> this.props.exit()}><i className="icon material-icons AdminFamilyBackArrow">arrow_back</i>Retour liste</button>
      { this.props.client.clientId !== "0" &&<div>
        <button className="AdminFamilyBack"  onClick={()=> this.props.testConfig(this.props.client.clientId)}><i className="icon material-icons AdminFamilyBackArrow">touch_app</i>Tester config</button>
      </div>  }
      {!this.state.load && <Container>

          <Row>
            <Col md="10">
            {!showMonument ?
              <div className="admin-main-title">
                <i className="icon material-icons show-icon" onClick={() => this.setState({showMonument: true, showGranit: false})}>
                  keyboard_arrow_up {/** keyboard_arrow_down */}
                </i>
                <span>Liste des monuments spécifiques </span>
                <small>{`   ${fullConfig.config.catalogListMonuments.length} monument(s) configuré(s)`}</small>
              </div>
            :    <AdminFamilyCatalog base={this.props.base}checkBox={(a,b)=>this.checkBox(a,b)} fullConfig={fullConfig}
                            updateCfg={(obj)=>this.updateConfigElt(obj)}infos={this.state.infos}
                            close={() => this.setState({showMonument: false})}/>
            }           
            {!showGranit ?
            <div className="admin-main-title">
              <i className="icon material-icons show-icon" onClick={() => this.setState({showGranit: true, showMonument: false})}>
                keyboard_arrow_up {/** keyboard_arrow_down */}
              </i>
              <span>Liste des granits cachés </span>
              <small>{`  ${fullConfig.config.granitesToHide.length} granit(s) configuré(s)`}</small>
            </div>
          :
            <div className="AdminMain-granits">
              <AdminMainGranits
                base={this.state.infos.granites}
                hidden={this.state.fullConfig.config.granitesToHide}
                update={config => this.updateConfigElt(config)}
                close={() => this.setState({showGranit: false})}
              />
            </div>
          }              

            </Col>
            <Col md="2">
              <SimpleAdminConfig fullConfig={this.state.fullConfig} updateCfg={(obj)=>this.updateConfigElt(obj)} />
            </Col>
{/*             <Col md="3">
              <AdminCatalog checkBox={(a,b)=>this.checkBox(a,b)} fullConfig={this.state.fullConfig}
                            updateCfg={(obj)=>this.updateConfigElt(obj)}  infos={this.state.infos}/>
            </Col>
           <Col md="4">
             <AdminConfigurator checkBox={(a,b)=>this.checkBox(a,b)} fullConfig={this.state.fullConfig}
                                updateCfg={(obj)=>this.updateConfigElt(obj)} infos={this.state.infos}/>
        </Col>   */}
        </Row>
       </Container>}
    </div>
    )
  }
}
export const AdminFamily = connect(
  state => ({
    adminConfig: state.adminConfig,
    user:state.user,
  }),dispatch => ({actions: bindActionCreators(adminConfigActions, dispatch),}))(AdminFamilyComponent);
