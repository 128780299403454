import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "reactstrap";
import * as adminConfigActions from "../../../actions/adminConfigActions";
import Select from 'react-dropdown-select';

class AdminMainGranitsComponent extends React.Component {

  state = {
    searching: false,
    sourceGranits: this.props.base || [],
    hiddenGranits: this.props.hidden || []
  };

  updateGranitList = () => {
    this.props.update({granitesToHide: this.state.hiddenGranits})
  };

  /**
   *
   * @param {*} hiddenGranits
   */
  changeGranitList = hiddenGranits => {
    this.setState({hiddenGranits});
  };

  /**
   * custome Render for react-dropdown-select Select/Searchable Input
   * @param {object}
   */
  customContentRender = ({props, state}) => {
    if (state.dropdown) {
      state.search = this.state.searching;
      return <input
          className="search-monument-input"
          placeholder="Granit(s)"
          autoFocus={true}
          onChange={event => this.setState({searching: event.target.value || ''})}
        />
    }

    if (this.state.searching !== "") this.setState({searching: ''});

    return <div className="custom-content-render">
            {`${props.values.length} granit(s) séléctionnés sur ${props.options.length}`}
          </div>;
  }

  /**
   * @param {object} granit {value: '', label: ''}
   */
  deleteItem = granit => {
    let tmpHiddenGranits = this.state.hiddenGranits;
    const index = tmpHiddenGranits.findIndex(item => item.value === granit.value);
    tmpHiddenGranits.splice(index, 1);
    this.setState({hiddenGranits: tmpHiddenGranits});
  };

  allowUpdate = () => {
    const hiddenGranits = this.state.hiddenGranits.map(item => item.value);
    const hiddenSource = this.props.hidden.map(item => item.value);
    const result = hiddenGranits
      .filter(x => !hiddenSource.includes(x))
      .concat(hiddenSource.filter(x => !hiddenGranits.includes(x)));

    if (result.length > 0) {
      return true;
    }

    return false;
  };

  render () {
    const { sourceGranits, hiddenGranits } = this.state;

    return (
      <>
        <div className="header-admin-principal">
          <i className="icon material-icons admin-bookmarks">bookmarks</i>{` Granits`}
          <i className="icon material-icons close-icon" onClick={() => this.props.close()}>close</i>

          <div className="container adminContainer granit-header">
              Cacher des granits
              <Button
                className="AdminFamilyButton"
                onClick={() => this.updateGranitList()}
                disabled={!this.allowUpdate()}
              >
                Mettre à jour
              </Button>
              <Select
                className="SelectAdmin"
                multi={true}
                isSearchable={true}
                searchable={true}
                options={sourceGranits.map(item => ({value: item.reference, label: `${item.reference} ${item.name}`}))} // catalogMonuments
                getOptionLabel={option => option.name}
                getOptionValue={option => option.reference}
                placeholder="Granit(s)"
                onChange={item => this.changeGranitList(item)}
                values={hiddenGranits} // monumentsRestrictList
                contentRenderer={this.customContentRender}
              />
          </div>
        </div>

        <ul className="admin-list-restricted">
          {hiddenGranits.map((granit, index) =>
            <div className="admin-list-wrapper" key={`item-${granit.value}`}>
              <li className="admin-list-restricted-monument">
                <i className="icon material-icons admin-reorder" style={{marginLeft: 10, marginRight: 20}} >visibility_off</i>
                <span>{`${granit.label}`}</span>
                <i className="icon material-icons delete-icon-granit" onClick={() => this.deleteItem(granit)}>
                  delete_outline
                </i>
              </li>
            </div>
          )}
        </ul>
      </>
    )
  };
};

export const AdminMainGranits = connect(
  state => ({
    adminConfig: state.adminConfig,
  }),dispatch => ({actions: bindActionCreators(adminConfigActions, dispatch),}))(AdminMainGranitsComponent);
