import {getStore} from '../store';
import * as uiActions from '../actions/uiActions'

export function addMessage(payload) {
  getStore().dispatch(uiActions.addMessage(payload))
}

export function removeMessage(payload) {
  getStore().dispatch(uiActions.removeMessage(payload))
}
