import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../actions/updateConfigurationActions';


class UndoRedoComponent extends Component {

  render() {
    // const {previousConfig,nextConfig} = this.props.configurator;
     return (
      <div className="UndoRedo ">
{/*           <button  className="SquareButton Annule" disabled = {!previousConfig || previousConfig.length===0 } title="Annuler"
           onClick={()=>this.props.actions.undo()}><img  src={require(`../../../images/annuler-icon.svg`)} alt="annuler"/>Annuler</button>
          <button className="SquareButton Restaure" disabled ={!nextConfig || nextConfig.length===0 } title="Refaire"
          onClick={()=>this.props.actions.redo()}><img  src={require(`../../../images/restaurer-icon.svg`)} alt="annuler"/>Restaurer</button> */}
      </div>
    );
  }
}

export const UndoRedo = connect((state, ownProps) => ({
    configurator: state.configurator
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(UndoRedoComponent);
