import React from 'react';
import { connect } from "react-redux";
import {emailRex} from '../../../services/utils.service';
import * as familyspaceActions from '../../../actions/familySpaceActions';
import { bindActionCreators } from "redux";
import { CheckCircle } from '@material-ui/icons';
import { Modal } from "reactstrap";
import { gtmFamily } from "../../../services/gtmFamily";

class FormInviteComponent extends React.Component {
  state = {
    open: true,
    openCss: true,
    showBigPicture: false,

    emails:[""],
    mailcheckError: false,
    mailsentError: false,
    mailFound: null,
    existingEmail: false, 
    sendingInvitation: false, 
    inviteSent: false,
    isOpen: false
  };

  handleSearchInput = ({target},index) => {
    let newEmails = [...this.state.emails];
    newEmails[index]= target.value;
    this.setState({emails:newEmails});
    let check = emailRex.test(target.value);
    let errorElem = document.getElementById(index);
    if (check || target.value===""){
      errorElem.style.display="none";
      errorElem.innerHTML = ""
      if (check){
        const { members } = this.props.familySpace;
        const existEmail = members.filter(m => m.activated && m.email === target.value);
        if (existEmail.length > 0){
          errorElem.innerHTML = "Cet e-mail est déjà membre dans un espace famille.";
          errorElem.style.display="block";
        }else{
          errorElem.style.display="none";
          errorElem.innerHTML = ""
        }
      }
    }else{
      errorElem.style.display="block";
      errorElem.innerHTML = "Format de l'email invalide.";
    }
  };

  delEmail = (index) => {
    let newEmails = this.state.emails;
    newEmails.splice(index, 1);
    this.setState({emails: newEmails})
  }

  addLine = ()=>{
    let newEmails = [...this.state.emails];
    newEmails.push("");
    this.setState({emails: newEmails});
  }

  checkMails = () =>{
    const { emails } = this.state;
    let goodEmails = [];
    if (!emails[0]){return false}
    let ind = 0;
    emails.forEach(email=>{
      ind++;
      const check = emailRex.test(email);
      if(email && !check){
        return false;
      }
      if (check){goodEmails.push(email)}
    })
    return goodEmails;
  }

  checkMailExists = async mails => {
    const { members } = this.props.familySpace;
    return await members.filter(m => m.activated && mails.find(mail => mail === m.email));
  };

  checkNewMail = async mails => {
    const { members } = this.props.familySpace;
    return await mails.filter(m => !members.some(member => m === member.email));
  };

  sendMail = async () => {
    const goodEmails = this.checkMails();
    this.setState({mailsentError: false});
    if (goodEmails) {
      const ExistEmails = await this.checkMailExists(goodEmails);
      let NewEmails = await this.checkNewMail(goodEmails);
      if (ExistEmails.length > 0 ){
        this.props.familySpaceAction.shareProject(ExistEmails,this.props.project)
        this.props.close();
      }
      if (NewEmails.length > 0 ){
        this.setState({sendingInvitation: true});
        this.props.familySpaceAction.sendInvitations(NewEmails)
          .then(response => {
            this.setState({sendingInvitation: false});
            if (response && response.length && !response[0].error){
              // GTM
              // if (this.props.from == "Smembers") {
              //   gtmFamily().ctaOfConfiguration({
              //     name: "envoyer une invitation",
              //     category: "partager",
              //     subcategory: "inviter un proche",
              //     type: "cta",
              //     from: "popup inviter un proche"
              //   }, { template: "espace famille", subtemplate: "famille" })
              // }
              // end GTM
              if (this.props.familySpace.socket){
                this.props.familySpace.socket.emit('chat', {type: 'memberActivities', room: `familyspace-${this.props.familySpace.id}`, user: this.props.familySpace.user });
              }
              this.props.familySpaceAction.getMembers();
              this.setState({inviteSent: true, isOpen: true});
              // this.closePopupAction()
            }else{
              this.setState({mailsentError: response[0].status});
            }
          });
      }
    } else {
      this.setState({mailcheckError: true});
    }
  }

  closePopupAction = () => {
    // GTMclosePopupAction
    if (this.props.from == "Smembers") {
      gtmFamily().ctaOfConfiguration({
        name: "annuler",
        category: "espace famille",
        subcategory: "inviter un proche",
        type: "cta",
        from: "popup inviter un proche"
      }, { template: "espace famille", subtemplate: "ma famille" })
    }
    // end GTM
    this.props.close()
  }

  render () {
    const { close } = this.props;
    const { mainColor, fontColor } = this.props.adminConfig.config
    const { emails, mailcheckError, mailFound, existingEmail, mailsentError, sendingInvitation, inviteSent, isOpen } = this.state;
    const modalTitle = "Invitez un proche sur cet espace";
    const BtnText = "Envoyer l'invitation";
    const txtDone = "Invitation envoyée avec succès!";

    return (
      <>
        <div className='overlay' style={{width:"100%", height:"200vh", zIndex:"9999", position:"fixed"}} onClick={() => this.props.close()}></div>
        <div className={`FormInvite`}>
          <div>
            <div className="closePanel" onClick={()=> close() }>
              <i className="icon material-icons">close</i>
            </div>

            <div className="form-invite-container">
              <div className="SBigTitleInvite">{modalTitle}</div>

              <div class="label-email">L'adresse mail</div>
              {emails.map((email, index) => 
              <>
                <div key={'frag'+index} style={{display: "flex"}}>
                  <div className="inputEmail" key={index} style={{flexGrow: 1}}>
                    <input 
                      key={'email'+index} placeholder="Entrez l'e-mail d'un proche" type="text" value={email}
                      className={`SsingleInput ${email === mailFound ? 'form-control is-invalid' : ''}`}
                      onChange={e => this.handleSearchInput(e, index)}
                    />
                  </div>
                  {emails.length>1 && <button key={'delButton'+index} onClick={()=>this.delEmail(index)} type="button" className="delEmailButton"><svg key={'svg-'+index} className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg></button>}
                </div>
                <p key={'errMsg'+index} id={index} className="errorMsg" style={{display: 'none'}}></p>
              </>
              )}

              {emails.length < 5 && <button 
                className="fsp-link addOne" 
                onClick={() => this.addLine()}>Ajouter un proche supplémentaire
              </button>}

              <div className="ActionButtons">
                {!sendingInvitation && <button 
                  style={{ "--f-main-color": mainColor, "--f-font-color": fontColor }}
                  className="fsp-button LeftOvalAction" 
                  onClick={() => this.sendMail()}>
                  {BtnText}
                </button>}
                {sendingInvitation && <span><img src={require(`../../../images/loader.gif`)} alt="Chargement"/> Envoi en cours</span>}
                {/* <button className="LeftOvalAction secondaryBtn textBtn Grey" onClick={() => this.closePopupAction()}>Annuler</button> */}
              </div>
              
              {mailcheckError && <div className="error alert alert-danger">Merci de vérifier le format de vos adresses mails</div>}
              {mailsentError && <div className="error alert alert-danger">{mailsentError}</div>}
              {existingEmail && mailFound && <div className="error alert alert-danger">L'utilisateur <span>{mailFound}</span> fait déjà parti de l'espace famille.</div>}
            </div>
          </div>
        </div>

        <Modal isOpen={isOpen} className="ModalInvite">
          <div className="Sinvite">
            { inviteSent && 
            <div className="modalBlock">
              <div className="inviteDoneBlock">
                {/*<img className="inviteDoneImg" src={require(`../../images/space/checked_001.png`)} alt=""/>*/}
                <CheckCircle/>
                <p className="inviteDoneTxt">{txtDone}</p>
                <button className="LeftOvalAction Grey" onClick={() => this.props.close()}>Fermer</button>
              </div>
            </div> 
            }
          </div>
        </Modal>
      </>
    );
  };
};

export const FormInvite = connect(state => ({
    current: state.configurator.current,
    isoview: state.isoView,
    user: state.user,
    adminConfig: state.adminConfig,
    familySpace: state.familyService,
  }), dispatch => ({
    familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
  }))(FormInviteComponent);
