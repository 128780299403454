import React from "react";
import {OvalAction} from "../components/OvalAction";

export const HoverInfo = ({hoverInfo}) => {
    return (
     <div className="HoverInfo">
          {hoverInfo.fct1 &&  <OvalAction action={hoverInfo.fct1 } text={hoverInfo.label1} arrow="right" addClass="Middle" color="Blue First"/>}
          {hoverInfo.fct2 &&  <OvalAction action={hoverInfo.fct2 } text={hoverInfo.label2} addClass="Middle" color="Second"/>}
      </div>
    );

}


