import React, {Component} from 'react';
import history from "../../history";
import * as configurationActions from '../../actions/configurationActions';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import * as familySaceActions from '../../actions/familySpaceActions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Fquote } from './Fquote';
import { Fheader } from './Fheader';
import {Ffooter} from "./Ffooter";
import {put} from '../../services/api.service';
import { OvalAction } from '../../components/OvalAction';
import {Viewer3D} from '../../components/Viewer3D';
import { Modal } from "../../components/Modal";
import * as _ from "lodash";
import * as apiService from "../../services/api.service";
import { getDesignTypeConfiguration,isFamilySpaceOn} from '../../services/utils.service';
import {isSmartphone} from '../../services/domFunctions';
import * as FileSaver from 'file-saver';
import loader from '../../images/loader@4x.gif';
import {SocialNetwork} from '../../components/SocialNetwork';
import {Alert} from "reactstrap";
import LoadConfig from '../../components/LoadConfig';
import { checkStatusFlowers } from '../../services/flower.service';
import showFlowerImg from '../../images/Ornement-ON-icon.svg';
import hideFlowerImg from '../../images/Ornement-OFF-icon.svg';

class FfinalizationComponent extends Component {

  state ={threeDOn:false,docOpen:false, urlHeadFront:false, pdfDownloading:false, pdfUrl:false, social:false,showAlert:0,
          pdfData:null,backgroundPdfDownloading: true, errorPdf: false,directDownload:false,status:"flowersDisabled",}

  componentDidMount () {
    const { clientId } = this.props.match.params;

    const directDownload = window.location.search.includes("download");
    const status = checkStatusFlowers();
    //If the url has ?download at the end a direct download in full screen is made
    this.setState({directDownload, status});

    const { configId } = this.props.match.params;
    if(!this.props.configurator){
      this.props.actions.getConfiguration(configId);
    } else {
      this.getPdfData();
    }
  }
  componentDidUpdate (prevProps,prevState) {
    const status = checkStatusFlowers();
    if (status !== this.state.status) {
      this.setState({status});
    }
    if (this.props.configurator && !prevProps.configurator) {
      this.getPdfData();
    }
    //Pdf download requested and pdf just finised downloading
    if (this.state.pdfDownloading && !this.state.backgroundPdfDownloading && prevState.backgroundPdfDownloading) {
      this.getPdfUrl();
    }
  }

  getPdfData(){
    const {reference} = this.props.configurator.configuration;
    apiService.get(`/api/get/pdf/config?type=family&configuration=${reference}`,false,false, "blob").then(result=>{
      this.setState({backgroundPdfDownloading:false,pdfData:result,errorPdf: result?false:true});
      if (this.state.directDownload){
        this.getPdfUrl();
      }
    });
  }

  Close = field =>  {
    let newState={...this.state};
    newState[field] = false;
    return(<div className="Close" onClick={()=>this.setState(newState)}>
      <span>Fermer</span>
      <i className="icon material-icons">&#xE14C;</i>
    </div>  )
  };

  checkSent = () => {
   const check = _.get(this.props.configurator, 'configuration.newfamilyInfos.emailSent');
   return check;
  };

  getPdfUrl = () => {
    const {configurator} = this.props;
    const {backgroundPdfDownloading,pdfData,} = this.state;
    if (configurator ){
      this.setState({pdfDownloading:true});
      if  (!backgroundPdfDownloading) {
      const file = new Blob([pdfData], {type: 'application/pdf'});
      if (isSmartphone() ){
        FileSaver.saveAs(file,`monument_${configurator.configuration.reference}.pdf`);
        this.setState({pdfDownloading:false});
      }else{
        const fileUrl = URL.createObjectURL(file);
        this.setState({pdfDownloading:false,pdfUrl:fileUrl,docOpen:true});
      }
      if (!configurator.configuration.stats.pdfDownloaded){
        let stats = configurator.configuration.stats;
        stats.pdfDownloaded = true;
        put(`/api/configuration/simpleupdate/${configurator.configuration.reference}`, {stats});
        // Appel uniquement pour les stats pas la peine de mettre à jour la config ni d'attendre la réponse
      }
    }
   }
  }
  pdfView = () => {
    const {configurator} = this.props;
    const {docOpen} = this.state;

    return (<Modal isOpen={configurator && docOpen} className="Pdf"
                  onRequestClose={()=>this.setState({docOpen:false,directDownload:false})}   >
              <iframe className="thePdf" src={this.state.pdfUrl} width="100%"  title="documents" height="100%" style={{border: 0, margin: 0, padding: 0}}/>
            </Modal>)
  }
  displayAlertSentMail = (result)=>{
      const typeAlert = result && result.messageId ? 1 : 2;
      this.setState({showAlert:typeAlert},()=>{window.setTimeout(()=>{this.setState({showAlert:0})},2000)});
  }

  updateConfigSocialStats = async item => {
    const { configurator } = this.props;
    let stats = configurator.configuration.stats;
    if (stats.share.includes(item)) return;
    stats.share.push(item);
    await put(`/api/configuration/simpleupdate/${configurator.configuration.reference}`, {"stats.share":stats.share});
  }

  render() {
    const {isoview,configurator,user, familyService} = this.props;
    const {threeDOn,docOpen,pdfDownloading,errorPdf,directDownload,social,showAlert,status} = this.state;
    const designType = getDesignTypeConfiguration(configurator.configuration);
    let hideFlowers = false;

    if (configurator && designType === "B") {
      const { client, reference } = configurator.configuration;
      history.push(`/famille/${client}/${reference}/customize`);
    }

    if (directDownload){
      if (!configurator || !docOpen) {
        return (<div className="directPdfDisplay">
                  Chargement  de votre document en cours
                  <img src={loader} alt="Chargement PDF"/></div>)
      }
        return this.pdfView();
    } else {
      if (configurator && configurator.configuration) {
        hideFlowers = configurator.configuration.hideFlowers;
      }
      return (
        !directDownload && <div className="Ffinalization" style={{marginTop: threeDOn===true ? 80 : 0}}>
            {designType === "A" && <Fheader match={this.props.match}/>}
            {!configurator && <LoadConfig big loading={this.props.loading} />}
            {configurator && <div>
            { threeDOn &&   <div>
              <Viewer3D configuration={configurator.configuration} />
              {this.Close("threeDOn")} </div>}
            <div className="TopBar" >
              {(!familyService.familySpace || !familyService.user) && isFamilySpaceOn(this.props.user) &&
                <OvalAction action={() => this.props.familySaceAction.openLoginModal()} text="Sauvegarder dans mon espace famille" frontIcon="save" color="Blue" />
              }
                {!errorPdf &&
                <OvalAction action={()=>this.getPdfUrl()} text={pdfDownloading?"Téléchargement...":"Télécharger"} color= {pdfDownloading?"Grey":"Blue"} frontIcon={pdfDownloading?"":"download"} />
              }
              <OvalAction action={()=>this.setState({threeDOn:true})} text={"Visualiser en 3D"}  color="Blue" frontIcon="3d_rotation" />
              <OvalAction action={()=>this.setState({social:!this.state.social})} text={"Partager"} color="Blue" frontIcon="share" />
              {status !== "flowersHiddenForOrg" && status !== "flowersNotAvailable" && <OvalAction
                action={()=> this.props.updateConfigurationAction.changeFlowerDisplay(!hideFlowers)}
                text={!hideFlowers ? "Cacher les fleurs" : "Afficher les fleurs"}
                color="Blue"
                frontIcon={!hideFlowers ? hideFlowerImg : showFlowerImg}
                image={true}
              />}
              {social && <SocialNetwork callback={this.displayAlertSentMail} getSocialStats={item => this.updateConfigSocialStats(item)}/>}
            </div>
            <div className="LowerPart">
              <div className="ContentFin">
                  {this.pdfView()}
                  {isoview.loading ?
                    <div className="Loading">Regénération du visuel en cours</div> :
                    !threeDOn && <img className="monumentImg" src={isoview.current} alt=" monument"/>
                  }
                <Fquote config={this.props.configurator} user={user} />
              </div>
            {!this.checkSent() && <Ffooter previous={()=>history.push(`./faccessoire`)} addClass="Wide" />}
              </div>
            { showAlert === 1 && <Alert color="success">Votre mail a bien été envoyé</Alert>}
            { showAlert === 2 && <Alert color="danger">Erreur sur envoi de mail</Alert>}
            </div>
            }
        </div>
      );

      }
    }
}

export const Ffinalization = connect(
  (state) => ({
    configurator: state.configurator.current,
    loading: state.configurator.loading,
    user: state.user,
    isoview: state.isoView,
    familyService: state.familyService,
  }),  (dispatch) => ({
    actions: bindActionCreators(configurationActions, dispatch),
    updateConfigurationAction: bindActionCreators(updateConfigurationActions, dispatch),
    familySaceAction: bindActionCreators(familySaceActions, dispatch),
  })
)(FfinalizationComponent);
