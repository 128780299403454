import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {imgs} from '../services/images.service';
import * as _ from 'lodash';

/**
 * Composant de visualisation 3D
 */
export class Viewer3D extends Component {
  static propTypes = {
    monument: PropTypes.object, // Monument
    configuration: PropTypes.object, // Configuration
    autoplay: PropTypes.bool, // Tourne tout seul
    showImagesOnLoad: PropTypes.bool, // Affiche les images au fur et à mesure du téléchargement
  };

  viewerId = _.uniqueId('viewer3d-');
  currentViewer = null;

  state = {
    images: [], // Images à charger
    loading: -1, // Du nombre d'images total à 0
  };

  initViewer = _.once(() => {
    let thisComponent = this;

    thisComponent.currentViewer = new window.FWDViewer({
      //----main----//
      divHolderId: this.viewerId,
      playListAndSkinId: this.viewerId + '-playlistAndSkin',
      displayType: "responsive",
      preloaderText: "Chargement...",
      startDraggingMode: "rotate",
      showLargeImageVersionOnZoom: "yes",
      useEntireScreenFor3dObject: "yes",
      stopRotationAtEnds: "no",
      addCorrectionForWebKit: "yes",
      addDragAndSpinSupport: "yes",
      startAtImage: 0,
      imageWidth: 1920,
      imageHeight: 1080,
      zoomFactor: 2,
      dragRotationSpeed: .7,
      dragAndSpinSpeed: .6,
      buttonsRotationSpeed: 300,
      slideShowDelay: 25,
      backgroundColor: "#FFFFFF",
      preloaderFontColor: "#929298",
      preloaderBackgroundColor: "#FFFFFF",
      //----controller----//
      buttons: 'scrollbar',
      slideShowAutoPlay: this.props.autoplay ? 'yes' : 'no',
      hideControllerDelay: 0.0001,
      controllerBackgroundOpacity: 0.0001,
      controllerMaxWidth: 0.0001,
      //----navigator----//
      showNavigator: "no",
      //----context menu----//
      showScriptDeveloper: "no"
    });

    let self = thisComponent.currentViewer;

    self.dataLoadComplete = function () {
      // no preloader
      // no context menu
      self.setupImageManager();
      self.setupDisableMarkersDumy();
      self.setupController();
    };
    self.setupPreloader = function (e) {
      // no preloader
    };
    self.setupNavigator = function () {
      // no navigator
    };
    self.setupController = function () {
      // light controller
      window.FWDController.setPrototype();
      self.controller_do = new window.FWDController(self.data, self);
      self.controller_do.addListener(window.FWDController.CHANGE_NAVIGATION_STYLE, self.setImageViewerNavigationStyleHandler);
      self.controller_do.addListener(window.FWDController.GOTO_NEXT_OR_PREV_IMAGE, self.gotoNextImageHandler);
      self.controller_do.addListener(window.FWDController.GOTO_NEXT_OR_PREV_IMAGE_COMPLETE, self.gotoNextImageCompleteHandler);
      self.controller_do.addListener(window.FWDController.START_SLIDE_SHOW, self.controllerStartSlideshowHandler);
      self.controller_do.addListener(window.FWDController.STOP_SLIDE_SHOW, self.controllerStopSlideshowHandler);
    };
    self._parent_setupImageManager = self.setupImageManager;
    self.setupImageManager = function (id) {
      let self = thisComponent.currentViewer;
      self._parent_setupImageManager(id);
      self = self.imageManager_do;
      self.showLoadedImage = function (id) {
        self.smallImage_sdo = new window.FWDSimpleDisplayObject("img");
        self.smallImage_sdo.setScreen(self.images_ar[id]);
        if (window.FWDUtils.isAndroid) self.smallImage_sdo.setBackfaceVisibility();
        self.smallDos_ar[id] = self.smallImage_sdo;
        self.mainImagesHolder_do.addChild(self.smallImage_sdo);
        if (thisComponent.props.showImagesOnLoad) {
          self.removeSmallSDOId_to = setTimeout(function () {
            if (self == null) return;
            if (id > 0) self.smallDos_ar[id - 1].setVisible(false);
          }, 40);
        } else {
          self.smallImage_sdo.setVisible(false);
          self.smallDos_ar[0].setVisible(true);
          self.curId = 0;
          self.smallImage_sdo = self.smallDos_ar[0];
        }

        self.resizeAndPosition();
        self.resiezeAndPositionIfNotAllImagesAreLoaded();
      };
    };
    self.onPreloaderHideCompleteHandler = function () {
      // no preloader
    };
    self.dataSkinProgressHandler = function (e) {
      // no preloader call
    };
    self.dataImagesProgressHandler = function (e) {
      // no preloader call
    };
    self.firstImageLoadComplete = function () {
      // prevent alpha on images
      self.imageManager_do.setAlpha(0);
      window.TweenMax.to(self.imageManager_do, .2, {alpha: 1});

      // register rotate/pan mode change on zoom
      var initScale = self.imageManager_do.currentScale;
      self.imageManager_do.addListener(window.FWDImageManager.IMAGE_ZOOM_COMPLETE, function () {
        if (self.imageManager_do.currentScale > initScale) {
          self.pan();
        } else {
          self.rotate();
        }
      });
    };
    self.onImageLoad = function (e) {
      self.imageManager_do.showLoadedImage(e.id);
    };
    self.dataImagesLoadComplete = function () {
      self.activateWithDelayImagemanagerId_to = setTimeout(function () {
        self.imageManager_do.activate();
      }, 0);
      if (self.data.hideController_bl) {
        self.setupHider();
        self.controller_do.setupHider(self.hider);
        self.imageManager_do.setupHider(self.hider);
        if (self.navigator_do) self.navigator_do.setupHider(self.hider);
        self.startHiderWithDelayId_to = setTimeout(function () {
          self.hider.start();
        }, self.data.hideControllerDelay);
      }
      if (self.customContextMenu) self.customContextMenu.isActive_bl = true;
      if (self.navigator_do) self.navigator_do.activate();
      self.setupDescriptionWindow();
      self.setupRotationDumy();
      self.setupPanDumy();
      if (self.data.slideShowAutoPlay_bl) self.controller_do.startSlideshow();
      self.safeToControll_bl = true;

      if (window) {
        window.addEventListener('resize', function () {
          window.setTimeout(function () {
            self.resizeHandler(true)
          }, 600);
        });
      }
    };
  });

  /**
   * Charge les grosses images (après la fin du viewer).
   */
  loadLargeImages = () => {

    if (this.currentViewer.data){
        this.state.images.forEach((images, index) => {
          this.currentViewer.data.largeImagesPaths_ar[index] = images.large;
        });
      }
  };

  zoomIn = async () => {
    //On ne récupère qu'une seule image
    const {curId} = this.currentViewer.imageManager_do;
    const image =  await (imgs.configuration['3d-image'](this.props.configuration,curId));
    this.currentViewer.data.largeImagesPaths_ar[curId] = image[0].large;
    this.currentViewer.imageManager_do.zoomInOrOutWithScrollBar(1);
  };

  zoom = (percent) => {
    this.currentViewer.imageManager_do.zoomInOrOutWithScrollBar(percent);
  };

  zoomOut = () => {
    this.currentViewer.imageManager_do.zoomInOrOutWithScrollBar(0);
  };

  onImageLoaded = () => {
    const loading = this.state.loading - 1;

    this.setState({
      loading,
    });

    // Dès le chargement de la première image
    if (loading < this.state.images.length) {
      this.initViewer();
      this.loadLargeImages();
    }
  };

  componentDidMount() {
    ( imgs.configuration['3d'](this.props.configuration))
      .then((images) => {
        const pureImages = images.map(image=>image.small);
        this.setState({images: pureImages,loading: pureImages.length});
       });
  };

  render() {
    const { designType } = this.props.configuration;
    return (
      <div
        className={'Viewer3DContainer ' + (this.state.images.length > 1 ? 'ThreeDMode' : 'ImageMode') +
        (this.state.loading ? (' Loading' +
          ((this.state.loading === this.state.images.length ||
          this.state.loading === -1) ? ' LoadingFirstImage' : '')) : '')}>
        <section className="Viewer3D" id={this.viewerId}>
          <div id={this.viewerId + '-playlistAndSkin'} style={{display: 'none'}}>
            <ul data-skin="">
              <li
                data-hand-move-path={`/vendor/easy360/skin/empty/cursor-${ this.state.images.length === 1 ? 'move-norotate' : 'move' }.png`}></li>
              <li data-hand-grab-path="/vendor/easy360/skin/empty/cursor-grab.png"></li>
              <li
                data-hand-rotate-path={`/vendor/easy360/skin/empty/cursor-${ this.state.images.length === 1 ? 'move-norotate' : 'rotate' }.png`}></li>
              <li data-preloader-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-main-lightbox-close-button-normal-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-main-lightbox-close-button-selected-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-controller-background-left-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-controller-background-center-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-controller-background-right-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-pan-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-pan-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-rotate-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-rotate-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-next-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-next-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-prev-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-prev-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-play-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-play-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-pause-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-pause-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-info-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-info-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-link-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-link-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-fullscreen-full-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-fullscreen-full-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-fullscreen-normal-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-fullscreen-normal-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-zoomin-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-zoomin-button-slected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-zoomout-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-zoomout-button-slected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-scrollba-background-left-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-scrollbar-background-middle-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-scrollbar-background-right-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-scrollbar-handler-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-controller-scrollbar-handler-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-button-tooltip-bottom-pointer-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-button-tooltip-top-pointer-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-button-tooltip-left-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-button-tooltip-middle-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li data-button-tooltip-right-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-info-window-close-button-normal-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
              <li
                data-info-window-close-button-selected-state-path="/vendor/easy360/skin/empty/empty.gif"></li>
            </ul>

            <ol data-paylist>
              {this.state.images.map(image => (
                <li key={image}>
                  <img data-small-image-path={image} alt="petite"/>
                  <img data-large-image-path={image} alt="grande"/>
                  <img data-navigator-image-path={image} alt="petite"/>
                </li>
              ))} 
            </ol>
          </div>
        </section>

        {/* On lance toutes les images en parallèle directement */}
        {this.state.images.length && this.state.loading &&
        _.map(this.state.images, (image, index) => (
          <figure className="FullSize" key={image}
                  style={(index || !this.state.loading) ? {visibility: 'hidden'} : {zIndex: 100}}>
            <img src={image} crossOrigin="anonymous"
                 onLoad={this.onImageLoaded} onError={this.onImageLoaded} alt="petite"/></figure>
        ))}

        <div className="Zoom">
          <button className="ZoomIn" onClick={this.zoomIn} title="Zoom +" disabled={this.state.loading}>
            <i className="icon material-icons">&#xE8FF;</i>
          </button>
          <button className="ZoomOut" onClick={this.zoomOut} title="Zoom -"
                  disabled={this.state.loading}>
            <i className="icon material-icons">&#xE900;</i>
          </button>
        </div>

        {designType === "B" &&
          <div className="advice-message">
            <span>Tourner et visualiser votre monument à 360°</span>
          </div>
        }

        <div className="LoadingMessage">
          <strong>Génération du rendu...</strong>
          <small>Cette opération peut prendre plusieurs secondes</small>
        </div>

        {
          this.state.images.length === 1 && (
            <span className="No360Message">La vue 360° est désactivée pour ce monument.</span>
          )
        }

        <span className="ViewerLoadingIcon">{this.state.loading ? 'Chargement...' : (
            <span>{Math.round((1 - (this.state.loading / this.state.images.length)) * 360)}°</span>)}
        </span>
        <span className="ViewerLoadingBar"
              style={{width: (1 - (this.state.loading / this.state.images.length)) * 100 + '%'}}/>
      </div>
    );
  }
}
