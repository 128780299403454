import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ModalButton} from '../../../components/ModalButton';
import {checkDisplaySimpleConfig, getReactAppExtranet} from "../../../services/utils.service";
import { TranslationContext } from "../../../context/TranslationContext";

function getDocumentsUrl(object) {
  if (object.quoteId) {
    return getReactAppExtranet(`/documents/${object.client}/${object.folderId}/${object.quoteId}/${object.reference}`)
  } else {
    return getReactAppExtranet(`/pro-space/configurations/${object.reference}/documents`)
  }
}

class DocumentsComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    configuration: PropTypes.object.isRequired,
  };

  modal;

  render() {
    const t = this.context;
    const additionalInfo = t("config3d_actionbuttons_documents") || "Docs.";
    const { isFinalizationStep = false, configuration} = this.props;
  
    const commonModalButtonProps = {
      buttonIcon: "documents-icon",
      buttonClassName: `${isFinalizationStep 
        ? "btn-config-secondary text-dark config-button-background config-button-hover mr-3" 
        : "btn-config-secondary text-dark background-page secondary3-hover text-dark-border"}`,
      children: (
        <iframe
          width="100%"
          title="documents"
          height="100%"
          style={{ border: 0, margin: 0, padding: 0, borderRadius: "15px"}}
          src={getDocumentsUrl(configuration)}>
        </iframe>
      ),
    };
  
    return (
      <div className="Documents btn-wrapper">
        {!isFinalizationStep && !checkDisplaySimpleConfig(configuration) ? (
          <ModalButton
            buttonContent="Espace Documents"
            className="DocumentsModal"
            overlayClassName="DocumentsModalOverlay"
            additionalInfo={additionalInfo}
            {...commonModalButtonProps}
          />
        ) : (
          <ModalButton
            buttonContent={additionalInfo}
            {...commonModalButtonProps}
          />
        )}
      </div>
    );
  }
}

export const Documents = connect((state, ownProps) => ({
    configuration: state.configurator.current.configuration,
  })
)(DocumentsComponent);
