import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

const InputRange = ({ value, onValueChange, step, ...others }) => {
  const formatNumber = (number) => {
    return _.isNumber(number) ? number.toString() : '';
  };

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(formatNumber(value));

  const {min:minValue, max:maxValue} = others;

  useEffect(() => {
    if (!focused) {
      setInputValue(formatNumber(value));
    }
  }, [value, focused]);

  /**
   * La méthode retourne undefined si l'utilisateur a saisi un champ vide, NaN s'il a saisi un nombre
   * incorrect et
  */
  const parseNumber = (event) => {
    if (event.target.validity && !event.target.validity.valid) {
      // Si le champ est vide, on retourne undefined
      return NaN;
    }

    const value = event.target.value.replace(',', '.');
    if (!value.length) {
      return undefined;
    }
    if (!/^(-|\+)?([0-9]+(\.[0-9]+)?)$/.test(value)) {
      // La valeur saisie n'est pas un nombre
      return NaN;
    }

    const number = Number(value);

    if ((!_.has(others, 'min') || number >= others.min) && (!_.has(others, 'max') || number <= others.max)) {
      return number;
    } else {
      // La valeur saisie ne respecte pas le range spécificé
      return NaN;
    }
  };

  const onChange = (event) => {
    setInputValue(event.target.value);
    const number = parseNumber(event);
    if (!_.isNaN(number) && number !== value) {
      onValueChange(number);
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
    setInputValue(formatNumber(value));
  };

  const progressRef = useRef(null);
  useEffect(() => {
    const progress = progressRef.current;
    if (progress) {
      const min = minValue || 0;
      const max = maxValue || 100;
      const percentage = ((inputValue - min) / (max - min)) * 100;
      progress.style.background = `linear-gradient(to right, var(--secondary1) 0%, var(--secondary1) ${percentage}%, #e2e2e2 ${percentage}%, #e2e2e2 100%)`;
    }
  }, [inputValue, minValue, maxValue]);


  return (
    <div className="range-slider">
      <input
        ref={progressRef}
        className="range"
        type="range"
        min={minValue}
        max={maxValue}
        step={step || 0.5}
        value={inputValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...others}
      />
      <input
        type="text"
        className="range-value"
        value={inputValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        min={minValue}
        max={maxValue}
        step={step || 0.5}
      />
    </div>
  );
};

InputRange.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func,
  step: PropTypes.number,
};

export default InputRange;