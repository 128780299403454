import * as actionTypes from '../actions/actionTypes';

const initialState = { config:{
  "catalogListMonuments": [],
  "mainColor": "#FF9D1E", // default color
  "fontColor": "#001F2A", // default color
  "engravingsToHide": [],
  "granitesToHide": [],
  "catalogBypassCarousel": false,
}, clientId:""};

export default function (state = initialState, {type, payload}) {
  switch (type) {

    case actionTypes.ADMIN_CONFIG_UPDATE_CONFIG_ELT:
      const key = Object.keys(payload);
      state.config[key[0]] = JSON.parse(JSON.stringify(payload[key[0]]));
      return state

    case actionTypes.ADMIN_CONFIG_GET_INIT_CONFIG:
      if(payload){
        return Object.assign({}, state, {config: payload.config,clientId: payload.clientId});
      }
      return state;

    default:
      return state; 
  }
}
