import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import { isMonumentTombale } from "../../../services/utils.service";
import * as _ from "lodash";
import { OptionPanel } from "../components/OptionPanel";
import { isConfigRequested } from "../../../services/configurationWarningChecks"
import { TranslationContext } from "../../../context/TranslationContext";

class BaseHeightOptionPanelComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    baseHeight: PropTypes.string.isRequired,
    baseHeights: PropTypes.array.isRequired
  };

  onSelectItem = baseHeight => {
    this.props.actions.updateBaseHeight(baseHeight);
  };
  getList = () => {
    const layout = this.props.monument.layout;
    const baseHeights = this.props.baseHeights.filter(item=>(item.configuration === layout)).map(item =>( item.baseHeight));
    return baseHeights;
  };
  getCurrentBH = (list) => {
    if (list.some(item=> item === this.props.baseHeight)){
      return this.props.baseHeight
    }
    else if (list.length>0){
      // Ce truc est super important car dès le début on met à jour la configuraiton avec le premier élèment de la liste   
      this.props.actions.updateBaseHeight(list[0]);
      return list[0]
    }
    return "";

  };

  render() {
    const t = this.context;
    const baseHeightsList = this.getList();
    const currentBaseHeght = this.getCurrentBH(baseHeightsList);
    const disabled = !(baseHeightsList.length && baseHeightsList[0] !== "0") || isConfigRequested()
    return (
      <>
      {!this.props.hideDisplay &&  <OptionPanel
        closeOnClickInside={true}
        className="BaseHeightOptionPanel"
        buttonContent={
          isMonumentTombale(this.props.monument) ? "Épaisseur" : t("config3d_monument_button_base") || "Soubassem."
        }
        buttonIcon="base-height-icon"
        buttonClassName={`btn-config-secondary text-dark config-button-background ${!disabled && 'config-button-hover'} `}
        showValue = {this.props.baseHeight}
        disabled={disabled}
      >
        {baseHeightsList.map(baseHeight => (
          <button
            key={baseHeight}
            onClick={() => this.onSelectItem(baseHeight)}
            className={cn("OptionPanelSquareItem secondary3-hover", {
              active:
                parseInt(currentBaseHeght, 10) === parseInt(baseHeight, 10) // == pour matcher string et number
            })}
          >
            {baseHeight}
          </button>
        ))}
      </OptionPanel>}
      </>
    );
  }
}

export const BaseHeightOptionPanel = connect(
  (state, ownProps) => ({
    monument: _.get(state, "configurator.current.configuration.monument"),
    baseHeight: _.get(
      state,
      "configurator.current.configuration.monument.base.height"
    ),
    baseHeights: state.configurator.current.options.layoutsBaseHeights
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(BaseHeightOptionPanelComponent);
