import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { Modal} from "../../components/Modal";
import history from "../../history";
import * as familyspaceActions from '../../actions/familySpaceActions';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import {postFs} from '../../services/api.familyspace.service';
import {ScreateSpace} from "./create_space/ScreateSpace";
import {Alert,Form,FormFeedback,FormGroup,Label,Input} from 'reactstrap';
import {emailRex} from "../../services/utils.service";
import clientLogo, {ClientLogo} from "../configurator/ClientLogo";
import { gtmFamily } from '../../services/gtmFamily';

class SFamilyLoginFormComponent extends React.Component {
  state = {
    username: '',
    password: '',
    openCreate: false,
    error:false,
    emailValid:true,
    forgotten:false,
    notify:false,
    errorRenew:false,
    waitingRenew: false
  };

  goToUrl(){
    if(this.props.url){
      history.push(this.props.url);}
    else{
      this.props.goTo()}
  }

  login = e => {
    e.preventDefault();
    const {username, password} = this.state;
    const {save}  = this.props;
    this.props.familySpaceAction.login(username, password)
    .then(async response => {
      if (response && !response.err && !response.error) {
         /** LOG IS OK => if save do it and redirect to familySpace */
        if (save){
          //save config

          await this.props.updateConfigurationAction.setNewfamilyInfosEmail(username);

          const config = this.props.configurator && this.props.configurator.current && this.props.configurator.current.configuration ? this.props.configurator.current.configuration : null;

          const data = {config_id: config.reference, space_owner: this.props.familyService.familySpace.id, name: '', origin:'familyConfig', user_id:this.props.familyService.familySpace.member_id}
          const projects = await this.props.familySpaceAction.postProject(data);
          this.goToUrl();
        }else {this.goToUrl()}
      } else {
        /** LOG FAILED */
        let error = response && response.error ? response.error: response.err ? response.err : "Une erreur est survenue";
        error =  (typeof error === 'string' || error instanceof String) ? error : "Une erreur est survenue";
        this.setState({error});
      }
    })
  };

  validateEmail(email) {
    if (email) {
      const emailValid = emailRex.test(email) ? true: false;
      this.setState({ emailValid })
    }
  }

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  };

  forgottenPasswordLink = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "mot de passe oublié",
      category: "espace famille",
      subcategory: "login mot de passe oublié",
      type: "lien",
      from: "login"
    }, { template: "espace famille", subtemplate: "login" })
    // end GTM
    this.setState({forgotten:true, error:false,notify:false});
  }

  createAccountLink = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "vous ne possédez pas encore d'espace famille - créez-en un",
      category: "espace famille",
      subcategory: "login création espace",
      type: "lien",
      from: "login"
    }, { template: "espace famille", subtemplate: "login" })
    // end GTM
    this.setState({openCreate:true})
  }

  renewPassword = async () => {
    this.setState({waitingRenew:true});
    const result = await postFs(`/api/familyspace/renew`,{login:this.state.username});
    this.setState({waitingRenew:false});
    if (result && !result.error){
      this.setState({notify:"L'email du réinitialisation du mot de passe a bien été envoyé",forgotten:false})
    }
    else{
      const error = "Une erreur est survenue";
      this.setState({error,forgotten:false});
    }
  }

  renewPasswordCloseAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "annuler",
      category: "espace famille",
      subcategory: "login",
      type: "cta",
      from: "login mot de passe oublié"
    }, { template: "espace famille", subtemplate: "login" })
    // end GTM
    this.setState({forgotten:false});
  }

  loginCloseAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "annuler",
      category: "espace famille",
      subcategory: "login",
      type: "cta",
      from: "login création espace"
    }, { template: "espace famille", subtemplate: "login" })
    // end GTM
    this.props.close(); 
  }

  render () {
    const { close,familyService } = this.props;
    const { username, password, openCreate, error, emailValid, forgotten, notify, waitingRenew } = this.state;
    const origin = familyService.loginModal ? "familyConfig" :"familySpace"; // On verifie si il y a une config à
    const { mainColor, fontColor } = this.props.adminConfig.config

    // sauvegarder au bout
    return (
      <div className="sfamilyspace-login">
        {/*<img className="clientLogo" src={logo} alt="Logo" />*/}
        {/* <ClientLogo replaceType={'grey'} /> */}
        <h2>{forgotten ? "Mot de passe oublié ?" : "Accéder à votre espace famille"}</h2>
        { error && !notify && <Alert color="danger"><span>{error}</span></Alert>}
        { notify && <Alert color="success"><span>{notify}</span></Alert>}
  { !forgotten && <Form className="sfamilyspace-login-form">
          <FormGroup>
                <Label className="required">Adresse email :</Label>
                <Input name="username" type="text" placeholder="Ex: contact@mail.com" value={username}
                 onBlur={e => this.validateEmail(e.target.value)} invalid={!emailValid}
                 onChange={e => this.handleChange(e)} />
                <FormFeedback > Email non valide</FormFeedback>
          </FormGroup>
          <FormGroup>
                <Label className="required">Mot de passe :</Label>
                <Input name="password" type="password" placeholder="************" value={password} onChange={e => this.handleChange(e)} />
          </FormGroup>

          <div className="sfamilyspace-forgot-password">
            <div className="fsp-link lostpassword" onClick={()=>this.forgottenPasswordLink()}>Mot de passe oublié ?</div>
          </div>
          <div className="sfamilyspace-login-buttons">
            <button className="fsp-button login" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={this.login}><strong>ACCÉDER À VOTRE ESPACE</strong></button>
            <button className="fsp-button outline" onClick={(event) => {event.preventDefault(); this.createAccountLink(); }}>Pas encore d'espace ? Créez-en un.</button>
            {/* <button className="LeftOvalAction Grey cancel" onClick={() => this.loginCloseAction()}>Annuler</button> */}
          </div>

          <div className="sfamilyspace-login-footer">
            {/* <div className="login-links" onClick={()=> this.createAccountLink()}>
              Vous ne possédez pas encore d'espace famille ? Créez-en un
            </div> */}
          
            <Modal className="FamilyCreateSpaceModal" isOpen={openCreate} onRequestClose={() => this.setState({openCreate: false})}>
              <ScreateSpace origin={origin} close={() => this.setState({openCreate:false})}/>
            </Modal>
          </div>
        </Form>}

        { forgotten && <div className="renewPassword" >
            <Label className="required">Envoyer un nouveau mot de passe</Label>
            <Input name="username" type="text" placeholder="adresse mail" value={username}
                 onBlur={e => this.validateEmail(e.target.value)} invalid={!emailValid}
                 onChange={e => this.handleChange(e)} />
            <FormFeedback > Email non valide</FormFeedback>
            {emailValid &&!waitingRenew && <button className="fsp-button login" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={()=>this.renewPassword()}>Envoyer</button>}
            <button className="fsp-button cancel" style={{borderRadius: "35px",backgroundColor: "#F6F6F6"}} onClick={() => this.renewPasswordCloseAction()}>Annuler</button>
            {emailValid && waitingRenew &&  <div className="WaitRenew"><img src={require(`../../images/loader.gif`)} alt="Chargement" /> Envoi en cours</div>}
            </div>}
      </div>
    );
  };
};

export const SFamilyLoginForm = connect(state => ({
  configurator: state.configurator,
  familyService: state.familyService,
  adminConfig: state.adminConfig
}), dispatch => ({
    familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
    updateConfigurationAction: bindActionCreators(updateConfigurationActions, dispatch),
  })
)(SFamilyLoginFormComponent);
