import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from '../../components/Modal';
import * as familyspaceActions from '../../actions/familySpaceActions';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import {Sproject} from "./Sproject";
import { ScompareProjects } from "./compare/ScompareProjects";
import { Row, Col, Button } from 'reactstrap';
import {  Folder } from '@material-ui/icons';
import {CircularProgress}  from '@material-ui/core';
import { isSmartphone } from "../../services/domFunctions";
import {LeftOvalAction} from "../../components/LeftOvalAction";
import {informFamily, checkMaxSize} from './utils/utils';
import * as _ from "lodash";
import MediaQuery from "react-responsive/src";
// import loadingPicto from "../../images/loader-config-500-new.gif";
import loadingPicto from "../../images/icons-config-famille/loading.svg";
import { gtmFamily } from "../../services/gtmFamily";

class SlistProjectsComponent extends Component {
  state = {
    searchInput: "",projects:[],selectedProjects:[],fullImage: false,displayAddButton: false,buttonSpinner: false,
    modalVisible: false,quoteName: "",priceQuote: "", quoteFile: null,
    imageFiles: [],errorImg: false,errorImgMessage: "",errorQuote: false,errorQuoteMessage: "",
  };


   async componentDidMount () {
      await this.props.familySpaceAction.getFiles();
      let projects = await this.props.familySpaceAction.getProjects();
      // GTM
      const { page } = this.props;
      if (page === "compare") {
        gtmFamily().pageDispayFamilySpace("compare", []);
      }
      // end GTM
     await this.props.familySpaceAction.getOrgsInfos(projects); // get infos from the different clients for price display
      if (projects.length){
        this.setState({projects,selectedProjects:[...projects]});
      }
     this.startListening();
  }


  async componentDidUpdate (prevProps) {
    // GTM
    // Onload projects condiftion
    const prosProjects = _.get(this.props, "familyService.projects", []).filter(p => p.archived == 0);
    const prevProsProjects = _.get(prevProps, "familyService.projects", []).filter(p => p.archived == 0);
    // 
    const projectsLoaded = prosProjects.filter(project => project.loading === undefined || project.loading === true).length == 0 ? true : false;
    const prevProjectsLoaded = prevProsProjects.filter(project => project.loading === undefined || project.loading === true).length == 0 ? true : false;
    // end Onload projects condiftion
     
    if (prevProps.page !== this.props.page || projectsLoaded !== prevProjectsLoaded) {
      const prosProjects = _.get(this.props, "familyService.projects", []).filter(p => p.archived == 0);
      if ( prosProjects.length > 0 ) {
        const projectsLoaded = prosProjects.filter(project => project.loading === undefined || project.loading === true).length == 0 ? true : false;
        if ( projectsLoaded ) {
          if (this.props.page === "projects") {
            gtmFamily().pageDispayFamilySpace("projects", prosProjects.filter(p => p.archived == 0));
          }
          else if (this.props.page === "compare") {
            let selectedProjects = []
            this.props.familyService.selectedProjects.map(spId => {
              const found = prosProjects.find(p => p.id == spId);
              if (found) {
                selectedProjects.push(found)
              }
            });
            gtmFamily().pageDispayFamilySpace("compare", selectedProjects);
          }
        }
      }
    }
    // end GTM
    const propsProjects = this.props.familyService.projects;
    const projects = this.props.familyService.projects // const projects = this.state.projects;
    if (projects){
        if (_.get(this.props,"match.params.projectId") && projects.length){// Si on a demandé à arriver sur un projet précis on l'affiche
        const found = projects.find(project=>project.archived === 0 && project.id === parseInt(this.props.match.params.projectId,10));
        if (found  && found.id !== this.state.fullImage){
          this.setState({fullImage:found.id})
          // GTM
            const foundFromProps = propsProjects.find(project=>project.archived === 0 && project.id === parseInt(this.props.match.params.projectId,10));
            if ( foundFromProps ) {
              gtmFamily().pageDispayFamilySpace("project", [], foundFromProps);
            }
          // end GTM
        }
      }else if (this.state.fullImage !== false){this.setState({fullImage:false})}
    }
    this.startListening();
  }
  startListening = () =>{
    if (this.props.familyService.socket && !this.state.socket){
      this.setState({socket:true});
      this.props.familyService.socket.on('chat', data => {
        if (data && data.id !== this.props.familyService.user.id){
          if (data.project_id && data.project){
            this.props.familyspaceActions.updateOneProject(data.project_id, data.project);
          }
          this.componentDidMount()
        }
      });
    }
  }

  cancelAction = () => {
    this.setState({quoteName: "",priceQuote: "",imageFiles: [],quoteFile: null,errorImg: false,errorImgMessage: "",
                    errorQuote: false,errorQuoteMessage: "",buttonSpinner: false,modalVisible: false,
});
  };

  handleSearchInput = ({target}) => {
    const selectedProjects = this.props.familyService.projects.filter(project => project.name.toLowerCase().includes(target.value.toLowerCase()));
    this.setState({selectedProjects, searchInput: target.value});
  };

  refreshList = () => {
    this.setState({ selectedProjects:[...this.props.familyService.projects]})
  };

  setFullImageIndex = idProject => {
    // GTM
    let selectedProject, selectedProduct;
    const projects = _.get(this.props, "familyService.projects", [])
    const found = projects.find(p => p.id == idProject)
    if (found) {
      const monument = _.get(found, "config.configuration.monument", {});
      const granites = _.get(found, "config.options.granites", []);
      const graniteMain = granites.find(g => g.reference == monument.graniteMain)
      const graniteSecondary = granites.find(g => g.reference == monument.graniteSecondary)
      selectedProject = {
        id: found.id,
        name: found.name,
        spaceId: found.space_id,
        reference: found.config_id,
        price_from: found.price || 0
      }
      selectedProduct = {
        id: monument.productId,
        name: "", // empty
        reference: monument.reference,
        price_from: 0, // empty
        granits: [
          graniteMain ? graniteMain.name : "" , 
          graniteSecondary ? graniteSecondary.name : ""
        ],
        styles: [], // second filter <--
        couleurs: [], // empty
        religions: [], // empty
        types: [], // main filter <--
        granit_main: graniteMain ? graniteMain.name : "",
        flower_button: "", // empty
        v360_button: true
      }
    }
    gtmFamily().ctaOfConfiguration({
      name: "détail",
      category: "espace famille",
      subcategory: "voir le tarif",
      type: "picto",
      from: "liste projets"
    }, 
    { 
      template: "espace famille", 
      subtemplate: "liste projets" 
    }, 
    { 
      product: selectedProduct, 
      project: selectedProject 
    }, "list")
    // end GTM
    this.props.history.push(`/famille/${this.props.match.params.clientId}/familyspace/project/${idProject}`)
  };

  toggleAddButton = () => {
    this.setState(state => ({displayAddButton: !state.displayAddButton}));
  }


  postQuote = async () => {
    const { imageFiles, quoteFile, quoteName, priceQuote } = this.state;
    const author = this.props.familyService.user;
    let files = [];
    const nameQuote = quoteFile && quoteFile.name? quoteFile.name : "";
    files = quoteFile ? [...imageFiles,quoteFile] : [...imageFiles];

    const dataFiles = await Promise.all([].map.call(files, function (file) {
      return new Promise(function (resolve, reject) {
          var reader = new FileReader();
          reader.onloadend = function () {
              const context = file.name === nameQuote ? 'project_quote_manual': 'project';
              resolve({ data: reader.result, infos: {context, originalName: file.name, size: file.size, mimeType: file.type}, author });
          };
          reader.readAsDataURL(file);
      });
    })).then(results => results);

    if (dataFiles) {
      const dataQuote = {config_id: null,name: quoteName,price: priceQuote,
        space_owner: this.props.familyService.familySpace.id,files: dataFiles};

      const projects = await this.props.familySpaceAction.postProject(dataQuote);
      if (projects) {
        informFamily(this.props.match.params.clientId);
        await this.props.familySpaceAction.getFiles();
        this.setState({modalVisible: false, priceQuote: "", quoteFile: null, imageFiles: [], quoteName: "",
                       buttonSpinner: false,selectedProjects:[...projects]});
      }
    }
  };

  handleImages = e => {
    const { files } = e.target;
    let error =false;

    files.forEach(file => {
      if (checkMaxSize(file.size)) {
        error = true;
        this.setState({errorImg: true, errorImgMessage: "Un des fichiers sélectionnés est trop volumineux ! ( > 2 Mo)"});
      }
    });
    if (!error) {this.setState({imageFiles: e.target.files,errorImg: false,errorMessage:""});}
  };

  handleQuote = e => {
    const file = e.target.files[0];

    if (checkMaxSize(file.size)) {
      this.setState({errorQuote: true, errorQuoteMessage: "Le fichier sélectionné est trop volumineux ! ( > 2 Mo)"});
    } else {
      this.setState({errorQuote: false, errorQuoteMessage: "", quoteFile: file});
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({[name]: value});
  };

  goToList = (archive = false) =>{
    if( archive ) {
      // GTM
      gtmFamily().ctaOfConfiguration({
        name: "voir les projets archivés",
        category: "espace famille",
        subcategory: "archives",
        type: "lien",
        from: "top menu"
      }, { template: "espace famille", subtemplate: "liste projets" })
      // end GTM
    }
    const endstring = archive ? "/archive":"";
    this.props.history.push(`/famille/${this.props.match.params.clientId}/familyspace/projects${endstring}`)
  }

  addNewProjectAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "créer un nouveau projet",
      category: "configurateur",
      subcategory: "liste produits",
      type: "menu",
      from: "top menu"
    }, { template: "espace famille", subtemplate: "liste projets" })
    // end GTM
    const { clientId } = this.props.match.params;
    this.props.history.push(`/famille/${clientId}`)
  }

  addQuoteAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "ajouter un devis",
      category: "espace famille",
      subcategory: "devis manuel",
      type: "menu",
      from: "top menu"
    }, { template: "espace famille", subtemplate: "liste projets" })
    // end GTM
    this.setState({modalVisible: true,displayAddButton:false})
  }

  render () {
    const { searchInput, fullImage, displayAddButton, modalVisible,
      quoteName, errorImg, errorImgMessage, errorQuote, errorQuoteMessage, imageFiles, quoteFile, priceQuote, buttonSpinner, Loaded
    } = this.state;
    const {archived,compare} = this.props;
    const {clientId}= this.props.match.params;
    const selectedProjects = this.state.selectedProjects.filter(p => p.archived === archived);
    const load = this.props.familyService.loadingProjects;
    const addClass = fullImage === false ? "": "hide";
    const createLabel = isSmartphone() ? "Créer projet" : "Créer mon nouveau projet";
    const {mainColor, fontColor} = this.props.adminConfig.config

    return (
      <div>
        {!compare && <div>
          {fullImage === false &&
          <div class="container-center">
            <div className="topPage top-page-items-container">
              <div className="Ssearch">
                <input placeholder="Chercher un monument" type="text" value={searchInput} onChange={e => this.handleSearchInput(e)}/>
                <i className="icon material-icons">search</i>
              </div>
              
              <div className="load-project-wrapper">
                <LeftOvalAction colorConfig={{mainColor, fontColor}} text="" addClass="fsp-button" icon="plus" iconFiles="files" action={() => this.toggleAddButton()}/>
              </div>
              <Modal
                isOpen={displayAddButton}
                hideClose={true}
                onRequestClose={() => this.setState({displayAddButton:false})}
                className="familyspace-newproject-modal"
                overlayClassName="familyspace-nexproject-overlay"
              >
                <div className="load-project-menu">
                  <ul>
                    <li class="fsp-link" onClick={() => this.addNewProjectAction()}>{createLabel}</li>
                    <li class="fsp-link" onClick={() => this.addQuoteAction()}>Ajouter un devis</li>
                  </ul>
                </div>
              </Modal>

              <div className="Toppest">
                {!archived ?
                  <div className="SsearchActions fsp-link" >
                    <span className="archiveIcon"></span>
                    <span onClick={() => this.goToList(true)}>Voir les projets archivés</span>
                  </div>
                  :
                  <div className="SsearchActions back fsp-link" >
                    <Folder onClick={() => this.goToList()}/>
                    <span onClick={() => this.goToList()}>Retour aux projets</span>
                  </div>
                }
              </div>
            </div>
          </div>
          }
          {archived ?
            <div className="archive-title row">
              <div className="col-0" style={{whiteSpace: "nowrap"}}>
                <span>Nos projets archivés</span>
              </div>
              <div className="hr col-12" />
            </div>
            : null
          }
          <div className ={`listProjects ${addClass}`}>
            <Row>
            {!fullImage && selectedProjects.length !== 0 &&
            // eslint-disable-next-line react/jsx-no-undef
              selectedProjects.map((project,index) => <Col md="12" lg="6"  key={project.id}>
                <Sproject key={index} compare ={compare} idProject={project.id} goFullImage={(id) => this.setFullImageIndex(id)}
                          isArchive={!!archived} archiveFilter={home => this.refreshList(home)} clientId={clientId}/>
              </Col>)
            }
            { !!load &&
              <div className="loadingBloc">
                <img className="loadPicto" src={loadingPicto} alt="Chargement..."/>
              </div>
            }
            </Row>
          </div>
          {fullImage !== false && <Sproject key={fullImage} idProject={fullImage} full={true} compare ={compare} archiveFilter={home => this.refreshList(home)}
                                            goSmallImage={() => this.props.history.push(`/famille/${clientId}/familyspace/projects`)} clientId={clientId}/>}
          <Modal
            isOpen={modalVisible}
            onRequestClose={() => this.cancelAction()}
            className="familyspace-addQuote-modal"
            overlayClassName="familyspace-login-overlay"
          >
            <h2 className="title">Ajouter un devis comme projet</h2>
            <div className="block name">
              <label>Nommer le projet :</label>
              <input type="text" name="quoteName" placeholder="Ex: Projet devis" value={quoteName} onChange={e => this.handleChange(e)} />
            </div>
            <div className="block files">
              <div className="images">
                <label>Ajouter un ou plusieurs visuels du monument funéraire :</label>
                <input className="file-type" type='file' onChange={e => this.handleImages(e)} multiple="multiple" accept="image/*" />
                {errorImg && <div className="error error-message">
                  {errorImgMessage}
                </div>}
              </div>
              <div className="quote">
                <label>Joindre le devis :</label>
                <input className="file-type" type="file" onChange={e => this.handleQuote(e)} />
                {errorQuote && <div className="error error-message">
                  {errorQuoteMessage}
                </div>}
              </div>
            </div>
            <div className="block price">
              <label>Indiquer le prix :</label>
              <input type="text" name="priceQuote" value={priceQuote} onChange={e => this.handleChange(e)} placeholder="Ex: 2000€" />
            </div>
            <div className="block actions">
              {buttonSpinner ? <CircularProgress className="CircularWait"/> :
                <Button className="add LeftOvalAction fsp-button" style={{ "--f-main-color":mainColor, "--f-font-color":fontColor }} onClick={() => this.setState({buttonSpinner: true}, () => this.postQuote())}
                        disabled={errorImg || errorQuote || (!quoteFile && !imageFiles)}>
                  Ajouter</Button>}
              <Button className="fsp-button cancel LeftOvalAction Grey" onClick={() => this.cancelAction()}>Annuler</Button>
            </div>
          </Modal>
        </div>}
        {compare && <ScompareProjects archived={archived} />}
      </div>)
  }
}

export const SlistProjects = connect(state => ({
    familyService: state.familyService,
    configurator: state.configurator,
    adminConfig: state.adminConfig
  }), dispatch => ({
    familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
    updateConfigurationAction: bindActionCreators(updateConfigurationActions, dispatch),
    familyspaceActions: bindActionCreators(familyspaceActions, dispatch),
  })
)(SlistProjectsComponent);
