import React, { useState, useEffect } from 'react';

function OptionsPopup({ children, opened, closer }) {
    const [visible, setVisible] = useState(opened)
    useEffect( () => setVisible(opened) , [opened])

    const close = () => {
        setVisible(false)
        closer()
    }

    const containerStyle = visible ? {display: "block"} : {display: "none"} 
    return (
        <>
            <div 
                className="option-popup-overlay" 
                style={containerStyle}
                onClick={close}>
            </div>
            <li className="option-popup" style={containerStyle}>
                { children }
            </li>
        </>
    )
}

export default OptionsPopup;