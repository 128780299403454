import React, { Component} from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import { OptionPanel } from "../components/OptionPanel";
import { isMonumentCineraire,isMonumentDouble } from "../../../services/utils.service";
import * as _ from "lodash";
import { VeneerConfigure } from "./veneer/VeneerConfigure";
import { isConfigRequested } from "../../../services/configurationWarningChecks"
import { TranslationContext } from "../../../context/TranslationContext";
export const VeneerOptionPanelId = "VeneerOptionPanelId";
// Composant qui affiche le premier menu puis appelle VeneerConfig qui fait le plus gros du boulot

class VeneerOptionPanelComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    veneer: PropTypes.shape({
      dimensions: PropTypes.shape({
        back: PropTypes.shape({
          left: PropTypes.number,
          right: PropTypes.number,
          length: PropTypes.number,
          thickness: PropTypes.number
        }),
        front: PropTypes.shape({
          left: PropTypes.number,
          right: PropTypes.number,
          length: PropTypes.number,
          thickness: PropTypes.number
        }),
        right: PropTypes.shape({
          front: PropTypes.number,
          back: PropTypes.number,
          length: PropTypes.number,
          thickness: PropTypes.number
        }),
        left: PropTypes.shape({
          front: PropTypes.number,
          back: PropTypes.number,
          length: PropTypes.number,
          thickness: PropTypes.number
        })
      })
    })
  };
 
  state = {step: false,displayVeneer:true}; // step est soit vide:affichage du premier menu soit égale à 1: appel du composant <VeneerConfigure>

  componentDidMount (){
    if (this.props.veneer && this.props.veneer.display === false){
      this.setState({displayVeneer:false})

    }
  }
  
  close = () => {
    this.refs.Popup.onClose(); // appel de la fonction close de OptionPanel sur appui sur Fermer
  };
  open = () =>{
    this.setState({ step: 1 }); 
    this.refs.Popup.onOpen();// appel de la fonction open de OptionPanel sur appui sur Configurer
  }
  firstStep = () => {
    this.setState({ step: 1 }); //Affichage des mesures
  };
  monumentHasSides = (side) => {
    // verifie si le(s) côté(s) existent (epaisseur,longueur et une hauteur)
    const tabSides = side !== "all" ? [side] : ["left", "right", "back", "front"];
    const data = this.props.veneer ;
    return _.some(tabSides, side => {
      const { left, right, back, front, length, thickness } = _.get(
        data,
        `dimensions.${side}`,
        {}
      );
      return (
        !!length && !!thickness && (!!left || !!right || !!back || !!front)
      );
    });
  };


  removeVeneer = () => { // supprime le placage actuel
    this.setState({ step: false,displayVeneer:true });
    this.props.actions.removeVeneer();
    this.close();
  };

  informClose = (open) =>{  // si le composant OptinPanel ferme il faut revenir à l'affichage du premier menu
    if (open === false){
      this.setState({step:false});
    }
  
  }
  toggleDisplayVeneer = ()=>{
    this.props.actions.displayVeneer(!this.state.displayVeneer);
    this.setState({displayVeneer:!this.state.displayVeneer});
  }


  render() {
    const t = this.context;
    const monumentComponents = ["SEM","PLT"];
    const isMonumentComponent = monumentComponents.includes(this.props.monument.category)
    const { monument, isVeneer} = this.props;
    let { step,displayVeneer } = this.state;
    const classNameStep = step ? "Step" : "NoStep";
    let hasVeneer = this.monumentHasSides("all","props");
    const displayValue = hasVeneer ? this.state.displayVeneer ? "Masquer":"Afficher": "Configurer"; // Affichage de la présence d'un placage
    const fctValue = hasVeneer ? this.toggleDisplayVeneer: this.open;
    const disabledVeneer=this.props.veneerEnabled !== undefined && !this.props.veneerEnabled


    if(isVeneer){
      const disabled = isMonumentCineraire(monument) || isMonumentDouble(monument) || isConfigRequested()
      return (
        <div className="btn-wrapper">
          <OptionPanel
            id="VeneerOptionPanelId"
            className="VeneerOptionPanel"
            buttonContent={t("config3d_veneer_button_veneer") || "Placage"}
            hasContent={hasVeneer}
            buttonIcon="veneer-icon"
            title={disabledVeneer && "Option non disponible pour ce monument"}
            isMonComponent={isMonumentComponent}
            disabled={
              /* On désactive pour les cinéraires */ disabled
            }
            fctValue ={fctValue}
            addClassName={classNameStep}
            informState= {this.informClose}
            ref= "Popup" /* utilisé pour appeler la fonction close de OpenPanel */ 
            buttonClassName={`btn-config-secondary text-dark config-button-background ${!disabledVeneer && "config-button-hover"}`}
          >
            {(<VeneerConfigure onClose={this.close} display={displayVeneer} />)}
          </OptionPanel>
        </div>
      );
    }

    const disabled = isMonumentDouble(monument) || isConfigRequested();
    const buttonContent = t("config3d_monument_button_veneer") || "Placage";
    return (
     <OptionPanel
        id="VeneerOptionPanelId"
        className="VeneerOptionPanel"
        buttonClassName={`btn-config-secondary text-dark config-button-background ${!disabledVeneer && "config-button-hover"}`}
        buttonContent={buttonContent}
        hasContent={hasVeneer}
        buttonIcon="veneer-icon"
        // buttonIcon={<i className="icon icon-veneer" title={disabledVeneer && "Option non disponible pour ce monument"} />}
        title={disabledVeneer && "Option non disponible pour ce monument"}
        disabled={disabledVeneer}
        showValue={(!isConfigRequested() && !disabledVeneer) && displayValue}
        fctValue ={fctValue}
        addClassName={classNameStep}
        informState= {this.informClose}
        ref= "Popup" /* utilisé pour appeler la fonction close de OpenPanel */ 
      >
        {!step && !hasVeneer && (
          <button className="VeneerAction add-veneer secondary3-hover" style={{ height:"64px" }}  onClick={() => this.firstStep()}>
            { t("config3d_monument_button_veneer_add") || "AJOUTER UN PLACAGE" }
          </button>
        )}
        {!step && hasVeneer && (
          <div className="Actions VeneerActions">
            <button className="VeneerAction" onClick={() => this.firstStep()}>
              { t("config3d_monument_button_veneer_edit") || "MODIFIER LES PLACAGES" }
            </button>
            {!isMonumentComponent && <button className="VeneerAction" onClick={() => this.removeVeneer()}>
              { t("config3d_monument_button_veneer_delete") || "SUPPRIMER LES PLACAGES" }
            </button>
            }     
          </div>
        )}
       {step && (<VeneerConfigure onClose={this.close} display={displayVeneer} />)}
      </OptionPanel>
    );
  }
}

export const VeneerOptionPanel = connect(
  (state) => ({
    monument: state.configurator.current.configuration.monument,
    veneer: state.configurator.current.configuration.veneer,
    veneerEnabled:state.configurator.current.options.functionalities.veneerEnabled

  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(VeneerOptionPanelComponent);
