import * as _ from "lodash";
/**
 * Assigne UNE valeur sur un chemin profond de l'objet source. Renvoie le résult. Ne mute pas
 * l'objet source.
 *
 * @param source Objet source dans lequel assigner la valeur
 * @param path Le chemin vers la valeur à remplacer (par exemple 'config.current.pattern.0.price)
 * @param value La valeur à positionner.
 *
 * @returns Un object avec le patch appliqué
 */
export function assignDeep(source, path, value) {
  return assignDeepMultiple(source, {[path]: value});
}

/**
 * Assigne PLUSIEURS valeurs dans l'objet source. Renvoie le résult. Ne mute pas
 * l'objet source.
 *
 * @param source Objet source dans lequel assigner les valeurs
 * @param rawPatches Objet qui map des path deeep à des valeurs.
 *        Par exemple :  {'current.configuration.accessories.2.price':450}
 *
 * @returns Un object avec le patch appliqué
 */
export function assignDeepMultiple(source, rawPatches) {
  const patches = _.map(rawPatches, (value, _path) => ({
    path: _path.split('.'),
    value
  }));
  return _assign(source, patches);
}

function _assign(source, patches) {
  if (patches.length === 1 && patches[0].path.length === 0) {
    return patches[0].value;
  }

  const toAssign = _.chain(patches)
    .map(_path =>({
      head: _.head(_path.path),
      path: _.drop(_path.path),
      value: _path.value,
    }))
    .groupBy('head')
    .mapValues((_path, key) => _assign(source[key] || {}, _path))
    .value();

  return _mergeArrayOrObject(source, toAssign);
}

function _mergeArrayOrObject(arrayOrObjectSource, objectNewValues) {
  let clone = _.clone(arrayOrObjectSource);
  _.each(objectNewValues, (val, key)=>_.set(clone, key, val))
  return clone;
}
