import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

/**
 * Composant de saisie de nombre gérant les décimales.
 * La valeur de l'input est synchro avec la prop 'value' lorsque l'input n'est pas focus
 * et sur le blur sinon pour éviter des comportements bizarres lorsque l'utilisateur est
 * en train de saisir un chiffre.
 */
export class InputNumber extends Component {
  static propTypes = {
    value: PropTypes.number,
    onValueChange: PropTypes.func,
    step: PropTypes.number,
  };

  state;

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      inputValue: this.formatNumber(props.value),
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.focused) {
      this.setState({
        inputValue: this.formatNumber(nextProps.value),
      })
    }
  }

  formatNumber = (number) => {
    return _.isNumber(number) ? number.toString() : ''
  };

  /**
   * La méthode retourne undefined si l'utilisateur a saisi un champ vide, NaN s'il a saisi un nombre
   * incorrect et
   */
  parseNumber = (event) => {
    if (event.target.validity && !event.target.validity.valid) {
      // Le navigateur a détecté que le champ était invalide
      return NaN;
    }

    const value = event.target.value.replace(',', '.');
    if (!value.length) {
      // Si le champ est vide, on retourne undefined
      return undefined;
    }
    if (!/^(-|\+)?([0-9]+(\.[0-9]+)?)$/.test(value)) {
      // La valeur saisie n'est pas un nombre
      return NaN;
    }

    const number = Number(value);

    if ((!_.has(this.props, 'min') || number >= this.props.min) &&
      (!_.has(this.props, 'max') || number <= this.props.max)) {
      return number;
    } else {
      // La valeur saisie ne respecte pas le range spécificé
      return NaN;
    }
  };

  onChange = (event) => {
    this.setState({
      inputValue: event.target.value
    });
    const number = this.parseNumber(event);
    if (!_.isNaN(number) && number !== this.props.value) {
      this.props.onValueChange(number);
    }
  };

  onFocus = () => {
    this.setState({
      focused: true,
    })
  };

  onBlur = () => {
    this.setState({
      focused: false,
      inputValue: this.formatNumber(this.props.value),
    })
  };

  render() {
    const {value, onValueChange, step, ...others} = this.props;
    return (
      <input type="number" step={step || 0.5} value={this.state.inputValue}
             onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} {...others}
      />
    )
  }

}
