import React, { Component } from "react";
import { Add  } from '@material-ui/icons';
import trash from '../../../images/space/trash-fill.svg';
import pdfIcon from '../../../images/space/pdf-file.svg';
import doc from '../../../images/space/pj.png';
import devisIcon from '../../../images/space/devis.png';
// import dessinIcon from '../../../images/space/dessin.png';
// import gravureIcon from '../../../images/space/gravure.png';
// import view3DIcon from '../../../images/space/vue3d.png';
import arrowIcon from '../../../images/space/arrow-solid.svg';
import {getFsGcsUrl} from "../../../services/api.familyspace.service";
import {getQuoteDoc} from "../utils/utils";
import * as _ from "lodash";
import MediaQuery from "react-responsive/src";

export class SdocSortcuts extends Component {
  state = {};

  findQuoteFile = (project,files,docs) => {
    const found = getQuoteDoc(project,docs, {files});
    if (found){
      return this.FileIcon("configDoc",devisIcon,"voir le PDF","configDoc",false,false,"pdf",true)
    }
     return <div/>
  }

  getSvgFromName(name) {
    const {mainColor} = this.props;
    const icons = [
      {
        name: "config-icon-gpg-granit",
        svg: `
          <svg id="Calque_17" data-name="Calque 17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <defs>
              <style>
                .svg-config {
                  fill: ${mainColor ? mainColor : "#334754"};
                }
              </style>
            </defs>
            <g id="Calque_1-2" data-name="Calque 1-2">
              <g>
                <path class="svg-config" d="m321.82,229.55c0-.77-.45-1.48-1.15-1.81l-114.15-53.32-.11-39.4c0-.75-.42-1.43-1.08-1.77l-15.26-7.87c-.93-.48-2.09-.16-2.63.75l-.83,1.38c-.71,1.18-7.31,11.44-18.56,9.14-15.69-3.21-33.89,14.56-35.95,16.63l-.46.49c-.35.37-.55.87-.54,1.38l.13,47.73-26.49,11.48c-.68.3-1.14.95-1.2,1.69l-.7,9.57c-.06.82.39,1.59,1.13,1.95l124.23,59.49c.31.19.67.29,1.04.29.3,0,.6-.07.88-.21l90.62-44.54c.69-.34,1.12-1.04,1.12-1.8l-.04-11.23Zm-155.29-85.57c8.72,1.46,17.72-1.87,23.44-8.66l.09-.1c.33-.39.63-.78.91-1.15l5.75,2.94c-3.22,3.13-11.21,8.81-26.01,8.04-.5-.03-.99-.04-1.48-.04-15.36,0-26.66,13.46-26.74,13.57l-.41.5c-.29.36-.46.81-.45,1.27l.12,44.32-3.2-1.56-.12-45.77c15.51-14.55,25.38-13.91,28.12-13.35Zm31.66,35.18l-49.18,20.39-.1-37.22c3.05-3.13,10.73-9.88,20.23-9.91h.92c9.75.89,19.49-1.55,27.58-6.88l.55,33.61Zm77.1,50.18c-7.17,3.31-19.82,9.61-41.03,20.42l-86.12-42.11,59.35-24.7,64.26,30.05,6.58,6.3c1.39,1.34,2.21,3.24,2.21,5.09-.33,1.75-2.06,3.37-5.26,4.93Zm-41.75,28.11c.57.27,1.23.27,1.79-.02l1.96-1.01c7.27-3.7,29.35-14.94,40.99-20.44,4.53-1.6,7.98-5.2,9.35-9.71.39.77.62,1.61.65,2.44l.02,5.06-53.89,28.55-95.85-47.07v-3.99s94.98,46.17,94.98,46.17Zm2.06,12.6l58.99-31.2c.66-.35,1.07-1.03,1.06-1.77l-.02-8.36c-.02-1.78-.41-3.51-1.14-5.12l20.06,9.33v4.47s-85.27,42.19-85.27,42.19l-118.92-56.9.2-2.65,20.69-8.96.02,7.64c0,.76.44,1.46,1.12,1.79l101.39,49.58c.57.28,1.25.27,1.81-.03Z"/>
                <g>
                  <path class="svg-config" d="m397.65,198.02c-3.15-19.14-22.92-36.44-57.17-50.04-28.75-11.42-67.38-19.87-105.96-23.21-2.15-.18-4.03,1.4-4.22,3.55l-.34,3.91c-.09,1.03.24,2.05.9,2.85.67.79,1.62,1.29,2.65,1.38,37.49,3.24,74.91,11.41,102.65,22.43,30.22,12,47.49,26.2,49.93,41.06,3.32,20.19-22.61,43.91-73.2,67.18l.34-3.78c.15-1.71-.84-3.32-2.43-3.96-1.6-.64-3.42-.16-4.5,1.18l-14.78,18.44c-.93,1.16-1.12,2.74-.49,4.09.63,1.35,1.96,2.22,3.45,2.25l24.51.53s.06,0,.08,0c1.74,0,3.27-1.15,3.75-2.84.48-1.71-.25-3.53-1.78-4.43l-2.24-1.32c26.24-12.14,46.18-24.4,59.29-36.52,15.31-14.14,21.89-28.52,19.55-42.75Z"/>
                  <path class="svg-config" d="m184.72,290.07c-80.11-6.91-165.79-33.23-170.49-65.31-2.87-19.57,26.2-42.91,82.05-66.06l-.44,3.92c-.19,1.7.75,3.33,2.33,4.01.5.22,1.02.32,1.55.32,1.12,0,2.22-.48,2.97-1.38l15.25-17.97c.94-1.11,1.19-2.66.63-4.01-.55-1.35-1.81-2.27-3.27-2.4l-23.64-2.06c-1.74-.15-3.35.86-3.98,2.48-.63,1.62-.11,3.46,1.27,4.51l2.49,1.9c-29.33,12.17-51.64,24.43-66.38,36.49-16.94,13.87-24.49,27.98-22.45,41.94,3.01,20.54,25.96,38.27,68.19,52.69,31.46,10.75,73.55,19.18,112.64,22.57.19.03.39.04.58.04.41,0,.83-.07,1.24-.2,1.47-.49,2.51-1.81,2.65-3.36l.34-3.91c.09-1.03-.24-2.05-.9-2.85-.67-.79-1.62-1.29-2.65-1.37Z"/>
                </g>
              </g>
            </g>
          </svg>
      `,
      },
      {
        name: "gravure-icon-gpg-granit",
        svg: `
          <svg id="Calque_14" data-name="Calque 14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <defs>
              <style>
                .svg-gravure {
                  fill: ${mainColor ? mainColor : "#334754"};
                }
              </style>
            </defs>
            <path class="svg-gravure" d="m311.07,96.31c-.54-2.47-.82-4.89-1.34-7.74l-1.13-6.34c-.32-1.82-1.96-3.09-3.81-2.95l-6.18.47c-1.85.16-3.6.32-5.37.33l-3.39.03c-10.91.08-21.2.16-31.41-1.69-2.98-.54-5.99-1.04-8.89-1.52l-.4-.07c-4.6-.76-9.35-1.55-13.98-2.54-10.43-2.22-21.43-5.86-34.59-11.46-7.61-3.23-15.11-6.27-23.32-7.86-8.13-1.58-16.39-2.02-25.24-1.33-8,.63-15.53,2.66-22.47,4.77-9.09,2.77-17.93,7.18-28.67,14.29-5.09,3.37-8,7.83-8.64,13.26-.23,1.91-.7,3.83-1.15,5.71l-.11.46c-.37,1.52-.75,3.1-1.05,4.7l-.53,2.77c-8.52,33.88-9.35,93.67-7.59,111.33,0,.1,3.04,30.42,3.04,30.42h-27.05c-1.99,0-3.6,1.61-3.6,3.6v52.63H8.77c-1.99,0-3.6,1.61-3.6,3.6v36.35c0,1.99,1.61,3.6,3.6,3.6h382.47c1.99,0,3.6-1.61,3.6-3.6v-36.35c0-1.99-1.61-3.6-3.6-3.6h-45.45v-52.63c0-1.99-1.61-3.6-3.6-3.6h-28.56l.71-7.22c.23-2.31.36-4.62.75-6.94,1.9-11.37,4.58-91.45-4.01-130.89ZM18.39,327.91v-17.1h39.25c.34,0,.67-.06.98-.15h13.54c.31.09.64.15.98.15h308.46v17.1H18.39Zm49.04-73.33h20.88c.36.12.75.2,1.15.2h219.6c.4,0,.78-.08,1.15-.2h22.37v42.85H67.43v-42.85Zm27.99-39.61l-.55-14.21c-.14-7.82-.28-15.9-.24-23.85.03-7.6.34-15.51.91-23.5h0c.23-3.3.72-6.64,1.18-9.87l.04-.25c.3-2.11.65-4.49.93-6.89.29-2.54.52-5.03.75-7.57.32-3.48.65-7.07,1.14-10.54.77-5.5,1.83-11.06,2.86-16.43l.49-2.56c.25-1.35.6-2.76.91-4.02.6-2.47,1.22-5.03,1.54-7.71.15-1.28.69-2.41,2.82-3.82,9.64-6.38,17.42-10.29,25.22-12.66,6.21-1.89,12.88-3.71,19.66-4.24,7.62-.59,14.71-.22,21.68,1.12,6.95,1.35,13.47,3.99,20.67,7.04,13.97,5.94,25.73,9.82,37.03,12.23,4.92,1.04,9.98,1.88,14.86,2.69l1.18.2c2.54.42,5.07.84,7.58,1.3,11.46,2.09,22.89,2,33.95,1.91l3.34-.03c1.15-.01,2.35-.06,3.76-.17.31,1.76.62,3.49.89,5.22.78,4.96,1.59,10.08,2.21,15.23.36,2.98.63,6.01.89,8.97l1.23,12.65c.37,3.58.74,7.08,1.05,10.55.3,3.22.44,5.83.45,8.21.04,10.07.08,23.38,0,37.02v1.34c-.04,6.15-.08,12.51-.48,18.64-.32,4.88-.86,9.82-1.39,14.59l-.03.23c-.26,2.34-.52,4.68-.76,7.03l-.84,8.55H98.09l-2.67-26.39Z"/>
            <g>
              <path class="svg-gravure" d="m207.68,130.62h-31.25v80.9h-14.96v-80.9h-31.38v-13.11h77.59v13.11Z"/>
              <path class="svg-gravure" d="m269.91,149.69h-26.75v61.84h-13.24v-61.84h-26.88v-11.65h66.87v11.65Z"/>
            </g>
          </svg>
        `,
      },
      {
        name: "dessin-icon-gpg-granit",
        svg: `
          <svg id="Calque_18" data-name="Calque 18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <defs>
              <style>
                .svg-dessin {
                  fill: ${mainColor ? mainColor : "#334754"};
                }
              </style>
            </defs>
            <path class="svg-dessin" d="m311.07,95.31c-.54-2.47-.82-4.89-1.34-7.74l-1.13-6.34c-.32-1.82-1.96-3.09-3.81-2.95l-6.18.47c-1.85.16-3.6.32-5.37.33l-3.39.03c-10.91.08-21.2.16-31.41-1.69-2.98-.54-5.99-1.04-8.89-1.52l-.4-.07c-4.6-.76-9.35-1.55-13.98-2.54-10.43-2.22-21.43-5.86-34.59-11.46-7.61-3.23-15.11-6.27-23.32-7.86-8.13-1.58-16.39-2.02-25.24-1.33-8,.63-15.53,2.66-22.47,4.77-9.09,2.77-17.93,7.18-28.67,14.29-5.09,3.37-8,7.83-8.64,13.26-.23,1.91-.7,3.83-1.15,5.71l-.11.46c-.37,1.52-.75,3.1-1.05,4.7l-.53,2.77c-8.52,33.88-9.35,93.67-7.59,111.33,0,.1,3.04,30.42,3.04,30.42h-27.05c-1.99,0-3.6,1.61-3.6,3.6v52.63H8.77c-1.99,0-3.6,1.61-3.6,3.6v36.35c0,1.99,1.61,3.6,3.6,3.6h382.47c1.99,0,3.6-1.61,3.6-3.6v-36.35c0-1.99-1.61-3.6-3.6-3.6h-45.45v-52.63c0-1.99-1.61-3.6-3.6-3.6h-28.56l.71-7.22c.23-2.31.36-4.62.75-6.94,1.9-11.37,4.58-91.45-4.01-130.89ZM18.39,326.91v-17.1h39.25c.34,0,.67-.06.98-.15h13.54c.31.09.64.15.98.15h308.46v17.1H18.39Zm49.04-73.33h20.88c.36.12.75.2,1.15.2h219.6c.4,0,.78-.08,1.15-.2h22.37v42.85H67.43v-42.85Zm27.99-39.61l-.55-14.21c-.14-7.82-.28-15.9-.24-23.85.03-7.6.34-15.51.91-23.5h0c.23-3.3.72-6.64,1.18-9.87l.04-.25c.3-2.11.65-4.49.93-6.89.29-2.54.52-5.03.75-7.57.32-3.48.65-7.07,1.14-10.54.77-5.5,1.83-11.06,2.86-16.43l.49-2.56c.25-1.35.6-2.76.91-4.02.6-2.47,1.22-5.03,1.54-7.71.15-1.28.69-2.41,2.82-3.82,9.64-6.38,17.42-10.29,25.22-12.66,6.21-1.89,12.88-3.71,19.66-4.24,7.62-.59,14.71-.22,21.68,1.12,6.95,1.35,13.47,3.99,20.67,7.04,13.97,5.94,25.73,9.82,37.03,12.23,4.92,1.04,9.98,1.88,14.86,2.69l1.18.2c2.54.42,5.07.84,7.58,1.3,11.46,2.09,22.89,2,33.95,1.91l3.34-.03c1.15-.01,2.35-.06,3.76-.17.31,1.76.62,3.49.89,5.22.78,4.96,1.59,10.08,2.21,15.23.36,2.98.63,6.01.89,8.97l1.23,12.65c.37,3.58.74,7.08,1.05,10.55.3,3.22.44,5.83.45,8.21.04,10.07.08,23.38,0,37.02v1.34c-.04,6.15-.08,12.51-.48,18.64-.32,4.88-.86,9.82-1.39,14.59l-.03.23c-.26,2.34-.52,4.68-.76,7.03l-.84,8.55H98.09l-2.67-26.39Z"/>
            <rect class="svg-dessin" x="123.15" y="127.77" width="156.99" height="12.25"/>
            <rect class="svg-dessin" x="147.62" y="153.3" width="108.05" height="12.25"/>
          </svg>
        `
      }
    ]
    const icon = icons.find(icon => icon.name === name);
    return icon ? icon.svg : null;
  }

  FileIcon = (index,src,alt,type,file,withTrash,addClass="",openUrl=false) => {
    const regex = /config-icon-gpg-granit|gravure-icon-gpg-granit|dessin-icon-gpg-granit/;
    const match = src && src.match(regex);
    if (match) {
      const svg = this.getSvgFromName(match[0]);
      return (
        <div key={index} className={`thumbnail-wrapper ${addClass}`}>
          <div className={`img-wrap ${addClass}`} onClick={() => openUrl ? window.open(getFsGcsUrl(file.space_id,`${file.uuid}.${file.ext}`),'_blank') : this.props.display(type,file)}>
            {/* <img key={'icon'+index} className="thumbnail-img" src={src} alt={alt}/> */}
            <div style={{width:"80px", margin:"0 auto"}} dangerouslySetInnerHTML={{ __html: svg }} />
          </div>
        </div>
      )
    } else {
      return (
        <div key={index} className={`thumbnail-wrapper ${addClass}`}>
          <div className={`img-wrap ${addClass}`} onClick={() => openUrl ? window.open(getFsGcsUrl(file.space_id,`${file.uuid}.${file.ext}`),'_blank') : this.props.display(type,file)}>
            <img key={'icon'+index} className="thumbnail-img" src={src} alt={alt}/>
          </div>
          { file && addClass ==='pdf' && <h4 key={'title'+index} className="docTitle">{file ? file.name : 'Vide'}</h4>}
          {withTrash && <div className="trash-wrap" onClick={() => this.props.removeFile(file)}>
            <img src={trash}  alt="Supprimer" />
          </div>}
        </div>
      )
    }
  }

  displayFile = (file, index) => {
    const existsFiles = this.props.files.map(file => file.context ="project");
    const canDelete = file.context !=="project_client" && existsFiles.length>1;
    if (file.mime_type && file.mime_type.split('/')[0] === "image") {
      return (this.FileIcon(index,getFsGcsUrl(file.space_id,`${file.uuid}.${file.ext}`),file.name,"file",file,canDelete,"files"))
    }else if (file.mime_type === "application/pdf"){
      return (this.FileIcon(index,pdfIcon,"voir le PDF","iframe",file,canDelete,"pdf",true))
    }
      return  (this.FileIcon(index,doc,"télécharger le document","",file,canDelete,"doc",true))
  }

  render () {
  const {project,files,docs,mainColor} = this.props;
  const decorations =  _.get(docs, 'proofs.pages');
  const arrowIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.595" viewBox="0 0 16 15.595"><defs><style>.svg-arrowUpIcon{fill:${mainColor && mainColor};}</style></defs><path class="svg-arrowUpIcon" d="M9.147,52.2l-.793.793a.854.854,0,0,1-1.211,0L.2,46.054a.854.854,0,0,1,0-1.211L7.144,37.9a.854.854,0,0,1,1.211,0l.793.793a.858.858,0,0,1-.014,1.225l-4.3,4.1H15.093a.855.855,0,0,1,.857.857v1.143a.855.855,0,0,1-.857.857H4.83l4.3,4.1A.852.852,0,0,1,9.147,52.2Z" transform="translate(0.05 -37.65)"/></svg>`
  return <div className="docIcons">
    <MediaQuery minWidth={768}>
      {/* <div className="AddBlock" color="link" onClick={() => this.props.openAdd()} >
        <div className="AddIcon"> <Add/> </div>
        <p className="AddText"> Ajouter une image ou un document </p>
      </div>
      <div className="SeparationLine"/> */}

      <div className="scroll-arrow arrow-up">
        <div 
          className="arrow-icon arrow-up-icon" 
          dangerouslySetInnerHTML={{ __html: arrowIcon }} 
          onClick={()=>{
            let x = document.querySelector('.scroll-slider')
            x.scroll(0,x.scrollTop-50)
          }} 
        />
        {/* <img className="arrow-icon arrow-up-icon" src={arrowIcon} alt="arrow up" onClick={()=>{
          let x = document.querySelector('.scroll-slider')
          x.scroll(0,x.scrollTop-50)
        }} /> */}

      </div>
    </MediaQuery>
      {/* Si c'est une config on affiche l'image et les docs */}
      <div id="myscroll" className="scroll-slider">
        {project && (project.config_id || project.quote_id)  &&
        <div className="shortIcons row">
          {this.FileIcon("configImageMain",this.props.startImg,"voir l'image","configImage",false,false,"mainImage")}
          {this.findQuoteFile(project,files,docs)}
          {decorations && decorations.map((decoration,index)=>
            this.FileIcon(`configGravure${index}`,"gravure-icon-gpg-granit","voir le détail","configDraw",decoration,false,"gravure"))}
          {project.config_id && this.FileIcon("config3D","config-icon-gpg-granit","voir la configuration Vue 3D","view3D",false,false,"vue3D")}
          {docs && docs.wireframe && this.FileIcon("configDessin","dessin-icon-gpg-granit","voir le Dessin Filaire","configDraw",docs.wireframe,false,"dessin")}
          {/* this.FileIcon("configDoc",pdfIcon,"Ouvrir le document PDF","configPDF","http://www.africau.edu/images/default/sample.pdf",false,"doc") */}
          {/* <MediaQuery maxWidth={767}>
            <div className="AddBlock" color="link"  onClick={() => this.props.openAdd()}>
              <div className="AddBlockContain">
                <div className="AddIcon"> <Add/> </div>
                <p className="AddText"> Ajouter une image ou un document </p>
              </div>
            </div>
          </MediaQuery> */}
        </div>
        }
        <div className="shortIcons row">
        {files && files.length > 0 && files.map((file, index) =>{
          if (file.context !=="project_quote_manual" && file.context !== "client_manual_quote"){
            return this.displayFile(file,index)
          }
          return <div key={index}/>
        } )}
        </div>
     </div>
    <MediaQuery minWidth={767}>
      <div className="scroll-arrow arrow-down">
        <div 
          className="arrow-icon arrow-down-icon" 
          dangerouslySetInnerHTML={{ __html: arrowIcon }} 
          onClick={()=>{
            let x = document.querySelector('.scroll-slider')
            x.scroll(0,x.scrollTop+50)
          }} 
        />
      </div>
      {/* <div className="scroll-arrow arrow-down"><img className="arrow-icon arrow-down-icon" src={arrowIcon} alt="arrow down" onClick={()=>{
        let x = document.querySelector('.scroll-slider')
        x.scroll(0,x.scrollTop+50)
      }} /></div> */}
    </MediaQuery>

</div>

  }
}
