import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as familyspaceActions from '../../actions/familySpaceActions';
import share from '../../images/space/share.svg';
import euro from '../../images/space/devis.svg';
import euro_blue from '../../images/space/euro.svg';
import comments_input from '../../images/space/comments_grey.svg';
import { SlikeDislike } from './social/SlikeDislike';
import { Scomments } from "./social/Scomments";
import { Row, Col } from 'reactstrap';
import { Sinvite } from "./Sinvite";
import {SaskQuote}  from "./Project/SaskQuote";
import { ScommentsBlock } from "./social/ScommentsBlock";
import {isFamilyConfigProject,getQuoteDoc, isManual} from "./utils/utils";
import { Modal } from '../../components/Modal';
import {LeftOvalAction} from "../../components/LeftOvalAction";
import fileIcon from "../../images/space/file.svg";
import * as _ from 'lodash';
import { gtmFamily } from "../../services/gtmFamily";
import {SprojectHeaderActions} from './SprojectHeaderActions';
import {SchangeName} from "./utils/SchangeName";
import MediaQuery from "react-responsive/src";
 class SprojectFooterComponent extends Component {

  

  state = {
    displayInvite:false,
    displayComment:false,
    fullPageQuote: undefined,errorQuote:false,
    askQuote: false,
    sent:false,
    commentEntered:"",
    changeName:false,
    variante:false,
  };

 componentDidMount(){
    /*this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);*/

  }

  handleClickOutside = (event)=>{
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
        this.setState({
            displayComment: false
        });
        document.removeEventListener('mousedown', this.handleClickOutside);
    } 
  }
  handleClickOutsideDisplayInvite = (event)=>{
    if (this.state.displayInvite === false){
      return;
    }
    const domNode = ReactDOM.findDOMNode(this);
    
    if (!domNode || !domNode.contains(event.target)) {
        if (event.target.contains(document.getElementById('modalInviteId'))){
          
          this.setState({
            displayInvite: false
        });
        // document.removeEventListener('mousedown', this.handleClickOutsideDisplayInvite);
        }
    } 
}



   getlistActions = full => {
    const quoteInfos = this.getQuoteInfos(this.props.project,this.props.project.config,full);
    const { mainColor } = this.props.adminConfig.config

    return ([
      {nbColumns:"1",disabled: false,component:<SlikeDislike mainColor={mainColor} project ={this.props.project} social="likes" full={this.props.full} />},
      {nbColumns:"1",disabled: false,component:<SlikeDislike mainColor={mainColor} project ={this.props.project} social="dislikes" full={this.props.full} />},
      {nbColumns:"1",disabled: false,component:<Scomments markAllAsRead={()=>this.markAllAsRead()} project ={this.props.project} addComment={()=>this.prepareComment()} />},
      // {nbColumns:"3",disabled: false,icon:share,text:"", action:()=>  this.shareProjectAction()},
      // {nbColumns:"4",...quoteInfos},
    ])
  }

  getQuoteInfos = (project,config,full)=> {
    // Plusieurs possibiilités:
    if (isFamilyConfigProject(project) ){
      const sent = _.get(config, 'configuration.newfamilyInfos.emailSent');
      if (sent || this.state.sent){
         // Si confguration famille et devis demandé afficher devis demandé et ne rien permettre
        return ({disabled:true ,icon:euro, text:`Devis demandé`,action:()=>console.log("Do nothing")})
      }else{
        return ({disabled:false,
          icon:euro_blue, 
          text:`Demander un devis`,
          action: () => this.askQuoteAction(),
          addClass: `block last fsp-button ask${full ? " top-left" : ""}`
        })
      }
    }else {
      if (isManual(project) && (!project.docs|| !project.docs.selling)){
        // Si configuration famille et devis non demandé afficher formulaire
        return ({disabled:false,icon:fileIcon,text:`Joindre un document`,action:()=>this.props.openAdd() })
      }else{
        // Si configuration famille et devis non demandé afficher formulaire
        return ({disabled:false,icon:euro,text:`Voir le devis`,action:()=>this.displayFullQuote()})
      }
    }
   // Afficher le devis en pleine page si devis marbrier ou config client ou devis manuel 
   
  }
  displayFullQuote = () => {
    const { project, docs, familyService} = this.props;
    const quoteUrl = getQuoteDoc(project,docs,familyService);
    if (quoteUrl) {window.open(quoteUrl.url);}
    /*if (!quoteUrl) {this.setState({errorQuote:true})}
    else{
      if (!quoteUrl.iframe){window.open(quoteUrl.url, "_blank")} // On télécharge le fichier
      else {this.setState({fullPageQuote:quoteUrl.url})}
    }*/
  }

  gtmProductObj = ()=> {
    if (!this.props.project.config) {
      return {}
    }

    const { monument } = this.props.project.config.configuration;
    const { granites } = this.props.project.config.options;
    const graniteMain = granites.find((granit => granit.reference == monument.graniteMain ))
    const graniteSecondary = granites.find((granit => granit.reference == monument.graniteSecondary ))
    
    return {
      id: monument.productId,
      name: "", // empty
      reference: monument.reference,
      price_from: 0, // empty
      granits: [
        graniteMain ? graniteMain.name : monument.graniteMain, 
        graniteSecondary ? graniteSecondary.name : monument.graniteSecondary
      ],
      styles: [], // second filter <--
      couleurs: [], // empty
      religions: [], // empty
      types: [], // main filter <--
      granit_main: graniteMain ? graniteMain.name : monument.graniteMain,
      flower_button: "", // empty
      v360_button: true //empty
    }
  }
  prepareComment = ()=>{
    if(!this.state.displayComment){
      // GTM
      const { project, full } = this.props;
      const product = this.gtmProductObj();
      gtmFamily().ctaOfConfiguration({
        name: "ajouter une commentaire",
        category: "espace famille",
        subcategory: "commenter",
        type: "picto",
        from: "vignette projet"
      }, 
      { 
        template: "espace famille", 
        subtemplate: full ? "projet" : "liste projets" 
      },
      {
        product: product
      })
      // end GTM
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    this.setState({displayComment:!this.state.displayComment}, () => {
      this.state.displayComment && this.eventsEnd && this.eventsEnd.scrollIntoView({ behavior: "smooth" });
    })
  }
  
  shareProjectAction = () => {
    // GTM
    const { project, full } = this.props;
    const product = this.gtmProductObj();
    gtmFamily().ctaOfConfiguration({
      name: "inviter un proche",
      category: "espace famille",
      subcategory: "popup inviter un proche",
      type: "picto",
      from: "vignette projet"
    }, 
    { 
      template: "espace famille", 
      subtemplate: full ? "projet" : "liste projets" 
    },
    {
      product: product
    })
    // end GTM
    this.setState({displayInvite:true})
  }

  askQuoteAction = () => {
    // GTM
    const { project, full, fromCompare } = this.props;
    let from = "vignette projet"
    let subtemplate = "liste projets"
    if (fromCompare) {
      from = "comparaison projet"
      subtemplate = "comparateur"
    } else if (full) {
      from = "projet"
      subtemplate = "projet"
    }
    const product = this.gtmProductObj();
  
    gtmFamily().ctaOfConfiguration({
      name: "demander un devis",
      category: "espace famille",
      subcategory: "popup demander un devis",
      type: "cta",
      from: from
    }, 
    { 
      template: "espace famille", 
      subtemplate: subtemplate 
    },
    {
      product: product
    })
    // end GTM
    this.setState({askQuote:true})
  }

  addComment = () => {
    const {project,familyspaceActions,sUser} = this.props;
    let myDate = new Date()
    const offset = Math.abs(new Date().getTimezoneOffset());
    myDate.setMinutes(myDate.getMinutes()+offset)
    familyspaceActions.addComment({projectId: project.id, tstamp: Math.round(myDate/1000) ,name: sUser.name , userId: sUser.id, comment: {text:this.state.commentEntered}, readBy: JSON.stringify([sUser])}).then(response => {
      if (this.props.familyService.socket){
        this.props.familyService.socket.emit('chat', {...{type: 'commentActivities', room: `familyspace-${this.props.familyService.familySpace.id}`, user: this.props.sUser}, ...{id: response.id, read_by: response.read_by}});
      }
    });
    this.setState({commentEntered:""});
  }

  keyPress = e => {
    if(e.keyCode === 13){ this.addComment()}
  }

  markAllAsRead = () => {
   /* Objective: making all project comments as read when the user open the comments list, by adding the connected user to read_by array of each comment if it doesn't already exists */
   const comments = _.cloneDeep(this.props.project.comments);
   let bChanged = false;
   comments.forEach(comment => {
     const had_read = comment && comment.read_by ? comment.read_by.filter(item => item.id === this.props.familyService.user.id): [];
     if (!had_read || had_read.length === 0){
       bChanged = true;
        comment.read_by.push(this.props.familyService.user)
     }
   })
    if (bChanged){
      this.props.familyspaceActions.updateComments(comments);
    }
  }

  creerVariante = ()=>{
    this.setState({changeName:true,variante:true});
  }

  render () {
    const {changeName, variante} = this.state;
    const { user, full, project } = this.props;
    const listActions = this.getlistActions(full);
    const {  displayInvite, displayComment, commentEntered, askQuote, fullPageQuote,errorQuote } = this.state;
    const comments = this.props.project.comments;
    const price = project && project.price ? project.price : "";
    const quoteInfos = project && this.getQuoteInfos(project,project.config);
    if(!this.state.displayInvite){
      document.addEventListener('mousedown', this.handleClickOutsideDisplayInvite);
    }
    const { mainColor, fontColor } = this.props.adminConfig.config
    return (
      <div>
      {this.props.fromCompare && <div className="ButtonFromCompare">
      {quoteInfos && <LeftOvalAction 
                        colorConfig={{mainColor, fontColor}} 
                        text={quoteInfos.text} 
                        disabled={quoteInfos.disabled}
                        addClass="fsp-button"
                        action={()=>quoteInfos.action()}/> }

      {askQuote && <SaskQuote 
                    close={()=>this.setState({askQuote:false})} 
                    price={price} 
                    config={project && project.config} 
                    user={user}
                    project={project}
                    colorConfig={{mainColor, fontColor}}
                    informSent= {()=>this.setState({sent:true})} />}
      </div>}
      {!this.props.fromCompare && <div className="SprojectFooter">
            <hr/>
            <Row className="d-flex justify-content-between">
              {<MediaQuery minWidth={576}>
                <Col xs={full ? "3" : "4"} sm={full ? "3" : "4"}>
                  <Row>
                    {listActions.map((action,index) => {
                      return (
                        <Col key={index}
                          xs={4} sm={4}
                          className={action.addClass ? action.addClass : index === listActions.length -1 ? "last" : ""}
                          style={full ? {"padding":"15px 0", "height":"100%", /*"minWidth":"50px"*/} : {}}>
                          {action.component ? action.component :
                          <div onClick={()=> action.disabled 
                            ? null 
                            : action.action()} style={{cursor: action.disabled ? 'not-allowed' : 'pointer', opacity: action.disabled ? 0.5 : 1,}}
                          >
                            {action.icon && <img  src={action.icon} alt={action.text} />}
                            <span>{action.text} </span>
                          </div>
                          }
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              </MediaQuery>}

              {<MediaQuery maxWidth={576}>
              <Col xs={full ? "5" : "5"} sm={full ? "5" : "5"}>
                  <Row>
                    {listActions.map((action,index) => {
                      return (
                        <Col key={index}
                          xs={4} sm={4}
                          className={action.addClass ? action.addClass : index === listActions.length -1 ? "last" : ""}
                          style={full ? {"padding":"15px 0", "height":"100%", /*"minWidth":"50px"*/} : {}}>
                          {action.component ? action.component :
                          <div onClick={()=> action.disabled 
                            ? null 
                            : action.action()} style={{cursor: action.disabled ? 'not-allowed' : 'pointer', opacity: action.disabled ? 0.5 : 1,}}
                          >
                            {action.icon && <img  src={action.icon} alt={action.text} />}
                            <span>{action.text} </span>
                          </div>
                          }
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              </MediaQuery>}
              
              {/* {<MediaQuery minWidth={576}> */}
              <Col xs={full ? "7" : "7"} sm={full ? "7" : "7"}>
                {/* !full && */ !project.archived && <SprojectHeaderActions 
                  full= {full}
                  project= {project} {...this.props} 
                  creerVariante={ ()=> this.creerVariante() } 
                  shareProjectAction={ ()=> this.shareProjectAction() }
                  archive={ () => {this.props.archiveFilter()} }
                  />}
              </Col>
              {/* </MediaQuery>} */}
            </Row>
            {askQuote && <SaskQuote 
                          close={()=>this.setState({askQuote:false})} 
                          price={price} 
                          config={project && project.config} 
                          user={user}
                          project={project} 
                          colorConfig={{mainColor, fontColor}}
                          informSent= {()=>this.setState({sent:true})} />}
            {displayInvite && <Sinvite project={project} close={()=>this.setState({displayInvite:false})}/>}

            {displayComment &&
              <div className="CommentsContainer fade-in">
                <div className={comments.length===0 ? `EnterComment fullRadius` : `EnterComment topRadius`} >
                  <img  src={comments_input} alt="Commentaires"/>
                  <input type="text" value={commentEntered} placeholder="Ajouter un commentaire..."
                        onKeyDown={(e)=>this.keyPress(e)}
                        onChange={(e)=>this.setState({commentEntered:e.target.value})}/>
                  {commentEntered && <i className="icon material-icons" onClick={()=>this.addComment()}>check</i>}
                </div>
                {comments && comments.length>0 ? <ScommentsBlock comments={comments} colorConfig={{mainColor, fontColor}} /> : null}
              </div>}

              {full && <div className="EndScroll" ref={(el) => { this.eventsEnd = el; }}/>}


          </div>}
          <Modal isOpen={!!fullPageQuote}  onRequestClose={() => this.setState({fullPageQuote: undefined})}>
                  <iframe width="100%" className="iFrameDoc-modal" title="documents" height="100%"  src={fullPageQuote}/>
          </Modal>
          <Modal isOpen={errorQuote}  overlayClassName="iFrameDoc-overlay-error" 
            onRequestClose={() => this.setState({errorQuote: false})}> 
            <div class="Error">Il n'y a pas de devis pour ce projet</div>
          </Modal>
          {changeName && <SchangeName colorConfig={{mainColor, fontColor}} close={()=>this.setState({changeName:false})} project= {project} variante={variante}/>}
        </div>
      )
  }
}

export const SprojectFooter = connect(
  state => ({
    user: state.user,
    sUser: state.familyService.user,
    familyService: state.familyService,
    adminConfig: state.adminConfig
  }),
  dispatch => ({
    familyspaceActions: bindActionCreators(familyspaceActions, dispatch)
  })
)(SprojectFooterComponent);

