import React, { Component } from "react";
import { connect } from "react-redux";
import {getDisplayPrice} from "../utils/display";
import DelIcon from "../../../images/space/x-circle_1.svg";
import history from "../../../history";
import ThumbUp from "@material-ui/icons/ThumbUp";
import {ThumbDown} from "@material-ui/icons";
import {SprojectFooter} from "../SprojectFooter";
import {getPriceInfos, isFamilyConfigProject} from "../utils/utils";
import {FpriceList} from "../../family/components/FpriceList";
export class SmobileCompareComponent extends Component {
  state = {
    projects:[],
    ids:[],
  };
  arrayEquals(a, b) {
    return (a.length === b.length) && a.every(function(element, index) {
      return element === b[index];
    });
  }
  componentDidMount() {
    let projects = []
    this.props.projects.map(project=> {
      let pro = this.props.familyService.projects.find(proj => proj.id === project);
      //  if (pro && pro.archived === this.props.archived ) {
      projects.push(pro)
      //}
    })
    this.setState({projects: projects,ids: this.props.projects})
  }
  componentDidUpdate(){
    if(!this.arrayEquals(this.props.projects , this.state.ids)){
      let projects = []
      this.props.projects.map(project=> {
        let pro = this.props.familyService.projects.find(proj => proj.id === project);
        //  if (pro && pro.archived === this.props.archived ) {
        projects.push(pro)
        //}
      })
      this.setState({projects: projects,ids: this.props.projects})
    }
  }
  render() {
    const {projects} = this.state;
    let nbComp = projects.length
    let maxComp = 3
    let i = [0,1,2]
    return(
      <table className={"ScompareTable"}>
        <thead>
        <tr>
          <th colSpan={maxComp}>
            <table className={"SheaderTable"} >
              <tbody>
              <tr className="projectTitle">
                {i.map(id => this.renderElement('title',projects, id, nbComp) )}
              </tr>
              <tr className="img">
                {i.map(id => this.renderElement(id === nbComp ? 'addBtn' : 'image',projects, id, nbComp) )}
              </tr>
              <tr className="devisBtn">
                {i.map(id => this.renderElement('devis',projects, id, nbComp) )}
              </tr>
              <tr className="price">
                {i.map(id => this.renderElement('price',projects, id, nbComp) )}
              </tr>
              </tbody>
            </table>
          </th>
        </tr>
        </thead>
        <tbody>
        {nbComp> 0 && this.renderHeader('like')}
        {nbComp> 0 && <tr className="like">
          {i.map(id => this.renderElement('like', projects, id, nbComp))}
        </tr>}
        {nbComp> 0 && this.renderHeader('dislike')}
        {nbComp> 0 && <tr className="like">
          {i.map(id => this.renderElement('dislike', projects, id, nbComp))}
        </tr>}
        {nbComp> 0 && this.renderHeader('details')}
        {nbComp> 0 && <tr className="detailsList">
          {i.map(id => this.renderElement('details', projects, id, nbComp))}
        </tr>}
        {nbComp> 0 && this.renderHeader('coords')}
        <tr className="coords">
          {i.map(id => this.renderElement('coords',projects, id, nbComp) )}
        </tr>
        </tbody>
      </table>
    )
  }
  renderElement(elt,projects,id, n) {
    let border = id===1? "borderAround" :""
    if(elt==='addBtn' && n === id){
      return <td className={`addBtn ${border}`} onClick={()=>this.props.open()} style={{maxWidth:0}}>
        <div className="addPlus">+</div>
        <div className="addText">Ajouter des monuments à comparer</div>
      </td>
    }
    if(id >= n){
      return <td key={id} className={" " + border}>
        <div style={{visibility:"hidden"}}>-</div>
      </td>
    }
    let project = projects[id]
    if(elt==='title'){
      return (
        <td key={id} className={"w33 " + border}>
          <div className="titleContainer">
            <div className="projectTitle">
              {project.name ? project.name : "Nom du projet"}
            </div>
            <img className="delCompProject"
                 src={DelIcon}
                 alt="delete"
                 onClick={() => this.props.delProject(project.id)}/>
          </div>
        </td>
      )
    }
    if(elt==='image'){
      return <td className={border}>
        {project.configImage &&
        <img style={{cursor: 'pointer'}}
             className="monumentImg compare"
             src={project.configImage} alt="Monument"
             onClick={() => history.push(`/famille/${this.props.user.identity.organization.reference}/familyspace/project/${project.id}`)}/>}
      </td>
    }
    if(elt==='devis'){
      return <td key={id} className={border}>
        <SprojectFooter fromCompare={true}
                        project={project}
                        config={project.config}
                        docs={project && project.docs}/>
      </td>
    }
    if(elt==='price'){
      return <td key={id} className={border}>
        {getDisplayPrice(project)}
      </td>
    }
    if(elt==='like'){
      const likes = project && project.likes ? project.likes.length: 0;
      return <td key={id} className={border}>
        {likes}
      </td>
    }
    if(elt==='dislike'){
      const dislikes = project && project.dislikes ? project.dislikes.length: 0;
      return <td key={id} className={border}>
        {dislikes}
      </td>
    }
    if(elt==='details'){
      const priceInfos = getPriceInfos(project,this.props.familyService);

      return <FpriceList current={project && project.config} user={this.props.user}
                         notFamily= {!isFamilyConfigProject(project)} priceInfos={priceInfos}
                         fromSpace={false} fromCompare={true} />
    }
    if(elt==='coords'){
      const infoClient = project && this.props.familyService.orgsInfos.find(info=>info.clientId=== project.client);
      return <td key={id} className={border}>
        { infoClient && <div className="clientInfo">
          <img className="compareClientInfoLogo" src={infoClient.logo} alt={infoClient.name}/>
          <span className="compareClientInfoName">{infoClient.name}</span>
          <p className="compareClientInfoAddress"><span className="street">{`${infoClient.street}`}</span><span className="postalncity">{`${infoClient.postal} ${infoClient.city}`}</span>
            <span className="compareClientInfoPhone">Tél {infoClient.phone.replaceAll(' ','.')}</span>
          </p>
        </div>}
      </td>
    }
  }
  renderHeader = (type) => {
    let hidden = {visibility:"hidden"}
    if (type === 'like') return(
      <tr className="lineHeader">
        <td ><div style={hidden}>-</div></td>
        <td>
          <div className="centerHeader">
            <ThumbUp/>
            <div>
              J'aime
            </div>
          </div>
        </td>
        <td ><div style={hidden}>-</div></td>
      </tr>
    )
    else if(type === 'dislike') return(
      <tr className="lineHeader">
        <td ><div style={hidden}>-</div></td>
        <td>
          <div className="centerHeader">
            <ThumbDown/>
            <div>
              Je n'aime pas
            </div>
          </div>
        </td>
        <td ><div style={hidden}>-</div></td>
      </tr>
    )
    else if(type === 'coords') return(
      <tr className="lineHeader">
        <td ><div style={hidden}>-</div></td>
        <td>
          Coordonnées du projet
        </td>
        <td ><div style={hidden}>-</div></td>
      </tr>
    )
    else return(
        <tr className="lineHeader">
          <td ><div style={hidden}>-</div></td>
          <td>
            Détails du projet
          </td>
          <td ><div style={hidden}>-</div></td>
        </tr>
      )
  }
}
export const SmobileCompare = connect(
  (state) => ({
    familyService:state.familyService,
    user:state.user,
  }),
)(SmobileCompareComponent);
