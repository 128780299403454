const familyConfigSteps = ['fgranit', 'fgravure', 'fmotif', 'faccessoire', 'ffinalisation'];

const engravingKinds = {
  'Sablées': 'Sablées',
  'Gravées Main': 'Gravées Main'
};

const engravingColors = {
  '#ffffff': 'Peintes en Blanc',
  '#ffdf46': 'Dorées à la feuille',
  '#dcdcdc': 'Peintes en Argenté',
  '#000000': 'Peintes en Noir'
};
const monumentCategory = {"PLT":"Placage","SEM":"Semelle","ACC":"Accessory"};

const familyConfigCategories = [
  {
    label: "Incontournables",
    token: "bced104d5aa25c0d9f2a9178285f4dba",
    categoryType: "600-699"
  },
  {
    label: "Contemporains",
    token: "08f6a4ff933b8a57ebbd46ed0e0b3226",
    categoryType: "400-499"
  },
  {
    label: "Plats",
    token: "9036948e43403a21badcc69b15ae0bd7",
    categoryType: "500-599"
  },
  {
    label: "Religieux",
    token: "fc7495632fc0eac331e5f859b5fa492e",
    categoryType: "800-899"
  },
  {
    label: "Doubles, Enfeux et Chapelles",
    token: "9e05997eb0d35bbeccf398785604122c",
    categoryType: "700-799"
  }
]
const FiltersBurial = [
  { label: "Tous", type: "", className: "", filter: "all"}
]
familyConfigCategories.forEach(category => {
  FiltersBurial.push({ label: category.label, categoryType: category.categoryType, filter: category.token, category: true })
})


const filterLayouts = [{size:'80,180',name:'B'},{size:'100,200',name:'A'},
                       {size:'100,230',name:'Y'},{size:'100,240',name:'E'},{size:'100,250',name:'F'}];

// CommonJS export pour que Webpack y ait accès également
module.exports = {
  FiltersBurial: FiltersBurial,
  engravingKinds: engravingKinds,
  engravingColors: engravingColors,
  filterLayouts:filterLayouts,
  monumentCategory:monumentCategory,
  familyConfigSteps,
};
