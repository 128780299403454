import React from 'react';
import { connect } from "react-redux";
import { Fquote } from '../Fquote';
import FquoteHelp from './FquoteHelp';
import { Modal } from '../../../components/Modal';
import { isSmartphone } from '../../../services/domFunctions';
import glass from '../../../images/Groupe_396.svg';
import { FpriceList,prepareData } from './FpriceList';
import { getDesignTypeConfiguration } from '../../../services/utils.service';

class FdetailInfosBComponent extends React.Component {
  state = {
    open: true,
    openCss: true,
    showBigPicture: false,
  };



  render () {
    const { isoview, user, current, close, showQuoteAsk } = this.props;
    const { open, openCss, showBigPicture } = this.state;
    const endPath = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1);
    const data = current &&  prepareData(current,user);
    const config = current ? current.configuration : false;
    const designType = getDesignTypeConfiguration(config);
    const {mainColor} = this.props.adminConfig.config
    return (
      <div className={`FdetailInfosB ${designType==='A' && 'hidden'}`}>
        <div id="smallDetailsPop" className={`FbigDetailB ${openCss ? 'FbigDetailB-open' : 'FbigDetailB-close'}  ${showQuoteAsk && 'quoteAskBtn'}`}>
        <div className="closePanel" style={{"--f-main-color":mainColor}} onClick={()=> close() }><i className="icon material-icons">close</i></div>
          {this.props.current && <FpriceList current={current} user={user} from="FbigDetailB" />}

          {current && <Fquote config={current} user={user} showAskForm={showQuoteAsk} noForm onClose={() => this.setState({openCss: false}, () => setTimeout(() => this.setState({open: false}), 480))} />}

          {current && <FquoteHelp color={mainColor} />}
        </div>

        <Modal
          isOpen={showBigPicture}
          onRequestClose={() => this.setState({showBigPicture: false})}
          className="big-picture-header-modal"
          overlayClassName="big-picture-header-modal-overlay"
        >
          {isoview.loading ?
            <p className="Loading">Regénération du visuel en cours</p>
            :
            <img className="monumentImg" src={isoview.current} alt="Monument"/>
          }
        </Modal>
      </div>
    );
  };
};

export const FdetailInfosB = connect(
  (state) => ({
    current: state.configurator.current,
    isoview: state.isoView,
    user: state.user,
    adminConfig: state.adminConfig
  })
)(FdetailInfosBComponent);
