import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import * as uiActions from '../../../actions/uiActions';
import {isQuote} from '../../../services/restrictedConfiguration';

import {Fabric, typeAccessory, defaultOptions} from '../components/Fabric';
import {displayMonumentTopConfigImage,displayAcccessoryConfigImage} from '../../../services/image.fct.service';
import * as _ from 'lodash';
import {fabric} from 'fabric';

const loadingImage = require('./../../../images/loader@4x.gif');

const accessoryDefaults = Object.assign({}, defaultOptions, {
  lockScalingFlip: true,
  lockScalingX: true,
  lockScalingY: true,
  lockUniScaling: true,
  originX: 'center',
  originY: 'center',
  selectable: true,
});

class AccessoryFabricComponent extends Fabric {

  itemsProperties = {
    accessories: {
      add: this.addAccessoriesToCanvas.bind(this),
      update: this.updateAccessoryProperties.bind(this),
    }
  };

  actionBarProps() {
    return {
      onChangeAccessoryGranite: this.onChangeAccessoryGranite,
    };
  }

  footerProps() {
    return {
      accessories: this.props.accessories,
      onChangeAccessoryGranite: this.onChangeAccessoryGranite,
    }
  }

  renderSpecificComponents() {
    return (
      <div/>
    )
  }

  hasBackgroundChanged(nextProps) {
    if (this.props.drawing !== nextProps.drawing) {
      // L'image de fond a changé
      this.updateBackgroundImage(nextProps, () => {
        // Il faut mettre à jour chaque accessoire parce qu'ils ont des propriétés spécifiques au drawing
        this.updateAccessories(nextProps, this.getItemsFromProps(nextProps));
      });
      return true;
    }
    return false;
  }

  getBackgroundImage({configuration, drawing}) {
    if (configuration.monument.category==='ACC'){
      return "";
    }else{
      return displayMonumentTopConfigImage(configuration,"large",drawing);
    }
  }

  addAllItemsToCanvas(props) {
    this.addAccessoriesToCanvas(props, this.getItemsFromProps(props));
  }

  getItemsFromProps({configuration}) {
    return _.filter(configuration.accessories.filter(accessory => !accessory.required), 'id');
  }

  addAccessoriesToCanvas(props, accessoriesToAdd) {
    const canvasWidth = this.canvas.width;
    const canvasHeight = this.canvas.height;
    _.each(accessoriesToAdd, accessory => {
      const imageObj = new fabric.Image(null, accessoryDefaults);
      imageObj.set({
        id: accessory.id,
        reference: accessory.reference,
        type: typeAccessory,
        left: accessory.position.fabricJS.x * canvasWidth,
        top: accessory.position.fabricJS.y * canvasHeight,
        angle: accessory.position.rotation,
      });
      this.updateAccessoryProperties(props, accessory, imageObj);
      this.canvas.add(imageObj);
    });
  }

  updateAccessories(props, accessories) {
    _.forEach(accessories, (accessory) => {
      const object = _.find(this.canvas.getObjects(), {id: accessory.id});
      if (object) {
        this.updateAccessoryProperties(props, accessory, object);
      }
    });
  }

  updateAccessoryProperties({configuration, drawing}, accessory, object) {
    if (drawing) {
      object.set({
        shadow: new fabric.Shadow('rgba(0,0,0,0.3) 0px 4px 16px')
      });
    } else {
      object.set({
        shadow: new fabric.Shadow('rgba(0,0,0,0.6) 0px 0px 64px')
      });
    }
    this.canvas.renderAll();

    const src = displayAcccessoryConfigImage(configuration,accessory,accessory.granite ||configuration.monument.graniteMain,true,true,"large")
    // imgs.accessory.top.large(accessory.reference, accessory.granite ||
    //   configuration.monument.graniteMain);
    if (src !== object.getSrc()) {
      // On affiche une icône de chargement qui est préchargé dans le cache du navigateur
      setImage.bind(this)(loadingImage);
      setTimeout(() => {
        // Puis on positionne la vrai image
        setImage.bind(this)(refreshImage(src, accessory));
      },isQuote(configuration)? 700 : 0)
    }

    function setImage(_src) {   
      object.setSrc(_src, () => {
        object.setCoords();
        if (this.canvas){ 
        this.canvas.renderAll();
        this.updateActionBarPosition();
        }
      });
    }

    function refreshImage(imgURL, accessory) {
      var objectURL = new URL(imgURL);
      return objectURL.href;
    }
  };

  onChangeAccessoryGranite = ()=> {
    /**
     *
     * Il y a deux contraintes principales à prendre en compte :
     *
     *  - Tous les accessoires d'une même référence doivent
     *    avoir le même granit.
     *
     *  - On ne peut éditer qu'une seule référence d'accessoire à la fois puisqu'on
     *    fait le rendu 3D de l'accessoire dans la modale de choix de granite,
     *    dans les suggestions.
     *
     * On ne peut donc proposer la modification du granit que pour une seule
     * référence d'accessoire à la fois, et considérer le granit du premier comme
     * granit de tous les accessoires selectionnés.
     *
     */

    const selectedAccessory = _.find(
      this.props.configuration.accessories.filter(accessory => !accessory.required),
      {id: this.state.selectedObjects[0].id})
    this.props.uiActions.chooseGraniteOfAccessories({
      id: selectedAccessory.id,
      reference: selectedAccessory.reference,
      currentGranite: selectedAccessory.granite || this.props.configuration.monument.graniteMain
    })
  }
}

export const AccessoryFabric = connect((state) => ({
  undoCounter:state.configurator.undoCounter,
    configuration: state.configurator.current.configuration,
    accessories: state.configurator.current.accessories,
    className: 'AccessoriesZone',
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),

    uiActions: bindActionCreators(uiActions, dispatch),
  })
)(AccessoryFabricComponent);
